import React from "react";

const Card = ({ color, children }) => {
  const props = {};

  if (color) {
    props.style = {
      backgroundColor: color,
    };
  }

  return (
    <div className={"white-card"} {...props}>
      {children}
    </div>
  );
};

export default Card;
