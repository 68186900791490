import React, { useState } from "react";
import { makeStyles, useMediaQuery, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import Base from "../../component/Layout/Base";
import { slotStatus, selectOfferToSlot, cancelOffer, sendCardsToWhell, increaseQuantity, decreaseQuantity, setLogo, setQuantity} from "../../redux/actions/campaignsActions"
import WheelIMG from "../../../src/assets/images/prize-wheel.png";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import close from "../../../src/assets/icons/closeMini.svg";
import upload from "../../../src/assets/icons/uploadLogo.svg"
import { TOAST } from "../../redux/actions/actions-types";
import { useDispatch, useSelector } from "react-redux";
import GoBackButton from "../../component/Layout/GoBackButton";
import Slider from "react-slick";
import Cleave from 'cleave.js/react';

const imgURL = `${process.env.REACT_APP_IMG_URL}/card-photo/`;
const logoURL =`${process.env.REACT_APP_IMG_URL}`;

function SampleNextArrow(props) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={matches ? {top: "312px"} : null}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={matches ? {top: "312px"} : null}
      onClick={onClick}
    />
  );
}

function SelectOffer(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [offersType, setOffersType] = useState(1);
  const [offerIndex, setOfferIndex] = useState('');
  const matches = useMediaQuery(`(max-width:500px)`);
  const useStyles = makeStyles((theme) => ({
    root: {},
    headerBlock: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      maxWidth: "500px",
      margin: "0 auto 25px auto",
    },
    headerChange: {
      width: "100%",
      borderBottom: "2px solid #9D9D9D",
      paddingBottom: "12px",
      color: "#9D9D9D",
      fontSize: "20px",
      cursor: "pointer",
    },
    createNew: {
      fontSize: "13px",
      height: "30px",
      textTransform: "capitalize",
      justifySelf: "center",
      width: "120px",
    },
    selectBTN: {
      boxSizing: "border-box",
      width: "185px",
      height: "55px",
      color: "#fff",
      background: "#000",
      borderRadius: "35px",
      fontSize: "29px",
      margin: "36px 0 15px",
      textTransform: "capitalize",
    },
    selectBTNM: {
      boxSizing: "border-box",
      width: "150px",
      height: "50px",
      color: "#fff",
      background: "#000",
      borderRadius: "25px",
      fontSize: "20px",
      margin: "25px 0 15px",
      textTransform: "capitalize",
    },
    cancelBTN : {
      fontSize: "29px",
      textTransform: "capitalize",
      width: "185px",
      borderRadius: "35px",
      boxSizing: "border-box",
      height: "55px",
      marginBottom: "50px",
    },
    cancelBTNM: {
      boxSizing: "border-box",
      width: "150px",
      height: "50px",
      borderRadius: "25px",
      fontSize: "20px",
      textTransform: "capitalize",
    },
    cardTitle: {
      margin: "0",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "rgba(0, 0, 0, 0.75385)",
      color: "#ffffff",
      fontSize: "18px",
      width: "100%",
      lineHeight: "30px",
    },
    sliderContainer: {
      position: "relative",
      outline: "none",
    },
    slide: {
      display: "grid",
      width: "500px",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridRowGap: "55px",
      margin: "5px 0",
      boxSizing: "border-box",
      minHeight: "265px",
      justifyItems: "center",
    },
    sliderBlock: {
      width: "145px",
      height: "100px",
      borderRadius: "14px",
      boxShadow: "0px 2px 4px 2px rgba(0, 0, 0, 0.5)",
      position: "relative",
      cursor: "pointer",
      '&:hover': {
        border: '3px solid',
      }
    },
    flexCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }
  }));
  const classes = useStyles();  
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentSlide(next),
    nextArrow: <SampleNextArrow style={{color: "red"}}/>,
    prevArrow: <SamplePrevArrow className={classes.cardTitle}/>
  };
  const setOffer = (offer) => {
    props.setOffer(offer);
    props.onClick(false);
  }
  const cancelOffer = () => {
    props.onClick(false);
  }

  let myOffers = [{color: "red", title: "new title"}, {color: "green", title: "offer"}];
  let charity = [{color: "red", title: "new title", quantity: "1/10"},
                {color: "red", title: "new title", quantity: "3/10"},
                {color: "red", title: "offer", quantity: "2/10"},
                {color: "green", title: "new title", quantity: "7/10"},
                {color: "red", title: "new title", quantity: "1/10"},
                {color: "yellow", title: "bigOffer", quantity: "10/10"},
                {color: "grey", title: "new title", quantity: "5/10"},
                {color: "red", title: "new title", quantity: "1/10"},
                {color: "yellow", title: "bigOffer", quantity: "10/10"},
                {color: "grey", title: "new title", quantity: "5/10"}];

  let big = [{color: "green", title: "new title", quantity: "7/10"},
                {color: "yellow", title: "new title", quantity: "5/10"},
                {color: "green", title: "Playstation", quantity: "2/10"},
                {color: "green", title: "50% off", quantity: "2/10"},
                {color: "red", title: "new title", quantity: "10/10"},
                {color: "black", title: "bigOffer", quantity: "10/10"},
                {color: "grey", title: "Pizza", quantity: "5/10"},
                {color: "red", title: "new title", quantity: "1/10"},
                {color: "yellow", title: "bigOffer", quantity: "10/10"},
                {color: "grey", title: "new title", quantity: "5/10"},
                {color: "grey", title: "new title", quantity: "5/10"},
                {color: "grey", title: "new title", quantity: "5/10"}];

  let offers,toRender = [];
  switch (offersType) {
    case 1: {
      offers = myOffers;
      break;
    }
    case 2: {
      offers = charity;
      break;
    }
    case 3: {
      offers = big;
      break;
    }
    default:
          break;
  }

  for (let i = 0; i <Math.ceil(offers.length/6); i++) {
    toRender[i] = offers.slice((i*6), (i*6) + 6);
  }

  if (toRender[toRender.length-1].length === 6) {
    toRender.push([]);
    console.log(toRender)
  }
  // if toRender.length
  
  return (
    <div>
      <div className={classes.headerBlock}>
        <span className={classes.headerChange} onClick={()=> setOffersType(1)}  style={offersType === 1 ? {color: "black", borderColor: "black"} : null}>My offers</span>
        <span className={classes.headerChange} onClick={()=> setOffersType(2)} style={offersType === 2 ? {color: "black", borderColor: "black"} : null}>Charity offers</span>
        <span className={classes.headerChange} onClick={()=> setOffersType(3)} style={offersType === 3 ? {color: "black", borderColor: "black"} : null}>Big offers</span>
      </div>
      <Slider {...settings} className="offersSlider">
            {/* <div className={classes.sliderContainer}>
              <img src='' alt="" width="440px" height="279px" className={classes.sliderIMG} style={matches ? {width: "300px", height: "192px"} : null}/>
              <p className={classes.cardTitle}>122554</p>
            </div> */}
        {toRender.map((offers, i) => {
          return (
            <div className={classes.sliderContainer} key={i}>
              <div className={classes.slide}>
                {offers.map((offer, j) => {
                  return(
                    <div className={classes.sliderBlock} style={offerIndex === `${i}${j}` ? {border: "3px solid"} : null} onClick={() => setOfferIndex(`${i}${j}`)}>
                      <p className={classes.cardTitle}>{offer.title}</p>
                    </div>
                  )
                })}
                {offers.length !== 6 ?
                  <div className={`${classes.sliderBlock} ${classes.flexCenter}`} style={{backgroundColor: "#E0E0E0"}}>
                    <AddIcon style={{fontSize: "50px"}}/>
                  </div> : null
                }
              </div>
            </div>
          )
        })}
      </Slider>
      <div className="flex flex-col a-center" style={matches ? {marginBottom: "65px"} : null}>
        <Button variant ="contained" className={matches ? classes.selectBTNM : classes.selectBTN}
          onClick={() => setOffer(props.cards[currentSlide])}>Select</Button>
        <Button className={matches ? classes.cancelBTNM : classes.cancelBTN}
          onClick={() => cancelOffer()}
        >cancel</Button>
      </div>
    </div>
  );
}

function Wheel({slots, setLogo, checkLogo, logoTemp }) {
  const hiddenInputRef = React.createRef();
  const [logo, setWheelLogo] = useState();
  const [img, setImg] = useState('');
  const matches = useMediaQuery(`(max-width:500px)`);
  const useStyles = makeStyles((theme) => ({
    root: {},
    imgContainer: {
      position: "absolute",
    },
    wheel: {
      width: "402px",
    },
    wheelM: {
      width: "402px",
      marginLeft: "-12px",
    },
    wheelImg: {
      display: "block",
      position: "relative",
      boxSizing: "border-box",
      width: "59px",
      height: "42px",
      borderRadius: "9px",
    },
    titleImg: {
      position: "absolute",
      textAlign: "center",
      width: "95%",
      padding: "0px 3px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      margin: "0",
      background: "rgba(0, 0, 0, 0.75385)",
      color: "#ffffff",
      fontSize: "7px",
      boxSizing: "border-box",
    },
    mainContent: {
      position: "relative",
      marginTop: "38px",
      marginBottom: "51px",
    },
    mainContentM: {
      position: "relative",
      marginTop: "0",
      marginBottom: "40px",
    },
    imgHorizontal1: {
      left: "149px",
      top: "186px",
      transform: "rotate(-90deg)",
    },
    imgHorizontal1M: {
      left: "48px",
      top: "186px",
      transform: "rotate(-90deg)",
    },
    imgHorizontal2: {
      right: "152px",
      top: "183px",
      transform: "rotate(90deg)",
    },
    imgHorizontal2M: {
      right: "47px",
      top: "183px",
      transform: "rotate(90deg)",
    },
    imgVertical1: {
      left: "260px",
      top: "75px",
    },
    imgVertical1M: {
      left: "160px",
      top: "73px",
    },
    imgVertical2: {
      left: "262px",
      bottom: "70px",
    },
    imgVertical2M: {
      left: "162px",
      bottom: "70px",
    },
    logoUpload: {
      position: "absolute",
      top: "174px",
      left: "268px",
    },
    logoUploadM: {
      position: "absolute",
      top: "174px",
      left: "167px",
    },
    logo: {
      width: "49px",
      height: "49px",
      cursor: "pointer",
      borderRadius: "50%",
    }
  }));
  const classes = useStyles();

  const handleOnClickInsertImage = () => {
    hiddenInputRef.current.click();
  };

  const handleOnSelectImage = (e) => {
    const image = e.target.files[0];
    setImg(image);
    if (image && image.name.match(/\.(jpg|jpeg|png)$/)) {
      const backgroundImage = URL.createObjectURL(image);
      setWheelLogo(backgroundImage);
      setLogo(image, backgroundImage);
    }
  };

  let wheelLogoImg;
  if (logo) {
    wheelLogoImg = logo;
  } else if (logoTemp) {
    wheelLogoImg = logoTemp;
  } else {
    wheelLogoImg = upload;
  }

  return (
    <div>
      <h1 className="campaign-header">Add up to 4 offers</h1>
      <input type={"file"} style={{ display: "none" }} ref={hiddenInputRef} onChange={handleOnSelectImage} />
        <div className={matches? classes.mainContentM : classes.mainContent}>
          <img src={WheelIMG} alt="" className={matches? classes.wheelM : classes.wheel}/>
          <div className={matches? classes.logoUploadM : classes.logoUpload}>
            {(checkLogo && !logo) ? <img src={`${logoURL}${checkLogo}`} alt="" className={classes.logo} onClick={handleOnClickInsertImage}/>:
            <img src={wheelLogoImg} alt="" className={classes.logo} onClick={handleOnClickInsertImage}/>}
            </div>
          {slots ? <React.Fragment>
            <div className={matches ? `${classes.imgContainer} ${classes.imgVertical1M}` : `${classes.imgContainer} ${classes.imgVertical1}`}>
              <img src={slots[0].cardPhoto ? `${imgURL}${slots[0].cardPhoto}` : null} style={slots[0].cardPhoto ? {display: "block"} : {display: "none"}} alt="" className={classes.wheelImg}/>
              <p className={classes.titleImg}>{slots[0].title}</p>
            </div>
            <div className={matches ? `${classes.imgContainer} ${classes.imgVertical2M}` : `${classes.imgContainer} ${classes.imgVertical2}`}>
              <img src={slots[2].cardPhoto ? `${imgURL}${slots[2].cardPhoto}` : null} style={slots[2].cardPhoto ? {display: "block"} : {display: "none"}} alt="" className={classes.wheelImg}/>
              <p className={classes.titleImg}>{slots[2].title}</p>
            </div>
            <div className={matches ? `${classes.imgContainer} ${classes.imgHorizontal2M}` : `${classes.imgContainer} ${classes.imgHorizontal2}`}>
              <img src={slots[1].cardPhoto ? `${imgURL}${slots[1].cardPhoto}` : null} style={slots[1].cardPhoto ? {display: "block"} : {display: "none"}} alt="" className={classes.wheelImg}/>
              <p className={classes.titleImg}>{slots[1].title}</p>
            </div>
            <div className={matches ? `${classes.imgContainer} ${classes.imgHorizontal1M}` : `${classes.imgContainer} ${classes.imgHorizontal1}`}>
              <img src={slots[3].cardPhoto ? `${imgURL}${slots[3].cardPhoto}` : null} style={slots[3].cardPhoto ? {display: "block"} : {display: "none"}} alt="" className={classes.wheelImg}/>
              <p className={classes.titleImg}>{slots[3].title}</p>
            </div>
          </React.Fragment> : null }
        </div>
    </div>
  );
};

function PrizeWheel() {
  const [selectOffer, setSelectOffer] = useState(false);
  const [currentSlot, setCurrentSlot] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const matches = useMediaQuery(`(max-width:500px)`);
  const slots = useSelector((state) => state.campaigns.slots);

  const useStyles = makeStyles((theme) => ({
    root: {},
    container: {
      textAlign: "center",
      width: "100%",
      marginTop: "10px",
      marginBottom: "80px",
      display: "flex",
      justifyContent: "center",
    },
    wrapper: {
    background: "#fff",
    boxSizing: "border-box",
    width: "375px",
    height: "auto",
    borderRadius: "51px",
    padding: "10px 0 20px 0",
    display: "flex",
    flexDirection: "column",
    },
    buttonsRow: {
      display: "flex",
      justifyContent: "space-around",
    },
    rectangle: {
      boxSizing: "border-box",
      width: "117px",
      height: "72px",
      borderRadius: "9px",
      background: "#E0E0E0",
      marginBottom: "14px",
      padding: "0px",
      // padding: "0px 11px",
    },
    rectangleM: {
      boxSizing: "border-box",
      width: "80px",
      height: "50px",
      borderRadius: "5px",
      background: "#E0E0E0",
      marginBottom: "14px",
      padding: "0px",
      // padding: "0px 11px",
    },
    quantityBlock: {
      height: "52px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

    },
    quantityBTN: {
      padding: "6px 0",
      color: "#000",
    },
    quantity: {
      fontSize: "29px",
    },
    winChanceBlock: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "3px 0px 8px 0px",
    },
    win: {
      margin: "0",
      fontSize: "16px",
    },
    percent: {
      margin: "0",
      fontSize: "21px",
    },
    slotContainer: {
      position: "relative",
      borderRadius: "9px",
      overflow: "hidden",
    },
    slotIMG: {
      width: "117px",
      height: "72px",
      display: "block", 
    },
    slotIMGM: {
      width: "80px",
      height: "50px",
      display: "block", 
    },
    slotTitle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "rgba(0, 0, 0, 0.75385)",
      color: "#ffffff",
      fontSize: "10px",
      width: "100%",
      margin: "0",
    },
    closeBtn: {
      padding: "0px",
      border: "none",
      outline: "0",
      width: "19px",
      height: "19px",
      background: "none",
      position: "absolute",
      right: "-7px",
      top: "-21px",
      cursor: "pointer",
      display: "block",
    },
    column: {
      position: "relative",
    },
    slotIndicator: {
      position: "absolute",
      top: "-40px",
      left: "50%",
      transform: "translate(-50%, 0px)",
      color: "#bdbcbc",
      fontWeight: "bold",
      width: "100%",
    },
    quantityInput: {
      width: "65px",
      border: "none",
      outline: "none",
      background: "none",
      textAlign: "center",
    },
    bottomBtn: {
      textTransform: "none !important",
      background: "linear-gradient(131.04deg, #4B478D 13.29%, #CA2E86 100%) !important",
      fontSize: "20px !important",
      color: "#fff !important",
      padding: "0px 25px !important",
      boxSizing: "border-box !important",
      height: "55px",
      borderRadius: "25px !important",
      width: "170px !important",
      margin: "30px auto 0 auto !important",
    }
  }));
  const classes = useStyles();

  const setStatus = (slot,status) => {
    slotStatus(slot,status);
    setSelectOffer(status);
    setCurrentSlot(slot);
  }

  const setCurrentOffer = (offer) => {
    selectOfferToSlot(currentSlot,offer)
  }

  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };

  const addQuantity = (slot) => {
    increaseQuantity(slot);
  }

  const subQuantity = (slot) => {
    if (slots[slot].quantity > 1) {
      decreaseQuantity(slot);
    }
  }

  const toConfirm = () => {
    if (slots[0].title || slots[1].title || slots[2].title || slots[3].title) {
      if ((slots[0].quantity === 0) || (slots[1].quantity === 0) || (slots[2].quantity === 0) || (slots[3].quantity === 0)) {
        showToast("error", "Error", "Cards quantity cant be 0");
        return
      }
      let confirmData = slots.map((slot => {
        if (!slot.title) {
          return null
        } else {
          return (
            slot = {id: slot.id,
            quantity: slot.quantity}
          )
        }
      }))
      // if (edit) {
      //   setEdit();
      // } 
      sendCardsToWhell(confirmData);
      history.push("/campaign/confirm");
    } else {
      showToast("error", "Error", "Choose Offer First");
    }
  }

  const renderTopBar = () => {
    return <GoBackButton onClick={() => history.goBack()} />;
  };

  return (
    <Base theme={"light"} topBar={renderTopBar}>
      <div className={classes.container}>
        <div className={matches ? classes.wrapper : "campaign-wrapper"}>
          {selectOffer ? <SelectOffer onClick={(value) => setSelectOffer(value)}
                                      // cards={cards}
                                      setOffer={(value) => setCurrentOffer(value)}
                                      cancelOffer={() => cancelOffer(currentSlot)}/>
                                        : <Wheel slots={slots} setLogo={(logo, url) => setLogo(logo, url)}/> }
          <div className={classes.buttonsRow}>
            <div className={classes.column}>
            <span className={classes.slotIndicator} style={currentSlot === 0 ? {color:"#784ab6"} : null}>Slot 1</span>
            <button className={classes.closeBtn} onClick={() => cancelOffer(0)} style={slots[0].title ? {display: "block"} : {display: "none"}}><img src={close} alt=""/></button>
              <Button variant="contained" className={matches? classes.rectangleM : classes.rectangle}
                onClick={() => setStatus(0,true)}>
                {slots[0].title ? <div className={classes.slotContainer}>
                  <img src={`${imgURL}${slots[0].cardPhoto}`} alt="" className={matches? classes.slotIMGM : classes.slotIMG}/>
                  <p className={classes.slotTitle}>{slots[0].title}</p>
                  </div> : <AddIcon />}
              </Button>
              {slots[0].id ? 
              <React.Fragment>
                <div className={`${classes.rectangle} ${classes.quantityBlock}`} style={matches ? {width: "80px"} : null}>
                <IconButton className={classes.quantityBTN} style={matches? {width: "22px"} : null} onClick={() => subQuantity(0)}>
                  <RemoveIcon />
                </IconButton>
                <Cleave type="text" className={`${classes.quantity} ${classes.quantityInput}`} style={{width: matches ? "32px" : null, color : slots[0].quantity === 0 ? "red" : null}} maxLength="4" value={slots[0].quantity} onChange={event => setQuantity(0, Number(event.target.value))} options={{
                      numeralPositiveOnly: true,
                      numericOnly: true,
                  }}/>
                <IconButton className={classes.quantityBTN} style={matches? {width: "22px"} : null} onClick={() => addQuantity(0)}>
                  <AddIcon />
                </IconButton>
                </div> 
                <div className={`${classes.rectangle} ${classes.winChanceBlock}`} style={matches ? {width: "80px", height: "60px"} : null}>
                  <p className={classes.win}>Win %</p>
                  <p className={classes.percent}>{slots[0].id ? `1/${slots[0].winChanceDivider}` : null}</p>
                </div>
              </React.Fragment> : null}  
            </div>
            <div className={classes.column}>
            <span className={classes.slotIndicator} style={currentSlot === 1 ? {color:"#784ab6"} : null}>Slot 2</span>
            <button className={classes.closeBtn} onClick={() => cancelOffer(1)} style={slots[1].title ? {display: "block"} : {display: "none"}}><img src={close} alt=""/></button>
              <Button variant="contained" className={matches? classes.rectangleM : classes.rectangle}
                onClick={() => setStatus(1,true)}>
                {slots[1].title ? <div className={classes.slotContainer}>
                  <img src={`${imgURL}${slots[1].cardPhoto}`} alt="" className={matches? classes.slotIMGM : classes.slotIMG}/>
                  <p className={classes.slotTitle}>{slots[1].title}</p>
                  </div> : <AddIcon />}
              </Button>
              {slots[1].id ? 
              <React.Fragment>
                <div className={`${classes.rectangle} ${classes.quantityBlock}`} style={matches ? {width: "80px"} : null}>
                <IconButton className={classes.quantityBTN} style={matches? {width: "22px"} : null} onClick={() => subQuantity(1)}>
                  <RemoveIcon />
                </IconButton>
                <Cleave type="text" className={`${classes.quantity} ${classes.quantityInput}`} style={{width: matches ? "32px" : null, color : slots[1].quantity === 0 ? "red" : null}} maxLength="4" value={slots[1].quantity} onChange={event => setQuantity(1, Number(event.target.value))} options={{
                      numeralPositiveOnly: true,
                      numericOnly: true,
                  }}/>
                <IconButton className={classes.quantityBTN} style={matches? {width: "22px"} : null} onClick={() => addQuantity(1)}>
                  <AddIcon />
                </IconButton>
                </div> 
                <div className={`${classes.rectangle} ${classes.winChanceBlock}`} style={matches ? {width: "80px", height: "60px"} : null}>
                  <p className={classes.win}>Win %</p>
                  <p className={classes.percent}>{slots[1].id ? `1/${slots[1].winChanceDivider}` : null}</p>
                </div>
              </React.Fragment> : null}  
            </div>
            <div className={classes.column}>
            <span className={classes.slotIndicator} style={currentSlot === 2 ? {color:"#784ab6"} : null}>Slot 3</span>
            <button className={classes.closeBtn} onClick={() => cancelOffer(2)} style={slots[2].title ? {display: "block"} : {display: "none"}}><img src={close} alt=""/></button>
              <Button variant="contained" className={matches? classes.rectangleM : classes.rectangle}
                onClick={() => setStatus(2,true)}>
                {slots[2].title ? <div className={classes.slotContainer}>
                  <img src={`${imgURL}${slots[2].cardPhoto}`} alt="" className={matches? classes.slotIMGM : classes.slotIMG}/>
                  <p className={classes.slotTitle}>{slots[2].title}</p>
                  </div> : <AddIcon />}
              </Button>
              {slots[2].id ? 
              <React.Fragment>
                <div className={`${classes.rectangle} ${classes.quantityBlock}`} style={matches ? {width: "80px"} : null}>
                <IconButton className={classes.quantityBTN} style={matches? {width: "22px"} : null} onClick={() => subQuantity(2)}>
                  <RemoveIcon />
                </IconButton>
                <Cleave type="text" className={`${classes.quantity} ${classes.quantityInput}`} style={{width: matches ? "32px" : null, color : slots[2].quantity === 0 ? "red" : null}} maxLength="4" value={slots[2].quantity} onChange={event => setQuantity(2, Number(event.target.value))} options={{
                      numeralPositiveOnly: true,
                      numericOnly: true,
                  }}/>
                <IconButton className={classes.quantityBTN} style={matches? {width: "22px"} : null}onClick={() => addQuantity(2)}>
                  <AddIcon />
                </IconButton>
                </div> 
                <div className={`${classes.rectangle} ${classes.winChanceBlock}`} style={matches ? {width: "80px", height: "60px"} : null}>
                  <p className={classes.win}>Win %</p>
                  <p className={classes.percent}>{slots[2].id ? `1/${slots[2].winChanceDivider}` : null}</p>
                </div>
              </React.Fragment> : null}  
            </div>
            <div className={classes.column}>
            <span className={classes.slotIndicator} style={currentSlot === 3 ? {color:"#784ab6"} : null}>Slot 4</span>
            <button className={classes.closeBtn} onClick={() => cancelOffer(3)} style={slots[3].title ? {display: "block"} : {display: "none"}}><img src={close} alt=""/></button>
              <Button variant="contained" className={matches? classes.rectangleM : classes.rectangle}
                onClick={() => setStatus(3,true)}>
                {slots[3].title ? <div className={classes.slotContainer}>
                  <img src={`${imgURL}${slots[3].cardPhoto}`} alt="" className={matches? classes.slotIMGM : classes.slotIMG}/>
                  <p className={classes.slotTitle}>{slots[3].title}</p>
                  </div> : <AddIcon />}
              </Button>
              {slots[3].id ? 
              <React.Fragment>
                <div className={`${classes.rectangle} ${classes.quantityBlock}`} style={matches ? {width: "80px"} : null}>
                <IconButton className={classes.quantityBTN} style={matches? {width: "22px"} : null} onClick={() => subQuantity(3)}>
                  <RemoveIcon />
                </IconButton>
                <Cleave type="text" className={`${classes.quantity} ${classes.quantityInput}`} style={{width: matches ? "32px" : null, color : slots[3].quantity === 0 ? "red" : null}} maxLength="4" value={slots[3].quantity} onChange={event => setQuantity(3, Number(event.target.value))} options={{
                      numeralPositiveOnly: true,
                      numericOnly: true,
                  }}/>
                <IconButton className={classes.quantityBTN} style={matches? {width: "22px"} : null} onClick={() => addQuantity(3)}>
                  <AddIcon />
                </IconButton>
                </div> 
                <div className={`${classes.rectangle} ${classes.winChanceBlock}`} style={matches ? {width: "80px", height: "60px"} : null}>
                  <p className={classes.win}>Win %</p>
                  <p className={classes.percent}>{slots[3].id ? `1/${slots[3].winChanceDivider}` : null}</p>
                </div>
              </React.Fragment> : null}  
            </div>
          </div>
          {(slots[0].id || slots[1].id || slots[2].id || slots[3].id) ? null : 
            <p style={{fontSize: "21px"}}>Select a slot and add a prize to the wheel</p> }
          <Button className={matches ? classes.bottomBtn : "campaign-bottomBTN"} variant="contained"
            onClick={() => toConfirm()}
          >Done</Button>
        </div>
      </div>
    </Base>
  );
}

export default PrizeWheel;
