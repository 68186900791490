import React, { Suspense } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import 'slick-carousel/slick/slick.scss';
import './App.scss';
import 'slick-carousel/slick/slick-theme.scss';
import { store } from './redux/redusers/rootReducer';
import { checkLoginAction } from './redux/actions/authActions';
import history from './helpers/history';
import Notifications from './component/Toast';
import Base from './component/Layout/Base';
// Layout for this app
import Login from './views/Login/index';
import SignUp from './views/SignUp/index';
import Forgotpassword from './views/ForgotPassword/index';
import Passwordrecovery from './views/PasswordRecovery/index';
import Home from './views/Home/index';
import GiftCard from './views/GiftCard/index';
import DropCard from './views/DropCard/index';
import StandardGift from './views/DropCard/StandardGift/StandardGift';
import GoldGift from './views/DropCard/GoldGift/GoldGift';
import Campaigns from './views/Campaigns/index';
import PendingApprovals from './views/PendingApprovals/index';
import Settings from './views/Settings/index';
import Logout from './views/Logout/Logout';
import PrizeWheel from './views/Campaigns/wheel/prize-wheel';
import NewCampaign from './views/Campaigns/new/new-campaign';
import CampaignType from './views/Campaigns/new/campaign-type';
import ChooseLocation from './views/Campaigns/new/choose-location';
import ConfirmWheel from './views/Campaigns/new/campaign-confirm';
import EditWheel from './views/Campaigns/wheel/edit-wheel';
import Offers from './views/GiftCard/offers-page';
import Offer from './views/GiftCard/offer-page';
import EditOffer2 from './views/GiftCard/edit-offer';
import NoOffer from './views/DropCard/StandardGift/NoOffer';
import EditOffer from './views/EditOffer/index';
import locationList from './views/Locations/locationList';
import Transactions from './views/Transactions';
import LocationFrom from './views/Locations/locationForm';
import TiersList from './views/Tiers/tiersList';
import TiersForm from './views/Tiers/tiersForm';
import VerifyEmail from './views/VerifyEmail';
import colorWheel from './views/SpinForStuff/colorWheel';
import QRcode from './views/SpinForStuff/QRcode';
import newWheel from './views/SpinForStuff/newWheel';
import analytics from './views/Campaigns/Details/campaignAnalytics';
import chest from './views/Campaigns/chest/timed-chest';
import createURL from './views/Campaigns/new/newURL';

class App extends React.Component {
  state = {
    canAccess: true,
  };
  componentWillMount() {
    let allowedUrl = [
      '/',
      '/signup',
      '/recovery-password',
      '/forgot-password',
      '/verify-email',
    ];
    if (localStorage.getItem('access-token')) {
      store.dispatch(checkLoginAction());
    } else if (!allowedUrl.includes(window.location.pathname)) {
      window.location.href = '/';
    }
  }
  render() {
    return (
      <div className='main'>
        <Notifications />
        <Router history={history}>
          <Suspense
            fallback={
              <Base theme={'light'}>
                <LinearProgress />
              </Base>
            }
          >
            <Switch>
              <Route
                path='/'
                exact
                component={Login}
              />
              <Route
                path='/signup'
                component={SignUp}
              />
              <Route
                path='/recovery-password'
                component={Passwordrecovery}
              />
              <Route
                path='/forgot-password'
                component={Forgotpassword}
              />
              <Route
                path='/logout'
                component={Logout}
              />
              <Route
                exact
                path='/locations'
                component={locationList}
              />
              <Route
                exact
                path='/locations/add'
                component={LocationFrom}
              />
              <Route
                exact
                path='/locations/edit/:id'
                component={LocationFrom}
              />
              <Route
                exact
                path='/tiers'
                component={TiersList}
              />
              <Route
                exact
                path='/tiers/add'
                component={TiersForm}
              />
              <Route
                exact
                path='/tiers/edit/:id'
                component={TiersForm}
              />
              <Route
                exact
                path='/profile-settings/:pricing?'
                component={Settings}
              />
              <Route
                exact
                path='/approvals'
                component={PendingApprovals}
              />
              <Route
                exact
                path='/campaign'
                component={Campaigns}
              />
              <Route
                exact
                path='/drop-card/gold-gift'
                component={GoldGift}
              />
              <Route
                exact
                path='/drop-card/no-offer'
                component={NoOffer}
              />
              <Route
                exact
                path='/invoices'
                component={Transactions}
              />
              <Route
                exact
                path='/verify-email/:key?'
                component={VerifyEmail}
              />
              <Route
                exact
                path='/campaign/new'
                component={NewCampaign}
              />
              <Route
                exact
                path='/campaign/type'
                component={CampaignType}
              />
              <Route
                exact
                path='/campaign/location'
                component={ChooseLocation}
              />
              <Route
                exact
                path='/campaign/confirm'
                component={ConfirmWheel}
              />
              <Route
                exact
                path='/campaign/edit'
                component={EditWheel}
              />
              <Route
                exact
                path='/offers'
                component={Offers}
              />
              <Route
                exact
                path='/offer'
                component={Offer}
              />
              <Route
                exact
                path='/offer/edit'
                component={EditOffer2}
              />
              <Route
                exact
                path='/drop-card/standard-gift'
                component={StandardGift}
              />
              <Route
                exact
                path='/drop-card'
                component={DropCard}
              />
              <Route
                exact
                path='/campaigns/edit-offer-details/:cardId'
                component={EditOffer}
              />
              <Route
                exact
                path='/gift-card'
                component={GiftCard}
              />
              <Route
                exact
                path='/dashboard'
                component={Home}
              />
              <Route
                exact
                path='/campaign/prize-wheel'
                component={PrizeWheel}
              />
              <Route
                exact
                path='/campaign/customize-wheel'
                component={colorWheel}
              />
              <Route
                exact
                path='/campaign/QRcode'
                component={QRcode}
              />
              <Route
                exact
                path='/campaign/newWheel'
                component={newWheel}
              />
              <Route
                exact
                path='/analytics'
                component={analytics}
              />
              <Route
                exact
                path='/campaign/timed-chest'
                component={chest}
              />
              <Route
                exact
                path='/campaign/timed-chest/edit'
                component={chest}
              />
              <Route
                exact
                path='/campaign/URL'
                component={createURL}
              />
              <Route
                path='*'
                render={() => <Redirect to='/dashboard' />}
              />
            </Switch>
          </Suspense>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.login && state.login.token,
  };
}

export default connect(mapStateToProps, null)(App);
