import { SET_USERS, SET_CURRENT_USER } from "../actions/actions-types";

const initialState = {
  users: [],
  currentUser: {},
};
export default function toastReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_CURRENT_USER :
      return {
        ...state,
        currentUser: action.payload,
      };
      default:
        return state
  }
}
