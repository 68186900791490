import * as types from './actions-types'

//saga
export const callPendingCards = () => ({
    type: types.CALL_PENDING_CARDS
})

export const callCollectionCards = () => ({
    type: types.CALL_COLLECTION_CARD
})

export const manageUserCard = (manageCard) => ({
    type: types.MANAGE_CARD,
    manageCard
})

//reducer
export function addPendingCards(pendingCards) {
    return {
        type: types.PENDING_CARD,
        pendingCards
    }
}

export function addCollectionCards(collectCards) {
    return {
        type: types.COLLECT_CARD,
        collectCards
    }
}

export function manageResult() {
    return {
        type: types.MANAGE_RESULT,
    }
}