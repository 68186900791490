import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useMediaQuery, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import Base from "../../../component/Layout/Base";
import GoBackButton from "../../../component/Layout/GoBackButton";
import { setCampaignName, clearState } from "../../../redux/actions/campaignsActions";
import { TOAST } from "../../../redux/actions/actions-types";
import { useDispatch } from "react-redux";

function NewURL() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [camel, setCamel] = useState('nameOfCampaign');
  const [name, setName] = useState('');
  const [error, setError] = useState(false);
  const sendBtn = useRef(null);
  const matches = useMediaQuery(`(max-width:500px)`);
  const useStyles = makeStyles((theme) => ({
    root: {},
    container: {
      textAlign: "center",
      width: "100%",
      marginTop: "10px",
      marginBottom: "80px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    containerM: {
      textAlign: "center",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    wrapper: {
      background: "#fff",
      boxSizing: "border-box",
      width: "375px",
      height: "auto",
      borderRadius: "51px",
      padding: "10px 0 20px 0",
      display: "flex",
      flexDirection: "column",
      },
      step: {
        fontSize: "22px",
        marginTop: "0",
      },
      header: {
        fontSize: "38px",
        margin: matches ? "0" : "initial",
      },
      description: {
        fontSize: "22px",
        maxWidth: "310px",
        textAlign: "center",
        margin:  matches ? "35px auto 50px auto" : "35px auto 150px auto",
      },
      inputBlock: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxSizing: "border-box",
        padding: "0 30px",
        minHeight: "140px",
      },
      input: {
        border: "none",
        outline: "none",
        height: "60px",
        padding: "0 25px",
        background: "#ececec",
        fontSize: "20px",
        textAlign: "left",
        boxSizing: "border-box",
      },
      link: {
        lineHeight: matches ? "30px" : "60px",
        color: "#919191",
      },
      camel: {
        color: "black",
        display: matches ? "block" : "inline",
      },
      button: {
        textTransform: "none !important",
        background: "linear-gradient(131.04deg, #4B478D 13.29%, #CA2E86 100%) !important",
        fontSize: "20px !important",
        color: "#fff !important",
        padding: "0px 25px !important",
        boxSizing: "border-box !important",
        height: "55px",
        borderRadius: "25px !important",
        width: "170px !important",
        margin: "20px auto 0 auto !important",
      },
  }));
  const classes = useStyles();

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };

  const onNameChange = (value) => {
      if (value && error) {
        setError(false)
      }
      setName(value);
      const camelCase = value.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
      setCamel(camelCase);
  }

  const sendURL = () => {
      if (!name) {
        showToast("error", "Error", "Name of campaign are required");
        setError(true);
        return
      }
      const data = {
        name,
        qrUrlPart: camel
      }
      dispatch(setCampaignName(data));
      history.push("/campaign/customize-wheel");
  }

  useEffect(() => {
    function onKeyup(e) {
      if (e.keyCode === 13) {
        sendBtn.current.click();
      }
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, []);

  const renderTopBar = () => {
    return <GoBackButton onClick={() => history.goBack()} />;
  };

  return (
    <Base theme={"light"} topBar={renderTopBar}>
      <div className={matches ? classes.containerM : classes.container}>
        <div className={matches? classes.wrapper : "campaign-wrapper"}>
            <p className={classes.step}>Step 1</p>
            <p className={classes.header}>Create Url for prize wheel</p>
            <p className={classes.description}>Create a unique URL to direct
                customers to spin for a chance to
                win your custom made offers.
            </p>
            <div className={classes.inputBlock}>
                <input className={classes.input} 
                placeholder="Name of campaign" 
                onChange={(event) => onNameChange(event.target.value)} 
                value={name}
                style={{border: error ? "2px solid red" : "none"}}/>
                <div className={classes.input}>
                    <span className={classes.link}>www.spinforstuff.com/<span className={classes.camel}>{camel}</span></span>
                </div>
            </div>
            <Button variant="contained" className={matches ? classes.button : "campaign-bottomBTN"} onClick={() => sendURL()} ref={sendBtn}>Next</Button>
        </div>
      </div>
    </Base>
  );
}

export default NewURL;
