import React from "react";

const CountryBadge = ({ name, selected, onClick }) => {
  return (
    <div className={`country-badge ${selected ? "selected" : ""}`} onClick={onClick}>
      <span style={{ textTransform: "capitalize" }}>{name}</span>
    </div>
  );
};

export default CountryBadge;
