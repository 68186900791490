import * as types from '../actions/actions-types'
import { httpGetCall } from "../../common/commonService";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

//saga
export const getUserCards = () => ({
    type: types.GET_DROP_CARDS_STANDARD
})

//reducer
export const dropUserCardsStandard = (userDropCards) => {
    return {
        type: types.DROP_USER_CARDS_STANDARD,
        userDropCards
    }
}

const setLoading = () => ({
    type: types.DROP_CARDS_LOADING
});

export const setIsLoading = () => dispatch => {
  dispatch(setLoading());
}

const locationDataSucces = (payload) => ({
    payload,
    type: types.GET_OFFERS_SUCCES
})

export const getOffers = () => async dispatch => {
    dispatch(setLoading());
    let filter = {
        where: {
          // isApproved: true,
          status: { $ne: "rejected" },
        },
      };
    try {
        const {data} = await httpGetCall(`${REACT_APP_BASE_URL}/cards/of-user?filter=${JSON.stringify(filter)}`);
        dispatch(locationDataSucces(data));
      } catch (err) {
        return err;
      }
}

const chestsByCardSucces = (payload) => ({
  payload,
  type: types.GET_CHEST_BY_CARD_SUCCES
})

export const getChestsByCard = (id) => async dispatch => {
    dispatch(setLoading());
    try {
        const {data} = await httpGetCall(`${REACT_APP_BASE_URL}/chests-by-card/${id}`);
        dispatch(chestsByCardSucces(data));
      } catch (err) {
        return err;
      }
}

const clearOffer = () => ({
  type: types.CLEAR_CURRENT_OFFER,
})

export const clearCurrentOffer = () => dispatch => {
  dispatch(clearOffer());
}

const setCurrentOffer = (payload, index) => ({
    payload,
    index,
    type: types.SET_OFFER
})

export const selectOffer = (id, index) => async dispatch => {
    dispatch(setLoading());
    try {
      const {data} = await httpGetCall(`${REACT_APP_BASE_URL}/cards/statistics/${id}?pastTime=0`);
      dispatch(setCurrentOffer(data, index));
    } catch (err) {
      return err;
    }
};

const setSlideNumber = (payload) => ({
  payload,
  type: types.SET_SLIDE_INDEX
})

export const setSliderIndex = (number) => dispatch => {
  dispatch(setSlideNumber(number));
}

const updateDropCard = (id, data) => ({
  id,
  data,
  type: types.UPDATE_CARD
})

export const updateCard = (id, data) => dispatch => {
  dispatch(updateDropCard(id, data));
}

const setAnalytics = (id, campaign) => ({
  id,
  campaign,
  type: types.SET_ANALYTICS_ID
})

export const setAnalyticsId = (id, type) => dispatch => {
  dispatch(setAnalytics(id, type));
}