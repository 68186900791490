import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const CitySearchItem = ({ id, name, country, onSelect }) => {
  return (
    <div className={"city-search-result-item"} onClick={() => onSelect(id)}>
      <div className={"map-icon-container"}>
        <FontAwesomeIcon
          icon={faMapMarkerAlt}
          color={"#858493"}
          style={{ fontSize: "12px" }}
        />
      </div>
      <p>
        {name}, <span> {country}</span>
      </p>
    </div>
  );
};

export default CitySearchItem;
