import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import validator from "validator";
import { CircularProgress } from "@material-ui/core";
import Base from "./../../component/Layout/Base";
import { fetchTiers, addTier, updateTier } from "../../redux/requests/tiersRequests";
import { TOAST } from "../../redux/actions/actions-types";
import style from "./tiers.module.css";
import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
const TiersForm = () => {
  const [tiers, setTiers] = useState({
    goldValue: "",
    min: "",
    max: "",
    chance: "",
    activateCampaignAfterViews: "",
    canWinAfterViews: "",
  });
  const [loader, setLoader] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (id) {
      setShowLoader(true);

      fetchTiers(id)
        .then((res) => {
          setTiers({
            goldValue: String(res.data.goldValue),
            min: String(res.data.min),
            max: String(res.data.max),
            chance: String(res.data.chance),
            activateCampaignAfterViews: String(res.data.activateCampaignAfterViews),
            canWinAfterViews: String(res.data.canWinAfterViews),
          });
          setShowLoader(false);
        })
        .catch((err) => console.log(err));
    } else {
      setShowLoader(false);
    }
  }, [id]);
  
  const validation = () => {
    if (!validator.isInt(tiers.goldValue) || tiers.goldValue < 0) {
      showError("! Invalid gold value");
      return false;
    } else if (!validator.isInt(tiers.min) || tiers.min < 0) {
      showError("! Invalid value of minimum");
      return false;
    } else if (!validator.isInt(tiers.max) || tiers.max < 0 || parseInt(tiers.min) > parseInt(tiers.max)) {
      showError("! Invalid value of max");
      return false;
    } else if (!validator.isNumeric(tiers.chance) || tiers.chance < 0 || tiers.chance > 100) {
      showError("! Invalid value of chance");
      return false;
    } else if (!validator.isInt(tiers.activateCampaignAfterViews) || tiers.activateCampaignAfterViews < 0) {
      showError("! Invalid value of activate campaign after");
      return false;
    } else if (!validator.isInt(tiers.canWinAfterViews) || tiers.canWinAfterViews < 0) {
      showError("! Invalid value of can win after");
      return false;
    }
    return true;
  };
  const submitForm = async (e) => {
    e.preventDefault();
    let isValid = validation();
    setLoader(true);
    let doRedirect = false;
    if (isValid) {
      const exactObj = {
        goldValue: Number(tiers.goldValue),
        min: Number(tiers.min),
        max: Number(tiers.max),
        chance: Number(tiers.chance),
        activateCampaignAfterViews: Number(tiers.activateCampaignAfterViews),
        canWinAfterViews: Number(tiers.canWinAfterViews),
      };
      if (id) {
        delete tiers.id;
        await updateTier(id, exactObj);
        doRedirect = true;
      } else {
        delete tiers.id;
        let istiers = await addTier(exactObj);
        if (istiers) {
          doRedirect = true;
        }
      }
    }
    if (doRedirect) {
      dispatch({
        type: TOAST,
        load: {
          type: "success",
          title: "Done",
          message: id ? "tiers Updated" : "tiers Added",
          show: true,
        },
      });
      history.goBack();
      // history.push(`/user/list/${type}`);
    }
    setLoader(false);
  };

  const showError = (message) => {
    dispatch({
      type: TOAST,
      load: {
        type: "error",
        title: "Error",
        message: message,
        show: true,
      },
    });
  };

  return (
    <Base theme="light">
      {showLoader ? (
        <h1 style={{ marginLeft: "20px" }}>Loading...</h1>
      ) : (
        <div className={"middle-content-area white-card"}>
          <h2 className={style.cardHeader} style={{ textAlign: "center", marginTop: "10px" }}>
            Tiers/{id ? "Edit" : "Add"}
          </h2>
          <div class="select-available-city" style={{ minHeight: "10px" }}>
            <h3 style={{ display: "inline" }}>Gold Value</h3>
            <input
              class="search-city-input"
              type="number"
              placeholder="Enter gold value"
              value={tiers.goldValue}
              onChange={(e) => {
                e.persist();
                setTiers((prevState) => ({
                  ...prevState,
                  goldValue: e.target.value,
                }));
              }}
            />
          </div>
          <div class="select-available-city" style={{ minHeight: "10px" }}>
            <h3 style={{ display: "inline" }}>Min</h3>
            <Tooltip title={"This is the lowest dollar value"} arrow placement="right-end">
              <InfoOutlinedIcon className="tooltip" />
            </Tooltip>
            <input
              class="search-city-input"
              type="number"
              placeholder="Enter minimum"
              value={tiers.min}
              onChange={(e) => {
                e.persist();
                setTiers((prevState) => ({
                  ...prevState,
                  min: e.target.value,
                }));
              }}
            />
          </div>
          <div class="select-available-city" style={{ minHeight: "10px" }}>
            <h3 style={{ display: "inline" }}>Max</h3>
            <Tooltip title={"This is the highest dollar value"} arrow placement="right-end">
              <InfoOutlinedIcon className="tooltip" />
            </Tooltip>
            <input
              class="search-city-input"
              type="number"
              placeholder="Enter maximum"
              value={tiers.max}
              onChange={(e) => {
                e.persist();
                setTiers((prevState) => ({
                  ...prevState,
                  max: e.target.value,
                }));
              }}
            />
          </div>
          <div class="select-available-city" style={{ minHeight: "10px" }}>
            <h3 style={{ display: "inline" }}>Chance</h3>
            <Tooltip
              title={
                "This is the percentage an offer in this tier will be picked at random for displaying to user during shuffle. A higher percentage means it will have a high chance of being picked to show during a shuffle event. This increases the views and gets offer closer to being active or won faster."
              }
              arrow
              placement="right-end">
              <InfoOutlinedIcon className="tooltip" />
            </Tooltip>
            <input
              class="search-city-input"
              type="number"
              placeholder="Enter chance"
              value={tiers.chance}
              onChange={(e) => {
                e.persist();
                if (e.target.value >= 0 && e.target.value <= 100) {
                  setTiers((prevState) => ({
                    ...prevState,
                    chance: e.target.value,
                  }));
                }
              }}
            />
          </div>
          <div class="select-available-city" style={{ minHeight: "10px" }}>
            <h3 style={{ display: "inline" }}>Activate campaign after views</h3>
            <Tooltip
              title={"This is how many views an offer in this tier need to have before it is eligible to be won."}
              arrow
              placement="right-end">
              <InfoOutlinedIcon className="tooltip" />
            </Tooltip>
            <input
              class="search-city-input"
              type="number"
              placeholder="Enter views"
              value={tiers.activateCampaignAfterViews}
              onChange={(e) => {
                e.persist();
                setTiers((prevState) => ({
                  ...prevState,
                  activateCampaignAfterViews: e.target.value,
                }));
              }}
            />
          </div>
          <div class="select-available-city" style={{ minHeight: "10px" }}>
            <h3 style={{ display: "inline" }}>Can win after views</h3>
            <Tooltip
              title={
                "This is how many views (after becoming active) is needed before an offer in this tier can be eligible for next win."
              }
              arrow
              placement="right-end">
              <InfoOutlinedIcon className="tooltip" />
            </Tooltip>
            <input
              class="search-city-input"
              type="number"
              placeholder="Enter views"
              value={tiers.canWinAfterViews}
              onChange={(e) => {
                e.persist();
                setTiers((prevState) => ({
                  ...prevState,
                  canWinAfterViews: e.target.value,
                }));
              }}
            />
          </div>
          <div className="bottom-button">
            <button onClick={submitForm} class="gradiant-button">
              {loader ? (
                <CircularProgress style={{ margin: 0, padding: 0, height: "20px", width: "20px" }} />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      )}
    </Base>
  );
};

export default TiersForm;
