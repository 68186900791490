import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Radio } from "@material-ui/core";
import { RadioButtonUnchecked } from "@material-ui/icons";

const GenderRadioInput = ({ genders, selectedGender, onselect, disabled }) => {
  return (
    <div className={"select-gender-container"}>
      {genders.map((gender, i) => (
        <div
          onClick={() => {
            return !disabled && onselect(gender);
          }} key={i}>
          <Radio
            key={gender}
            disabled={disabled}
            color={"secondary"}
            checked={gender === selectedGender}
            checkedIcon={<FontAwesomeIcon icon={faCircle} color={"#000"} />}
            icon={<RadioButtonUnchecked />}
          />
          <span className={`${gender === selectedGender && "selected"}`}>{gender}</span>
        </div>
      ))}
    </div>
  );
};

export default GenderRadioInput;
