import React from "react";
import { useMediaQuery } from "@material-ui/core";

const GoBackButton = ({ big, onClick }) => {
  const matches = useMediaQuery(`(max-width:500px)`);
  return (
    <button className={`go-back-button active ${big && 'accept-reject-button'}`} onClick={onClick} style ={matches ? {marginLeft: "auto"} : null}>
      Back
    </button>
  );
};

export default GoBackButton;
