import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import Base from "../../../component/Layout/Base";
import wheel from "../../../../src/assets/images/mini-wheel.png"
import timer from "../../../../src/assets/images/drop-box-gold.png"
import GoBackButton from "../../../component/Layout/GoBackButton";

function CampaignType() {
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    root: {},
    container: {
      textAlign: "center",
      width: "100%",
      marginTop: "10px",
      marginBottom: "80px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    wrapper: {
      background: '#fff',
      width: "334px",
      height: "284px",
      borderRadius: "20px",
      position: "relative",
      boxSizing: "border-box",
      padding: "62px 0 25px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "54px",
      cursor: "pointer",
    },
    wrapper2: {
      opacity : "0.35",
      padding: "0",
      cursor: "initial",
    },
    title: {
      fontSize: "35px",
      lineHeight: "41px",
      color: "#000000",
      textAlign: "center",
      margin: "0 0 68px",
    },
    paragraph: {
      fontSize: "22px",
    },
    helper: {
      width: "70px",
      height: "70px",
      borderRadius: "50%",
      background: "#000",
      color: "#fff",
      fontSize: "30px",
      position: "absolute",
      bottom: "4px",
      right: "11px",
      '&:hover': {
        background: "#000",
      },
    }
  }));
  const classes = useStyles();

  const renderTopBar = () => {
    return <GoBackButton onClick={() => history.goBack()} />;
  };

  return (
    <Base theme={"light"} topBar={renderTopBar}>
      <div className={classes.container}>
          <h1 className={classes.title}>Choose type of campaign</h1>
        <div className={classes.wrapper}
          onClick={() => history.push("/campaign/location")}>
          <img src={wheel} alt="" width="160px"/>
          <p className={classes.paragraph}>Prize wheel</p>
        </div>
        <div className={`${classes.wrapper} ${classes.wrapper2}`}>
          <p className={classes.paragraph}>Coming soon</p>
          <img src={timer} alt="" width="112px"/>
          <p className={classes.paragraph}>Virtual Timer </p>
        </div>
      </div>
    </Base>
  );
}

export default CampaignType;
