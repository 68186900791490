import React, { useState, useEffect } from "react";
import { Button, useMediaQuery, makeStyles, Tooltip, FormControl, Select, MenuItem, LinearProgress, CircularProgress, Grid } from "@material-ui/core";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Hint from "../../../assets/icons/hintSmall.svg";
import GoBackButton from "../../../component/Layout/GoBackButton";
import history from "../../../helpers/history";
import Base from "../../../component/Layout/Base";
import Switch from "../../../component/Layout/SwitchInput";
import Card from "../../../component/Layout/Card";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DateRangePicker from "../../../component/Layout/DateRangePicker";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Collapsible from "../../../component/Layout/Collapsible";
import {
  updateCampaignDetails,
  addCategoryCampaign,
  removeCampaignCategory,
} from "../../../redux/requests/cardRequests";
import { addCategory, fetchCategory, updateCategory, saveArrayCategories } from "../../../redux/requests/categoryRequests";
import { TOAST } from "../../../redux/actions/actions-types";
import { changeCampaignStatus, getStatistics, getTargetedCampaign } from "../../../redux/actions/campaignsActions";
import { setAnalyticsId } from "../../../redux/actions/dropCardsActions";
import { useLocation } from "react-router-dom";

function PerformanceDateSelect({ onSelect }) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const [range, setDateRange] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setDateRange(event.target.value);
    if (event.target.value !== 0) {
      onSelect(moment().subtract(event.target.value, 'days').format('x'));
    } else {
      onSelect(0);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const useStyles = makeStyles((theme) => ({
    root: {},
    select: {
      width: "200px",
      background: "transparent",
      boxSizing: "border-box",
      fontSize: "24px",
      color: "#000 !important",
      textAlign: "right",
      "&::before": {
        content: "''",
        display: "none",
      }
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <FormControl>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={range}
          onChange={handleChange}
          className={classes.select}
          style={matches? {fontSize: "20px", width: "auto"} : null}
        > 
        <MenuItem value={1}>Today</MenuItem>
        <MenuItem value={7}>Last 7 days</MenuItem>
        <MenuItem value={14}>Last 14 days</MenuItem>
        <MenuItem value={30}>Last 30 days</MenuItem>
        <MenuItem value={0}>All Time</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

function CampaignsDetail(props) {
  const card = props.card;
  const cardIndex = 0;
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.login.userData);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [editShop, setEditShop] = useState(false);
  const [cards, setCards] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [isContest, setIsContest] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [contestDateRange, setContestDateRange] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [categories, setCategories] = useState();
  const [categoriesSelect, setCategoriesSelect] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [typedCategory, setTypedCategory] = useState();
  const [editCategory, setEditCategory] = useState();
  const [changedCategory, setChangedCategory] = useState();
  const [apGpData, setApGpData] = useState({
    ap: false,
    gp: true,
  });
  const matches = useMediaQuery(`(max-width:500px)`);
  const useStyles = makeStyles((theme) => ({
    root: {},
    container: {
      textAlign: "center",
      width: "100%",
      marginTop: "10px",
      marginBottom: "80px",
      display: "flex",
      justifyContent: "center",
    },
    wrapper: {
    background: "#fff",
    boxSizing: "border-box",
    width: "375px",
    height: "auto",
    borderRadius: "51px",
    padding: "30px 0 20px 0",
    display: "flex",
    flexDirection: "column",
    },
    statusContainer: {
      boxSizing: "border-box",
      width: "100%",
      background: "#fff",
      borderRadius: "13px",
      padding: "12px 22px",
      display: "flex",
      justifyContent: "space-between",
      margin: "19px 0",
    },
    status: {
      display: "block",
      fontSize: "20px",
      color: "#00D913",
    },
    pause: {
      display: "block",
      padding: "6px 0",
      minWidth: "initial",
      textTransform: "none",
      fontSize: "25px",
      fontWeight: "bold",
    },
    performanceContainer: {
      display: "grid",
      gridTemplateColumns: "50% 50%",
      gridRowGap: "35px",
      margin: "35px 0 0 0",
    },
    performanceContainerM: {
      display: "grid",
      gridTemplateColumns: "50% 50%",
      gridRowGap: "20px",
      margin: "0",
      background: "#fff",
      borderRadius: "13px",
      padding: "20px 0 0 0",
      boxSizing: "border-box",
    },
    performance: {
      fontSize: "24px",
      whiteSpace: "nowrap",
      textAlign: "left",
      fontWeight: "bold",
    },
    statisticsBlock: {
      alignItems: "flex-start",
      textAlign: "left"
    },
    statisticsHeader: {
      fontSize: "22px",
      fontWeight: "bold",
      display: "block",
      marginBottom: "14px",
      marginTop: "-4px",
      whiteSpace: "nowrap"
    },
    statisticsValue: {
      fontSize: "25px",
    },
    campaignsContainer: {
      boxSizing: "border-box",
      width: "100%",
      background: "#fff",
      borderRadius: "13px",
      padding: "22px 0 22px 22px",
      justifyContent: "space-between",
      marginBottom: "30px",
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
    },
    label:{
        textTransform: "none",
        fontSize: "22px",
        fontWeight: "bold",
        lineHeight: "1",
    },
    text: {
      padding: "0",
      margin: "0 22px 0 0",
    },
    campaignLine: {
      display: "grid",
      gridTemplateColumns: "210px 1fr 1fr",
      justifyItems: "left",
      alignItems: "center",
    },
    campaignName: {
      fontSize: "18px",
      fontWeight: "bold",
      padding: "18px 0",
    },
    iconBtn: {
      justifySelf: "right",
      marginRight: "15px",
      color: "#000",
    },
    campaignStatus: {
      fontSize: "18px",
    },
    hint: {
      cursor: "pointer",
      display: "block",
      marginRight: "9px",
    },
    creditCard: {
      display: "block",
      fontSize: "25px",
      fontWeight: "bold",
      margin: "auto 0",
    },
    amount: {
      fontSize: "25px",
    },
    sliderContainer: matches ? {
      position: "relative",
      borderRadius: "12px",
      boxShadow: "0 0 5px #444",
      width: "300px",
      height: "180px",
      margin: "0 auto",
      overflow: "hidden",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    } : {
      position: "relative",
      borderRadius: "12px",
      boxShadow: "0 0 5px #444",
      width: "400px",
      height: "230px",
      margin: "0 auto",
      overflow: "hidden",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    cardImg: {
      width: "100%",
      height: "100%",
      display: "block",
    },
    cardTitle: {
      margin: "0",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "rgba(0, 0, 0, 0.6);",
      color: "#ffffff",
      fontSize: "26px",
      width: "100%",
      height: "75px",
      lineHeight: "75px",
    },
    btnContainer: {
      boxSizing: "border-box",
      width: "100%",
      background: "#fff",
      borderRadius: "13px",
      marginBottom: "25px",
      minHeight: "90px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    analyticsText: {
      fontSize: "25px",
      fontWeight: "bold",
      textTransform: "none",
    },
    cardLogo: {
      position: "absolute",
      height: "50px",
      width: "50px",
      left: "15px",
      top: "15px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    statsHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    circular: {
      margin: "0 auto",
      paddingTop: "35px",
      height: "408px",
      boxSizing: "border-box",
    },
    circularM: {
      margin: "0 auto",
      paddingTop: "20px",
      height: "316px",
      boxSizing: "border-box",
    },
    whiteColor: {
      color: "black",
    },
    formControl: {
      width: "100%"
    },
  }));
  const classes = useStyles();

  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  useEffect(() => {
    if (id) {
      dispatch(getTargetedCampaign(id))
    }
  }, [id, dispatch]);

  const isAdmin = user.role === "admin";

  useEffect(() => {
    if (isAdmin) getCategory();
  }, [isAdmin]);

  useEffect(() => {
    if (props.card.categories && !selectedCategory && categories) {
      let currentCategories = [];
      categories.forEach(category => {
        if (props.card.categories.find(cat => cat.categoryId === category.id)) {
          currentCategories.push(category);
        }
      })
      setCategoriesSelect(currentCategories);
    }
  },[props.card.categories, categories, selectedCategory]);

  const setCurrentCampaign = props.setCurrentCampaign

  useEffect(() => {
    setCurrentCampaign(id);
    setStatusUpdate(false);
    setApGp()
    setIsFeaturedAndContest(card);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.card, setCurrentCampaign, id]);

  const changeApGP = (key, value) => {
    let data = { ...apGpData };
    if (key === "ap") {
      data.ap = value;
      if (value) {
        data.gp = false;
        data.gpValue = "";
      } else {
        data.gp = true;
      }
    } else if (key === "gp") {
      data.gp = value;
      if (value) {
        data.ap = false;
        data.apValue = "";
      } else {
        data.ap = true;
      }
    } else {
      data[key] = value;
    }
    setApGpData(data);
  };

  const saveApGp = async () => {
    let data = { ...apGpData };
    if (!data.gp && !data.ap) {
      showToast("error", "Error", "One of gold and ap value should be given");
    } else if (data.gp && (!data.gpValue || isNaN(parseInt(data.gpValue)) || data.gpValue < 0)) {
      showToast("error", "Error", "Invalid Gold value");
    } else if (data.ap && (!data.apValue || isNaN(parseInt(data.apValue)) || data.apValue < 0)) {
      showToast("error", "Error", "Invalid ap value");
    } else {
      let submitData = {
        shopPriceAP: data.ap ? parseInt(data.apValue) : 0,
        shopPriceGP: data.gp ? parseInt(data.gpValue) : 0,
        inShop: data.inShop,
        inShuffle: data.inShuffle,
      };
      setShowLoading(true)
      await updateCampaignDetails(props.card._id, submitData);
      setEditShop(false);
      setShowLoading(false);
    }
  };

  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };

  const setIsFeaturedAndContest = (card) => {
    if (!card) return;
    setIsContest(card.isContest);
    setIsFeatured(card.isFeatured);
    if (card.contestOn && card.contestDrawOn)
      setContestDateRange({ startDate: new Date(card.contestOn), endDate: new Date(card.contestDrawOn) });
  };

  const setApGp = () => {
    if (!card) return;
    let data = { ...apGpData };
    data.ap = card.shopPriceAP && card.shopPriceAP > 0;
    data.gp = card.shopPriceGP && card.shopPriceGP > 0;
    data.apValue = card.shopPriceAP;
    data.gpValue = card.shopPriceGP;
    data.inShop = card.inShop;
    data.inShuffle = card.inShuffle;
    if (!data.gp && !data.ap) {
      data.gp = true;
    }
    setApGpData(data);
  };

  const toggleDatePicker = () => setShowDatePicker(!showDatePicker);

  const onChangeDatesRange = (range) => {
    setContestDateRange(range);
  };

  const saveContest = async () => {
    let copyCard = { ...card };
    if (isContest && !contestDateRange) {
      showToast("error", "Error", "Contest dates must have to be selected.");
    } else if (isContest) {
      let data = {
        contestOn: moment.utc(contestDateRange.startDate).format(),
        contestDrawOn: moment.utc(contestDateRange.endDate).format(),
        isContest,
      };
      let campaignResp = await updateCampaignDetails(copyCard._id, data);
      if (campaignResp && campaignResp.name !== "Error") {
        showToast("success", "Done", "Campaign updated");
        copyCard = { ...copyCard, ...data };
        updateCard(copyCard);
      }
    } else {
      let data = {
        isContest: false,
      };
      let campaignResp = await updateCampaignDetails(copyCard._id, data);
      if (campaignResp && campaignResp.name !== "Error") {
        showToast("success", "Done", "Campaign updated");
        copyCard = { ...copyCard, ...data };
        updateCard(copyCard);
      }
    }
  };

  const updateCard = (cardObj) => {
    let card = { ...cards[cardIndex] };
    const newCard = { ...card, ...cardObj };
    const cardsCopy = [...cards];
    cardsCopy[cardIndex] = newCard;
    setCards(cardsCopy);
  };

  const saveFeatured = async () => {
    let copyCard = { ...card };
    let data = {
      isFeatured,
    };
    let campaignResp = await updateCampaignDetails(copyCard._id, data);
    if (campaignResp && campaignResp.name !== "Error") {
      showToast("success", "Done", "Campaign updated");
      copyCard = { ...copyCard, ...data };
      updateCard(copyCard);
    }
  };

  const renderCampaignCategories = (campaignCategory) => {
    let items = [];
    items =
      categories &&
      campaignCategory.map((item) => {
        const categoryName = getCategoryName(item.categoryId);
        return (
          <Grid container spacing={3} key={item.id}>
            <Grid item xs={8}>
              <div className="category-items">
                {item.id === editCategory ? (
                  <input
                    value={changedCategory || categoryName}
                    onChange={(e) => setChangedCategory(e.target.value)}
                    className="switch-input"
                  />
                ) : (
                  categoryName
                )}
              </div>
            </Grid>

            <Grid item xs={4}>
              <div>
                {item.id === editCategory ? (
                  <Button
                    onClick={() => {
                      updateCampaignCategory(item.categoryId);
                    }}>
                    save
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setChangedCategory(categoryName);
                      setEditCategory(item.id);
                    }}>
                    Edit
                  </Button>
                )}
                <Button onClick={() => removeCategory(item.categoryId)}>Delete</Button>
              </div>
            </Grid>
          </Grid>
        );
      });
    return items;
  };

  const saveCategories = async () => {
    const categories = categoriesSelect.map((item => item.id));
    const data = {
      campaignId: props.card._id,
      categories: categories,
    }
    let categoryResp = await saveArrayCategories(data);
    if (categoryResp && categoryResp.name !== "Error") {
      showToast("success", "Done", "Categories updated");
    }
  }

  const getCategoryName = (categoryId) => {
    let allCategories = [...categories];
    let selectedCategory = allCategories.findIndex((items) => items.id === categoryId);
    selectedCategory = allCategories[selectedCategory];

    return selectedCategory.name;
  };

  const updateCampaignCategory = async (categoryId) => {
    let categoryResp = await updateCategory(categoryId, { name: changedCategory });
    if (categoryResp && categoryResp.name !== "Error") {
      const card = cards[cardIndex];
      let copyCard = { ...card };
      let campaignCategories = [...copyCard.campaignCategories];
      let editCatIndex = campaignCategories.findIndex((items) => items.categoryId === categoryId);
      campaignCategories[editCatIndex].category.name = changedCategory;
      copyCard.campaignCategories = campaignCategories;
      updateCard(copyCard);

      showToast("success", "Done", "Category updated");
      getCategory();
      setChangedCategory();
      setEditCategory();
    }
  };

  const removeCategory = async (categoryId) => {
    const card = cards[cardIndex];
    const copyCard = { ...card };
    let campaignResp = await removeCampaignCategory(copyCard.id, categoryId);
    if (campaignResp && campaignResp.name !== "Error") {
      showToast("success", "Done", "Category deleted");
      copyCard.campaignCategories = copyCard.campaignCategories.filter((items) => items.categoryId !== categoryId);
      updateCard(copyCard);
    }
  };

  const getCategory = async () => {
    // if (!isAdmin) return;
    let categoryResp = await fetchCategory();
    if (categoryResp && categoryResp.name !== "Error") {
      setCategories(categoryResp.data);
    }
  };

  const getOptions = () => {
    const card = cards[cardIndex];
    const copyCard = { ...card };
    if (!categories) return [];
    if (!copyCard) return [];
    if (!copyCard.campaignCategories) return categories;
    // let data = categories.filter((c) => copyCard.campaignCategories.findIndex((c2) => c2.categoryId === c.id) === -1);
    return categories;
  };

  const changeCategory = (value) => {
    if (selectedCategory) setSelectedCategory();
    setTypedCategory(value);
  };

  const saveCategory = async () => {
    const card = cards[cardIndex];
    const copyCard = { ...card };

    if (selectedCategory) {
      if (copyCard.campaignCategories && copyCard.campaignCategories.length > 0) {
        if (copyCard.campaignCategories.findIndex((items) => items.categoryId === selectedCategory) > -1) {
          return showToast("error", "Error", "This category already added.");
        }
      }
      let campaignCategoryResp = await addCategoryCampaign(props.card._id, selectedCategory);
      props.card.categories.push(campaignCategoryResp.data);
      updateCardForCategory(campaignCategoryResp);
      showToast("success", "Done", "New category added.");
      setTypedCategory("");
    } else if (typedCategory && typedCategory.length > 0) {
      let isCategoryExists = categories.filter((items) => items.name.toLowerCase() === typedCategory.toLowerCase());
      if (isCategoryExists && isCategoryExists.length > 0) {
        if (copyCard.campaignCategories && copyCard.campaignCategories.length > 0) {
          if (copyCard.campaignCategories.findIndex((items) => items.categoryId === isCategoryExists[0].id) > -1) {
            return showToast("error", "Error", "This category already added.");
          }
        }
        let campaignCategoryResp = await addCategoryCampaign(copyCard._id, isCategoryExists[0].id);
        // getCampaignData();
        updateCardForCategory(campaignCategoryResp);
        showToast("success", "Done", "New category added.");
        setTypedCategory("");
      } else {
        let categoryResp = await addCategory({ name: typedCategory });
        if (categoryResp && categoryResp.name !== "Error") {
          showToast("success", "Done", "New category added.");
          let category_data = [...categories];
          category_data.push(categoryResp.data);
          setCategories(category_data);
          let campaignCategoryResp = await addCategoryCampaign(copyCard._id, categoryResp.data.id);

          if (campaignCategoryResp && campaignCategoryResp.name !== "Error") {
            showToast("success", "Done", "Category added to campaign.");
            updateCardForCategory(campaignCategoryResp, category_data);
            // getCampaignData();
          }
        }
      }
    } else {
      return showToast("error", "Error", "Category couldn't be added ");
    }
  };

  const updateCardForCategory = (categoryResp, category_data = null) => {
    let allCategory = [...categories];
    if (category_data) {
      allCategory = category_data;
    }

    const card = cards[cardIndex];
    let copyCard = { ...card };
    if (copyCard.campaignCategories && copyCard.campaignCategories.length > 0) {
      categoryResp.data.category = allCategory.filter((items) => items.id === categoryResp.data.categoryId)[0];
      copyCard.campaignCategories.push(categoryResp.data);
    } else {
      copyCard.campaignCategories = [];
      categoryResp.data.category = allCategory.filter((items) => items.id === categoryResp.data.categoryId)[0];
      copyCard.campaignCategories.push(categoryResp.data);
    }
    updateCard(copyCard);
  };
  // const handleOnClickBlock = async () => {
  //   const copyCard = { ...card };
  //   copyCard.status = copyCard.status === "active" ? "paused" : "active";

  //   let updateResp = await updateCampaignDetails(copyCard.id, {
  //     status: copyCard.status,
  //   });
  // };

  // const handleOnClickEditCardDetails = () => {
  //   history.push(`/campaigns/edit-offer-details/${cards[cardIndex].cardId}`);
  // };

  // const handleOnClickEditTargetting = () => {
  //   history.push(`/drop-card/standard-gift?edit=true&id=${cards[cardIndex].id}`);
  // };

  // {props.card && renderCard()}

  const handlePause = (id) => {
    setStatusUpdate(true);
    props.changeCampaignStatus(id);
  }

  let color;
  if (props.card._id) {
    switch (props.card.status) {
      case "active": {
        color = {color: "#00D913"};
        break;
      }
      case "paused": {
        color = {color: "#A9A9A9"};
        break;
      }
      case ("expired" || "stopped") : {
        color = {color: "#FF0000"};
        break;
      }
      default:
        color = {color: "#000000"};
        break;
    }
  }

  const setRange = (range) => {
    dispatch(getStatistics(props.card._id, range, 'targeted'));
  }

  const getCardAnalytics = () => {
    history.push(`/analytics?id=${props.card._id}&type=campaign`);
  }

  if (!props.isLoading) {
    if (props.card._id) {
      return (
        <React.Fragment>
          <div style={{padding: "15px"}}><GoBackButton onClick={() => history.goBack()}/></div>
            <div className={classes.container}>
              <div className={matches ? classes.wrapper : "campaign-wrapper-offers"} style={{height: "auto"}}>
              <div className={classes.sliderContainer} style={{backgroundImage : `url(${process.env.REACT_APP_IMG_URL}/card-photo/${card?.card?.cardPhoto})`}}>
              <div className={classes.cardLogo} style={matches? {top: "7px"} : null}>
                {card?.card?.logoShowing && (
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}/avatars/${card?.card?.logoPhoto}`}
                    alt=""
                    style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                  />)}
              </div>
                <p className={classes.cardTitle} style={{maxWidth: "440px"}}>{card?.card?.title}</p>
              </div>
                <div className={classes.statusContainer} style={matches ? { width: "375px" } : null}>
                  <div style={{ textAlign: "left" }}>
                    {statusUpdate ? <CircularProgress /> :
                    <React.Fragment>
                      <span className={classes.status} style={matches ? { fontSize: "20px", ...color } : color}><span className="bold">Status:</span>{card.status}</span>
                      <Button className={classes.pause} onClick={() => handlePause(card._id)}>{card.status === "active" ? "Pause" : "Resume"}</Button>
                    </React.Fragment>}
                  </div>
                  <Button className={classes.pause} style={matches ? { fontSize: "20px" } : null} onClick={() => history.push(`/drop-card/standard-gift?edit=true&id=${props.card._id}`)}>Edit campaign</Button>
                </div>
                <div className={classes.statusContainer} style={{display: "block"}}>
                <div className={classes.statsHeader}>
                  <span className={classes.performance} style={matches? {fontSize: "18px"} : null}>Performance summary</span>
                  <PerformanceDateSelect onSelect={(value)=> setRange(value)}/>
                </div>
                {!card.campaignStatistics ? <div className={matches? classes.circularM : classes.circular}><CircularProgress/></div> :
                <div className={matches? classes.performanceContainerM : classes.performanceContainer}>
                <div className={`${classes.statisticsBlock} flex`}>
                <Tooltip title="Number of times a user clicks to spin at location" className={classes.hint}><img src={Hint} alt="" style={matches? {width: "15px"} : null}/></Tooltip>
                  <div className={`${classes.statisticsBlock} flex`}>
                    <div style={card.campaignStatistics.consumed === card.campaignStatistics.total ? { color : "red"} : null}>
                      <span className={classes.statisticsHeader} style={matches? {fontSize: "16px"} : null}>Quantity:</span>
                      <span className={classes.statisticsValue}>{card.campaignStatistics.consumed > -1? card.campaignStatistics.consumed : 0}/{card.campaignStatistics.total > -1? card.campaignStatistics.total : 0}</span>
                    </div>
                  </div>
                </div>
                <div className={`${classes.statisticsBlock} flex`}>
                  <div>
                  </div>
                </div>
                <div className={`${classes.statisticsBlock} flex`}>
                <Tooltip title="Number of offers won buy users" className={classes.hint}><img src={Hint} alt="" style={matches? {width: "15px"} : null}/></Tooltip>
                  <div className={`${classes.statisticsBlock} flex`}>
                    <div>
                      <span className={classes.statisticsHeader} style={matches? {fontSize: "16px"} : null}>Collects</span>
                      <span className={classes.statisticsValue}>{card.campaignStatistics.collects > -1? card.campaignStatistics.collects : "No Data"}</span>
                    </div>
                  </div>
                </div>
                <div className={`${classes.statisticsBlock} flex`} style={{paddingLeft: "20px"}}>
                <Tooltip title="Number of times an offer is clicked to view in the app" className={classes.hint}><img src={Hint} alt="" style={matches? {width: "15px"} : null}/></Tooltip>
                  <div className={`${classes.statisticsBlock} flex`}>
                    <div>
                      <span className={classes.statisticsHeader} style={matches? {fontSize: "16px"} : null}>Offer click views:</span>
                      <span className={classes.statisticsValue}>{card.campaignStatistics.offerClickViews > -1? card.campaignStatistics.offerClickViews : "No Data"}</span>
                    </div>
                  </div>
                </div>
                <div className={`${classes.statisticsBlock} flex`}>
                <Tooltip title="Number of times a user clicks to spin at location" className={classes.hint}><img src={Hint} alt="" style={matches? {width: "15px"} : null}/></Tooltip>
                  <div className={`${classes.statisticsBlock} flex`}>
                    <div>
                      <span className={classes.statisticsHeader} style={matches? {fontSize: "16px"} : null}>Expired:</span>
                      <span className={classes.statisticsValue}>{card.campaignStatistics.expired > -1? card.campaignStatistics.expired : "No Data"}</span>
                    </div>
                  </div>
                </div>
                <div className={`${classes.statisticsBlock} flex`} style={{paddingLeft: "20px"}}>
                <Tooltip title="Number of time link on the back of the gift offer" className={classes.hint}><img src={Hint} alt="" style={matches? {width: "15px"} : null}/></Tooltip>
                  <div className={`${classes.statisticsBlock} flex`}>
                    <div>
                      <span className={classes.statisticsHeader} style={matches? {fontSize: "16px"} : null}>Link clicks:</span>
                      <span className={classes.statisticsValue}>{card.campaignStatistics.linkClicks > -1? card.campaignStatistics.linkClicks : "No Data"}</span>
                    </div>
                  </div>
                </div>
                <div className={`${classes.statisticsBlock} flex`}>
                <Tooltip title="Number of times gift offer was clicked to be redeemed" className={classes.hint}><img src={Hint} alt="" style={matches? {width: "15px"} : null}/></Tooltip>
                  <div className={`${classes.statisticsBlock} flex`}>
                    <div>
                      <span className={classes.statisticsHeader} style={matches? {fontSize: "16px"} : null}>Redeems:</span>
                      <span className={classes.statisticsValue}>{card.campaignStatistics.redeems > -1? card.campaignStatistics.redeems : "No Data"}</span>
                    </div>
                  </div>
                </div>
                <div className={`${classes.statisticsBlock} flex`} style={{paddingLeft: "20px"}}>
                <Tooltip title="Number of times offer appears on users screen" className={classes.hint}><img src={Hint} alt="" style={matches? {width: "15px"} : null}/></Tooltip>
                  <div className={`${classes.statisticsBlock} flex`}>
                    <div>
                      <span className={classes.statisticsHeader} style={matches? {fontSize: "16px"} : null}>Impressions:</span>
                      <span className={classes.statisticsValue}>{card.campaignStatistics.impressions > -1? card.campaignStatistics.impressions : "No Data"}</span>
                    </div>
                  </div>
                </div>
              </div>
                }
              </div>
                <div className={classes.statusContainer} style={matches ? { width: "375px" } : null}>
                  <span className={classes.creditCard} style={matches ? { fontSize: "20px" } : null}>Card: xxxx 5439</span>
                  <div style={{ textAlign: "left" }}>
                    <span className={classes.creditCard} style={matches ? { fontSize: "20px" } : null}>Amount spent:</span>
                    <span className={classes.amount} style={matches ? { fontSize: "20px" } : null}>$50.00</span>
                  </div>
                </div>
                <div className={classes.btnContainer} style={matches ? {width: "375px"} : null}>
                  <Button className={classes.analyticsBtn} classes={{label : classes.analyticsLabel, text: classes.analyticsText}} style={matches ? {fontSize: "20px"} : null} onClick={() => getCardAnalytics()}>View card analytics</Button>
                </div>
                {props.isAdmin === "admin" && (
              <>
                {/** Pick and pick% */}
                {/* <div className={"existing-campaign-edit-section-2"}>
                  <Card>
                    <div className={"bulk-edit-con"}>
                      <div className={"bulk-edit-grid-col-3"}>
                        <div className={"bulk-edit-item"}>
                          <span className="h3">Pick %:</span>
                          {!editPick ? <span></span> : <input />}
                        </div>
                        <div className={"bulk-edit-item"}>
                          <span className="h3">picks:</span>
                          {!editPick ? <span></span> : <input />}
                        </div>
                        <div className={"bulk-edit-item"}>
                          <span className="h3">Activation on:</span>
                          {!editPick ? <span></span> : <input />}
                        </div>
                      </div>
                      <div className={"bulk-edit-grid-col-2"}>
                        <div className={"bulk-edit-item"}>
                          <span className="h3">Win on:</span>
                          {!editPick ? <span></span> : <input />}
                        </div>
                        <div className={"bulk-edit-item"}>
                          <span className="h3">Tier:</span>
                          {!editPick ? <span></span> : <input />}
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: "right", marginRight: "1rem" }}>
                      {!editPick ? (
                        <Button onClick={() => setEditPick(true)} style={{ fontWeight: "bold" }}>
                          Edit
                        </Button>
                      ) : (
                        <Button onClick={() => setEditPick(false)}>Save</Button>
                      )}
                    </div>
                  </Card>
                </div> */}
                {/** Pick and pick% end*/}

                <div className={"existing-campaign-edit-section-2"}>
                  <Card>
                    <div className={"bulk-edit-con"}>
                      <div className={"bulk-edit-grid-col-3"}>
                        <Switch
                          disabled={!editShop}
                          name="inShuffle"
                          title="shuffle"
                          checked={apGpData.inShuffle}
                          handleChange={changeApGP}
                        />
                        <div className="m-t-4">
                          <div className="h3 d-block m-l-8">Gold Value</div>
                          <div className={"bulk-edit-item"}>
                            <input
                              type="number"
                              className="switch-input"
                              onChange={(e) => changeApGP("gpValue", e.target.value)}
                              value={apGpData.gpValue || ""}
                              disabled={!apGpData.gp || !editShop}
                            />
                            <Switch
                              size="small"
                              disabled={!editShop || apGpData.gp}
                              name="gp"
                              title=""
                              checked={apGpData.gp ? true : false}
                              handleChange={changeApGP}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={"bulk-edit-grid-col-3"}>
                        <Switch
                          disabled={!editShop}
                          name="inShop"
                          title="Gift Shop"
                          checked={apGpData.inShop}
                          handleChange={changeApGP}
                        />
                        <div className="m-t-4">
                          <div className="h3 d-block m-l-8">AP Value</div>
                          <div className={"bulk-edit-item"}>
                            <input
                              type="number"
                              className="switch-input"
                              onChange={(e) => changeApGP("apValue", e.target.value)}
                              value={apGpData.apValue || ""}
                              disabled={!apGpData.ap || !editShop}
                            />
                            <Switch
                              size="small"
                              disabled={!editShop || apGpData.ap}
                              name="ap"
                              title=""
                              checked={apGpData.ap ? true : false}
                              handleChange={changeApGP}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: "right", marginRight: "1rem" }}>
                      {showLoading ? 
                        <LinearProgress />
                        :
                        !editShop ? (
                          <Button onClick={() => setEditShop(true)} style={{ fontWeight: "bold" }}>
                            Edit
                          </Button>
                        ) : (
                          <Button onClick={() => saveApGp()}>Save</Button>
                        )}
                    </div>
                  </Card>
                </div>

                <div className={"existing-campaign-edit-section-2"}>
                  <Card>
                    <Collapsible header="Category">
                      <div className="category-list">
                        <Grid container spacing={3}>
                          <Grid item xs={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="contest"
                                  checked={isContest || false}
                                  onChange={() => setIsContest(!isContest)}
                                />
                              }
                              label="Contest"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            {isContest && (
                              <>
                                <div className="contest-date-picker">
                                  <DateRangePicker
                                    toggle={toggleDatePicker}
                                    show={showDatePicker}
                                    onChange={onChangeDatesRange}
                                    initialDateRange={contestDateRange}
                                  />
                                </div>
                                <div className="d-inline">
                                  <span style={{ verticalAlign: "middle" }}>
                                    {contestDateRange &&
                                      `${moment(contestDateRange.startDate).format("MMM DD,YY")} - ${moment(
                                        contestDateRange.endDate
                                      ).format("MMM DD,YY")}`}
                                  </span>
                                </div>
                                <Button onClick={() => toggleDatePicker()}>
                                  <DateRangeIcon />
                                </Button>
                              </>
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Button onClick={() => saveContest()}>Save</Button>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="featured"
                                  checked={isFeatured || false}
                                  onChange={() => setIsFeatured(!isFeatured)}
                                />
                              }
                              label="Featured"
                            />
                          </Grid>
                          <Grid item xs={6}>
                          </Grid>
                          <Grid item xs={3}>
                            <Button onClick={() => saveFeatured()}>Save</Button>
                          </Grid>
                        </Grid>
                        {card.campaignCategories && renderCampaignCategories(card.campaignCategories)}
                        <Grid container spacing={3} style={{marginTop: "-25px"}}>
                          <Grid item xs={9}>
                            <FormControl className="new-category">
                              <Autocomplete
                                options={getOptions()}
                                value={categoriesSelect || ''}
                                multiple
                                id="controlled-demo"
                                onChange={(event, value) => setCategoriesSelect(value)}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                  <>
                                    <TextField
                                      id="standard-adornment-password"
                                      type={"text"}
                                      label="Add New Category"
                                      {...params}
                                      className="category-add-input"
                                      onChange={(e) => changeCategory(e.target.value)}
                                    />
                                    <span className="category-add-button" onClick={() => saveCategory()}>
                                      <AddCircleOutlineIcon />
                                    </span>
                                  </>
                                )}
                                // renderOption={(option) => {
                                //   return <div style={{color: card.categories.find(category => category.categoryId === option.id) ? "#04b016" : "inherit"}}>{option.name}<span>delete</span></div>;
                                // }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={3} style={{marginTop: "16px"}}>
                            <Button onClick={() => saveCategories()}>Save</Button>    
                          </Grid>
                        </Grid>
                      </div>
                    </Collapsible>
                  </Card>
                </div>
              </>
            )}
              </div>
            </div>
        </React.Fragment>
      );
    } else {
      return (
        <Base theme={"light"} topGap>
          <h1 style={{ marginLeft: "20px" }}>No campaign found</h1>
        </Base>
      );
    }
  } else {
    return <LinearProgress />;
  }
}

const mapStateToProps = (state) => ({
  card: state.campaigns.targetedData,
  isLoading: state.campaigns.isLoading,
  campaigns: state.dropCards.offers.cardWithAdditions,
  index: state.dropCards.offerIndex,
  offer: state.dropCards.offer,
  isAdmin: state.login.userData.role
});

export default connect(mapStateToProps, { changeCampaignStatus, setAnalyticsId })(CampaignsDetail);
