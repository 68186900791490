import React from "react";

const CardDetailsItem = ({ label, value, onClickEdit, isLink, edit }) => {
  return (
    <div className={"card-details-item"}>
      <p>
        {label}:{" "}
        {isLink ? (
          <a href={value} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        ) : (
          value
        )}
      </p>
      {edit && <button onClick={onClickEdit}>Edit</button>}
    </div>
  );
};

export default CardDetailsItem;
