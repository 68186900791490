import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  link: {
    margin: theme.spacing(0),
    color: "#fff",
  },
  redirect: {
    color: "#fff !important",
    textDecoration: "none",
  },
}));

export default function Copyright() {
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      className={classes.link}
      align="center"
      classes={{ marginTop: "10px" }}
      style={{ marginTop: "10px" }}
    >
      {"© "}
      <Link className={classes.redirect} to="/" style={{ marginTop: "0" }}>
        RFS Portal
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
