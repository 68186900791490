import React, { useState, useEffect } from 'react';
import GiftCard from './GiftCard';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { TOAST } from '../../../redux/actions/actions-types';
import { useDispatch } from 'react-redux';
import { addCard, setCardPhoto } from '../../../redux/requests/cardRequests';
import CardDetailsItem from './CardDetailsItem';
import Footer from './Footer';

const useStyles = makeStyles(theme => ({
  bottomSection: {
    backgroundColor: '#EAEAEA',
    paddingTop: 1,
    paddingBottom: 40,
  },
}));

const DesignOfferReview = ({
  onNavigate,
  handleOnClickButton,
  details,
  viewOnly,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [card, setCard] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const matches = useMediaQuery(`(max-width:500px)`);
  const [cardSide, setCardSide] = useState('front');

  useEffect(() => {
    if (details) {
      setCard({
        backgroundImage: details.cardPhoto
          ? details.cardPhoto.includes(window.location.protocol)
            ? details.cardPhoto
            : `${process.env.REACT_APP_IMG_URL}/card-photo/${details.cardPhoto}`
          : null,
        title: details.title,
        logo: details.logoPhoto
          ? details.logoPhoto.includes(window.location.protocol)
            ? details.logoPhoto
            : `${process.env.REACT_APP_IMG_URL}/avatars/${details.logoPhoto}`
          : null,
        description: details.details,
        offer: details.cardValue,
        cardTier: details.cardTier,
        quantity: details.quantity,
        discountCode: details.code,
        promoteUrlLink: details.promoteUrl,
      });
    }
  }, [details]);

  const toggleCardSide = () => {
    setCardSide(cardSide === 'front' ? 'back' : 'front');
  };

  const submitCard = async tabIndex => {
    if (tabIndex === 4) {
      setShowLoading(true);
      let photo = details.photo;
      delete details.photo;
      const exactObj = {
        ...details,
        // cardPhoto : details.photo,
      };
      exactObj.cardBack = '';
      exactObj.cardFront = '';
      exactObj.cardPhoto = '';

      delete details.cardFront;

      delete details.cardBack;

      /* let frontFromData = new FormData();
      frontFromData.append("card-photo", thumbnailFront);
      let backFromData = new FormData();
      backFromData.append("card-photo", thumbnailBack); */
      let addCardResp = await addCard(exactObj);
      if (addCardResp && addCardResp.data) {
        let formData = new FormData();
        formData.append('cardPhoto', photo);
        await setCardPhoto(addCardResp.data.id, formData);
        //   let cardThumbnailFront = await setFrontCardImage(addCardResp.data.id, frontFromData);
        //   let cardThumbnailBack = await setBackCardImage(addCardResp.data.id, backFromData);
      }
      dispatch({
        type: TOAST,
        load: {
          type: 'success',
          title: 'Success',
          message: 'card added',
          show: true,
        },
      });
      handleOnClickButton(tabIndex);
      setShowLoading(false);
    } else {
      handleOnClickButton(tabIndex);
    }
  };

  let logo;

  if (card) {
    if (card.logoPhoto) {
      logo = `${process.env.REACT_APP_IMG_URL}/avatars/${card.logoPhoto}`;
    }
  }

  return (
    <div>
      {card && (
        <div>
          <div
            className={'card-offer-container'}
            style={matches ? { width: '375px', marginTop: '15px' } : null}
          >
            <div className='card-offer-content'>
              <div className={'card-offer-header'}>
                <p>Review</p>
              </div>
              <div className={'card-section review'}>
                <GiftCard
                  side={cardSide}
                  view={true}
                  cardPhoto={card.backgroundImage}
                  title={card.title}
                  logoPhoto={logo}
                  details={card.description}
                  code={card.discountCode}
                  promoteUrl={card.promoteUrlLink}
                  rounded
                  shadow
                  step={3}
                />
                <p>
                  <button onClick={toggleCardSide}>
                    View {cardSide === 'front' ? 'back' : 'front'} of card
                  </button>
                </p>
              </div>
              <div
                className={`bottom-section card-review-container ${classes.bottomSection}`}
              >
                {/* task98 */}
                <CardDetailsItem
                  label={'Quantity'}
                  value={`${card.quantity}`}
                  onClickEdit={() => onNavigate(2)}
                />
                <CardDetailsItem
                  label={'Value of offer'}
                  value={`$${card.offer}`}
                  onClickEdit={() => onNavigate(2)}
                />
                <CardDetailsItem
                  label={'Tier of offer'}
                  value={`${card.cardTier}`}
                  onClickEdit={() => onNavigate(2)}
                />

                <CardDetailsItem
                  label={'Discount Code'}
                  value={`${card.discountCode}`}
                  onClickEdit={() => onNavigate(1)}
                />
                <CardDetailsItem
                  label={'Description'}
                  value={`${card.description}`}
                  onClickEdit={() => onNavigate(1)}
                  edit={!viewOnly}
                />
                <CardDetailsItem
                  label={'Promote url link'}
                  value={`${card.promoteUrlLink}`}
                  onClickEdit={() => onNavigate(1)}
                  isLink={true}
                  edit={!viewOnly}
                />
              </div>
            </div>
          </div>

          {!viewOnly && (
            <Footer
              buttons={[
                {
                  title: 'Back',
                  tabIndex: 2,
                },
                {
                  title: 'Submit',
                  tabIndex: 4,
                  disabled: showLoading,
                  showLoader: showLoading,
                },
              ]}
              onClickButton={submitCard}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DesignOfferReview;
