import React, { useState, useEffect } from "react";
import { makeStyles, Avatar, Button, TextField, Grid, Typography, CircularProgress } from "@material-ui/core";

import { Link } from "react-router-dom";
import Auth from "../../component/Layout/Auth";
import LockIcon from "@material-ui/icons/Lock";
import { useDispatch } from "react-redux";
// import { forgotPass } from "../../redux/actions/authActions";
import { TOAST } from "../../redux/actions/actions-types";
import { forgotPass } from "../../redux/requests/authRequests";
import validator from "validator";
const useStyles = makeStyles((theme) => ({
  title: {
    color: "#fff",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#d72d83",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#3f51b5 !important",
  },
  link: {
    margin: theme.spacing(0),
    color: "#d72d83",
  },
}));

function ForgotPassword(props) {
  const { history } = props;
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const recovery = localStorage.getItem("recovery");
    recovery ? history.push("/recovery-password") : void 0;
    localStorage.removeItem("recovery");
  });

  const changePassword = async () => {
    if (!validator.isEmail(email)) {
      return dispatch({
        type: TOAST,
        load: {
          type: "error",
          title: "Error",
          message: "Invalid email",
          show: true,
        },
      });
    } else {
      setShowSpinner(true);
      let forgotPasswordResp = await forgotPass(email);
      setShowSpinner(false);

      if (forgotPasswordResp) {
        localStorage.setItem("recovery-email", email);

        dispatch({
          type: TOAST,
          load: {
            type: "success",
            title: "Done",
            message: "Check your email for the verification key",
            show: true,
          },
        });
        history.push("/recovery-password");
      }
    }
  };

  return (
    <Auth>
      <Avatar className={classes.avatar}>
        <LockIcon />
      </Avatar>
      <Typography component="h1" className={classes.title} variant="h5">
        Forgot Password
      </Typography>
      <form name="form-layout" className={classes.form} noValidate>
        <TextField
          onChange={(value) => setEmail(value.nativeEvent.target.value)}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={changePassword}
          style={{ color: "#3f51b5" }}>
          {showSpinner ? (
            <CircularProgress color="secondary" />
          ) : (
            <Link to="#" variant="body2" style={{ color: "#fff" }}>
              Recover Password
            </Link>
          )}
        </Button>
        <Grid container justify="center">
          <Grid item>
            <p className={classes.link}>
              Already have an account?{" "}
              <Link to="/" variant="body2" className="color-pink">
                Sign In
              </Link>
            </p>
          </Grid>
        </Grid>
      </form>
    </Auth>
  );
}

export default ForgotPassword;
