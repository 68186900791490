import React, { useEffect, useState, useCallback } from 'react';
import {
  withStyles,
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  LinearProgress,
  useMediaQuery,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import GoBackButton from '../../../component/Layout/GoBackButton';
import Base from '../../../component/Layout/Base';
import { fetchCardAnalytics } from '../../../redux/requests/cardRequests';
import { getLocations } from '../../../redux/actions/campaignsActions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import ChestAnalyticsTable from '../chest/statistic/chestAnaliticsTable';

const SelectCampaign = ({ locations, onSelect, current }) => {
  const [loc, setLocation] = React.useState(current);
  const [open, setOpen] = React.useState(false);

  const handleChange = event => {
    setLocation(event.target.value);
    onSelect(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const matches = useMediaQuery(`(max-width:500px)`);
  const useStyles = makeStyles(theme => ({
    root: {},
    select: {
      width: '520px',
      background: '#ffffff',
      boxSizing: 'border-box',
      height: '52px',
      fontSize: '22px',
      color: '#000 !important',
      textAlign: 'left',
      margin: '0',
    },
    selectM: {
      width: '350px',
      background: '#ffffff',
      boxSizing: 'border-box',
      height: '45px',
      fontSize: '20px',
      color: '#000 !important',
      textAlign: 'left',
      margin: '0',
    },
    selectLi: {
      '& ul': {
        maxWidth: '520px',
      },
      '& li': {
        whiteSpace: 'normal',
        paddingRight: '40px',
      },
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <FormControl>
        <Select
          labelId='demo-controlled-open-select-label'
          id='demo-controlled-open-select'
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={loc || current}
          onChange={handleChange}
          className={matches ? classes.selectM : classes.select}
          MenuProps={{ classes: { paper: classes.selectLi } }}
        >
          <MenuItem
            value={1000}
            disabled
          >
            Choose campaign
          </MenuItem>
          <MenuItem value={-1}>All Campaigns</MenuItem>
          {locations ? (
            locations.map((location, i) => {
              return (
                <MenuItem
                  value={i}
                  key={i}
                >
                  {location.name}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value={10000}>Loading locations</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export default function CampaignsList(props) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const [height, setHeight] = React.useState(window.innerHeight);
  React.useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    return _ => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: height - 80,
    },
    table: {
      minWidth: 700,
    },
    tableheadcell: {
      backgroundColor: '#1C1524',
      fontFamily: 'Lato',
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: '23px',
    },
    tablebodycell: {
      fontFamily: 'Lato',
      fontSize: '22px',
      fontWeight: '400',
      lineHeight: '27px',
    },
    backwrapper: {
      // backgroundColor: "#000",
      minHeight: '60px',
      display: 'flex',
      alignItems: 'center',
      padding: '0 1rem',
      position: 'sticky',
      left: '0',
      top: '0',
    },
    totalCardAmount: {
      fontFamily: 'Lato',
      fontSize: '25px',
      fontWeight: '700',
      lineHeight: '29px',
      textAlign: 'left',
      padding: '70px 15px',
    },
    export: {
      paddingBottom: '5px',
      borderBottom: '1px solid',
      cursor: 'pointer',
      color: '#fff',
    },
    total: {
      textAlign: matches ? 'center' : 'right',
      padding: matches ? '0 70px 0 10px' : '0 70px',
      fontSize: '22px',
      fontWeight: 'bold',
      marginTop: '-55px',
    },
    selectWrapper: {
      display: 'flex',
      justifyContent: 'center',
      height: '82px',
      alignItems: 'center',
      marginTop: matches ? '0' : '-76px',
    },
  });
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [csv, setCsv] = useState([]);
  const [loading, setLoading] = useState(true);
  const [wheelType, setWheelType] = useState(false);
  const [current, setCurrent] = useState('');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const userId = useSelector(state => state.login.userData.id);
  const locations = useSelector(state => state.campaigns.locations);
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const type = params.get('type');

  useEffect(() => {
    if (rows) {
      const newRows = rows.map(row => {
        const container = {};
        container.name = row?.email?.firstName;
        container.email = row?.email?.email;
        container.date = row?.playedAt
          ? moment(row.playedAt).format('LLL')
          : '';
        return container;
      });
      setCsv(newRows);
    }
  }, [rows]);

  const getCardAnalytics = useCallback(
    async user => {
      let analyticsResp = await fetchCardAnalytics(id, type, user);
      setLoading(false);
      if (analyticsResp && analyticsResp.data) {
        setRows(analyticsResp.data);
      }
    },
    [id, type]
  );

  useEffect(() => {
    dispatch(getLocations('QR'));
  }, [dispatch]);

  useEffect(() => {
    if ((userId && id) || (userId && type === 'ALL')) {
      getCardAnalytics(userId);
    }
  }, [userId, id, type, getCardAnalytics]);

  useEffect(() => {
    if ((id && type && userId) || (!id && type === 'ALL' && userId)) {
      if (type === 'QR' || type === 'ALL') {
        setWheelType('QR');
      }
    }
    if (locations.length) {
      const wheelCampaign = locations.findIndex(
        locations => locations.id === id
      );
      setCurrent(wheelCampaign);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCardAnalytics, history, id, type, userId, locations.length]);

  const setCampaign = index => {
    setLoading(true);
    if (index === -1) {
      history.push('/analytics/?type=ALL');
    } else {
      history.push(`/analytics?id=${locations[index].id}&type=QR`);
    }
  };

  const renderTopBar = () => {
    return <GoBackButton onClick={() => history.goBack()} />;
  };

  return (
    <Base
      theme={'light'}
      topBar={renderTopBar}
    >
      <p className={classes.total}>{rows?.length || 0} total</p>
      <div className={classes.selectWrapper}>
        <SelectCampaign
          locations={locations}
          current={current}
          onSelect={value => setCampaign(value)}
        />
      </div>
      <div
        className='card-container campaign-list-area'
        style={{ marginTop: '-2px' }}
      >
        {type === 'chest' ? (
          <ChestAnalyticsTable
            rows={rows}
            classes={classes}
          />
        ) : (
          <TableContainer className={classes.container}>
            <Table
              className={classes.table}
              stickyHeader
              aria-label='sticky table'
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell className={classes.tableheadcell}>
                    {wheelType === 'QR' ? 'First name' : 'Gift Card'}
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableheadcell}>
                    {wheelType === 'QR' ? 'Email address' : 'ID'}
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableheadcell}>
                    {wheelType === 'QR' ? (
                      <CSVLink
                        data={csv}
                        filename={'Collected-leads.csv'}
                      >
                        <span className={classes.export}>
                          Export list (.csv)
                        </span>
                      </CSVLink>
                    ) : (
                      'Status'
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.tableheadcell}
                    style={{
                      display: wheelType === 'QR' ? 'none' : 'table-cell',
                    }}
                  >
                    Date
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.tableheadcell}
                    style={{
                      display: wheelType === 'QR' ? 'none' : 'table-cell',
                    }}
                  >
                    Demographic
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.tableheadcell}
                    style={{
                      display: wheelType === 'QR' ? 'none' : 'table-cell',
                    }}
                  >
                    User
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.tableheadcell}
                    style={{
                      display: wheelType === 'QR' ? 'none' : 'table-cell',
                    }}
                  >
                    Email
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(rows) &&
                  rows.map((row, i) => (
                    <StyledTableRow
                      key={i}
                      className={'table-row-min-height'}
                    >
                      <StyledTableCell
                        component='th'
                        scope='row'
                        className={
                          wheelType === 'QR' ? `${classes.tablebodycell}` : ''
                        }
                      >
                        {wheelType === 'QR' ? (
                          row?.firstName
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_IMG_URL}/card-photo/${row?.card[0]?.cardPhoto}`}
                            width='100'
                            alt=''
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>
                        {wheelType === 'QR' ? row?.email : row?.card[0]?.code}
                      </StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>
                        {wheelType === 'QR' ? '' : row.status}
                      </StyledTableCell>
                      <StyledTableCell
                        className={classes.tablebodycell}
                        style={{
                          display: wheelType === 'QR' ? 'none' : 'table-cell',
                        }}
                      >
                        {moment(row.createdAt).format('LLL')}
                      </StyledTableCell>
                      <StyledTableCell
                        className={classes.tablebodycell}
                        style={{
                          display: wheelType === 'QR' ? 'none' : 'table-cell',
                        }}
                      >
                        {row?.owner[0]?.gender}
                      </StyledTableCell>
                      <StyledTableCell
                        className={classes.tablebodycell}
                        style={{
                          display: wheelType === 'QR' ? 'none' : 'table-cell',
                        }}
                      >
                        {row?.owner[0]?.fullname}
                      </StyledTableCell>
                      <StyledTableCell
                        className={classes.tablebodycell}
                        style={{
                          display: wheelType === 'QR' ? 'none' : 'table-cell',
                        }}
                      >
                        {row?.owner[0]?.email}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {loading && <LinearProgress />}
        {!rows?.length && !loading && (
          <p style={{ textAlign: 'center', fontSize: '26px' }}>No Actions</p>
        )}
        {rows && rows[0]?.campaign?.quantity - rows[0]?.campaign?.collected ? (
          <div
            className={classes.totalCardAmount}
            style={{
              display: loading || wheelType === 'QR' ? 'none' : 'block',
            }}
          >
            Gift Cards available:{' '}
            {(rows &&
              rows[0]?.campaign?.quantity - rows[0]?.campaign?.collected) ||
              0}
          </div>
        ) : null}
      </div>
    </Base>
  );
}
