import React from "react";
import { makeStyles, useMediaQuery, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import Base from "../../component/Layout/Base";
import GoBackButton from "../../component/Layout/GoBackButton";
import { connect } from "react-redux";
import { fetchCardsWhell } from "../../redux/actions/campaignsActions";

function EditOffer({ cards, fetchCardsWhell }) {
  const history = useHistory();
  const matches = useMediaQuery(`(max-width:500px)`);
  const useStyles = makeStyles((theme) => ({
    root: {},
    container: {
      textAlign: "center",
      width: "100%",
      marginTop: "10px",
      marginBottom: "80px",
      display: "flex",
      justifyContent: "center",
    },
    wrapper: {
      background: "#fff",
      boxSizing: "border-box",
      width: "375px",
      height: "auto",
      borderRadius: "51px",
      padding: "10px 0 20px 0",
      display: "flex",
      flexDirection: "column",
    },
    editList: {
      background: "#f0f0f0",
      padding: "24px",
    },
    listItem: {
      background: "#fff",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "55px",
      marginBottom: "5px",
      padding: "0 40px",
      fontWeight: "bold",
      fontSize: "17px",
    },
    text: {
      width: "190px",
      margin: "0 auto 10px auto",
      textTransform: "none",
      fontSize: "19px",
      fontWeight: "bold",
    },
    edit: {
      padding: "0",
      fontWeight: "bold",
      fontSize: "17px",
      textTransform: "none",
    }
  }));
  const classes = useStyles();

  // useEffect(() => {
  //   fetchCardsWhell();
  // }, [])

  const renderTopBar = () => {
    return <GoBackButton onClick={() => history.goBack()} />;
  };

  return (
    <Base theme={"light"} topBar={renderTopBar}>
      <div className={classes.container}>
        <div className={matches ? classes.wrapper : "campaign-wrapper"} style={{height: "auto", paddingBottom: "0"}}>
            <h1 className="campaign-header">Edit</h1>
            <div style={{width: "440px", height : "280px", background: "red", margin: "25px auto 10px auto"}}></div>
            <Button classes={{text: classes.text}}>View back of offer</Button>
          <div className={classes.editList}>
            <div className={classes.listItem}>
              <span className={classes.editDescription}>Expiry date: 30days</span>
              <Button className={classes.edit} classes={{text: classes.edit}}>Edit</Button>
            </div>
            <div className={classes.listItem}>
              <span className={classes.editDescription}>Quantity: 300</span>
              <Button className={classes.edit} classes={{text: classes.edit}}>Edit</Button>
            </div>
            <div className={classes.listItem}>
              <span className={classes.editDescription}>Value of offer: $5 Win % (1/30)</span>
              <Button className={classes.edit} classes={{text: classes.edit}}>Edit</Button>
            </div>
            <div className={classes.listItem}>
              <span className={classes.editDescription}>Code: RFS50</span>
              <Button className={classes.edit} classes={{text: classes.edit}}>Edit</Button>
            </div>
            <div className={classes.listItem}>
              <span className={classes.editDescription}>Details: Redeem at any coffee shop location.</span>
              <Button className={classes.edit} classes={{text: classes.edit}}>Edit</Button>
            </div>
            <div className={classes.listItem}>
              <span className={classes.editDescription}>Learn more: www.facebook.com/coffeeshop</span>
              <Button className={classes.edit} classes={{text: classes.edit}}>Edit</Button>
            </div>
          </div>
        </div>  
      </div>
    </Base>
  );
}

const mapStateToProps = (state) => ({
  cards: state.campaigns.cardsForWheel,
});

export default connect(mapStateToProps, { fetchCardsWhell })(EditOffer);