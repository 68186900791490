import React from "react";

const PromoteLinkInput = ({ value, onChange, hideLabel }) => {
  return (
    <div className={"step-2-input-container"}>
      {!hideLabel && (
        <div className={"label-container"}>
          <label>Promote url link:</label>
        </div>
      )}
      <div className={"right-section"}>
        <input className={"gift-card-text-input"} type={"text"} value={value} onChange={onChange} />
        <div>
          <ul>
            <li>
              <p>*When a user clicks “learn more” this will take them to this link provided</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PromoteLinkInput;
