import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Sidebar from "./include/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  themeDark: {
    backgroundColor: "#5D5C5E",
  },
  themeLight: {
    backgroundColor: "#F0F0F0",
  },
  topGap: {
    paddingTop: "100px",
  },
}));

export default function Base(props) {
  const classes = useStyles();

  const [showMenu, setShowMenu] = useState(false);

  return (
    <div
      className={`${classes.root} 
      ${props.theme === "dark" && classes.themeDark} 
      ${props.theme === "light" && classes.themeLight}`}
    >
      <Sidebar showMenu={showMenu} />
      <div
        className={`sidebar-menu-icon ${showMenu && "sidebar-open"}`}
        onClick={() => setShowMenu(!showMenu)}
      >
        <FontAwesomeIcon
          icon={showMenu ? faArrowLeft : faBars}
          size={"2x"}
          className={"input-control-caret"}
        />
      </div>

      <main
        className={`${classes.content}
        ${showMenu && "right-content-area-menu-open"} 
        right-content-area
        ${props.topGap && "base-top-gap"} ${
          props.topBar && "base-top-gap-by-bar"
        }`}
      >
        {props.topBar && (
          <div className={"top-action-bar"}>{props.topBar()}</div>
        )}
        {/* <div className={classes.appBarSpacer} /> */}
        {props.children}
      </main>
    </div>
  );
}
