import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  makeStyles,
  useMediaQuery,
  Button,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import Cleave from 'cleave.js/react';
import Base from '../../../component/Layout/Base';
import StripeForm from '../../../views/Settings/Payment/index';
import GoBackButton from '../../../component/Layout/GoBackButton';
import sponsor from '../../../../src/assets/images/custom-location.png';
import close from '../../../../src/assets/icons/closeBig.svg';
import cards from '../../../../src/assets/icons/paymentMethod.png';
import { useLoadScript } from '@react-google-maps/api';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import './search.css';
import { TOAST } from '../../../redux/actions/actions-types';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import {
  sendLocation,
  getLocations,
  setLocationToWheel,
  getWheelData,
  clearState,
  deleteLocation,
  getKey,
} from '../../../redux/actions/campaignsActions';

function Search({ setCoords, setAddress, error }) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const useStyles = makeStyles(theme => ({
    root: {},
    input: {
      outline: 'none',
      border: 'none',
      height: '60px',
      fontSize: '20px',
      marginTop: '15px',
      padding: '0 15px',
      borderRadius: '8px',
      boxSizing: 'border-box',
      marginBottom: '20px',
      width: '100%',
    },
    inputM: {
      outline: 'none',
      border: 'none',
      height: '40px',
      fontSize: '16px',
      marginTop: '15px',
      padding: '0 15px',
      borderRadius: '8px',
      boxSizing: 'border-box',
      marginBottom: '20px',
      width: '100%',
    },
  }));
  const classes = useStyles();
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 49.251888, lng: () => -123.081334 },
      radius: 100 * 1000,
    },
    debounce: 500,
  });

  const handleInput = e => {
    setValue(e.target.value);
  };

  const handleSelect = async address => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setCoords(lng, lat);
      setAddress(address);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder='Search your location'
        className={matches ? classes.inputM : classes.input}
        style={error ? { border: '1px solid red' } : null}
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === 'OK' &&
            data.map(({ id, description, i }) => (
              <ComboboxOption
                key={i}
                value={description}
              />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
}

const ControlledOpenSelect = forwardRef(
  ({ locations, onSelect, deleteLoc }, ref) => {
    const [location, setLocation] = React.useState(1000);
    const [open, setOpen] = React.useState(false);

    const loc = () => {
      setTimeout(() => {
        setLocation(1000);
      }, 50);
    };

    useImperativeHandle(ref, () => {
      return {
        loc: loc,
      };
    });

    const handleChange = event => {
      setLocation(event.target.value);
      onSelect(event.target.value);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleOpen = () => {
      setOpen(true);
    };

    const removeLocation = id => {
      deleteLoc(id);
    };

    const matches = useMediaQuery(`(max-width:500px)`);
    const useStyles = makeStyles(theme => ({
      root: {},
      select: {
        width: '520px',
        background: '#ECECEC',
        boxSizing: 'border-box',
        height: '63px',
        fontSize: '22px',
        color: '#000 !important',
        textAlign: 'left',
        marginTop: '52px',
      },
      selectM: {
        width: '350px',
        background: '#ECECEC',
        boxSizing: 'border-box',
        height: '45px',
        fontSize: '20px',
        color: '#000 !important',
        textAlign: 'left',
        marginTop: '25px',
      },
      closeBtnList: {
        padding: '0px',
        border: 'none',
        outline: '0',
        width: '19px',
        height: '19px',
        background: 'none',
        position: 'absolute',
        right: '16px',
        cursor: 'pointer',
        display: 'block',
      },
      selectLi: {
        '& ul': {
          maxWidth: '520px',
        },
        '& li': {
          whiteSpace: 'normal',
          paddingRight: '40px',
        },
      },
    }));
    const classes = useStyles();

    return (
      <div>
        <FormControl>
          <Select
            labelId='demo-controlled-open-select-label'
            id='demo-controlled-open-select'
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={location}
            onChange={handleChange}
            className={matches ? classes.selectM : classes.select}
            MenuProps={{ classes: { paper: classes.selectLi } }}
          >
            <MenuItem
              value={1000}
              disabled
            >
              Choose location
            </MenuItem>
            {locations ? (
              locations.map((location, i) => {
                return (
                  <MenuItem
                    value={location}
                    key={i}
                  >
                    {`${location.name}: ${location.address}`}
                    <button
                      className={classes.closeBtnList}
                      onMouseUp={() => removeLocation(location.id)}
                      style={open ? { display: 'block' } : { display: 'none' }}
                    >
                      <img
                        src={close}
                        alt=''
                        width='20px'
                      />
                    </button>
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem value={10000}>Loading locations</MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
    );
  }
);

const libraries = ['places'];

function ChooseLocation({
  sendLocation,
  setLocationToWheel,
  locations,
  getWheelData,
  deleteLocation,
  isLoading,
  success,
  wheelType,
}) {
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [billingOpen, setBillingOpen] = React.useState(false);
  const [location, setLocation] = React.useState('');
  const [address, setFullAddress] = React.useState('');
  const [fullLocation, setFullLocation] = React.useState('');
  const [error, setError] = React.useState(false);
  const [lat, setLat] = React.useState('');
  const [long, setLong] = React.useState('');
  const matches = useMediaQuery(`(max-width:500px)`);
  const useStyles = makeStyles(theme => ({
    root: {},
    container: {
      textAlign: 'center',
      width: '100%',
      marginTop: '10px',
      marginBottom: '80px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    containerM: {
      textAlign: 'center',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    // wrapper: {
    //   position: "relative",
    // },
    sponsroIMG: {
      margin: '60px auto 48px auto',
      width: '136px',
      height: '136px',
    },
    paragraphHeader: {
      fontSize: '22px',
      margin: '100px 0 0 0',
    },
    paragraphHeaderM: {
      fontSize: '20px',
      margin: '10px 0 0 0',
    },
    helper: {
      width: '70px',
      height: '70px',
      borderRadius: '50%',
      background: '#000',
      color: '#fff',
      fontSize: '30px',
      position: 'absolute',
      bottom: '4px',
      right: '11px',
      '&:hover': {
        background: '#000',
      },
    },
    modal: {
      background: '#F6F6F6',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      padding: '46px 18px 18px 18px',
      width: '410px',
      boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.5)',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      zIndex: '0',
    },
    modalM: {
      background: '#F6F6F6',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      padding: '25px 12px 12px 12px',
      width: '350px',
      boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.5)',
      left: '50%',
      top: '61%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      zIndex: '1',
    },
    closeBtn: {
      padding: '0px',
      border: 'none',
      outline: '0',
      width: '48px',
      height: '48px',
      background: 'none',
      position: 'absolute',
      left: '-24px',
      top: '-24px',
      display: 'block',
      cursor: 'pointer',
    },
    closeBtnM: {
      padding: '0px',
      border: 'none',
      outline: '0',
      width: '22px',
      height: '22px',
      background: 'none',
      position: 'fixed',
      left: '-14px',
      top: '-14px',
      display: 'block',
      cursor: 'pointer',
    },
    label: {
      textAlign: 'left',
      fontSize: '20px',
      display: 'block',
    },
    input: {
      outline: 'none',
      border: 'none',
      height: '60px',
      fontSize: '20px',
      marginTop: '15px',
      padding: '0 15px',
      borderRadius: '8px',
      boxSizing: 'border-box',
      marginBottom: '20px',
    },
    inputM: {
      outline: 'none',
      border: 'none',
      height: '40px',
      fontSize: '16px',
      marginTop: '15px',
      padding: '0 15px',
      borderRadius: '8px',
      boxSizing: 'border-box',
      marginBottom: '20px',
    },
    dialogBTN: {
      marginTop: '45px !important',
    },
    locBlock: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    locInput: {
      width: '165px',
    },
    paymentHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    creditCard: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '60px',
      borderRadius: '8px',
      background: '#fff',
      padding: '0 15px',
      marginBottom: '35px',
    },
    creditInput1: {
      height: '23px',
      fontSize: '15px',
      width: '148px',
      padding: '0 0 8px 0',
      borderBottom: '1px solid #979797',
      borderRadius: '0',
    },
    creditInput2: {
      height: '23px',
      fontSize: '15px',
      width: '39px',
      padding: '0 0 8px 0',
      borderBottom: '1px solid #979797',
      borderRadius: '0',
    },
    creditInput3: {
      height: '23px',
      fontSize: '15px',
      width: '27px',
      padding: '0 0 8px 0',
      borderBottom: '1px solid #979797',
      borderRadius: '0',
    },
    billing: {
      textTransform: 'none !important',
      fontSize: '19px',
      fontWeight: 'bold',
    },
    shadow: {
      zIndex: '1',
      position: 'fixed',
      right: '0px',
      bottom: '0px',
      top: '0px',
      left: '0px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalBilling: {
      boxSizing: 'border-box',
      width: '730px',
      background: '#296C8D',
      zIndex: '1',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      flexDirection: 'column',
    },
    modalBillingM: {
      boxSizing: 'border-box',
      width: '350px',
      background: '#296C8D',
      zIndex: '2',
      left: '50%',
      top: '111%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '45px 0px 30px 50px',
      borderBottom: '5px solid #fff',
    },
    lastRow: {
      borderBottom: 'none',
    },
    rowHeader: {
      margin: '0 0 20px 0',
      fontSize: '20px',
      textAlign: 'left',
      color: '#fff',
      maxWidth: '275px',
      fontWeight: '900',
    },
    paragraph: {
      fontSize: '15px',
      lineHeight: '18px',
      textAlign: 'left',
      margin: '0',
      color: '#fff',
      maxWidth: '370px',
    },
    priceBlock: {
      width: '100%',
      maxWidth: '300px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    price: {
      fontSize: '20px',
      fontWeight: '900',
      color: '#fff',
    },
    blackBtn: {
      textTransform: 'none !important',
      background: '#000 !important',
      fontSize: '20px !important',
      color: '#fff !important',
      padding: '0px 25px !important',
      boxSizing: 'border-box !important',
      height: '55px',
      borderRadius: '25px !important',
      margin: '0 auto !important',
    },
    bottomBtn: {
      textTransform: 'none !important',
      background:
        'linear-gradient(131.04deg, #4B478D 13.29%, #CA2E86 100%) !important',
      fontSize: '20px !important',
      color: '#fff !important',
      padding: '0px 25px !important',
      boxSizing: 'border-box !important',
      height: '55px',
      borderRadius: '25px !important',
      width: '170px !important',
      margin: '20px auto 0 auto !important',
    },
    wrapper: {
      background: '#fff',
      boxSizing: 'border-box',
      width: '375px',
      height: 'auto',
      borderRadius: '51px',
      padding: '10px 0 20px 0',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '800px',
    },
  }));

  useEffect(() => {
    dispatch(clearState());
    if (wheelType === 'QR') {
      history.push('/dashboard');
    }
  }, [history, wheelType, dispatch]);

  useEffect(() => {
    if (success) {
      handleClose();
    }
  }, [success]);

  useEffect(() => {
    dispatch(getLocations());
  }, [dispatch]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries,
  });

  const classes = useStyles();
  const modal = useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLocation('');
  };

  const handleBillingOpen = () => {
    setBillingOpen(true);
  };

  const handleBillingClose = () => {
    setBillingOpen(false);
  };

  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };

  const newLocation = () => {
    if (location === '' || address === '') {
      setError(true);
      showToast('error', 'Error', 'Name & Address are required');
      return;
    }
    if (long === '' || lat === '') {
      showToast('error', 'Error', 'Can Get Coordinates');
      return;
    }
    setError(false);
    sendLocation(location, address, long, lat);
    setLocation('');
    setAddress('');
  };

  const renderTopBar = () => {
    return <GoBackButton onClick={() => history.goBack()} />;
  };

  const toWheel = () => {
    if (!fullLocation) {
      showToast('error', 'Error', 'Choose Location');
      return;
    }
    if (fullLocation.campaignId) {
      setLocationToWheel(fullLocation);
      getWheelData(fullLocation.campaignId);
      history.push(`/campaign/edit?id=${fullLocation.campaignId}`);
      showToast('success', 'Find', 'Prize wheel campaign already exists');
      return;
    }
    setLocationToWheel(fullLocation);
    history.push('/campaign/prize-wheel');
  };

  const setCoords = (long, lat) => {
    setLong(long);
    setLat(lat);
  };

  const setAddress = address => {
    setFullAddress(address);
  };

  const removeLocation = id => {
    deleteLocation(id);
    ref.current.loc();
  };

  if (loadError) return 'Error';
  // if (!isLoaded) return "Loading...";

  return (
    <Base
      theme={'light'}
      topBar={renderTopBar}
    >
      <div
        className={classes.shadow}
        style={billingOpen ? { display: 'block' } : { display: 'none' }}
      ></div>
      <div className={matches ? classes.containerM : classes.container}>
        <div
          className={matches ? classes.wrapper : 'campaign-wrapper'}
          style={{ position: 'relative' }}
        >
          <div
            className={matches ? classes.modalBillingM : classes.modalBilling}
            style={billingOpen ? { display: 'flex' } : { display: 'none' }}
          >
            <button
              className={matches ? classes.closeBtnM : classes.closeBtn}
              onClick={handleBillingClose}
            >
              <img
                src={close}
                alt=''
                style={matches ? { width: '22px', height: '22px' } : null}
              />
            </button>
            <div
              className={classes.row}
              style={matches ? { paddingLeft: '15px' } : null}
            >
              <div className={classes.description}>
                <p className={classes.rowHeader}>Creating a gift template</p>
                <p
                  className={classes.paragraph}
                  style={matches ? { fontSize: '18px' } : null}
                >
                  - Upload an image of your offer and create description of your
                  gift offers.
                </p>
              </div>
              <div className={classes.priceBlock}>
                <span className={classes.price}>Free</span>
              </div>
            </div>
            <div
              className={classes.row}
              style={matches ? { paddingLeft: '15px' } : null}
            >
              <div className={classes.description}>
                <p className={classes.rowHeader}>Spin wheel location sponsor</p>
                <p
                  className={classes.paragraph}
                  style={matches ? { fontSize: '18px' } : null}
                >
                  - Place a virtual spin wheel game at your chosen location.
                </p>
                <br />
                <p
                  className={classes.paragraph}
                  style={matches ? { fontSize: '18px' } : null}
                >
                  - Free promotional material sent to your business for cross
                  promotion.
                </p>
                <br />
                <p
                  className={classes.paragraph}
                  style={matches ? { fontSize: '18px' } : null}
                >
                  - Free notifications when players walk by your location up to
                  100 meters!
                </p>
                <br />
                <p
                  className={classes.paragraph}
                  style={matches ? { fontSize: '18px' } : null}
                >
                  - Unlimited impressions. Allow users on app to click on your
                  location and preview your spin wheel and prizes.
                </p>
              </div>
              <div className={classes.priceBlock}>
                <span className={classes.price}>$0.20 per spin</span>
              </div>
            </div>
            <div
              className={classes.row}
              style={matches ? { paddingLeft: '15px' } : null}
            >
              <div className={classes.description}>
                <p className={classes.rowHeader}>
                  Cost per collected reward /Cost per win
                </p>
                <p
                  className={classes.paragraph}
                  style={matches ? { fontSize: '18px' } : null}
                >
                  When a player wins a reward you offered either at a customer
                  location or in a random game at targeted locations.
                </p>
              </div>
              <div className={classes.priceBlock}>
                <span className={classes.price}>$0.20 per win</span>
              </div>
            </div>
            <div
              className={`${classes.row} ${classes.lastRow}`}
              style={matches ? { paddingLeft: '15px' } : null}
            >
              <div className={classes.description}>
                <p className={classes.rowHeader}>Analytics</p>
                <p
                  className={classes.paragraph}
                  style={matches ? { fontSize: '18px' } : null}
                >
                  - Get unique analytics such as changes in foot-traffic, unique
                  redeems on gifts, impressions, and demographics.
                </p>
              </div>
              <div className={classes.priceBlock}>
                <span className={classes.price}>Free</span>
              </div>
            </div>
          </div>
          <h1
            className='campaign-header'
            style={matches ? { fontSize: '24px' } : null}
          >
            Step 1 Choose your Location
          </h1>
          <p
            className={
              matches ? classes.paragraphHeaderM : classes.paragraphHeader
            }
          >
            Which location?
          </p>
          <ControlledOpenSelect
            locations={locations}
            ref={ref}
            onSelect={value => setFullLocation(value)}
            deleteLoc={value => removeLocation(value)}
          />
          <img
            src={sponsor}
            alt=''
            className={classes.sponsroIMG}
          />
          <div
            className={matches ? classes.modalM : classes.modal}
            style={open ? { display: 'flex' } : { display: 'none' }}
            ref={modal}
          >
            <button
              className={matches ? classes.closeBtnM : classes.closeBtn}
              onClick={handleClose}
              style={billingOpen ? { display: 'none' } : null}
            >
              <img
                src={close}
                alt=''
                style={matches ? { width: '22px', height: '22px' } : null}
              />
            </button>
            <label className={classes.label}>Create a name for location</label>
            <input
              type='text'
              className={matches ? classes.inputM : classes.input}
              onChange={event => setLocation(event.target.value)}
              value={location}
              placeholder="Mike's Cafe"
              style={error && !location ? { border: '1px solid red' } : null}
            />
            <label className={classes.label}>What is the address?</label>
            {!isLoaded ? (
              'loading...'
            ) : (
              <Search
                setCoords={(long, lat) => setCoords(long, lat)}
                setAddress={address => setAddress(address)}
                error={error && !address}
              />
            )}
            <div className={classes.locBlock}>
              <div>
                <label className={classes.label}>Long</label>
                <Cleave
                  type='text'
                  className={
                    matches
                      ? classes.inputM
                      : `${classes.input} ${classes.locInput}`
                  }
                  maxLength='12'
                  placeholder='-----'
                  value={long}
                  onChange={event => setLong(event.target.value)}
                  options={{
                    // delimiters: ['.'],
                    // blocks: [2, 7],
                    numeralDecimalScale: 7,
                    // numericOnly: true,
                    // numeralPositiveOnly: false,
                    numeral: true,
                  }}
                  style={matches ? { width: '155px' } : null}
                />
              </div>
              <div>
                <label className={classes.label}>Lat</label>
                <Cleave
                  type='text'
                  className={
                    matches
                      ? classes.inputM
                      : `${classes.input} ${classes.locInput}`
                  }
                  maxLength='12'
                  placeholder='-----'
                  value={lat}
                  onChange={event => setLat(event.target.value)}
                  options={{
                    // delimiters: ['.'],
                    // blocks: [2, 7],
                    numeralDecimalScale: 7,
                    // numericOnly: true,
                    // numeralPositiveOnly: false,
                    numeral: true,
                  }}
                  style={matches ? { width: '155px' } : null}
                />
              </div>
            </div>
            <div className={classes.paymentHeader}>
              <label className={classes.label}>Payment method</label>
              <img
                src={cards}
                alt=''
              />
            </div>
            <div
              className={classes.creditCard}
              style={matches ? { height: '45px' } : null}
            >
              {/* <Cleave type="text" className={`${classes.input} ${classes.creditInput1}`} onChange={event => setCardNumber(event.target.value)}  value={cardNumber} placeholder="1234 1234 1234 1234" options={{creditCard: true}}/>
              <Cleave type="text" className={`${classes.input} ${classes.creditInput2}`} placeholder="12/12" options={{date: true, datePattern: ['m', 'y']}}/>
              <Cleave type="text" maxLength="3" className={`${classes.input} ${classes.creditInput3}`} placeholder="123" options={{numeral: true}}/> */}
              <StripeForm style={{ width: '100%' }} />
            </div>
            <Button
              className={classes.billing}
              onClick={handleBillingOpen}
              style={matches ? { marginTop: '35px' } : { marginTop: '23px' }}
            >
              Review Billing details
            </Button>
            {isLoading ? (
              <div style={{ margin: 'auto' }}>
                <CircularProgress />
              </div>
            ) : (
              <Button
                variant='contained'
                className={
                  matches
                    ? classes.bottomBtn
                    : `campaign-bottomBTN ${classes.dialogBTN}`
                }
                onClick={newLocation}
              >
                Confirm
              </Button>
            )}
          </div>
          <Button
            variant='contained'
            className={matches ? classes.blackBtn : 'campaign-blackBTN'}
            onClick={handleClickOpen}
            style={open ? { zIndex: '-1' } : { zIndex: '0' }}
          >
            Sponsor a new location
          </Button>
          <Button
            variant='contained'
            className={matches ? classes.bottomBtn : 'campaign-bottomBTN'}
            onClick={() => toWheel()}
          >
            Next
          </Button>
        </div>
      </div>
    </Base>
  );
}

const mapStateToProps = state => ({
  locations: state.campaigns.locations,
  isLoading: state.campaigns.isLoading,
  success: state.campaigns.success,
  wheelType: state.login.wheelType,
});

export default connect(mapStateToProps, {
  sendLocation,
  setLocationToWheel,
  getWheelData,
  deleteLocation,
  getKey,
})(ChooseLocation);
