export const REMOVE_OFFER = Object.freeze({
  TITLE: 'Are you sure you would like to remove offer?',
  MAIN_TEXT: 'Any campaigns created with this offer will also be removed. Removing offer will delete all existing campaigns. Offers collected prior to deletion will remain in users wallet until redemption or expiry date.',
  MINOR_TEXT: 'This action can not be undone.'
});

export const REMOVE_CAMPAIGN = Object.freeze({
  TITLE: 'Are you sure you would like to remove campaign?',
  MAIN_TEXT: 'Campaign will be deleted.',
  MINOR_TEXT: 'This action can not be undone.'
});
