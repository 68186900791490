import React from "react";
import { DateRangePicker } from "materialui-daterange-picker";

const DateRangePickerComponent = ({ show, toggle, onChange, initialDateRange }) => {
  return (
    <>
      <DateRangePicker
        open={show}
        toggle={toggle}
        onChange={(range) => {
          onChange(range);
          toggle();
        }}
        initialDateRange={initialDateRange}
      />
    </>
  );
};

export default DateRangePickerComponent;
