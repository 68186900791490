import React, { useEffect, useState } from "react";
import {
  withStyles,
  makeStyles,
  Table,
  Paper,
  TableRow,
  Input,
  TableContainer,
  TableCell,
  TableBody,
  CircularProgress,
  Modal,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, connect, useSelector } from "react-redux";
import {
  fetchCardTemplate,
  updateCardTemplate,
  searchCardTemplate,
  deleteCardTemplate
} from "../../../redux/requests/pendingApprovalRequest";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardDetails from "./CardDetails";
import { TOAST } from "../../../redux/actions/actions-types";
import { RemoveActionCell } from "../../../component/ui/TableUtils";
import { REMOVE_OFFER } from "../../../constants/text";
import history from '../../../helpers/history';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const CssTextField = withStyles({
  root: {
    "& .MuiInput-input": {
      color: "#000",
    },
    "& label.Mui-focused": {
      color: "red",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "red",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  tableheadcell: {
    backgroundColor: "#1C1524",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "23px",
    textTransform: "capitalize",
  },
  tablebodycell: {
    fontFamily: "Lato",
    fontSize: "22px",
    fontWeight: "400",
    lineHeight: "27px",
    textTransform: "capitalize",
  },
  tablebodycellM: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
    textTransform: "capitalize",
    padding: "8px!important",
  },
  tableOpenTitle: {
    cursor: 'pointer',
  },
  backwrapper: {
    minHeight: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 2rem",
    left: "0",
    top: "0",
  },
  textTypo: {
    color: "#fff",
    marginTop: "0 !important",
    marginBottom: "20px !important",
  },
  paper: {
    position: "absolute",
    width: "auto",
    maxHeight: "80vh",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: "hidden",
    overflowY: "auto",
  };
}

function Template(props) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const classes = useStyles();
  const [cards, setCards] = useState();
  const [open, setOpen] = useState(false);
  const [cardId, setCardId] = useState();
  const [search, setSearch] = useState("");
  const [showApproveSpinner, setShowApproveSpinner] = useState();
  const [showRejectSpinner, setShowRejectSpinner] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  const [rejectText, setRejectText] = useState("");
  const dispatch = useDispatch();
  const [modalStyle] = useState(getModalStyle);
  const role = useSelector((state) => state.login.userData.role);
  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };
  useEffect(() => {
    getTemplates();
  }, [role]);

  const getTemplates = async () => {
    let filter = {};

    filter.order = ["date DESC"];
    let cardData = await fetchCardTemplate(filter);
    if (cardData && cardData.data) {
      setCards(cardData.data);
    }
  };

  const approve = async (id) => {
    let data = {
      status: "active",
      isApproved: true,
    };
    setShowApproveSpinner(id);
    let updateResp = await updateCardTemplate(id, data);
    if (updateResp.name !== "Error") {
      showToast("success", "Done", "Approved!");
    }
    getTemplates();
    setShowApproveSpinner();
  };
  const reject = (id) => {
    setCardId(id);
    setOpen(true);
  };
  const submitReject = async () => {
    let rejectReason = rejectText && rejectText.trim();
    if (!rejectReason || rejectReason.length < 1) {
      return showToast("error", "Error", "You should specify a rejection reject.");
    }
    let data = {
      status: "rejected",
      isApproved: false,
      rejectionReason: rejectReason,
    };
    setShowRejectSpinner(true);
    let updateResp = await updateCardTemplate(cardId, data);

    if (updateResp.name !== "Error") {
      showToast("success", "Done", "Rejected");
      getTemplates();
      setOpen(false);
    }
    setShowRejectSpinner(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const reducer = () => {
    let totalQuantity = 0;
    if (!Array.isArray(cards)) return totalQuantity;
    for (let card of cards) {
      totalQuantity = totalQuantity + card.quantity;
    }
    return totalQuantity;
  };

  const searchFor = async () => {
    let res = await searchCardTemplate(search);

    if (res.data && res.data && Array.isArray(res.data)) {
      if (res.data.length === 0) {
        showToast("error", "Alert", "No match found");
      }
      setCards(res.data);
    }
  };

  const onDelete = (row) => {
    setCards(cards.filter(card => card.id !== row.id));
    deleteCardTemplate(row.id);
  };

  const onEditOffer = (cardId) => {
    history.push(`/campaigns/edit-offer-details/${cardId}`);
  };

  return (
    <div className="card-container">
      <div>
        <Input
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={(e) => (e.key === "Enter" ? searchFor() : "")}
          inputProps={{ style: { color: "black" } }}
        />
        <Button type="submit" onClick={searchFor}>
          Search
        </Button>
      </div>
      <div className={classes.backwrapper}>
        <h3 align="left" className={classes.textTypo}>
          Total Gift Cards available{" "}
        </h3>

        <h3 align="left" className={classes.textTypo}>
          <span className="m-r-4"> Total: {cards?.length} Templates</span>
        </h3>

        <h3 className={classes.textTypo}>
          <span>Quantity: {reducer()}</span>
        </h3>
      </div>
      <TableContainer component={Paper} className="rounded-0">
        <Table className={classes.table} aria-label="customized table">
          <TableBody>
            {cards?.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell className={matches? classes.tablebodycellM : classes.tablebodycell}>
                    <span className={classes.tableOpenTitle} onClick={() => {
                      setSelectedCard(row);
                      setShowDetails(true);
                    }}>{row.title}</span>
                </StyledTableCell>
                {/* <StyledTableCell className={classes.tablebodycell}>
                  <Link to="">Company: </Link>
                </StyledTableCell> */}
                <StyledTableCell className={matches? classes.tablebodycellM : classes.tablebodycell}>Quantity: {row.quantity}</StyledTableCell>
                <StyledTableCell className={matches? classes.tablebodycellM : classes.tablebodycell}>
                  status : {row.status.split("-").join(" ")}
                </StyledTableCell>
                <RemoveActionCell onApprove={() => { onDelete(row); }} title={REMOVE_OFFER.TITLE} mainText={REMOVE_OFFER.MAIN_TEXT} minorText={REMOVE_OFFER.MINOR_TEXT} />
                <StyledTableCell className={matches? classes.tablebodycellM : classes.tablebodycell}>
                  {role === "admin" && row.status.includes("pending") && (
                    <>
                      <button className="go-back-button text-success" style={{marginBottom: "10px", border: "1px solid #dddddd"}} onClick={() => approve(row.id)}>
                        {showApproveSpinner === row.id ? <CircularProgress /> : "Approve"}
                      </button>
                      <button className="go-back-button text-danger" style={{border: "1px solid #dddddd"}} onClick={() => reject(row.id)}>
                        Reject
                      </button>
                    </>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={showDetails}
        style={{ overflowX: "auto" }}
        onClose={() => {
          setSelectedCard({});
          setShowDetails(false);
        }}>
        <div style={modalStyle} className={classes.paper}>
          <div id="simple-modal-description">
            <CardDetails details={selectedCard} />
          </div>
          <Box mt={2} style={{ textAlign: "center" }}>
            <Button
              color="inherit"
              size="large"
              variant="outlined"
              style={{margin: '0 5px'}}
              onClick={() => {
                setSelectedCard({});
                setShowDetails(false);
              }}>
              Close
            </Button>

            {selectedCard &&
            <Button
              color="inherit"
              size="large"
              variant="outlined"
              style={{margin: '0 5px', paddingLeft: '15px'}}
              onClick={() => {
                onEditOffer(selectedCard?.id)
              }}>
              <EditIcon style={{marginRight: '5px', fontSize: 17}}/>
              Edit
            </Button>
            }
          </Box>
        </div>
      </Modal>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reject</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Submit a reason why this template is being rejected. So that owner can review this and make changes
            accordingly.
          </DialogContentText>
          <CssTextField
            autoFocus
            margin="dense"
            id="reason"
            label="Rejection reason"
            onChange={(e) => setRejectText(e.target.value)}
            multiline
            InputProps={{
              className: classes.textDark,
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick={() => submitReject()}>
            {showRejectSpinner ? <CircularProgress /> : "Reject"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    cards: state.approvals.cards,
  };
}

export default connect(mapStateToProps, null)(Template);
