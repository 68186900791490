import React from "react";

const DiscountCodeInput = ({ value, onChange, hideLable }) => {
  return (
    <div className={"step-2-input-container"}>
      {!hideLable && (
        <div className={"label-container"}>
          <label>Discount Code:</label>
        </div>
      )}
      <div className={"right-section"}>
        <input className={"gift-card-text-input"} type={"text"} value={value} onChange={onChange} />
        <div>
          <ul>
            <li>
              <p>*For online businesses</p>
              <p>Customers will use this code in checkout to redeem offer.</p>
            </li>
            <li>
              <p>*For local businesses</p>
              <p>Customers will display to merchant in store for verification.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DiscountCodeInput;
