import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import RadioCard from '../../../../component/Layout/RadioCard';
import chest1 from '../../../../assets/images/chest/Chest1.png';
import chest2 from '../../../../assets/images/chest/Chest2.png';
import chest3 from '../../../../assets/images/chest/Chest3.png';
import Hint from '../../../../assets/icons/hintSmall.svg';

const ChooseTier = ({ selectedValue, onTierSelect }) => {
  const useStyles = makeStyles(theme => ({
    root: {},
    radioGroup: {
      display: 'flex',
      justifyContent: 'space-around',
    },
    wrapper: {
      marginTop: '15px',
    },
    imageWrapper: {
      width: '30px',
      height: '30px',
    },
    chestImage: {
      objectFit: 'contain',
      maxWidth: '100%',
    },
    cardInformationWrapper: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      position: 'relative',
      gap: '10px',
    },
    tooltip: {
      position: 'absolute',
      top: '-10px',
      right: '-10px',
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <h2>Choose chest tier</h2>
      <div className={classes.radioGroup}>
        <RadioCard
          value={1}
          selectedValue={selectedValue}
          onChange={onTierSelect}
        >
          <div className={classes.cardInformationWrapper}>
            <h3>Tier 1</h3>
            <div
              className={classes.imageWrapper}
              title='Require 1 key'
            >
              <img
                src={chest1}
                alt='chest_tier_1'
                className={classes.chestImage}
              />
            </div>
            <Tooltip
              className={classes.tooltip}
              arrow
              placement='top'
              title={
                <p>
                  - Requires 1 key to open.
                  <br />
                  - Can contain gift cards up to $10.
                  <br />- All users can open.
                </p>
              }
            >
              <img
                src={Hint}
                alt='hint'
                style={{ width: '15px' }}
              />
            </Tooltip>
          </div>
        </RadioCard>
        <RadioCard
          value={2}
          selectedValue={selectedValue}
          onChange={onTierSelect}
        >
          <div className={classes.cardInformationWrapper}>
            <h3>Tier 2</h3>

            <div className={classes.imageWrapper}>
              <img
                src={chest2}
                alt='chest_tier_2'
                className={classes.chestImage}
              />
            </div>
            <Tooltip
              className={classes.tooltip}
              arrow
              placement='top'
              title={
                <p>
                  - Requires 2 keys.
                  <br />
                  - Can contain gift cards up to $50.
                  <br />- Users with tier 2 and 3 can open.
                </p>
              }
            >
              <img
                src={Hint}
                alt='hint'
                style={{ width: '15px' }}
              />
            </Tooltip>
          </div>
        </RadioCard>
        <RadioCard
          value={3}
          selectedValue={selectedValue}
          onChange={onTierSelect}
        >
          <div className={classes.cardInformationWrapper}>
            <h3>Tier 3</h3>

            <div className={classes.imageWrapper}>
              <img
                src={chest3}
                alt='chest_tier_3'
                className={classes.chestImage}
              />
            </div>
            <Tooltip
              className={classes.tooltip}
              arrow
              placement='top'
              title={
                <p>
                  - Requires 3 or more keys.
                  <br />
                  - Can contain any gift cards.
                  <br />- Only users with tier 3 can open.
                </p>
              }
            >
              <img
                src={Hint}
                alt='hint'
                style={{ width: '15px' }}
              />
            </Tooltip>
          </div>
        </RadioCard>
      </div>
    </div>
  );
};

export default ChooseTier;
