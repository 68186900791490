import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  useMediaQuery,
  Button,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Modal,
  Box,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Slider from 'react-slick';
import Base from '../../component/Layout/Base';
import Hint from '../../assets/icons/hintSmall.svg';
import GoBackButton from '../../component/Layout/GoBackButton';
import CardDetails from '../PendingApprovals/template/CardDetails';
import GiftCard from '../GiftCard/DesignOffer/GiftCard';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  selectOffer,
  setSliderIndex,
  setAnalyticsId,
  getOffers,
  getChestsByCard,
} from '../../redux/actions/dropCardsActions';
import {
  getWheelData,
  getTargetedCampaign,
  getStatistics,
  setWheelType,
} from '../../redux/actions/campaignsActions';
import { TOAST } from '../../redux/actions/actions-types';

const imgURL = `${process.env.REACT_APP_IMG_URL}`;

function SampleNextArrow(props) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={matches ? { top: '250px' } : null}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={matches ? { top: '250px' } : null}
      onClick={onClick}
    />
  );
}

function SelectOffer(props) {
  const [cardSide, setCardSide] = useState('front');
  const matches = useMediaQuery(`(max-width:500px)`);
  const history = useHistory();
  const useStyles = makeStyles(theme => ({
    root: {},
    selectBTN: {
      boxSizing: 'border-box',
      width: '185px',
      height: '55px',
      color: '#fff',
      background: '#000',
      borderRadius: '35px',
      fontSize: '29px',
      margin: '36px 0 15px',
      textTransform: 'capitalize',
    },
    selectBTNM: {
      boxSizing: 'border-box',
      width: '150px',
      height: '50px',
      color: '#fff',
      background: '#000',
      borderRadius: '25px',
      fontSize: '20px',
      margin: '25px 0 15px',
      textTransform: 'capitalize',
    },
    cardTitle: {
      margin: '0',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: 'rgba(0, 0, 0, 0.75385)',
      color: '#ffffff',
      fontSize: '25px',
      width: '100%',
      height: '83px',
      lineHeight: '83px',
    },
    sliderContainer: {
      position: 'relative',
    },
    sliderIMG: {
      width: '440px',
      height: '279px',
    },
    hover: {
      '&:hover': {
        opacity: '0.75',
      },
    },
  }));

  const setNextcard = next => {
    props.setIndex(next);
    props.selectOffer(props.cards[next].id, next);
    history.push(`offer?id=${props.cards[next].id}`);
    setCardSide('front');
  };
  const classes = useStyles();

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: props.initialSlide,
    beforeChange: (current, next) => setNextcard(next),
    nextArrow: <SampleNextArrow style={{ color: 'red' }} />,
    prevArrow: <SamplePrevArrow className={classes.cardTitle} />,
  };

  const switchCardSide = () => {
    setCardSide(cardSide === 'front' ? 'back' : 'front');
  };

  return (
    <div>
      <Slider
        {...settings}
        className={matches ? 'offerPageSliderM' : 'offerPageSlider'}
      >
        {props.cards?.map((card, i) => {
          return (
            <React.Fragment key={i}>
              <GiftCard
                {...props.offer}
                title={card.title}
                cardPhoto={`${imgURL}/card-photo/${card.cardPhoto}`}
                side={cardSide}
                logoPhoto={
                  props.offer.logoShowing
                    ? props.offer.logoPhoto &&
                      props.offer.logoPhoto.includes(window.location.protocol)
                      ? props.offer.logoPhoto
                      : props.offer.logoPhoto.length > 0
                      ? `${imgURL}/avatars/${props.offer.logoPhoto}`
                      : null
                    : null
                }
                view
                viewDetails
                shadow
                rounded
                step={3}
                margin
              />
              <button
                className={`${classes.hover} transparent-btn view-details-btn`}
                onClick={switchCardSide}
              >
                Tap to view details
              </button>
            </React.Fragment>
          );
        })}
      </Slider>
    </div>
  );
}

function PerformanceDateSelect({ onSelect }) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const [range, setDateRange] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleChange = event => {
    setDateRange(event.target.value);
    if (event.target.value !== 0) {
      onSelect(moment().subtract(event.target.value, 'days').format('x'));
    } else {
      onSelect(0);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const useStyles = makeStyles(theme => ({
    root: {},
    select: {
      width: '200px',
      background: 'transparent',
      boxSizing: 'border-box',
      fontSize: '24px',
      color: '#000 !important',
      textAlign: 'right',
      '&::before': {
        content: "''",
        display: 'none',
      },
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <FormControl>
        <Select
          labelId='demo-controlled-open-select-label'
          id='demo-controlled-open-select'
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={range}
          onChange={handleChange}
          className={classes.select}
          style={matches ? { fontSize: '20px', width: 'auto' } : null}
        >
          <MenuItem value={1}>Today</MenuItem>
          <MenuItem value={7}>Last 7 days</MenuItem>
          <MenuItem value={14}>Last 14 days</MenuItem>
          <MenuItem value={30}>Last 30 days</MenuItem>
          <MenuItem value={0}>All Time</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

function Offer({
  cards,
  index,
  offer,
  initialSlide,
  getWheelData,
  getTargetedCampaign,
  isLoading,
  setSliderIndex,
  stats,
  setAnalyticsId,
  selectOffer,
  chests,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [cardIndex, setCardIndex] = useState(initialSlide || 0);
  const [initialSlideIndex, setSlideIndex] = useState(initialSlide || '');
  const [selectedCard, setSelectedCard] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const matches = useMediaQuery(`(max-width:500px)`);
  const useStyles = makeStyles(theme => ({
    root: {},
    container: {
      textAlign: 'center',
      width: '100%',
      marginTop: '10px',
      marginBottom: '80px',
      display: 'flex',
      justifyContent: 'center',
    },
    wrapper: {
      background: '#fff',
      boxSizing: 'border-box',
      width: '375px',
      height: 'auto',
      borderRadius: '51px',
      padding: '30px 0 20px 0',
      display: 'flex',
      flexDirection: 'column',
    },
    statusContainer: {
      boxSizing: 'border-box',
      width: '100%',
      background: '#fff',
      borderRadius: '13px',
      padding: '12px 22px',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '19px 0',
    },
    status: {
      display: 'block',
      fontSize: '20px',
    },
    pause: {
      display: 'block',
      padding: '6px 0',
      minWidth: 'initial',
      textTransform: 'none',
      fontSize: '25px',
      fontWeight: 'bold',
    },
    performanceContainer: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      gridRowGap: '35px',
      margin: '35px 0 0 0',
    },
    performanceContainerM: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      gridRowGap: '20px',
      margin: '0',
      background: '#fff',
      borderRadius: '13px',
      padding: '20px 0 0 0',
      boxSizing: 'border-box',
    },
    performance: {
      fontSize: '24px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
      fontWeight: 'bold',
    },
    statisticsBlock: {
      alignItems: 'flex-start',
      textAlign: 'left',
    },
    statisticsHeader: {
      fontSize: '22px',
      fontWeight: 'bold',
      display: 'block',
      marginBottom: '14px',
      marginTop: '-4px',
      whiteSpace: 'nowrap',
    },
    statisticsValue: {
      fontSize: '25px',
    },
    campaignsContainer: {
      boxSizing: 'border-box',
      width: '100%',
      background: '#fff',
      borderRadius: '13px',
      padding: '22px 0 22px 22px',
      justifyContent: 'space-between',
      marginBottom: '30px',
      margin: '19px 0',
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    label: {
      textTransform: 'none',
      fontSize: '22px',
      fontWeight: 'bold',
      lineHeight: '1',
    },
    text: {
      padding: '0',
      margin: '0 22px 0 0',
    },
    campaignLine: {
      display: 'grid',
      gridTemplateColumns: '210px 1fr 1fr',
      justifyItems: 'left',
      alignItems: 'center',
    },
    campaignName: {
      fontSize: '18px',
      fontWeight: 'bold',
      padding: '18px 0',
    },
    iconBtn: {
      justifySelf: 'right',
      marginRight: '15px',
      color: '#000',
    },
    campaignStatus: {
      fontSize: '18px',
    },
    hint: {
      cursor: 'pointer',
      display: 'block',
      marginRight: '9px',
    },
    paper: {
      position: 'absolute',
      width: 'auto',
      maxHeight: '80vh',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      outline: 'none',
    },
    modal: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden',
      overflowY: 'auto',
    },
    statsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    circular: {
      margin: '0 auto',
      paddingTop: '35px',
      height: '306px',
      boxSizing: 'border-box',
    },
    circularM: {
      margin: '0 auto',
      paddingTop: '20px',
      height: '237px',
      boxSizing: 'border-box',
    },
    btnContainer: {
      boxSizing: 'border-box',
      width: '100%',
      background: '#fff',
      borderRadius: '13px',
      minHeight: '90px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    analyticsText: {
      fontSize: '25px',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  }));
  const classes = useStyles();

  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  useEffect(() => {
    if (id && !offer.id && !cards) {
      selectOffer(id);
    }
    if (!cards) {
      dispatch(getOffers());
    }
  }, [offer.id, dispatch, location.search, cards, id, selectOffer]);

  useEffect(() => {
    if (cards && initialSlideIndex === '') {
      const index = cards.findIndex(card => card.id === id);
      setSlideIndex(index);
      setIndex(index);
    }
  }, [cards, id, initialSlideIndex, dispatch]);

  useEffect(() => {
    if (offer.id && !chests) {
      dispatch(getChestsByCard(id));
    }
  }, [offer.id, dispatch, chests, id]);

  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };

  const setIndex = value => {
    setCardIndex(value);
  };

  const onEditOffer = cardId => {
    setSliderIndex(cardIndex);
    history.push(`/campaigns/edit-offer-details/${cardId}`);
  };

  const getCardAnalytics = () => {
    history.push(`/analytics?id=${offer.id}&type=card`);
  };

  const renderTopBar = () => {
    return <GoBackButton onClick={() => history.goBack()} />;
  };

  const toCampaign = index => {
    setSliderIndex(cardIndex);

    switch (offer.cardCampaigns[index].type) {
      case 'standard': {
        history.push(`/campaign?id=${offer.cardCampaigns[index].id}`);
        break;
      }
      case 'wheel': {
        dispatch(setWheelType('standard'));
        history.push(
          `/campaign/edit?id=${offer.cardCampaigns[index].id}&location=true`
        );
        break;
      }
      case 'qr-wheel': {
        dispatch(setWheelType('QR'));
        history.push(
          `/campaign/edit?id=${offer.cardCampaigns[index].id}&type=QR&location=true`
        );
        break;
      }
      case 'country-wide': {
        history.push(`/campaign?id=${offer.cardCampaigns[index].id}`);
        break;
      }
      default:
        history.push(
          `/campaign/timed-chest/edit?id=${offer.cardCampaigns[index].id}`
        );
        break;
    }
  };

  if (!offer.id) {
    return (
      <Base theme={'light'}>
        <LinearProgress />
      </Base>
    );
  }

  const setRange = range => {
    dispatch(getStatistics(offer.id, range, 'card'));
  };

  let companyColor;
  let color;
  let type;

  if (cards) {
    switch (cards[cardIndex]?.status) {
      case 'active': {
        companyColor = { color: '#00D913' };
        break;
      }
      case 'paused': {
        companyColor = { color: '#A9A9A9' };
        break;
      }
      case 'expired' || 'stopped': {
        companyColor = { color: '#FF0000' };
        break;
      }
      default:
        companyColor = { color: 'red' };
        break;
    }
  }

  return (
    <Base
      theme={'light'}
      topBar={renderTopBar}
    >
      <div className={classes.container}>
        <div
          className={matches ? classes.wrapper : 'campaign-wrapper-offers'}
          style={{ height: 'auto' }}
        >
          <SelectOffer
            cards={cards}
            offer={offer}
            setIndex={value => setIndex(value)}
            selectOffer={selectOffer}
            index={index}
            initialSlide={initialSlideIndex}
          />
          {isLoading ? (
            <div style={{ margin: 'auto' }}>
              <CircularProgress />
            </div>
          ) : (
            <React.Fragment>
              <div
                className={classes.statusContainer}
                style={matches ? { width: '375px' } : null}
              >
                <div style={{ textAlign: 'left' }}>
                  <span
                    className={classes.status}
                    style={companyColor}
                  >
                    <span className='bold'>Status:</span>
                    {cards?.length ? cards[cardIndex].status : ''}
                  </span>
                  <span
                    className={classes.pause}
                    style={matches ? { fontSize: '20px' } : null}
                  >
                    {offer.cardCampaigns
                      ? `${offer.cardCampaigns.length} campaigns`
                      : 'no campaigns'}
                  </span>
                </div>
                <Button
                  className={classes.pause}
                  style={matches ? { fontSize: '16px' } : null}
                  onClick={() => {
                    setSelectedCard(offer);
                    setShowDetails(true);
                  }}
                >
                  Edit offer details
                </Button>
              </div>
              <div
                className={classes.statusContainer}
                style={{ display: 'block' }}
              >
                <div className={classes.statsHeader}>
                  <span
                    className={classes.performance}
                    style={matches ? { fontSize: '18px' } : null}
                  >
                    Performance summary
                  </span>
                  <PerformanceDateSelect onSelect={value => setRange(value)} />
                </div>
                {!offer.cardStatistics ? (
                  <div
                    className={matches ? classes.circularM : classes.circular}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div
                    className={
                      matches
                        ? classes.performanceContainerM
                        : classes.performanceContainer
                    }
                  >
                    <div className={`${classes.statisticsBlock} flex`}>
                      <Tooltip
                        title='Number of offers won buy users'
                        className={classes.hint}
                      >
                        <img
                          src={Hint}
                          alt=''
                          style={matches ? { width: '15px' } : null}
                        />
                      </Tooltip>
                      <div className={`${classes.statisticsBlock} flex`}>
                        <div>
                          <span
                            className={classes.statisticsHeader}
                            style={matches ? { fontSize: '16px' } : null}
                          >
                            Collects
                          </span>
                          <span className={classes.statisticsValue}>
                            {offer.cardStatistics.collects > -1
                              ? offer.cardStatistics.collects
                              : 'No Data'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${classes.statisticsBlock} flex`}
                      style={{ paddingLeft: '10px' }}
                    >
                      <Tooltip
                        title='Number of times an offer is clicked to view in the app'
                        className={classes.hint}
                      >
                        <img
                          src={Hint}
                          alt=''
                          style={matches ? { width: '15px' } : null}
                        />
                      </Tooltip>
                      <div className={`${classes.statisticsBlock} flex`}>
                        <div>
                          <span
                            className={classes.statisticsHeader}
                            style={matches ? { fontSize: '16px' } : null}
                          >
                            Total engagements:
                          </span>
                          <span className={classes.statisticsValue}>
                            {offer.cardStatistics.totalEngagements > -1
                              ? offer.cardStatistics.totalEngagements
                              : 'No Data'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={`${classes.statisticsBlock} flex`}>
                      <Tooltip
                        title='Number of times a user clicks to spin at location'
                        className={classes.hint}
                      >
                        <img
                          src={Hint}
                          alt=''
                          style={matches ? { width: '15px' } : null}
                        />
                      </Tooltip>
                      <div className={`${classes.statisticsBlock} flex`}>
                        <div>
                          <span
                            className={classes.statisticsHeader}
                            style={matches ? { fontSize: '16px' } : null}
                          >
                            Expired:
                          </span>
                          <span className={classes.statisticsValue}>
                            {offer.cardStatistics.expired > -1
                              ? offer.cardStatistics.expired
                              : 'No Data'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${classes.statisticsBlock} flex`}
                      style={{ paddingLeft: '10px' }}
                    >
                      <Tooltip
                        title='Number of time link on the back of the gift offer'
                        className={classes.hint}
                      >
                        <img
                          src={Hint}
                          alt=''
                          style={matches ? { width: '15px' } : null}
                        />
                      </Tooltip>
                      <div className={`${classes.statisticsBlock} flex`}>
                        <div>
                          <span
                            className={classes.statisticsHeader}
                            style={matches ? { fontSize: '16px' } : null}
                          >
                            Impressions:
                          </span>
                          <span className={classes.statisticsValue}>
                            {offer.cardStatistics.impressions > -1
                              ? offer.cardStatistics.impressions
                              : 'No Data'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={`${classes.statisticsBlock} flex`}>
                      <Tooltip
                        title='Number of times gift offer was clicked to be redeemed'
                        className={classes.hint}
                      >
                        <img
                          src={Hint}
                          alt=''
                          style={matches ? { width: '15px' } : null}
                        />
                      </Tooltip>
                      <div className={`${classes.statisticsBlock} flex`}>
                        <div>
                          <span
                            className={classes.statisticsHeader}
                            style={matches ? { fontSize: '16px' } : null}
                          >
                            Redeems:
                          </span>
                          <span className={classes.statisticsValue}>
                            {offer.cardStatistics.redeems > -1
                              ? offer.cardStatistics.redeems
                              : 'No Data'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${classes.statisticsBlock} flex`}
                      style={{ paddingLeft: '10px' }}
                    >
                      <Tooltip
                        title='Expenses for campaign activity'
                        className={classes.hint}
                      >
                        <img
                          src={Hint}
                          alt=''
                          style={matches ? { width: '15px' } : null}
                        />
                      </Tooltip>
                      <div className={`${classes.statisticsBlock} flex`}>
                        <div>
                          <span
                            className={classes.statisticsHeader}
                            style={matches ? { fontSize: '16px' } : null}
                          >
                            Amount spent:
                          </span>
                          <span className={classes.statisticsValue}>
                            {offer.cardStatistics.amountSpent > -1
                              ? offer.cardStatistics.amountSpent
                              : 'No Data'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={classes.btnContainer}
                style={matches ? { width: '375px' } : null}
              >
                <Button
                  classes={{ text: classes.analyticsText }}
                  style={matches ? { fontSize: '20px' } : null}
                  onClick={() => getCardAnalytics()}
                >
                  View card analytics
                </Button>
              </div>
              <div
                className={classes.campaignsContainer}
                style={matches ? { width: '375px' } : null}
              >
                <div className={classes.flex}>
                  <span style={{ fontSize: '22px', fontWeight: 'bold' }}>
                    Campaigns
                  </span>
                  <Button
                    classes={{ label: classes.label, text: classes.text }}
                    className={classes.createBtn}
                    onClick={() =>
                      cards[cardIndex]?.status !== 'active'
                        ? showToast('error', 'Error', 'Activate Card First')
                        : history.push('/campaign/new')
                    }
                  >
                    Create New
                  </Button>
                </div>
                {offer.cardCampaigns?.length ? (
                  offer.cardCampaigns.map((offer, i) => {
                    switch (offer.status) {
                      case 'active': {
                        color = { color: '#00D913' };
                        break;
                      }
                      case 'paused': {
                        color = { color: '#A9A9A9' };
                        break;
                      }
                      case 'expired' || 'stopped': {
                        color = { color: '#FF0000' };
                        break;
                      }
                      default:
                        color = null;
                        break;
                    }
                    switch (offer.type) {
                      case 'country-wide':
                      case 'standard': {
                        type = 'Targetted campaign';
                        break;
                      }
                      case 'qr-wheel':
                      case 'wheel': {
                        type = 'Custom placement';
                        break;
                      }
                      default:
                        type = 'Chest';
                        break;
                    }
                    if (
                      moment(offer.activatesOn).isBefore(new Date()) &&
                      !offer.status
                    ) {
                      color = { color: '#FF0000' };
                    } else if (!offer.status) {
                      color = { color: '#00D913' };
                    }
                    return (
                      <div
                        className={classes.campaignLine}
                        style={
                          i === 0 ? null : { borderTop: '1px solid #979797' }
                        }
                        key={i}
                      >
                        <span className={classes.campaignName}>{type}</span>
                        <span
                          className={classes.campaignStatus}
                          style={color}
                        >
                          <span style={{ fontWeight: 'bold' }}>Status: </span>
                          {offer.status
                            ? offer.status
                            : moment(offer.activatesOn).isBefore(new Date())
                            ? 'Expired'
                            : 'Active'}
                        </span>
                        <IconButton
                          component='span'
                          className={classes.iconBtn}
                          onClick={() => toCampaign(i)}
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </div>
                    );
                  })
                ) : (
                  <p style={{ fontSize: '22px' }}>
                    No Campaigns, Create a new one
                  </p>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <Modal
        open={showDetails}
        style={{ overflowX: 'auto' }}
        onClose={() => {
          setSelectedCard({});
          setShowDetails(false);
        }}
      >
        <div className={`${classes.paper} ${classes.modal}`}>
          <div id='simple-modal-description'>
            <CardDetails details={selectedCard} />
          </div>
          <Box
            mt={2}
            style={{ textAlign: 'center' }}
          >
            <Button
              color='inherit'
              size='large'
              variant='outlined'
              style={{ margin: '0 5px' }}
              onClick={() => {
                setSelectedCard({});
                setShowDetails(false);
              }}
            >
              Close
            </Button>

            {selectedCard && (
              <Button
                color='inherit'
                size='large'
                variant='outlined'
                style={{ margin: '0 5px', paddingLeft: '15px' }}
                onClick={() => {
                  onEditOffer(selectedCard?.id);
                }}
              >
                <EditIcon style={{ marginRight: '5px', fontSize: 17 }} />
                Edit
              </Button>
            )}
          </Box>
        </div>
      </Modal>
    </Base>
  );
}

const mapStateToProps = state => ({
  cards: state.dropCards.offers.cardWithAdditions,
  offer: state.dropCards.offer,
  index: state.dropCards.offerIndex,
  initialSlide: state.dropCards.initialSlide,
  isLoading: state.dropCards.isLoading,
  stats: state.dropCards.offer.cardStatistics,
  chests: state.dropCards.chests,
});

export default connect(mapStateToProps, {
  getWheelData,
  getTargetedCampaign,
  setSliderIndex,
  setAnalyticsId,
  selectOffer,
})(Offer);
