import React from "react";

const CardFrontOption = ({ label, warning, img, onClick }) => {

  return (
    <div className={"card-front-option"} onClick={onClick}>
      <div className={"card-front-content-container"}>
        <div className={"img-container"}>
          <div className={"border"}>
            <img src={img} alt=""/>
          </div>
        </div>
        <p>{label}</p>
        <p>{warning}</p>
      </div>
    </div>
  );
};

export default CardFrontOption;
