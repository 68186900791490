import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useMediaQuery, makeStyles, Tooltip, Button } from '@material-ui/core';
import Hint from '../../../../assets/icons/hintSmall.svg';

const ChestStatistic = ({ id, chestStatistic }) => {
  const chest = useSelector(state => state.campaigns.chest);
  const history = useHistory();

  const matches = useMediaQuery(`(max-width:500px)`);
  const useStyles = makeStyles(theme => ({
    root: {},
    container: {
      textAlign: 'center',
      width: '100%',
      marginTop: '10px',
      marginBottom: '80px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    wrapper: {
      background: '#fff',
      boxSizing: 'border-box',
      width: matches ? '375px' : '580px',
      height: 'auto',
      borderRadius: '51px',
      padding: matches ? '10px 20px 20px 20px' : '40px 40px 40px 40px',
      display: 'flex',
      flexDirection: 'column',
    },
    performanceContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridRowGap: '35px',
      margin: '0 0 15px 0',
    },
    performanceContainerM: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridRowGap: '20px',
      margin: '0 0 15px 0',
      // width: '375px',
      background: '#fff',
      borderRadius: '13px',
      padding: '10px',
      boxSizing: 'border-box',
    },
    performance: {
      fontSize: '29px',
    },
    statisticsBlock: {
      alignItems: 'flex-start',
      textAlign: 'left',
    },
    statisticsHeader: {
      fontSize: matches ? '16px' : '24px',
      fontWeight: 'bold',
      display: 'block',
      marginBottom: '14px',
      marginTop: '-4px',
    },
    statisticsValue: {
      fontSize: '31px',
    },
    hint: {
      cursor: 'pointer',
      display: 'block',
      marginRight: '9px',
    },
    analyticsText: {
      fontSize: '25px',
      fontWeight: 'bold',
      textTransform: 'none',
    },
    statusContainer: {
      margin: '0 auto 35px auto',
    },
    status: {
      display: 'block',
      fontSize: '20px',
      color: '#00D913',
    },
  }));

  const classes = useStyles();

  const isGeoChestComplete =
    chest.inShop === false && chest.status === 'completed';

  const getChestAnalytics = () => {
    history.push(`/analytics?id=${chest.id}&type=chest`);
  };

  let collected = 0;
  let redeemed = 0;

  const getCardCollectionCount = chestStatistic => {
    if (Array.isArray(chestStatistic) && chestStatistic.length) {
      chestStatistic.forEach(cardCollection => {
        switch (cardCollection.status) {
          case 'collected':
            collected += 1;
            break;
          case 'redeemed':
            redeemed += 1;
            break;
          default:
            break;
        }
      });
      return { collected, redeemed };
    }
  };

  getCardCollectionCount(chestStatistic);

  let color;
  if (chest) {
    switch (chest.status) {
      case 'active': {
        color = { color: '#00D913' };
        break;
      }
      case 'completed': {
        color = { color: '#A9A9A9' };
        break;
      }
      // case 'expired' || 'stopped': {
      //   color = { color: '#FF0000' };
      //   break;
      // }
      default:
        color = { color: '#000000' };
        break;
    }
  }

  return (
    <section className={classes.container}>
      <div className={classes.wrapper}>
        <h3 className={classes.performance}>Chest Statistic</h3>
        <div className={classes.statusContainer}>
          <span
            className={classes.status}
            style={matches ? { fontSize: '20px', ...color } : color}
          >
            <span className='bold'>Status:</span>
            {chest.status}
          </span>
        </div>

        <div
          className={
            matches
              ? classes.performanceContainerM
              : `${classes.statusContainer} ${classes.performanceContainer}`
          }
        >
          <div className={`${classes.statisticsBlock} flex`}>
            <Tooltip
              title='Card quantity at the chest creation moment'
              className={classes.hint}
            >
              <img
                src={Hint}
                alt=''
                style={matches ? { width: '15px' } : null}
              />
            </Tooltip>
            <div className={classes.statisticsWrapper}>
              <span className={classes.statisticsHeader}>Total Cards</span>
              <span className={classes.statisticsValue}>
                {chest.giftsStatic?.total || 0}
              </span>
            </div>
          </div>

          <div className={`${classes.statisticsBlock} flex`}>
            <Tooltip
              title='Card quantity in the chest left'
              className={classes.hint}
            >
              <img
                src={Hint}
                alt=''
                style={matches ? { width: '15px' } : null}
              />
            </Tooltip>
            <div>
              <span className={classes.statisticsHeader}>Card Remaining</span>
              <span className={classes.statisticsValue}>
                {chest.leftCards || 0}
              </span>
            </div>
          </div>

          <div className={`${classes.statisticsBlock} flex`}>
            <Tooltip
              title='Number of times gift offer was clicked to be redeemed'
              className={classes.hint}
            >
              <img
                src={Hint}
                alt=''
                style={matches ? { width: '15px' } : null}
              />
            </Tooltip>
            <div>
              <span className={classes.statisticsHeader}>Card Redeemed</span>
              <span className={classes.statisticsValue}>{redeemed}</span>
            </div>
          </div>

          <div className={`${classes.statisticsBlock} flex`}>
            <Tooltip
              title='Number of chest winner'
              className={classes.hint}
            >
              <img
                src={Hint}
                alt=''
                style={matches ? { width: '15px' } : null}
              />
            </Tooltip>
            <div>
              <span className={classes.statisticsHeader}>Card Collected</span>
              <span className={classes.statisticsValue}>
                {collected + redeemed}
              </span>
            </div>
          </div>

          <div className={`${classes.statisticsBlock} flex`}>
            <Tooltip
              title={
                isGeoChestComplete
                  ? 'Number of unique user used key to enter draw'
                  : 'Number of times chest was opened'
              }
              className={classes.hint}
            >
              <img
                src={Hint}
                alt=''
                style={matches ? { width: '15px' } : null}
              />
            </Tooltip>
            <div>
              <span className={classes.statisticsHeader}>
                {isGeoChestComplete ? 'All participants' : 'Opened times'}
              </span>
              <span className={classes.statisticsValue}>
                {chest?.openedCount || 0}
              </span>
            </div>
          </div>

          {isGeoChestComplete ? (
            <div className={`${classes.statisticsBlock} flex`}>
              <Tooltip
                title='Active participants when draw stared'
                className={classes.hint}
              >
                <img
                  src={Hint}
                  alt=''
                  style={matches ? { width: '15px' } : null}
                />
              </Tooltip>
              <div>
                <span className={classes.statisticsHeader}>
                  Active participants
                </span>
                <span className={classes.statisticsValue}>
                  {chest.drawParticipants || 0}
                </span>
              </div>
            </div>
          ) : null}

          <div className={`${classes.statisticsBlock} flex`}>
            <Tooltip
              title='Number of times chest was showed in app'
              className={classes.hint}
            >
              <img
                src={Hint}
                alt=''
                style={matches ? { width: '15px' } : null}
              />
            </Tooltip>
            <div>
              <span className={classes.statisticsHeader}>Viewed Times</span>
              <span className={classes.statisticsValue}>
                {chest.views || 0}
              </span>
            </div>
          </div>
        </div>
        <Button
          classes={{ text: classes.analyticsText }}
          style={matches ? { fontSize: '20px' } : null}
          onClick={() => getChestAnalytics()}
        >
          View chest winners
        </Button>
      </div>
    </section>
  );
};

export default ChestStatistic;
