import {
  httpPostCall,
  httpGetCall,
  httpPatchCall,
} from '../../common/commonService';
const URL = process.env.REACT_APP_BASE_URL;

export const addCard = async cardDetails => {
  try {
    let addCardResp = await httpPostCall(`${URL}/cards/`, cardDetails);
    return addCardResp;
  } catch (err) {
    return err;
  }
};
export const setCardPhoto = async (id, data) => {
  try {
    let setCardPhotoResp = await httpPostCall(
      `${URL}/cards/${id}/set-photo`,
      data
    );
    return setCardPhotoResp;
  } catch (err) {
    return err;
  }
};

export const fetchCards = async () => {
  try {
    let filter = {
      where: {
        // isApproved: true,
        status: { $ne: 'rejected' },
      },
    };
    let cardResp = await httpGetCall(
      `${URL}/cards?filter=${JSON.stringify(filter)}`
    );
    return cardResp;
  } catch (err) {
    return err;
  }
};
export const fetchCardsWhell = async () => {
  try {
    let cards = await httpGetCall(`${URL}/cards/for-wheel`);
    return cards;
  } catch (err) {
    return err;
  }
};

export const fetchLocation = async () => {
  try {
    let locationResp = await httpGetCall(`${URL}/locations`);
    return locationResp;
  } catch (err) {
    return err;
  }
};

export const fetchCountries = async () => {
  try {
    let countriesResp = await httpGetCall(`${URL}/countries`);
    return countriesResp;
  } catch (err) {
    return err;
  }
};

export const addCampaign = async data => {
  try {
    let campaignResp = await httpPostCall(`${URL}/campaigns`, data);
    return campaignResp;
  } catch (err) {
    return err;
  }
};

export const addCountryCampaign = async (data, id) => {
  try {
    let countryCampaignResp = await httpPostCall(
      `${URL}/campaigns?countryDocId=${id}`,
      data
    );
    return countryCampaignResp;
  } catch (err) {
    return err;
  }
};

export const editCountryCampaign = async (data, id) => {
  try {
    let countryCampaignResp = await httpPatchCall(
      `${URL}/campaigns/${id}`,
      data
    );
    return countryCampaignResp;
  } catch (err) {
    return err;
  }
};

export const fetchCampaign = async () => {
  try {
    let filter = {
      where: {
        type: {
          neq: 'wheel',
        },
      },
      include: [
        {
          relation: 'card',
        },
        {
          relation: 'campaignCategories',
          scope: { include: [{ relation: 'category' }] },
        },
      ],
      order: ['expiresOn ASC'],
    };
    let campaignResp = await httpGetCall(
      `${URL}/campaigns?filter=${JSON.stringify(filter)}`
    );
    return campaignResp;
  } catch (err) {
    return err;
  }
};

export const fetchCardAnalytics = async (id, type, userId) => {
  let dynamicURL;
  switch (type) {
    case 'card':
      dynamicURL = `/card-collections-analytics?cardId=${id}`;
      break;
    case 'campaign':
      dynamicURL = `/card-collections-analytics?campaignId=${id}`;
      break;
    case 'QR':
      dynamicURL = `/campaigns/view-leads/${userId}?campaignId=${id}`;
      break;
    case 'chest':
      dynamicURL = `/chest/statistic/${id} `;
      break;
    case 'ALL':
      dynamicURL = `/campaigns/view-leads/${userId}`;
      break;
    default:
  }

  try {
    let url = `${URL}${dynamicURL}`;

    let analyticsResp = await httpGetCall(url);
    return analyticsResp;
  } catch (err) {
    return err;
  }
};

export const fetchCampaignDetails = async id => {
  try {
    let campaignResp = await httpGetCall(`${URL}/campaigns/${id}`);
    return campaignResp;
  } catch (err) {
    return err;
  }
};

export const updateCampaignDetails = async (id, data) => {
  try {
    let campaignResp = await httpPatchCall(`${URL}/campaigns/${id}`, data);
    return campaignResp;
  } catch (err) {
    return err;
  }
};

export const fetchCardDetails = async id => {
  try {
    let cardResp = await httpGetCall(`${URL}/cards/${id}`);
    return cardResp;
  } catch (err) {
    return err;
  }
};

export const updateCardDetails = async (id, data) => {
  try {
    let cardResp = await httpPatchCall(`${URL}/cards/${id}`, data);
    return cardResp;
  } catch (err) {
    return err;
  }
};

// export const setFrontCardImage = async (id, data) => {
//   try {
//     let cardResp = await httpPostCall(`${URL}/cards/${id}/set-front`, data);
//     return cardResp;
//   } catch (err) {
//     return JSON.parse(JSON.stringify(err));
//   }
// };
// export const setBackCardImage = async (id, data) => {
//   try {
//     let cardResp = await httpPostCall(`${URL}/cards/${id}/set-back`, data);
//     return cardResp;
//   } catch (err) {
//     return JSON.parse(JSON.stringify(err));
//   }
// };

export const addCategoryCampaign = async (campaignId, categoryId) => {
  try {
    let cardResp = await httpPostCall(
      `${URL}/campaigns/${campaignId}/add-category/${categoryId}`
    );
    return cardResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const removeCampaignCategory = async (campaignId, categoryId) => {
  try {
    let cardResp = await httpPostCall(
      `${URL}/campaigns/${campaignId}/remove-category/${categoryId}`
    );
    return cardResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};
