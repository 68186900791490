import React, { useEffect, useState } from "react";
import {
  withStyles,
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
} from "@material-ui/core";

import Base from "../../component/Layout/Base";
import { fetchTiers } from "../../redux/requests/tiersRequests";
import { useHistory } from "react-router-dom";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableheadcell: {
    backgroundColor: "#1C1524",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "23px",
  },
  tablebodycell: {
    fontFamily: "Lato",
    fontSize: "22px",
    fontWeight: "400",
    lineHeight: "27px",
  },
  backwrapper: {
    // backgroundColor: "#000",
    minHeight: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0 1rem",
    position: "sticky",
    left: "0",
    top: "0",
  },
  totalCardAmount: {
    fontFamily: "Lato",
    fontSize: "25px",
    fontWeight: "700",
    lineHeight: "29px",
    textAlign: "left",
    padding: "70px 15px",
  },
});

const TiersList = () => {
  const classes = useStyles();
  const [tiersList, setTiersList] = useState([]);
  const history = useHistory();

  // Fetch location list
  useEffect(() => {
    fetchTiers()
      .then((res) => {
        setTiersList(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
    return;
  }, []);

  return (
    <Base theme={"light"}>
      <div className="card-container campaign-list-area">
        <div className={classes.backwrapper}>
          <button
            className={`go-back-button active accept-reject-button`}
            onClick={() => {
              history.push("/tiers/add");
            }}>
            Add+
          </button>
        </div>
        <TableContainer className="rounded-0">
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell className={classes.tableheadcell}>#</StyledTableCell>
                <StyledTableCell className={classes.tableheadcell}>Min</StyledTableCell>
                <StyledTableCell className={classes.tableheadcell}>Max</StyledTableCell>
                <StyledTableCell className={classes.tableheadcell}>Gold value</StyledTableCell>
                <StyledTableCell className={classes.tableheadcell}>Chance</StyledTableCell>

                <StyledTableCell className={classes.tableheadcell}>Activate after(views)</StyledTableCell>
                <StyledTableCell className={classes.tableheadcell}>Win after(views)</StyledTableCell>
                <StyledTableCell className={classes.tableheadcell}>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(tiersList) &&
                tiersList.map(
                  ({ id, goldValue, chance, min, max, activateCampaignAfterViews, canWinAfterViews }, index) => (
                    <StyledTableRow key={id} className={"table-row-min-height"}>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>${min}</StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>${max}</StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>{goldValue}</StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>{chance} %</StyledTableCell>

                      <StyledTableCell className={classes.tablebodycell}>{activateCampaignAfterViews}</StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>{canWinAfterViews}</StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>
                        <button
                          className={`go-back-button active accept-reject-button`}
                          onClick={() => {
                            history.push(`/tiers/edit/${id}`);
                          }}>
                          Edit
                        </button>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Base>
  );
};

export default TiersList;
