import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const CityItem = ({ id, name, country, onDismiss }) => {
  return (
    <div className={"city-item"}>
      <p>{name}</p>
      <span onClick={() => onDismiss(name)}>
        <FontAwesomeIcon icon={faTimes} color={"#fff"} />
      </span>
    </div>
  );
};

export default CityItem;
