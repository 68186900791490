import { httpPostCall, httpGetCall } from "../../common/commonService";
const URL = process.env.REACT_APP_BASE_URL;

export const addPaymentMethod = async (data) => {
  try {
    let paymentResp = await httpPostCall(
      `${URL}/users/add-payment/?pmId=${data.pmId}&card=${JSON.stringify(data.card)}`
    );
    return paymentResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const getPaidInvoices = async () => {
  try {
    let filter = {
      where: {
        status: "paid",
      },
    };
    let invoiceResponse = await httpGetCall(`${URL}/invoices?filter=${JSON.stringify(filter)}`);
    return invoiceResponse;
  } catch (err) {
    return err;
  }
};

export const getAllInvoices = async (filter = null) => {
  try {
    let url = `${URL}/invoices`;
    if (filter) url += `?filter=${JSON.stringify(filter)}`;
    let invoiceResponse = await httpGetCall(`${url}`);
    return invoiceResponse;
  } catch (err) {
    return err;
  }
};

export const getCompanies = async (id = null) => {
  try {
    let filter = {
      where: {
        role: "company",
      },
    };
    let companiesResp = await httpGetCall(`${URL}/users/?filter=${JSON.stringify(filter)}`);
    return companiesResp;
  } catch (err) {
    return err;
  }
};
