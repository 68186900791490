import React from 'react';
import { makeStyles } from '@material-ui/core';

const RadioCard = ({ value, selectedValue, onChange, children }) => {
  const useStyles = makeStyles(theme => ({
    root: {},
    label: {
      display: 'block',
      padding: '20px',
      border: '2px solid #ccc',
      borderRadius: '10px',
      cursor: 'pointer',
      margin: '5px',
      userSelect: 'none',
      outline: 'none',
      transition: 'all 0.2s ease',
      ...(selectedValue === value && {
        borderColor: '#000',
        boxShadow: '0 0 3px rgba(0, 123, 255, 0.5)',
      }),
      '&:focus': {
        borderColor: '#007bff',
        boxShadow: '0 0 3px rgba(0, 123, 255, 0.5)',
      },
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 0 4px rgba(0, 0, 0, 0.5)',
      },
    },
    input: {
      position: 'absolute',
      opacity: 0,
      pointerEvents: 'none',
    },
  }));
  const classes = useStyles();

  return (
    <label
      className={classes.label}
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          onChange(value);
        }
      }}
    >
      <input
        type='radio'
        name='radio-card-group'
        value={value}
        checked={selectedValue === value}
        onChange={() => onChange(value)}
        className={classes.input}
      />
      {children}
    </label>
  );
};

export default RadioCard;
