import React, { useState, useEffect } from "react";
import GiftCard from "./GiftCard";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  bottomSection: {
    backgroundColor: "#FFFFFF",
    paddingTop: 1,
    paddingBottom: 40,
  },
}));

const DesignOfferFinal = ({ handleOnClickButton, details }) => {
  const classes = useStyles();
  const [card, setCard] = useState();
  const matches = useMediaQuery(`(max-width:500px)`);
  useEffect(() => {
    if (details) {
      setCard({
        backgroundImage: details.cardPhoto,
        title: details.title,
        logoPhoto: details.logoPhoto,
        description: details.details,
        offer: details.cardValue,
        quantity: details.quantity,
        discountCode: details.code,
        promoteUrlLink: details.promoteUrl,
      });
    }
  }, [details]);

  let logo;

  if (card) {
    if (card.logoPhoto) {
        logo = `${process.env.REACT_APP_IMG_URL}/avatars/${card.logoPhoto}`
      }
  }

  const cardSide = "front";
  return (
    <div>
      {card && (
        <div>
          <div className={"card-offer-container"} style={matches? {width : "375px", marginTop: "15px"} : null}>
            <div className="card-offer-content">
              <div className={"congrats-emoji"}>
                <img src={require("../../../assets/images/party.png")} alt="" />
              </div>
              <div className={"card-offer-header final"}>
                <p>Congrats on new offer</p>
              </div>
              <div className={"card-section"}>
                <GiftCard
                  side={cardSide}
                  view={true}
                  cardPhoto={card.backgroundImage}
                  title={card.title}
                  logoPhoto={logo}
                  details={card.description}
                  code={card.discountCode}
                  promoteUrl={card.promoteUrlLink}
                  rounded
                  shadow
                />
              </div>
              <div className={`bottom-section card-review-container ${classes.bottomSection}`}>
                <div className={"final-review-text"}>
                  <p>We will review shortly.</p>
                  <p>Then you can create a new campaign!</p>
                </div>
              </div>
            </div>
          </div>

          <Footer
            buttons={[
              {
                title: "Done",
                tabIndex: -1,
              },
            ]}
            onClickButton={handleOnClickButton}
          />
        </div>
      )}
    </div>
  );
};

export default DesignOfferFinal;
