import React, { useState } from "react";
import { makeStyles, withStyles, TableCell } from "@material-ui/core";
import { ApproveRemoveModal } from "./ApproveRemoveModal";

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const useStyles = makeStyles({
  buttonRemove: {
    fontFamily: "Lato",
    lineHeight: "27px",
    textTransform: "capitalize",
    color: 'red',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: 24,
    padding: '6px 12px',
    backgroundColor: '#fff',
    borderRadius: '30px',
    border: 0,
    transition: 'all, .3s',
    '&:hover': {
      backgroundColor: '#dddddd',
      transition: 'all, .3s',
    }
  }
});

export function RemoveActionCell({ onApprove, title, mainText, minorText }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleApprove = () => {
    onApprove();
    setOpen(false);
  };

  return (
    <>
      <StyledTableCell onClick={() => { setOpen(true); }}>
        <button className={classes.buttonRemove}>Remove</button>
      </StyledTableCell>

      <ApproveRemoveModal 
        open={open} 
        onClose={() => { setOpen(false); }} 
        title={title}
        mainText={mainText}
        minorText={minorText}
        onApprove={handleApprove}
      />
    </>
  );
}
