import React from "react";
import { Link } from "react-router-dom";
import Base from "./../../component/Layout/Base";
import DropBox from "./DropBox";

export default function DropCard() {

  return (
    <Base theme={"light"} topGap>
      <div className="drop-card-wrapper">
        <Link to="/drop-card/standard-gift">
          <DropBox
            boxIcon={require("../../assets/images/drop-box-standard.png")}
            toolTip={"Place your gift cards randomly"}
            giftType={"Standard Gift"}
          />
        </Link>
        <Link to="/drop-card/gold-gift">
          <DropBox
            boxIcon={require("../../assets/images/drop-box-gold.png")}
            toolTip={"Custom place you gift cards"}
            giftType={"Gold Gift"}
          />
        </Link>
      </div>
    </Base>
  );
}
