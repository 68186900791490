import { TOAST } from "../redux/actions/actions-types";
import { store } from "../redux/redusers/rootReducer";

const axios = require("axios");

// import { showLoading, hideLoading, showErrorToast } from "../lib/loader";
// import { getData } from "./asyncStorage";
const services = {};
axios.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    let state = store.getState();

    let token = state.login.accessToken;
    if (!token) {
      token = localStorage.getItem("access-token");
    }
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    store.dispatch({
      type: TOAST,
      load: {
        type: "error",
        title: "Error",
        message: "Oops! Something went wrong",
        show: true,
      },
    });
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  (error) => {
    return Promise.reject(error);
  }
);

services.axiosCall = (option) => {
  return new Promise((resolve, reject) => {
    axios(option)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        handleError(err);
        reject(err);
      });
  });
};

export const httpGetCall = (url) =>
  new Promise((resolve, reject) => {
    const option = {
      method: "get",
      url: url,
    };
    services
      .axiosCall(option)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const httpPostCall = (url, data) => {
  // console.log(url, data);

  return new Promise((resolve, reject) => {
    const option = {
      method: "post",
      url: url,
      data,
    };
    services
      .axiosCall(option)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
export const httpPutCall = (url, data) =>
  new Promise((resolve, reject) => {
    const option = {
      method: "put",
      url: url,
      data,
    };
    services
      .axiosCall(option)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const httpPatchCall = (url, data) =>
  new Promise((resolve, reject) => {
    const option = {
      method: "patch",
      url: url,
      data,
    };
    services
      .axiosCall(option)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const httpDeleteCall = (url) =>
  new Promise((resolve, reject) => {
    const option = {
      method: "delete",
      url: url,
    };
    services
      .axiosCall(option)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });

const handleError = (err) => {
  if (err?.response?.data?.error?.message) {
    // showErrorToast(err.response.data.message);
    store.dispatch({
      type: TOAST,
      load: {
        type: "error",
        title: "Error",
        message: err.response.data.error.message,
        show: true,
      },
    });
  } else {
    store.dispatch({
      type: TOAST,
      load: {
        type: "error",
        title: "Error",
        message: "Oops! Something went wrong",
        show: true,
      },
    });
    // showErrorToast("Oops! Something went wrong");
  }
};

export default services;
