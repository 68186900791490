import React from "react";
import { Tooltip, withStyles, Typography } from "@material-ui/core";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    color: "#fff",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    border: "1px solid #979797",
    marginLeft: "25px",
    fontWeight: "700",
  },
}))(Tooltip);

const DropBox = ({ boxIcon, toolTip, giftType }) => {
  return (
    <div className={"drop-box-container"}>
      <img src={boxIcon} alt="Standard Gift" width="194" />
      <div className={'tooltip-container'}>
        <p>{giftType}</p>
        <HtmlTooltip
          placement="top-start"
          title={
            <React.Fragment>
              <Typography color="inherit">{toolTip}</Typography>
            </React.Fragment>
          }
        >
          <img src={require("../../assets/images/help.png")} alt="Help" />
        </HtmlTooltip>
      </div>
    </div>
  );
};

export default DropBox;
