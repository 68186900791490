import React from "react";
import { Button } from "@material-ui/core";
const Navigator = ({
  enableNext,
  enablePrev,
  onNavigateToNext,
  onNavigateToPrev,
}) => {
  return (
    <div className={"gift-card-navigator"}>
      <Button onClick={onNavigateToPrev}>Previous Card</Button>
      <Button onClick={onNavigateToNext}>Next Card</Button>
    </div>
  );
};

export default Navigator;
