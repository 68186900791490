import React, { useState, useEffect } from 'react';
import {
  httpPostCall,
  httpPutCall,
  httpDeleteCall,
} from '../../../common/commonService';
import {
  makeStyles,
  Button,
  useMediaQuery,
  LinearProgress,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchCardsWhell,
  getChest,
} from '../../../redux/actions/campaignsActions';
import { TOAST } from '../../../redux/actions/actions-types';
import { useLocation } from 'react-router-dom';
import Cleave from 'cleave.js/react';
import Slider from 'react-slick';
import GoBackButton from '../../../component/Layout/GoBackButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Base from '../../../component/Layout/Base';
import Switch from '../../../component/Layout/SwitchInput';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import { useLoadScript } from '@react-google-maps/api';
import moment from 'moment';
import { unmountComponentAtNode } from 'react-dom';
import VerifyCheckbox from '../../GiftCard/DesignOffer/Inputs/VerifyCheckbox';
import ChestStatistic from './statistic/chestStatistic';
import { fetchCardAnalytics } from '../../../redux/requests/cardRequests';
import ErrorBoundary from '../../../component/errorBoundary/ErrorBoundary';
import ChooseTier from './components/chooseTier';
import NoOffer from '../../DropCard/StandardGift/NoOffer';

const imgURL = `${process.env.REACT_APP_IMG_URL}/card-photo/`;
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

function Search({ setCoords, setAddress, error, addressValue }) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const useStyles = makeStyles(theme => ({
    root: {},
    input: {
      outline: 'none',
      height: '60px',
      fontSize: '20px',
      marginTop: '15px',
      padding: '0 10px',
      boxSizing: 'border-box',
      marginBottom: '20px',
      width: '100%',
      border: '3px solid #D8D8D8',
      borderRadius: '10px',
    },
    inputM: {
      outline: 'none',
      border: '3px solid #D8D8D8',
      height: '40px',
      fontSize: '16px',
      marginTop: '15px',
      padding: '0 10px',
      borderRadius: '8px',
      boxSizing: 'border-box',
      marginBottom: '20px',
      width: '100%',
    },
  }));
  const classes = useStyles();
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 49.251888, lng: () => -123.081334 },
      radius: 100 * 1000,
    },
    debounce: 500,
  });

  const handleInput = e => {
    setValue(e.target.value);
  };

  const handleSelect = async address => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setCoords(lng, lat);
      setAddress(address);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={addressValue || 'Search your location'}
        className={matches ? classes.inputM : classes.input}
        style={error ? { border: '1px solid red' } : null}
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === 'OK' &&
            data.map(({ id, description }, i) => (
              <ComboboxOption
                key={i}
                value={description}
              />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
}

const libraries = ['places'];

function SampleNextArrow(props) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{ top: matches ? '235px' : '292px' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{ top: matches ? '235px' : '292px' }}
      onClick={onClick}
    />
  );
}

function SelectOffer(props) {
  const [currentPage, setCurrentPage] = useState(null);
  const [offer, setOffer] = useState(null);
  const history = useHistory();
  const matches = useMediaQuery(`(max-width:500px)`);
  const useStyles = makeStyles(theme => ({
    root: {},
    header: {
      margin: matches ? '30px 0px 15px 0px' : '50px 0px 15px 0px',
      fontSize: '28px',
    },
    slider: {
      marginBottom: matches ? '50px' : '90px',
    },
    cardTitle: {
      margin: '0',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: 'rgba(0, 0, 0, 0.75385)',
      color: '#ffffff',
      fontSize: matches ? '16px' : '18px',
      width: '101%',
      lineHeight: '30px',
    },
    sliderIMG: {
      width: '145px',
      height: '98px',
      boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.5)',
    },
    sliderContainer: {
      position: 'relative',
      outline: 'none',
    },
    slide: {
      display: 'grid',
      width: matches ? '335px' : '500px',
      gridTemplateColumns: '1fr 1fr 1fr',
      margin: '5px 0',
      boxSizing: 'border-box',
      minHeight: matches ? '205px' : '265px',
      justifyItems: 'center',
    },
    sliderBlock: {
      width: matches ? '100px' : '145px',
      height: matches ? '68px' : '98px',
      overflow: 'hidden',
      borderRadius: '14px',
      boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.5)',
      position: 'relative',
      cursor: 'pointer',
      boxSizing: 'border-box',
      '&:hover': {
        border: '3px solid',
      },
    },
    flexCenter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#E0E0E0',
    },
    pages: {
      fontSize: '25px',
    },
    offer: {
      width: matches ? '300px' : '400px',
      overflow: 'hidden',
      boxSizing: 'border-box',
      borderRadius: '7px',
      margin: '0 auto',
      position: 'relative',
    },
    offerImg: {
      width: matches ? '300px' : '400px',
      height: matches ? '169px' : '225px',
      display: 'block',
    },
    offerTitle: {
      top: '50%',
      left: '50%',
      color: '#ffffff',
      width: '100%',
      height: '60px',
      margin: '0',
      position: 'absolute',
      fontSize: '25px',
      transform: 'translate(-50%, -50%)',
      background: 'rgba(0, 0, 0, 0.75385)',
      lineHeight: '60px',
    },
    cancelBTN: {
      boxSizing: 'border-box',
      width: matches ? '130px' : '185px',
      display: 'block',
      color: 'red',
      fontSize: '20px',
      fontWeight: 'bold',
      margin: '10px auto 20px auto',
      cursor: 'pointer',
    },
  }));

  useEffect(() => {
    if (
      typeof props.current === 'number' &&
      offer === null &&
      !props.secondaryOffer
    ) {
      setOffer(props.current);
    }
  }, [props, offer]);

  useEffect(() => {
    if (
      typeof props.secondaryOffer === 'number' &&
      props.secondary &&
      props.secondaryOffer >= 0
    ) {
      setOffer(props.secondaryOffer);
    }
  }, [props]);

  const classes = useStyles();
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: (current, next) => setCurrentSlide(next),
    nextArrow: <SampleNextArrow style={{ color: 'red' }} />,
    prevArrow: <SamplePrevArrow className={classes.cardTitle} />,
  };

  const eligibleCardsByTier = props.cards.filter(
    item => item.cardTier <= props.selectedChestTier
  );

  const setCurrentSlide = next => {
    setCurrentPage(next);
  };

  const chooseOffer = id => {
    const offer = props.cards.findIndex(card => card.id === id);
    setOffer(offer);
    props.setOffer(props.cards[offer]);
  };

  const resetCard = () => {
    setOffer(null);
    props.setOffer(null);
  };

  let toRender = [];

  if (eligibleCardsByTier.length) {
    for (let i = 0; i < Math.ceil(eligibleCardsByTier.length / 6); i++) {
      toRender[i] = eligibleCardsByTier.slice(i * 6, i * 6 + 6);
    }
    if (toRender[toRender.length - 1].length === 6) {
      toRender.push([]);
    }
  }

  return (
    <React.Fragment>
      <h1
        className={classes.header}
        style={matches ? { fontSize: '25px' } : null}
      >
        {props.secondary
          ? `Choose secondary prize #${props.secondaryIndex + 1}`
          : 'Choose Primary Gift Card'}
      </h1>
      {offer != null ? (
        <React.Fragment>
          <div className={classes.offer}>
            <img
              className={classes.offerImg}
              src={`${imgURL}${props.cards[offer].cardPhoto}`}
              alt='card-foto'
            />
            <p className={classes.offerTitle}>{props.cards[offer].title}</p>
          </div>
          <span
            className={classes.cancelBTN}
            onClick={() => resetCard()}
          >
            Cancel
          </span>
        </React.Fragment>
      ) : (
        <div
          className={
            props.secondary ? `${classes.slider} slider` : classes.slider
          }
          style={{ marginBottom: props.secondary ? '20px' : 'inherit' }}
        >
          <Slider
            {...settings}
            className='offersSlider'
          >
            {toRender.map((offers, i) => {
              return (
                <div key={i}>
                  <div className={classes.slide}>
                    {offers.map((offer, i) => {
                      return (
                        <div
                          className={classes.sliderBlock}
                          key={i}
                          onClick={() => chooseOffer(offer.id)}
                        >
                          <img
                            src={`${imgURL}${offer.cardPhoto}`}
                            alt=''
                            className={classes.sliderIMG}
                          />
                          <p className={classes.cardTitle}>{offer.title}</p>
                        </div>
                      );
                    })}
                    {offers.length !== 6 ? (
                      <div
                        className={`${classes.sliderBlock} ${classes.flexCenter}`}
                        onClick={() => history.push('/gift-card')}
                      >
                        <AddIcon style={{ fontSize: '50px' }} />
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </Slider>
          <span className={classes.pages}>
            Page {currentPage >= 0 ? currentPage + 1 : 1} of {toRender.length}
          </span>
        </div>
      )}
    </React.Fragment>
  );
}

const materialTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        color: 'black',
        fontSize: '15px',
        width: '150px',
        cursor: 'pointer',
        boxSizing: 'border-box',
        textAlign: 'center',
      },
    },
  },
});

function NewCampaign() {
  const history = useHistory();
  const dispatch = useDispatch();
  const cards = useSelector(state => state.campaigns.cardsForWheel);
  const isLoading = useSelector(state => state.campaigns.isLoading);
  const chest = useSelector(state => state.campaigns.chest);
  const user = useSelector(state => state.login.userData);
  const matches = useMediaQuery(`(max-width:500px)`);
  const matches2 = useMediaQuery(`(max-width:1280px)`);
  const [probability, setProbability] = useState('');
  const [long, setLong] = useState('');
  const [lat, setLat] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [expiresOn, setExpiresOn] = useState(new Date());
  const [hasExpiresDate, setHasExpiresDate] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [keys, setKeys] = useState(1);
  const [name, setName] = useState('Chest');
  const [offer, setOffer] = useState(null);
  const [offers, setOffers] = useState([]);
  const [secondaryPrizes, setSecondaryPrizes] = useState([0]);
  const [slideIndex, setSlideIndex] = useState('');
  const [description, setDescription] = useState('');
  const [inShop, setInShop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [address, setFullAddress] = useState('');
  const [chestStatistic, setChestStatistics] = useState([]);
  const [tier, setTier] = useState(0);
  const loc = useLocation();
  const params = new URLSearchParams(loc.search);
  const id = params.get('id');
  const useStyles = makeStyles(theme => ({
    root: {},
    container: {
      textAlign: 'center',
      width: '100%',
      marginTop: '10px',
      marginBottom: '30px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    wrapper: {
      background: '#fff',
      boxSizing: 'border-box',
      width: matches ? '375px' : '580px',
      height: 'auto',
      borderRadius: '51px',
      padding: matches ? '10px 20px 20px 20px' : '80px 40px 39px 40px',
      display: 'flex',
      flexDirection: 'column',
    },
    inputsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: matches ? '0' : '0 40px',
    },
    inputsWrapperCenter: {
      justifyContent: hasExpiresDate ? 'space-between' : 'center',
    },
    inputsWrapperJustifyCenter: {
      justifyContent: 'center',
    },
    header: {
      fontWeight: 'bold',
      fontSize: '18px',
      display: 'block',
      textAlign: 'left',
      padding: '20px 0',
    },
    input: {
      background: '#D8D8D8',
      border: 'none',
      outline: 'none',
      width: matches ? '130px' : '150px',
      height: matches ? '40px' : '60px',
      fontSize: '25px',
      padding: '0 10px',
      borderRadius: '10px',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    inputFlex: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    inputProbability: {
      position: 'relative',
      '&::after': {
        display: 'block',
        content: '"%"',
        position: 'absolute',
        top: '77px',
        right: '5px',
        'font-size': '25px',
      },
    },
    quantityInput: {
      width: matches ? '35px' : '50px',
      border: 'none',
      outline: 'none',
      background: 'none',
      textAlign: 'center',
      fontSize: '25px',
    },
    quantityBTN: {
      color: '#000',
    },
    keys: {
      margin: matches ? '20px auto' : '40px auto',
    },
    launchBTN: {
      boxSizing: 'border-box',
      width: matches ? '130px' : '185px',
      height: matches ? '50px' : '60px',
      color: '#fff',
      background: '#000',
      borderRadius: '35px',
      fontSize: '28px',
      margin: matches ? '0 auto' : '36px auto 0',
      textTransform: 'capitalize',
    },
    launchBTNRed: {
      background: 'red',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    companyNameInput: {
      width: '100%',
      boxSizing: 'border-box',
    },
    expired: {
      width: '100%',
      color: 'red',
    },
    companyName: {
      margin: matches ? '0' : '0 40px',
    },
    searchInput: {
      margin: matches ? '0' : '0 40px',
    },
    modal: {
      boxSizing: 'border-box',
      width: '320px',
      background: '#ffffff',
      zIndex: '1',
      left: '50%',
      top: '50%',
      transform: matches2 ? 'translate(-50%, -50%)' : 'none',
      position: 'absolute',
      flexDirection: 'column',
      borderRadius: '13px',
    },
    modalHeader: {
      margin: '20px auto',
      fontSize: '25px',
    },
    modalButtons: {
      width: '100%',
      justifyContent: 'space-evenly',
      margin: '20px 0',
      display: 'flex',
    },
    shadow: {
      zIndex: '1',
      position: 'fixed',
      right: '0px',
      bottom: '0px',
      top: '0px',
      left: '0px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    textArea: {
      width: '100%',
      boxSizing: 'border-box',
      height: 'auto',
      borderRadius: '10px',
      resize: 'none',
      padding: '10px',
      marginBottom: '30px',
    },
    addPrize: {
      fontSize: '22px',
      textDecoration: 'underline',
      cursor: 'pointer',
      marginTop: '30px',
      display: 'block',
    },
  }));
  const classes = useStyles();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries,
  });

  const setAddress = address => {
    setFullAddress(address);
  };

  const handleTierChange = value => {
    setTier(value);
    setKeys(value);
  };

  useEffect(() => {
    dispatch(fetchCardsWhell());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id && !isEdit) {
      setEdit(true);
      dispatch(getChest(id));
      const getChestStatistic = async () => {
        const req = await fetchCardAnalytics(id, 'chest');
        setChestStatistics(req.data);
      };
      getChestStatistic();
    }
  }, [dispatch, cards, id, isEdit]);

  useEffect(() => {
    if (chest && !isLoading && isEdit && cards.length) {
      setLong(chest?.location?.coordinates[0]);
      setLat(chest?.location?.coordinates[1]);
      setKeys(chest?.keys);
      setQuantity(chest?.quantity);
      setName(chest?.name);
      setSelectedDate(chest?.activatesOn);
      setDescription(chest?.description);
      setInShop(chest?.inShop);
      setAddress(chest?.address);
      setTier(chest?.tier);
      if (chest?.secondaryGifts) {
        let offers = [];
        let secondaryPrizes = [];
        chest.secondaryGifts.forEach(prize => {
          let item = cards.find(card => card.id === prize.id);
          item.companyQuantity = prize.quantity;
          offers.push({ ...item });
          secondaryPrizes.push(prize.id);
        });
        setOffers(offers);
        setSecondaryPrizes(secondaryPrizes);
      }
      if (chest?.expiresOn) {
        setHasExpiresDate(true);
        setExpiresOn(chest.expiresOn);
      } else {
        setHasExpiresDate(false);
      }
      if (chest?.probability) setProbability(chest.probability * 100);
    }
  }, [chest, isEdit, cards, isLoading]);

  useEffect(() => {
    if (cards.length && chest) {
      const currentOffer = cards.findIndex(card => card.id === chest.cardId);
      if (currentOffer >= 0 && isEdit) {
        setSlideIndex(currentOffer);
        setOffer(cards[currentOffer]);
      }
    }
  }, [cards, chest, isEdit]);

  if (loadError) return 'Error';

  if (isLoading) {
    return (
      <Base theme={'light'}>
        <LinearProgress />
      </Base>
    );
  }

  if (cards.length === 0) {
    return <NoOffer></NoOffer>;
  }

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const handleExpiresOn = date => {
    const formatedDate = moment(date).format('YYYY-MM-DD');
    setExpiresOn(formatedDate);
  };

  const handleQuantity = value => {
    if (offer) {
      if (value <= offer.quantity) {
        setQuantity(value);
      }
    } else {
      showToast('error', 'Error', 'Select Card First');
    }
  };

  const addQuantity = type => {
    if (offer) {
      if (type === 'quantity' && quantity < offer.quantity) {
        setQuantity(quantity + 1);
      } else if (type !== 'quantity') {
        setKeys(keys + 1);
      }
    } else {
      showToast('error', 'Error', 'Select Card First');
    }
  };

  const subQuantity = type => {
    if (offer) {
      if (type === 'quantity' && quantity > 1) {
        setQuantity(prev => prev - 1);
      } else if (keys > 1 && type !== 'quantity') {
        if (keys - 1 >= 3) setKeys(prev => prev - 1);
        else
          showToast(
            'error',
            'Error',
            'Chest tier 3 should have 3 or keys to open'
          );
      }
    } else {
      showToast('error', 'Error', 'Select Card First');
    }
  };

  const renderTopBar = () => {
    return <GoBackButton onClick={() => history.goBack()} />;
  };

  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };

  const handleProbability = value => {
    if (isNaN(value)) return;
    if (value > 100) {
      return;
    }

    setProbability(value);
  };

  const setCoords = (long, lat) => {
    setLong(long);
    setLat(lat);
  };

  const setCurrentOffer = offer => {
    setOffer(offer);
    setQuantity(1);
  };

  const addSecondaryOffer = (offerData, index) => {
    const copyState = [...offers];
    if (offerData) {
      offerData.companyQuantity = 1;
    }
    copyState[index] = offerData;
    setOffers(copyState);
  };

  const removePrize = index => {
    const node = document.getElementsByClassName('slider')[index];
    if (node) {
      unmountComponentAtNode(node);
    }
    secondaryPrizes.splice(index, 1);
    const arr = [...secondaryPrizes];
    setSecondaryPrizes(arr);
    const copyState = [...offers];
    copyState.splice(index, 1);
    setOffers(copyState);
  };

  const changeSecondaryOfferQuantity = (value, index) => {
    if (offers[index]) {
      if (value <= offers[index].quantity) {
        const editedArray = offers.map((content, i) =>
          i === index ? { ...content, companyQuantity: value } : content
        );
        setOffers(editedArray);
      }
    } else {
      showToast('error', 'Error', 'Select Card First');
    }
  };

  const addSecondaryQuantity = index => {
    if (offers[index]) {
      if (offers[index].companyQuantity + 1 <= offers[index].quantity) {
        const editedArray = offers.map((content, i) =>
          i === index
            ? { ...content, companyQuantity: offers[index].companyQuantity + 1 }
            : content
        );
        setOffers(editedArray);
      }
    } else {
      showToast('error', 'Error', 'Select Card First');
    }
  };

  const subSecondaryQuantity = index => {
    if (offers[index]) {
      if (offers[index].companyQuantity > 1) {
        const editedArray = offers.map((content, i) =>
          i === index
            ? { ...content, companyQuantity: offers[index].companyQuantity - 1 }
            : content
        );
        setOffers(editedArray);
      }
    } else {
      showToast('error', 'Error', 'Select Card First');
    }
  };

  const sendChestData = async () => {
    setLoading(true);
    try {
      let data = {
        quantity,
        keys,
        name,
        ownerId: user.id,
        location: {
          type: 'Point',
          coordinates: [Number(long), Number(lat)],
        },
        activatesOn: selectedDate,
        cardId: offer.id,
        description,
        inShop,
        address,
        secondaryGifts: [],
      };
      if (offers.length) {
        const secondaryGifts = offers.map(prize => {
          prize = {
            id: prize.id,
            quantity: prize.companyQuantity,
          };
          return prize;
        });
        data.secondaryGifts = secondaryGifts;
      }
      if (hasExpiresDate) {
        data.expiresOn = expiresOn;
      }

      if (inShop) data.probability = probability / 100;
      console.log('data', data);
      let resp = await httpPostCall(`${REACT_APP_BASE_URL}/chests`, data);
      if (resp) {
        setLoading(false);
        showToast('success', 'Done', 'Campaign created');
        history.push('/dashboard');
      }
    } catch (err) {
      setLoading(false);
      showToast('error', 'Error', 'Server Error');
      return err;
    }
  };

  const sendEditData = async () => {
    setLoading(true);
    try {
      let data = {
        quantity,
        keys,
        name,
        location: {
          type: 'Point',
          coordinates: [Number(long), Number(lat)],
        },
        activatesOn: selectedDate,
        cardId: offer.id,
        description,
        inShop,
        address,
        secondaryGifts: [],
      };
      if (offers.length) {
        const secondaryGifts = offers.map(prize => {
          prize = {
            id: prize.id,
            quantity: prize.companyQuantity,
          };
          return prize;
        });
        data.secondaryGifts = secondaryGifts;
      }
      if (inShop) {
        delete data.location;
      }
      if (hasExpiresDate) {
        data.expiresOn = expiresOn;
      } else {
        data.expiresOn = null;
      }
      if (inShop) data.probability = probability / 100;

      let resp = await httpPutCall(`${REACT_APP_BASE_URL}/chests/${id}`, data);
      if (resp) {
        setLoading(false);
        showToast('success', 'Done', 'Campaign updated');
        history.push('/dashboard');
      }
    } catch (err) {
      setLoading(false);
      showToast('error', 'Error', 'Server Error');
      return err;
    }
  };

  const removeData = async () => {
    setLoading(true);
    try {
      let resp = await httpDeleteCall(`${REACT_APP_BASE_URL}/chests/${id}`);
      if (resp) {
        setLoading(false);
        showToast('success', 'Done', 'Campaign deleted');
        history.push('/dashboard');
      }
    } catch (err) {
      setLoading(false);
      showToast('error', 'Error', 'Server Error');
      return err;
    }
  };

  const sendData = () => {
    if ((!long || !lat) && !inShop) {
      showToast('error', 'Error', 'Please enter coordinates');
      return;
    }

    if (!(Math.abs(Number(lat)) <= 90) && lat) {
      showToast('error', 'Error', 'Latitude can be more than ±90');
      return;
    }

    if (!(Math.abs(Number(long)) <= 180) && lat) {
      showToast('error', 'Error', 'Longitude can be more than ±180');
      return;
    }

    if (!offer) {
      showToast('error', 'Error', 'Please select card');
      return;
    }

    if (moment(selectedDate).isBefore(new Date())) {
      showToast('error', 'Error', 'Please enter correct countdown date');
      return;
    }

    if (hasExpiresDate && moment(expiresOn).isBefore(new Date())) {
      showToast('error', 'Error', 'Please select correct expire date');
      return;
    }

    if (keys <= 0) {
      showToast('error', 'Error', 'Keys quantity cant be 0');
      return;
    }
    if (quantity <= 0) {
      showToast('error', 'Error', 'Quantity cant be 0');
      return;
    }
    if (inShop) {
      if (probability > 100) {
        showToast('error', 'Error', 'Probability cant be more then 100');
        return;
      }
      if (probability <= 0) {
        showToast('error', 'Error', 'Probability cant be 0 or less');
        return;
      }
    }

    let error = false;

    if (offer.cardTier > tier) {
      error = true;
      showToast(
        'error',
        'Error',
        `Primary prize has tier ${offer.cardTier} and chest tier is ${tier}`
      );
      return;
    }

    secondaryPrizes.forEach((offer, index) => {
      if (!offers[index]?.id) {
        error = true;
        showToast(
          'error',
          'Error',
          `Please select or delete secondary prize #${index + 1}`
        );
        return;
      }
      if (offers[index].cardTier > tier) {
        error = true;
        showToast(
          'error',
          'Error',
          `Secondary prize #${index + 1} has tier ${
            offers[index].cardTier
          } and chest tier is ${tier}`
        );
        return;
      }
    });
    if (error) {
      return;
    }

    if (isEdit) {
      sendEditData();
    } else {
      sendChestData();
    }
  };

  return (
    <Base
      theme={'light'}
      topBar={renderTopBar}
    >
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.companyName}>
            <span className={classes.header}>Name of campaign</span>
            <input
              className={`${classes.companyNameInput} ${classes.input}`}
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>

          <ChooseTier
            selectedValue={tier}
            onTierSelect={handleTierChange}
          />

          <Switch
            title='Display Chest in App'
            checked={inShop}
            sx={{
              '& .Component-thumb': {
                backgroundColor: '#66f875',
              },
            }}
            handleChange={() => setInShop(!inShop)}
            fontSize={'18px'}
          />
          {!isLoaded ? (
            'loading...'
          ) : (
            <div
              className={classes.searchInput}
              style={{ display: inShop ? 'none' : 'block' }}
            >
              <Search
                setCoords={(long, lat) => setCoords(long, lat)}
                setAddress={address => setAddress(address)}
                addressValue={address}
              />
            </div>
          )}
          {!inShop ? (
            <div className={classes.inputsWrapper}>
              <div className={classes.inputBlock}>
                <span className={classes.header}>Long</span>
                <Cleave
                  className={classes.input}
                  options={{
                    numeral: true,
                    numeralDecimalScale: 10,
                    numeralThousandsGroupStyle: 'none',
                  }}
                  value={long}
                  onChange={e => setLong(e.target.value)}
                />
              </div>
              <div className={classes.inputBlock}>
                <span className={classes.header}>Lat</span>
                <Cleave
                  className={classes.input}
                  options={{
                    numeral: true,
                    numeralDecimalScale: 10,
                    numeralThousandsGroupStyle: 'none',
                  }}
                  value={lat}
                  onChange={e => setLat(e.target.value)}
                />
              </div>
            </div>
          ) : (
            <div
              className={`${classes.inputsWrapper} ${classes.inputsWrapperJustifyCenter}`}
            >
              <div className={classes.inputProbability}>
                <span className={classes.header}>Enter probability</span>
                <input
                  className={classes.input}
                  value={probability}
                  style={{ textAlign: 'center' }}
                  maxLength='8'
                  onChange={e => {
                    handleProbability(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
          <div className={classes.slider}>
            <SelectOffer
              cards={cards}
              setOffer={value => setCurrentOffer(value)}
              current={slideIndex}
              selectedChestTier={tier}
            />
          </div>
          <div
            className={`${classes.inputsWrapper} ${classes.inputsWrapperCenter}`}
          >
            <div className={classes.inputBlock}>
              <span className={classes.header}>
                Countdown{' '}
                {moment(selectedDate).isBefore(new Date()) && (
                  <span className={classes.expired}>Expired</span>
                )}
              </span>
              <div className={`${classes.input} ${classes.flex}`}>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      disablePast={false}
                      hideTabs
                      value={selectedDate}
                      onChange={handleDateChange}
                      format='yyyy/MM/dd HH:mm'
                      InputProps={{ disableUnderline: true }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </div>
            </div>
            {hasExpiresDate && (
              <div className={classes.inputBlock}>
                <span className={classes.header}>
                  Expires on{' '}
                  {moment(expiresOn).isBefore(new Date()) && (
                    <span className={classes.expired}>Expired</span>
                  )}
                </span>
                <div className={`${classes.input} ${classes.flex}`}>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        disablePast={true}
                        hideTabs
                        value={expiresOn}
                        onChange={handleExpiresOn}
                        format='yyyy/MM/dd'
                        InputProps={{ disableUnderline: true }}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </div>
              </div>
            )}
          </div>
          <VerifyCheckbox
            onChange={() => setHasExpiresDate(!hasExpiresDate)}
            label={'Without expiry date'}
            defaultIcon={true}
            checked={!hasExpiresDate}
            className={'chest-campaign-checkbox'}
          />
          <div className={classes.inputsWrapper}>
            <div className={classes.inputBlock}>
              <span className={classes.header}>Keys required</span>
              <div className={`${classes.input} ${classes.inputFlex}`}>
                <IconButton
                  className={classes.quantityBTN}
                  style={matches ? { width: '22px' } : null}
                  onClick={() => subQuantity('keys')}
                  disabled={tier < 3 || keys <= 3}
                >
                  <RemoveIcon />
                </IconButton>
                <Cleave
                  type='text'
                  className={`${classes.quantity} ${classes.quantityInput}`}
                  style={{ color: keys === 0 ? 'red' : null }}
                  value={keys}
                  disabled={tier < 3}
                  onChange={event => setKeys(Number(event.target.value))}
                  min={tier === 3 ? 3 : 0}
                  options={{
                    numeralPositiveOnly: true,
                    numericOnly: true,
                  }}
                />
                <IconButton
                  className={classes.quantityBTN}
                  style={matches ? { width: '22px' } : null}
                  onClick={() => addQuantity('keys')}
                  disabled={tier < 3}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.inputBlock}>
              <span className={classes.header}>
                Quantity (Max {offer ? offer.quantity : 0})
              </span>
              <div
                className={`${classes.input} ${classes.inputFlex}`}
                style={{ marginLeft: 'auto' }}
              >
                <IconButton
                  className={classes.quantityBTN}
                  style={matches ? { width: '22px' } : null}
                  onClick={() => subQuantity('quantity')}
                >
                  <RemoveIcon />
                </IconButton>
                <input
                  type='text'
                  className={`${classes.quantity} ${classes.quantityInput}`}
                  style={{ color: quantity === 0 ? 'red' : null }}
                  value={quantity}
                  onChange={event => handleQuantity(Number(event.target.value))}
                />
                <IconButton
                  className={classes.quantityBTN}
                  style={matches ? { width: '22px' } : null}
                  onClick={() => addQuantity('quantity')}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
          </div>
          {secondaryPrizes.length ? (
            secondaryPrizes.map((prize, index) => {
              return (
                <React.Fragment key={index}>
                  <SelectOffer
                    cards={cards}
                    setOffer={value => addSecondaryOffer(value, index)}
                    current={slideIndex}
                    secondary
                    secondaryIndex={index}
                    secondaryOffer={cards.findIndex(
                      card => card.id === offers[index]?.id
                    )}
                    selectedChestTier={tier}
                  />
                  <div className={classes.inputBlock}>
                    <span className={classes.header}>
                      Quantity (Max {offers ? offers[index]?.quantity : 0})
                    </span>
                    <div className={classes.flex}>
                      <div className={`${classes.input} ${classes.inputFlex}`}>
                        <IconButton
                          className={classes.quantityBTN}
                          style={matches ? { width: '22px' } : null}
                          onClick={() => subSecondaryQuantity(index)}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <input
                          type='text'
                          className={`${classes.quantity} ${classes.quantityInput}`}
                          style={{
                            color:
                              offers[index]?.companyQuantity === 0
                                ? 'red'
                                : null,
                          }}
                          value={
                            offers[index]?.companyQuantity ||
                            offers[index]?.quantity ||
                            0
                          }
                          onChange={event =>
                            changeSecondaryOfferQuantity(
                              Number(event.target.value),
                              index
                            )
                          }
                        />
                        <IconButton
                          className={classes.quantityBTN}
                          style={matches ? { width: '22px' } : null}
                          onClick={() => addSecondaryQuantity(index)}
                        >
                          <AddIcon />
                        </IconButton>
                      </div>
                      <Button
                        className={`${classes.launchBTN} ${classes.launchBTNRed}`}
                        onClick={() => removePrize(index)}
                        style={{ margin: '0 0 0 auto' }}
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                  <span
                    className={classes.addPrize}
                    onClick={() =>
                      setSecondaryPrizes([...secondaryPrizes, index + 1])
                    }
                  >
                    Add another prize
                  </span>
                </React.Fragment>
              );
            })
          ) : (
            <span
              className={classes.addPrize}
              onClick={() => setSecondaryPrizes([...secondaryPrizes, 0])}
            >
              Add secondary prize
            </span>
          )}
          <span className={classes.header}>Description(not required)</span>
          <textarea
            className={`${classes.input} ${classes.textArea}`}
            rows='5'
            value={description}
            onChange={e => setDescription(e.target.value)}
          ></textarea>

          {isEdit ? (
            <div className={classes.buttons}>
              <Button
                className={classes.launchBTN}
                onClick={() => sendData()}
              >
                Save
              </Button>
              <Button
                className={`${classes.launchBTN} ${classes.launchBTNRed}`}
                onClick={() => setOpen(true)}
              >
                Remove
              </Button>
            </div>
          ) : loading ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            <Button
              className={classes.launchBTN}
              onClick={() => sendData()}
            >
              Launch
            </Button>
          )}
        </div>
      </div>
      {open && (
        <div
          className={classes.shadow}
          style={open ? { display: 'block' } : { display: 'none' }}
        ></div>
      )}
      {open && (
        <div
          className={classes.modal}
          style={open ? { display: 'flex' } : { display: 'none' }}
        >
          <p className={classes.modalHeader}>Campaign will be deleted</p>
          <div className={classes.modalButtons}>
            <Button
              className={classes.launchBTN}
              onClick={() => setOpen(false)}
              style={{ width: '140px' }}
            >
              Back
            </Button>
            <Button
              className={`${classes.launchBTN} ${classes.launchBTNRed}`}
              onClick={() => removeData()}
              style={{ width: '140px' }}
            >
              Remove
            </Button>
          </div>
        </div>
      )}

      {isEdit ? (
        <ErrorBoundary>
          <ChestStatistic
            chestStatistic={chestStatistic}
            id={id}
          />
        </ErrorBoundary>
      ) : null}
    </Base>
  );
}

export default NewCampaign;
