import { DROP_USER_CARDS_STANDARD,
         DROP_CARDS_LOADING,
         GET_OFFERS_SUCCES,
         SET_OFFER,
         SET_SLIDE_INDEX,
         CARD_STATISTICS_CLEAR,
         CARD_STATISTICS_SUCCESS,
         UPDATE_CARD,
         SET_ANALYTICS_ID,
         GET_CHEST_BY_CARD_SUCCES,
         CLEAR_CURRENT_OFFER} from '../actions/actions-types'

const initialState = {
    standardUserCards: {},
    offers: [],
    isLoading: true,
    offerIndex: undefined,
    offer: '',
    initialSlide: 0,
    chests: null,
}

export default function dropCardsReducer(state = initialState, action) {
    switch (action.type) {
        case DROP_USER_CARDS_STANDARD:
            return {
                ...state,
                standardUserCards: action.userDropCards
            }
        case DROP_CARDS_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case GET_OFFERS_SUCCES:
            return {
                ...state,
                offers: action.payload,
                isLoading: false,
            }
        case SET_OFFER:
            return {
                ...state,
                offerIndex: action.index,
                offer: action.payload,
                isLoading: false,
            }
        case CLEAR_CURRENT_OFFER:
            return {
                ...state,
                offer: {},
                chests: null,
            }
        case GET_CHEST_BY_CARD_SUCCES:
            if (!state?.offer?.cardCampaigns) {
                return {
                    ...state,
                    offer: {
                        ...state.offer,
                        cardCampaigns: action.payload,
                    },
                    chests: action.payload,
                    isLoading: false,
                }
            } else {
                return {
                    ...state,
                    offer: {
                        ...state.offer,
                        cardCampaigns: state.offer.cardCampaigns.concat(action.payload),
                    },
                    chests: action.payload,
                    isLoading: false,
                }
            } 
        case SET_SLIDE_INDEX:
            return {
                ...state,
                initialSlide: action.payload,
            }
        case UPDATE_CARD:
            return {
                ...state,
                offers: {
                    ...state.offers,
                    cardWithAdditions: state.offers.cardWithAdditions.map(
                        (content) => content.id === action.id ? {...content, ...action.data}
                        : content
                    ),
                },
                offer: {...state.offer, quantity: action.data.quantity}
            };
        case CARD_STATISTICS_SUCCESS:
            return {
                ...state,
                offer: {...state.offer, cardStatistics : action.payload.cardStatistics},
            };
        case CARD_STATISTICS_CLEAR:
            return {
                ...state,
                offer: {...state.offer, cardStatistics : null},
            };
        case SET_ANALYTICS_ID:
            return {
                ...state,
                analyticsId: action.id,
                analyticsType: action.campaign,
            };
        default:
            return state
    }
}

