export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOGOUT = "LOGIN_LOGOUT";
export const ERROR = "ERROR";
export const ADD_CARD_SUCCESS = "ADDCARDSUCCESS";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const PENDING_CARD = "PENDING_CARD";
export const USER_DATA = "USER_DATA";
export const RECOVERY_PASSWORD = "RECOVERY_PASSWORD";
export const COLLECT_CARD = "COLLECT_CARD";
export const MANAGE_RESULT = "MANAGE_RESULT";
export const ADD_CAMPAIGNS_CARDS = "ADD_CAMPAIGNS_CARDS";
export const DROP_USER_CARDS_STANDARD = "DROP_USER_CARDS_STANDARD";
export const TOAST = "TOAST";
export const SET_USER_DATA = "SET_USER_DATA";
export const CARDS_FOR_WHEEL_DATA_SUCCES = "CARDS_FOR_WHEEL_DATA_SUCCES";
export const CHANGE_SLOT_STATUS = "CHANGE_SLOT_STATUS";
export const INITIAL_STATE_LOADING = "INITIAL_STATE_LOADING";
export const SET_OFFER_TO_SLOT = "SET_OFFER_TO_SLOT";
export const CLEAR_OFFER_FROM_SLOT = "CLEAR_OFFER_FROM_SLOT";
export const SEND_CARDS_TO_WHEEL = "SEND_CARDS_TO_WHEEL";
export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION";
export const LOCATIONS_FOR_WHEEL_DATA_SUCCES = "LOCATIONS_FOR_WHEEL_DATA_SUCCES";
export const SEND_WHEEL_DATA_SUCCES = "SEND_WHEEL_DATA_SUCCES";
export const INCREASE_CARDS_QUANTITY = "INCREASE_CARDS_QUANTITY";
export const RESET_CAMPAIGN_STATE = "RESET_CAMPAIGN_STATE";
export const DECREASE_CARDS_QUANTITY = "DECREASE_CARDS_QUANTITY";
export const GET_WHEEL_DATA_SUCCES = "GET_WHEEL_DATA_SUCCES";
export const GET_WHEEL_DATA_SUCCES_QR = "GET_WHEEL_DATA_SUCCES_QR";
export const DROP_CARDS_LOADING = "DROP_CARDS_LOADING";
export const GET_OFFERS_SUCCES = "GET_OFFERS_SUCCES";
export const SET_OFFER = "SET_OFFER";
export const SAVE_LOGO_TO_STATE = "SAVE_LOGO_TO_STATE";
export const SET_EDIT_WHEEL = "SET_EDIT_WHEEL";
export const SET_QUANTITY_TO_SLOT = "SET_QUANTITY_TO_SLOT";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const SET_PAYMENT_ID = "SET_PAYMENT_ID";
export const SET_SLIDE_INDEX ="SET_SLIDE_INDEX";
export const GET_TARGETED_CAMPAIGN = "GET_TARGETED_CAMPAIGN";
export const CHANGE_CAMPAIGN_STATUS = "CHANGE_CAMPAIGN_STATUS";
export const ADD_NEW_LOCATION = "ADD_NEW_LOCATION";
export const SET_USERS = "SET_USERS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CARD_STATISTICS_SUCCESS = "CARD_STATISTICS_SUCCESS";
export const CARD_STATISTICS_CLEAR = "CARD_STATISTICS_CLEAR";
export const MAIN_STATISTICS_SUCCESS = "MAIN_STATISTICS_SUCCESS";
export const MAIN_STATISTICS_CLEAR = "MAIN_STATISTICS_CLEAR";
export const QR_STATISTICS_CLEAR = "QR_STATISTICS_CLEAR";
export const QR_STATISTICS_SUCCESS = "QR_STATISTICS_SUCCESS";
export const WHEEL_STATISTICS_CLEAR = "WHEEL_STATISTICS_CLEAR";
export const WHEEL_STATISTICS_SUCCESS = "WHEEL_STATISTICS_SUCCESS"
export const TARGETED_STATISTICS_SUCCESS = "TARGETED_STATISTICS_SUCCESS";
export const TARGETED_STATISTICS_CLEAR = "TARGETED_STATISTICS_CLEAR";
export const SIDEBAR_WHEEL_CHANGE = "SIDEBAR_WHEEL_CHANGE";
export const SEND_COLORS_TO_WHEEL = "SEND_COLORS_TO_WHEEL";
export const SET_QRWHEEL_ID = "SET_QRWHEEL_ID";
export const SET_CHANCE_TO_SLOT = "SET_CHANCE_TO_SLOT";
export const UPDATE_CARD = "UPDATE_CARD";
export const SET_ANALYTICS_ID = "SET_ANALYTICS_ID";
export const SET_CAMPAIGN_NAME = "SET_CAMPAIGN_NAME";
export const SET_EDIT_COLORS = "SET_EDIT_COLORS";
export const GET_CHEST_DATA_SUCCESS = "GET_CHEST_DATA_SUCCESS";
export const GET_CHESTS_DATA_SUCCESS ="GET_CHESTS_DATA_SUCCESS";
export const SEND_CHEST_DATA_SUCCESS = "SEND_CHEST_DATA_SUCCESS";
export const GET_CHEST_BY_CARD_SUCCES = "GET_CHEST_BY_CARD_SUCCES";
export const CLEAR_CURRENT_OFFER = "CLEAR_CURRENT_OFFER";
export const CHANGE_EXPIRY_COMPANY = "CHANGE_EXPIRY_COMPANY";

//Saga actions
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const LOGIN_USER = "LOGIN_USER";
export const SINGUP_USER = "SINGUP_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const GET_USER_DATA = "GET_USER_DATA";
export const ADD_CARD = "GET_USER_DATA";
export const CALL_PENDING_CARDS = "CALL_PENDING_CARDS";
export const CALL_COLLECTION_CARD = "CALL_COLLECTION_CARD";
export const MANAGE_CARD = "MANAGE_CARD";
export const CALL_USER_CARDS = "CALL_USER_CARDS";
export const EDIT_USER_CARD = "EDIT_USER_CARD";
export const GET_DROP_CARDS_STANDARD = "GET_DROP_CARDS_STANDARD";