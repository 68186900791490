import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";

const ExpiryDateInput = ({ value, onChange }) => {
  const handleOnChangeDate = (date) => {
    const expiryDate = moment(date).format("YYYY-MM-DD");
    onChange(expiryDate);
  };

  const CustomInput = (props) => {
    return (
        <input
            className={"gift-card-number-input"}
            onClick={props.onClick}
            value={props.value}
            type="text"
            readOnly={true}
            id="expiry-date-input"
        />
    )
  }

  return (
    <div className={"offer-input-container expiry-date-input-container"}>
      <label className={"noselect"}>Expiry date: {moment(value).isBefore(new Date()) && <span style={{ color: "red" }}>Expired</span>}</label>
      <div className={"input-area"}>
        <DatePicker
          selected={moment(value, "YYYY-MM-DD").toDate()}
          onChange={handleOnChangeDate}
          id={"expiry-date-input"}
          dateFormat={"yyyy-MM-dd"}
          customInput={<CustomInput/>}
          maxDate={new Date(moment().add(6, "months").format("LLL"))}
          todayButton="Today"
        />
      </div>
      <div className={"input-control noselect"}>
        <div className={"calendar-input-icon"} onClick={() => document.getElementById("expiry-date-input").click()}>
          <img src={require("../../../../assets/images/calendar.svg")} alt=""/>
        </div>
      </div>
    </div>
  );
};

export default ExpiryDateInput;
