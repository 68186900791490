import React from "react";
import { Checkbox } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { faStop } from "@fortawesome/free-solid-svg-icons";

const VerifyCheckbox = ({ checked, label, onChange, defaultIcon, className }) => {
  return (
    <div className={className ? `${className} verify-checkbox` : "verify-checkbox"}>
      <div className={"verify-checkbox-container"}>
        <Checkbox
          checked={checked}
          onChange={onChange}
          color={"default"}
          icon={!defaultIcon ? <FontAwesomeIcon
            icon={faStop}
            color={'#fff'}
          /> : <CheckBoxOutlineBlankIcon/>}
        />
      </div>
      <p>{label}</p>
    </div>
  );
};

export default VerifyCheckbox;
