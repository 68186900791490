import { httpPostCall, httpGetCall } from "../../common/commonService";
const URL = process.env.REACT_APP_BASE_URL;

export const loginUserReq = async (user) => {
  try {
    let loginResp = await httpPostCall(`${URL}/users/login`, user);
    return loginResp;
  } catch (err) {
    return err;
  }
};

export const checkLogin = async () => {
  try {
    let loginResp = await httpGetCall(`${URL}/users/me`);
    return loginResp;
  } catch (err) {
    return err;
  }
};

export const forgotPass = async (email) => {
  try {
    let forgotPasswordResp = await httpPostCall(`${URL}/users/forgot-password?email=${email}`);
    return forgotPasswordResp;
  } catch (err) {
    return err;
  }
};

export const resetPass = async (data) => {
  try {
    let url = encodeURI(`${URL}/users/reset-password?email=${data.email}&key=${data.key}&newPassword=${data.password}`);
    let resetPassResp = await httpPostCall(url, {});
    return resetPassResp;
  } catch (err) {
    return err;
  }
};

export const registerUser = async (user) => {
  try {
    let registerResp = await httpPostCall(`${URL}/users`, user);
    return registerResp;
  } catch (err) {
    return err;
  }
};

export const updateUserData = async (data) => {
  try {
    let userResp = await httpPostCall(`${URL}/users/me/update`, data);
    return userResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const updateCompanyAvatar = async (data) => {
  try {
    let userResp = await httpPostCall(`${URL}/users/set-avatar`, data);
    return userResp;
  } catch (err) {
    return err;
  }
};

export const verifyEmail = async (key) => {
  try {
    let userResp = await httpPostCall(`${URL}/users/verify-email?key=${key}`);
    return userResp;
  } catch (err) {
    return err;
  }
};

export const resendEmailReq = async (email) => {
  try {
    let userResp = await httpPostCall(`${URL}/users/resend-verification-email/?email=${email}`);
    return userResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};
