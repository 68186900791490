import React from "react";
import InputControl from "./InputControl";

const OfferInput = ({ value, onChange, minValue, maxValue, isEdit }) => {
  const handleOnIncrease = () => {
    // console.log("maxValue :>> ", value + 1, parseInt(value) + 1 <= maxValue);
    if (parseInt(value) + 1 <= maxValue) {
      onChange(parseInt(value) + 1);
    }
  };

  const handleOnDecrease = () => {
    if (value && parseInt(value) - 1 >= minValue) {
      onChange(parseInt(value) - 1);
    }
  };

  const handleOnChange = (value) => {
    if (value > 0 && parseInt(value) <= maxValue && parseInt(value) >= minValue) {
      onChange(parseInt(value));
    } else if (!value) {
      onChange();
    } else if (value < 1) {
      onChange(Math.abs(value));
    }
  };
  return (
    <div className={"offer-input-container"}>
      <label className={"noselect"}>Value of offer</label>
      <div className={"input-area"}>
        <span>$</span>
        <input
          className={"gift-card-number-input"}
          type={"number"}
          value={value}
          onChange={(e) => handleOnChange(e.target.value)}
        />
      </div>
      <InputControl onIncrease={handleOnIncrease} onDecrease={handleOnDecrease} />
    </div>
  );
};

export default OfferInput;
