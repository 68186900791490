import { httpPostCall, httpGetCall, httpPatchCall } from "../../common/commonService";
const URL = process.env.REACT_APP_BASE_URL;

export const fetchTiers = async (id = null) => {
  try {
    let filter = {
      order: ["min asc"],
    };
    const post = id ? id : "";
    let tiers = await httpGetCall(`${URL}/tiers/${post}?filter=${JSON.stringify(filter)}`);
    return tiers;
  } catch (err) {
    return err;
  }
};

export const addTier = async (data) => {
  try {
    let location = await httpPostCall(`${URL}/tiers/`, data);
    return location;
  } catch (err) {
    return err;
  }
};
export const updateTier = async (id, data) => {
  try {
    let campaignResp = await httpPatchCall(`${URL}/tiers/${id}`, data);
    return campaignResp;
  } catch (err) {
    return err;
  }
};
