import React from "react";
import Base from "../../../component/Layout/Base";
import GoBackButton from "../../../component/Layout/GoBackButton";
import { Link } from "react-router-dom";

const NoOffer = () => {
  const renderTopBar = () => {
    return (
      <Link to={"/dashboard"}>
        <GoBackButton />
      </Link>
    );
  };

  return (
    <Base theme={"light"} topBar={renderTopBar}>
      <div className={"middle-content-area transparent create-new-campaign"}>
        <p>Create a new campaign</p>
        <div className={"no-offers-container"}>
          <p>
            Sorry, looks like you <br /> have no available offers yet.{" "}
          </p>
          <Link to={"/gift-card"}>
            <div className={"new-offer-card"}>
              <div className={"plus-icon"}>
                <img src={require("../../../assets/images/home/create_new_campaign.png")} alt=""/>
              </div>
              <p className={"text"}>Create a new offer</p>
            </div>
          </Link>
        </div>
      </div>
    </Base>
  );
};

export default NoOffer;
