import React, { useState, useEffect } from "react";
import { makeStyles, Avatar, Button, TextField, FormControlLabel, Checkbox, Grid, Typography } from "@material-ui/core";
import validator from "validator";
import { TOAST } from "../../redux/actions/actions-types";
import { Link } from "react-router-dom";
import Auth from "../../component/Layout/Auth";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { connect, useDispatch } from "react-redux";
import { loginSuccess, setUserData, checkLoginAction } from "../../redux/actions/authActions";
import { loginUserReq } from "../../redux/requests/authRequests";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  title: {
    color: "#fff",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#d72d83",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: theme.spacing(6),
    backgroundColor: "#3f51b5 !important",
  },
  signUp: {
    margin: theme.spacing(3, 0, 2),
    marginTop: theme.spacing(2),
    backgroundColor: "#3f51b5 !important",
  },
  link: {
    margin: theme.spacing(0),
  },
  error: {
    backgroundColor: "tomato",
    margin: 0,
    padding: 15,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    position: "absolute",
    top: 0,
    "& p": {
      color: "#fff",
    },
  },
}));

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRemember] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };

  const validateForm = (user) => {
    if (!validator.isEmail(user.email)) {
      showToast("error", "Error", "Invalid Email");
      return false;
    } else if (!validator.isLength(user.password, { min: 6 })) {
      showToast("error", "Error", "Invalid password");
      return false;
    } else {
      return true;
    }
  };

  const loginHandler = async () => {
    let isValid = validateForm({ email, password });
    if (isValid) {
      let loginResp = await loginUserReq({ email, password });
      if (loginResp.data) {
        const role = loginResp.data.data.user.role;
        if (role === "admin" || role === "company") {
          dispatch(loginSuccess(loginResp.data.data.token));
          dispatch(setUserData(loginResp.data.data.user));
          dispatch(checkLoginAction());
        } else {
          showToast("error", "Error", "Access Denied");
        }
      } else {
      }
      // console.log(loginResp);
    }
  };

  const emailChange = (value) => {
    setEmail(value.toLowerCase());
  };

  const passwordChange = (value) => {
    setPassword(value);
  };

  const changRemember = () => {
    localStorage.setItem("rememberMe", !rememberMe);
    setRemember(!rememberMe);
    !rememberMe ? localStorage.setItem("email", email) : localStorage.removeItem("email");
  };

  useEffect(() => {
    // accessToken ? history.push("/dashboard") : void 0;

    if (localStorage.getItem("rememberMe") === "true") {
      setRemember(true);
      setEmail(localStorage.getItem("email"));
    }
  }, []);

  const classes = useStyles();
  return (
    <Auth>
      <Avatar className={classes.avatar}>
        <LockOpenIcon />
      </Avatar>
      <Typography component="h2" className={classes.title} variant="h6">
        Sign in
      </Typography>
      <form name="form-layout" className={classes.form} noValidate>
        <TextField
          onChange={(value) => emailChange(value.nativeEvent.target.value)}
          value={email}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          onChange={(value) => passwordChange(value.nativeEvent.target.value)}
          value={password}
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onKeyDown={(e) => e.key === "Enter" && loginHandler()}
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
          checked={rememberMe}
          onChange={changRemember}
        />

        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={loginHandler}>
          Sign In
        </Button>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          className={classes.signUp}
          onClick={() => history.push("/signup")}>
          Sign up
        </Button>
        <Grid container style={{ marginTop: "3%" }}>
          <Grid item xs>
            <Grid item>
              <Link to="/forgot-password" className="color-pink" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            {/* <p className={`${classes.link} color-pink`}>
              Don't have an account?{" "}
              <Link to="/signup" variant="body2" className="color-pink">
                Sign up
              </Link>
            </p> */}
          </Grid>
        </Grid>
      </form>
    </Auth>
  );
}

function mapStateToProps(state) {
  return {
    accessToken: state.login && state.login.accessToken,
    error: state.login && state.login.error,
  };
}

export default connect(mapStateToProps, null)(Login);
