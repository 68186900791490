import React, { useState } from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import GiftCard from './GiftCard';
import VerifyCheckbox from './Inputs/VerifyCheckbox';
import PromoteLinkInput from './Inputs/PromoteLinkInput';
import DiscountCodeInput from './Inputs/DiscountCodeInput';
import { useDispatch } from 'react-redux';
import { TOAST } from '../../../redux/actions/actions-types';
import Hint from './Hint';
import Footer from './Footer';
import validator from 'validator';

const useStyles = makeStyles(theme => ({
  bottomSection: {
    backgroundColor: '#EAEAEA',
    paddingTop: 15,
    paddingBottom: 25,
  },
}));

const DesignOfferBackStep1 = ({ handleOnClickButton, details }) => {
  const classes = useStyles();
  const matches = useMediaQuery(`(max-width:500px)`);
  const dispatch = useDispatch();
  const [card, setCard] = useState({
    backgroundImage: details.cardPhoto,
    title: details.title,
    logo: details.logoPhoto,
    offerValue: details.cardValue || 1,
    quantity: details.quantity || 1,
    details: details.details || '',
    promoteUrlLink: details.promoteUrl || '',
    discountCode: details.code || '',
    verify: false,
  });
  const getThumbnail = async () => {
    let thumbnail = 3;

    return thumbnail;
  };
  const handleOnChangeInput = (key, value) => {
    const cardCopy = { ...card };
    cardCopy[key] = value;
    setCard(cardCopy);
  };

  const validation = () => {
    if (!validator.isLength(card.details, { min: 3 })) {
      showError('Add details');
      return false;
    } else if (!validator.isLength(card.discountCode, { min: 3 })) {
      showError('Discount code must contain three characters');
      return false;
    } else if (!validator.isURL(card.promoteUrlLink)) {
      showError('Invalid promo url');
      return false;
    } else if (card.verify === false) {
      showError('Please mark the checkbox as verified!');
      return false;
    }
    return true;
  };

  const handleNextButton = async tabIndex => {
    if (tabIndex === 2) {
      if (validation()) {
        let thumbnail = await getThumbnail();
        handleOnClickButton(tabIndex, {
          details: card.details,
          code: card.discountCode,
          promoteUrl: card.promoteUrlLink,
          cardBack: thumbnail,
        });
      }
    } else {
      handleOnClickButton(tabIndex);
    }
  };
  const showError = message => {
    dispatch({
      type: TOAST,
      load: {
        type: 'error',
        title: 'Error',
        message: message,
        show: true,
      },
    });
  };
  const handleOnChangeVerifyCheckbox = () => {
    const cardCopy = { ...card };
    cardCopy.verify = !cardCopy.verify;
    setCard(cardCopy);
  };

  let logo;

  if (card) {
    if (card.logoPhoto) {
      logo = `${process.env.REACT_APP_IMG_URL}/avatars/${card.logoPhoto}`;
    }
  }

  return (
    <div>
      <div>
        <div
          className={'card-offer-container'}
          style={matches ? { width: '375px', marginTop: '15px' } : null}
        >
          <div className='card-offer-content'>
            <div className={'card-offer-header'}>
              <p>Step 2 of 3</p>
              <p> Now, details on the back.</p>
            </div>
            <div className={'card-section'}>
              <div style={{ opacity: 0, position: 'absolute' }}>
                <div id='offer-card-back'>
                  <GiftCard
                    side={'back'}
                    rounded
                    shadow
                    backgroundImage={card.backgroundImage}
                    cardTitle={card.cardTitle}
                    cardLogo={logo}
                    details={card.details}
                    onChangeDescription={e =>
                      handleOnChangeInput('details', e.target.value)
                    }
                    step={1}
                    cardPhoto={card.backgroundImage}
                    title={card.cardTitle}
                    code={card.discountCode}
                    promoteUrl={card.promoteUrlLink}
                    view={true}
                  />
                </div>
              </div>
              <GiftCard
                side={'back'}
                rounded
                shadow
                backgroundImage={card.backgroundImage}
                cardTitle={card.cardTitle}
                cardLogo={card.cardLogo}
                details={card.details}
                onChangeDescription={e =>
                  handleOnChangeInput('details', e.target.value)
                }
                step={1}
                cardPhoto={card.backgroundImage}
                title={card.cardTitle}
                code={card.discountCode}
                promoteUrl={card.promoteUrlLink}
              />
            </div>
            <div
              className={`bottom-section card-back-options-container-1 ${classes.bottomSection}`}
            >
              <Hint>Unqiue offer code:</Hint>
              <DiscountCodeInput
                value={card.discountCode}
                onChange={e =>
                  handleOnChangeInput('discountCode', e.target.value)
                }
                hideLable
              />
              <div style={{ marginTop: '15px' }}></div>
              <Hint>Promote url link:</Hint>
              <PromoteLinkInput
                value={card.promoteUrlLink}
                onChange={e =>
                  handleOnChangeInput('promoteUrlLink', e.target.value)
                }
                hideLabel
              />
              <VerifyCheckbox
                onChange={handleOnChangeVerifyCheckbox}
                label={
                  'Check to verify any discount codes created are valid for use immediately upon approval.'
                }
              />
            </div>
          </div>
        </div>
      </div>

      <Footer
        buttons={[
          {
            title: 'Back',
            tabIndex: 0,
          },
          {
            title: 'Next',
            tabIndex: 2,
          },
        ]}
        onClickButton={handleNextButton}
      />
    </div>
  );
};

export default DesignOfferBackStep1;
