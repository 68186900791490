import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

const InputControl = ({ minValue, maxValue, onIncrease, onDecrease }) => {
  return (
    <div className={"input-control"}>
      <button className={"transparent-button"} onClick={onIncrease}>
        <FontAwesomeIcon
          icon={faCaretUp}
          size={"2x"}
          className={"input-control-caret"}
        />
      </button>
      <button className={"transparent-button"} onClick={onDecrease}>
        <FontAwesomeIcon
          icon={faCaretDown}
          size={"2x"}
          className={"input-control-caret"}
        />
      </button>
    </div>
  );
};

export default InputControl;