import React from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import FooterButton from "./FooterButton";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: "30px",
    width: "500px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  alignmentSpaceBetween: {
    justifyContent: "space-between",
  },
  alignmentCenter: {
    justifyContent: "center",
  },
}));

const Footer = ({ buttons, onClickButton }) => {
  const buttonAlignment = buttons.length === 1 ? "center" : "space-between";
  const classes = useStyles();
  const matches = useMediaQuery(`(max-width:500px)`);

  return (
    <div
      className={`${classes.container} ${
        buttonAlignment === "center" ? classes.alignmentCenter : classes.alignmentSpaceBetween
      }`} style={matches? {maxWidth: "375px"} : {maxWidth: "500px"}}>
      {buttons.map((button, i) => (
        <>
          {button.showLoader ? (
            <FooterButton showLoader={button.showLoader} onClick={() => null} key={i}/>
          ) : (
            <FooterButton title={button.title} onClick={() => onClickButton(button.tabIndex)} key={i}/>
          )}
        </>
      ))}
    </div>
  );
};

export default Footer;
