import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  makeStyles,
  useMediaQuery,
  Button,
  MenuItem,
  FormControl,
  Select,
  Tooltip,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import { httpDeleteCall } from '../../../common/commonService';
import Base from '../../../component/Layout/Base';
import GoBackButton from '../../../component/Layout/GoBackButton';
import Hint from '../../../assets/icons/hintSmall.svg';
import Wheel from '../wheel/prize-wheel';
import moment from 'moment';
import { TOAST } from '../../../redux/actions/actions-types';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  sendLocation,
  getLocations,
  setLocationToWheel,
  getWheelData,
  getWheelDataQR,
  clearCampaign,
  getStatistics,
} from '../../../redux/actions/campaignsActions';
import { setAnalyticsId } from '../../../redux/actions/dropCardsActions';
import custom from '../../../../src/assets/images/custom-location.png';
import * as QRCode from 'qrcode.react';

const imgURL = `${process.env.REACT_APP_IMG_URL}/card-photo/`;
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

function ControlledOpenSelect({
  locations,
  onSelect,
  onSelectQR,
  currentLoc,
  wheelId,
  setLocationToWheel,
  wheelType,
}) {
  const [location, setLocation] = React.useState(currentLoc);
  const [open, setOpen] = React.useState(false);
  const matches = useMediaQuery(`(max-width:500px)`);

  const handleChange = event => {
    if (wheelType !== 'QR') {
      setLocation(event.target.value);
      onSelect(event.target.value);
    } else {
      onSelectQR(event.target.value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const useStyles = makeStyles(theme => ({
    root: {},
    select: {
      width: '520px',
      background: '#ECECEC',
      boxSizing: 'border-box',
      height: '63px',
      fontSize: '22px',
      color: '#000 !important',
      textAlign: 'left',
      marginTop: '52px',
      marginBottom: wheelType === 'QR' ? '45px' : 'initial',
    },
    selectM: {
      width: '350px',
      background: '#ECECEC',
      boxSizing: 'border-box',
      height: '45px',
      fontSize: '20px',
      color: '#000 !important',
      textAlign: 'left',
      marginTop: '25px',
      marginBottom: wheelType === 'QR' ? '45px' : 'initial',
    },
    selectLi: {
      '& ul': {
        maxWidth: '520px',
      },
      '& li': {
        whiteSpace: 'normal',
      },
    },
  }));
  const classes = useStyles();

  return (
    <div style={{ display: locations ? 'block' : 'none' }}>
      <FormControl>
        <Select
          labelId='demo-controlled-open-select-label'
          id='demo-controlled-open-select'
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={location || currentLoc}
          onChange={handleChange}
          className={matches ? classes.selectM : classes.select}
          MenuProps={{ classes: { paper: classes.selectLi } }}
        >
          <MenuItem
            value={''}
            disabled
          >
            Choose location
          </MenuItem>
          {locations ? (
            locations.map((location, i) => {
              if (!location.campaignId && wheelType !== 'QR') {
                return null;
              }
              return (
                <MenuItem
                  value={i}
                  key={i}
                >
                  {wheelType === 'QR'
                    ? location.name
                    : `${location.name}: ${location.address}`}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value={10000}>Loading locations</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
}

function PerformanceDateSelect({ onSelect }) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const [location, setLocation] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleChange = event => {
    setLocation(event.target.value);
    if (event.target.value !== 0) {
      onSelect(moment().subtract(event.target.value, 'days').format('x'));
    } else {
      onSelect(0);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const useStyles = makeStyles(theme => ({
    root: {},
    select: {
      width: '200px',
      background: 'transparent',
      boxSizing: 'border-box',
      fontSize: '26px',
      color: '#000 !important',
      textAlign: 'right',
      '&::before': {
        content: "''",
        display: 'none',
      },
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <FormControl>
        <Select
          labelId='demo-controlled-open-select-label'
          id='demo-controlled-open-select'
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={location}
          onChange={handleChange}
          className={classes.select}
          style={matches ? { fontSize: '20px', width: 'auto' } : null}
        >
          <MenuItem value={1}>Today</MenuItem>
          <MenuItem value={1}>Last 7 days</MenuItem>
          <MenuItem value={2}>Last 14 days</MenuItem>
          <MenuItem value={3}>Last 30 days</MenuItem>
          <MenuItem value={0}>All Time</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

function EditWheel({
  sendLocation,
  setLocationToWheel,
  locations,
  isLoading,
  stats,
  location,
  cards,
  getWheelData,
  getWheelDataQR,
  wheelId,
  setAnalyticsId,
  wheelType,
  campaignName,
  campaignId,
  QRstats,
  QRcampaignId,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const loc = useLocation();
  const [current, setCurrent] = React.useState('');
  const [modal, setModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const matches = useMediaQuery(`(max-width:500px)`);
  const useStyles = makeStyles(theme => ({
    root: {},
    container: {
      textAlign: 'center',
      width: '100%',
      marginTop: '10px',
      marginBottom: '80px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    wrapper: {
      alignItems: 'center',
      padding: '45px 0',
      borderRadius: '13px',
      minHeight: 'auto',
    },
    wrapperM: {
      background: '#fff',
      boxSizing: 'border-box',
      width: '375px',
      minHeight: 'auto',
      borderRadius: '51px',
      padding: '10px 0 20px 0',
      display: 'flex',
      flexDirection: 'column',
    },
    sponsroIMG: {
      margin: '33px auto',
      width: '127px',
      height: '135px',
    },
    paragraph: {
      fontSize: '27px',
      margin: '0',
    },
    existing: {
      margin: '31px',
      fontSize: '27px',
      textAlign: 'center',
    },
    statusContainer: {
      boxSizing: 'border-box',
      width: '580px',
      background: '#fff',
      borderRadius: '13px',
      padding: '12px 22px',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '19px 0',
    },
    status: {
      display: 'block',
      fontSize: '25px',
      color: '#00D913',
    },
    pause: {
      display: 'block',
      padding: '6px 0',
      minWidth: 'initial',
      textTransform: 'none',
      fontSize: '25px',
      fontWeight: 'bold',
    },
    performanceContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridRowGap: '35px',
      margin: '0 0 15px 0',
    },
    performanceContainerM: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridRowGap: '20px',
      margin: '0 0 15px 0',
      width: '375px',
      background: '#fff',
      borderRadius: '13px',
      padding: '10px',
      boxSizing: 'border-box',
    },
    performance: {
      fontSize: '29px',
    },
    statisticsBlock: {
      alignItems: 'flex-start',
      textAlign: 'left',
    },
    statisticsHeader: {
      fontSize: matches ? '16px' : '24px',
      fontWeight: 'bold',
      display: 'block',
      marginBottom: '14px',
      marginTop: '-4px',
    },
    statisticsValue: {
      fontSize: '31px',
    },
    statisticsPercent: {
      fontSize: '23px',
      color: '#00D90D',
    },
    hint: {
      cursor: 'pointer',
      display: 'block',
      marginRight: '9px',
    },
    amountContainer: {
      padding: '15px 35px',
      margin: '0',
    },
    amountValue: {
      fontSize: '26px',
      display: 'block',
      lineHeight: '35px',
      textAlign: 'left',
      fontWeight: 'bold',
    },
    quantityBTN: {
      padding: '0',
      background: '#000',
      color: '#fff',
      width: '25px',
      height: '25px',
      marginLeft: '5px',
    },
    offers: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: '34px',
      boxSizing: 'border-box',
      width: '580px',
    },
    offersM: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: '34px',
      boxSizing: 'border-box',
      width: '375px',
      justifyItems: 'center',
    },
    offer: {
      boxSizing: 'border-box',
      width: '273px',
      background: '#fff',
      borderRadius: '21px',
      padding: '19px 13px 15px 13px',
    },
    offerheader: {
      fontSize: '26px',
    },
    imgBlock: {
      position: 'relative',
      margin: '23px auto 27px auto',
    },
    offerImg: {
      width: '116px',
      height: '69px',
    },
    imgTitle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#fff',
      width: '116px',
      margin: '0',
      lineHeight: '13px',
      fontSize: '13px',
      background: 'rgba(0, 0, 0, 0.509804)',
    },
    offerStatistics: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridRowGap: '37px',
    },
    offerTitle: {
      display: 'block',
      fontSize: '20px',
      lineHeight: '25px',
    },
    blackBtn: {
      textTransform: 'none !important',
      background: '#000 !important',
      fontSize: '20px !important',
      color: '#fff !important',
      padding: '0px 25px !important',
      boxSizing: 'border-box !important',
      height: '55px',
      borderRadius: '25px !important',
      margin: '0 auto !important',
    },
    quantityInput: {
      width: '50px',
      border: 'none',
      outline: 'none',
      background: 'none',
      textAlign: 'center',
    },
    btnContainer: {
      boxSizing: 'border-box',
      width: '580px',
      background: '#fff',
      borderRadius: '13px',
      minHeight: '90px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '5px 0 19px 0',
    },
    analyticsText: {
      fontSize: '25px',
      fontWeight: 'bold',
      textTransform: 'none',
    },
    modal: {
      display: modal ? 'flex' : 'none',
      position: 'absolute',
      width: '300px',
      background: '#fff',
      borderRadius: '13px',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: '10',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    modalHeader: {
      fontSize: '20px',
    },
    modalBtn: {
      textTransform: 'none !important',
      background: '#000 !important',
      fontSize: '20px !important',
      color: '#fff !important',
      padding: '0px 25px !important',
      boxSizing: 'border-box !important',
      height: '55px',
      borderRadius: '25px !important',
      margin: '0 auto 25px !important',
    },
    modalDelete: {
      background: 'red !important',
    },
    shadow: {
      zIndex: '1',
      position: 'fixed',
      right: '0px',
      bottom: '0px',
      top: '0px',
      left: '0px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    circularWrapper: {
      height: '55px',
      marginBottom: '25px',
    },
    circular: {
      marginRight: '-250px',
      paddingTop: '35px',
      height: '440px',
      boxSizing: 'border-box',
    },
    circularM: {
      marginRight: '-180px',
      paddingTop: '20px',
      height: '345px',
      boxSizing: 'border-box',
    },
  }));
  const classes = useStyles();

  const params = new URLSearchParams(loc.search);
  const id = params.get('id');
  const QR = params.get('type');
  const locParam = params.get('location');

  useEffect(() => {
    dispatch(clearCampaign);
  }, [dispatch]);

  useEffect(() => {
    if (!wheelId || locations.length) {
      if (id && QR) {
        getWheelDataQR(id);
        dispatch(getStatistics(id, 0, 'QR'));
      } else if (id) {
        getWheelData(id);
        dispatch(getStatistics(id, 0, 'wheel'));
      }
    }
    if (locParam) {
      if (id && QR) {
        dispatch(getLocations('QR'));
      } else if (id) {
        dispatch(getLocations());
      }
    }
    if (!locations.length && QR && !locParam) {
      dispatch(getLocations('QR'));
    } else if (!locations.length && !QR && !locParam) {
      dispatch(getLocations());
    }
    if (
      isLoading === false &&
      !location.campaignId &&
      !wheelId &&
      !locations.length &&
      !id
    ) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (QR) {
      const wheelLocQR = locations.findIndex(locations => locations.id === id);
      if (wheelLocQR >= 0) {
        setCurrent(wheelLocQR);
      }
    } else {
      const wheelLoc = locations.findIndex(
        locations => locations.campaignId === id
      );
      if (wheelLoc >= 0) {
        setCurrent(wheelLoc);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, locations.length, id, locParam]);

  const renderTopBar = () => {
    return <GoBackButton onClick={() => history.goBack()} />;
  };

  const setCampaign = index => {
    setLocationToWheel(index);
    history.push(`/campaign/edit?id=${locations[index].campaignId}`);
  };

  const setCampaignQR = index => {
    setLocationToWheel(index);
    history.push(`/campaign/edit?id=${locations[index].id}&type=QR`);
  };

  const getCardAnalytics = () => {
    history.push(`/analytics?id=${wheelId}&type=campaign`);
  };

  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };

  const deleteCampaign = async () => {
    setLoading(true);
    try {
      let resp = await httpDeleteCall(`${REACT_APP_BASE_URL}/wheels/qr/${id}`);
      if (resp) {
        setLoading(false);
        showToast('success', 'Done', 'Campaign Deleted');
        history.push('/dashboard');
      }
    } catch (err) {
      setLoading(false);
      showToast('error', 'Error', 'Server Error');
      setModal(false);
      return err;
    }
  };

  const viewLeads = () => {
    history.push(`/analytics?id=${QRcampaignId}&type=QR`);
  };

  const setRange = range => {
    if (wheelType === 'QR') {
      dispatch(getStatistics(QRcampaignId, range, 'QR'));
    } else {
      dispatch(getStatistics(campaignId, range, 'wheel'));
    }
  };

  if (isLoading === true || !locations.length || !wheelId) {
    return (
      <Base theme={'light'}>
        <LinearProgress />
      </Base>
    );
  }

  return (
    <Base
      theme={'light'}
      topBar={renderTopBar}
    >
      <div className={classes.container}>
        <div
          className={
            matches ? classes.wrapperM : `campaign-wrapper ${classes.wrapper}`
          }
          style={{ position: 'relative' }}
        >
          <div
            className={classes.shadow}
            style={modal ? { display: 'block' } : { display: 'none' }}
          ></div>
          <div
            className={classes.modal}
            style={modal ? { display: 'flex' } : { display: 'none' }}
          >
            <p className={classes.modalHeader}>
              Are you sure? Campaign will be deleted
            </p>
            <Button
              variant='contained'
              className={classes.modalBtn}
              onClick={() => setModal(false)}
            >
              Back
            </Button>
            {loading ? (
              <div className={classes.circularWrapper}>
                <CircularProgress />
              </div>
            ) : (
              <Button
                variant='contained'
                className={`${classes.modalBtn} ${classes.modalDelete}`}
                onClick={() => deleteCampaign()}
              >
                Delete Campaign
              </Button>
            )}
          </div>
          <p className={classes.paragraph}>
            {wheelType === 'QR' ? 'Your campaign' : 'Which location?'}
          </p>
          <div style={{ display: wheelType === 'QR' ? 'block' : 'none' }}>
            <h1 className='campaign-header'>Your QR code</h1>
            <div style={{ margin: 'auto' }}>
              <QRCode
                includeMargin={true}
                size={350}
                value={`https://spinforstuff.com/${campaignName}?id=${wheelId}`}
                renderAs='svg'
              />
              <p>{`Direct link https://spinforstuff.com/${campaignName}?id=${wheelId}`}</p>
            </div>
          </div>
          {locations && (
            <ControlledOpenSelect
              locations={locations}
              onSelect={value => setCampaign(value)}
              onSelectQR={value => setCampaignQR(value)}
              currentLoc={current}
              wheelId={wheelId}
              setLocationToWheel={setLocationToWheel}
              wheelType={wheelType}
            />
          )}
          <img
            src={custom}
            alt=''
            className={classes.sponsroIMG}
            style={{ display: wheelType === 'QR' ? 'none' : 'block' }}
          />
          <Button
            variant='contained'
            className={matches ? classes.blackBtn : 'campaign-blackBTN'}
            onClick={wheelType === 'QR' ? () => viewLeads() : null}
          >
            {wheelType === 'QR'
              ? 'View collected leads'
              : 'Choose new location'}
          </Button>
          <div
            style={{
              marginTop: '40px',
              display: wheelType === 'QR' ? 'block' : 'none',
            }}
          >
            <Button
              variant='contained'
              className={matches ? classes.blackBtn : 'campaign-blackBTN'}
              onClick={() => setModal(true)}
            >
              Delete Wheel
            </Button>
          </div>
        </div>
        <p className={classes.existing}>Existing Prize wheel campaigns</p>
        <Wheel
          hideTop={true}
          edit={true}
        />
        <div
          className={classes.statusContainer}
          style={matches ? { width: '375px' } : null}
        >
          <div
            className='flex'
            style={{ alignItems: 'center' }}
          >
            <span
              className={classes.status}
              style={matches ? { fontSize: '20px' } : null}
            >
              <span className='bold'>Status:</span> Live
            </span>
            {/* <Button className={classes.pause}>Pause</Button> */}
          </div>
          <Button
            className={classes.pause}
            style={matches ? { fontSize: '20px' } : null}
          >
            Edit offer details
          </Button>
        </div>
        <div>
          <div
            className={
              matches
                ? classes.performanceContainerM
                : ` ${classes.performanceContainer}`
            }
          >
            <span
              className={classes.performance}
              style={matches ? { fontSize: '20px' } : null}
            >
              Performance
            </span>
            <PerformanceDateSelect onSelect={value => setRange(value)} />
            {!QRstats || !stats ? (
              <div className={matches ? classes.circularM : classes.circular}>
                <CircularProgress />
              </div>
            ) : (
              <React.Fragment>
                <div className={`${classes.statisticsBlock} flex`}>
                  <Tooltip
                    title='Number of times a user clicks to spin at location'
                    className={classes.hint}
                  >
                    <img
                      src={Hint}
                      alt=''
                      style={matches ? { width: '15px' } : null}
                    />
                  </Tooltip>
                  <div>
                    <span className={classes.statisticsHeader}>
                      {wheelType === 'QR' ? 'New leads' : 'Spin engagements:'}
                    </span>
                    <span className={classes.statisticsValue}>
                      {wheelType === 'QR'
                        ? QRstats
                          ? QRstats.performance.leads
                          : 0
                        : stats
                        ? stats.campaignStats.spinEngagements
                        : 0}
                    </span>
                    <span className={classes.statisticsPercent}>
                      {' '}
                      +{' '}
                      {stats?.percentages?.spinEngagements
                        ? stats.percentages.spinEngagements
                        : 0}
                      %
                    </span>
                  </div>
                </div>
                <div
                  className={`${classes.statisticsBlock} flex`}
                  style={{ paddingLeft: '30px' }}
                >
                  <Tooltip
                    title='Number of time link on the back of the gift offer'
                    className={classes.hint}
                  >
                    <img
                      src={Hint}
                      alt=''
                      style={matches ? { width: '15px' } : null}
                    />
                  </Tooltip>
                  <div>
                    <span className={classes.statisticsHeader}>
                      {wheelType === 'QR'
                        ? 'Social Media Refer'
                        : 'Link clicks:'}
                    </span>
                    <span className={classes.statisticsValue}>
                      {wheelType === 'QR'
                        ? QRstats
                          ? QRstats.performance.socialRefers
                          : 0
                        : stats
                        ? stats.campaignStats.linkClicks
                        : 0}
                    </span>
                    <span className={classes.statisticsPercent}>
                      {' '}
                      +{' '}
                      {stats?.percentages?.linkClicks
                        ? stats.percentages.linkClicks
                        : 0}
                      %
                    </span>
                  </div>
                </div>
                <div className={`${classes.statisticsBlock} flex`}>
                  <Tooltip
                    title='Number of times an offer is clicked to view in the app'
                    className={classes.hint}
                  >
                    <img
                      src={Hint}
                      alt=''
                      style={matches ? { width: '15px' } : null}
                    />
                  </Tooltip>
                  <div>
                    <span className={classes.statisticsHeader}>
                      {wheelType === 'QR'
                        ? 'Offer redeems'
                        : 'Offer click views:'}
                    </span>
                    <span className={classes.statisticsValue}>
                      {wheelType === 'QR'
                        ? QRstats
                          ? QRstats.performance.redeems
                          : 0
                        : stats
                        ? stats.campaignStats.clickViews
                        : 0}
                    </span>
                    <span className={classes.statisticsPercent}>
                      {' '}
                      +{' '}
                      {stats?.percentages?.clickViews
                        ? stats.percentages.clickViews
                        : 0}
                      %
                    </span>
                  </div>
                </div>
                <div
                  className={`${classes.statisticsBlock} flex`}
                  style={{ paddingLeft: '30px' }}
                >
                  <Tooltip
                    title='Number of offers won buy users'
                    className={classes.hint}
                  >
                    <img
                      src={Hint}
                      alt=''
                      style={matches ? { width: '15px' } : null}
                    />
                  </Tooltip>
                  <div>
                    <span className={classes.statisticsHeader}>
                      {wheelType === 'QR'
                        ? 'Offers collected'
                        : 'Offer collected:'}
                    </span>
                    <span className={classes.statisticsValue}>
                      {wheelType === 'QR'
                        ? QRstats
                          ? QRstats.performance.collected
                          : 0
                        : stats
                        ? stats.campaignStats.collected
                        : 0}
                    </span>
                    <span className={classes.statisticsPercent}>
                      {' '}
                      +{' '}
                      {stats?.percentages?.collected
                        ? stats.percentages.collected
                        : 0}
                      %
                    </span>
                  </div>
                </div>
                <div className={`${classes.statisticsBlock} flex`}>
                  <Tooltip
                    title='Number of times offer appears on users screen'
                    className={classes.hint}
                  >
                    <img
                      src={Hint}
                      alt=''
                      style={matches ? { width: '15px' } : null}
                    />
                  </Tooltip>
                  <div>
                    <span className={classes.statisticsHeader}>
                      {wheelType === 'QR' ? 'Amount spent' : 'Impressions:'}
                    </span>
                    <span className={classes.statisticsValue}>
                      {stats?.campaignStats?.impressions
                        ? stats.campaignStats.impressions
                        : 0}
                    </span>
                    <span className={classes.statisticsPercent}>
                      {' '}
                      +{' '}
                      {stats?.percentages?.impressions
                        ? stats.percentages.impressions
                        : 0}
                      %
                    </span>
                  </div>
                </div>
                <div
                  className={`${classes.statisticsBlock} flex`}
                  style={{
                    paddingLeft: '30px',
                    display: wheelType === 'QR' ? 'none' : 'flex',
                  }}
                >
                  <Tooltip
                    title='Number of notifications sent to users walking by in 100 meter radius'
                    className={classes.hint}
                  >
                    <img
                      src={Hint}
                      alt=''
                      style={matches ? { width: '15px' } : null}
                    />
                  </Tooltip>
                  <div>
                    <span className={classes.statisticsHeader}>
                      Walk-by Notifications:
                    </span>
                    <span className={classes.statisticsValue}>
                      {stats ? stats?.campaignStats?.walkByNotifications : null}
                    </span>
                    <span className={classes.statisticsPercent}>
                      {' '}
                      + {stats ? stats?.percentages?.walkByNotifications : null}
                      %
                    </span>
                  </div>
                </div>
                <div
                  className={`${classes.statisticsBlock} flex`}
                  style={{ display: wheelType === 'QR' ? 'none' : 'flex' }}
                >
                  <Tooltip
                    title='Number of times gift offer was clicked to be redeemed'
                    className={classes.hint}
                  >
                    <img
                      src={Hint}
                      alt=''
                      style={matches ? { width: '15px' } : null}
                    />
                  </Tooltip>
                  <div>
                    <span className={classes.statisticsHeader}>
                      Offer redeems:
                    </span>
                    <span className={classes.statisticsValue}>
                      {stats ? stats?.campaignStats?.redeemed : null}
                    </span>
                    <span className={classes.statisticsPercent}>
                      {' '}
                      + {stats ? stats?.percentages?.redeemed : null}%
                    </span>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          <div
            className={classes.btnContainer}
            style={{
              width: matches ? '375px' : null,
              display: wheelType === 'QR' ? 'none' : 'flex',
            }}
          >
            <Button
              classes={{ text: classes.analyticsText }}
              style={matches ? { fontSize: '20px' } : null}
              onClick={() => getCardAnalytics()}
            >
              View campaign analytics
            </Button>
          </div>
          {/* <div className={`${classes.statusContainer} ${classes.amountContainer}`} style={matches? {width: "375px", padding: "15px"} : null}>
            <div>
              <span className={classes.amountValue} style={matches? {fontSize: "20px", lineHeight: "35px"} : null}>Current balance owing</span>
              <span className={classes.amountValue} style={matches? {fontSize: "20px", lineHeight: "35px"} : null}>$50</span>
            </div>
            <div className="flex" style={{alignItems: "center"}}>
              <span style={matches? {fontSize: "20px", fontWeight: "bold", cursor: "pointer"} : {fontSize: "26px", fontWeight: "bold", cursor: "pointer"}} onClick={() => history.push("/profile-settings")}>See invoices</span>
            </div>
          </div> */}
          <p className={classes.existing}>Offer Breakdown</p>
          {stats ? (
            <div className={matches ? classes.offersM : classes.offers}>
              {stats?.cardsStatistics?.map((card, i) => {
                if (card === null) {
                  return null;
                }
                let index;
                if (cards.findIndex(slot => slot.id === card.cardId) !== -1) {
                  index = cards.findIndex(slot => slot.id === card.cardId);
                }
                return (
                  <div
                    className={classes.offer}
                    key={i}
                  >
                    <span className={classes.offerHeader}>Offer {i + 1}</span>
                    {wheelType === 'QR' ? (
                      <div className={classes.imgBlock}>
                        <img
                          src={
                            cards[i] !== null
                              ? `${imgURL}${cards[index ? index : 0].cardPhoto}`
                              : ''
                          }
                          className={classes.offerImg}
                          alt=''
                        />
                        <p className={classes.imgTitle}>
                          {cards[index ? index : 0]
                            ? cards[index ? index : 0].title
                            : 'null'}
                        </p>
                      </div>
                    ) : (
                      <div className={classes.imgBlock}>
                        <img
                          src={
                            cards[i] !== null
                              ? `${imgURL}${cards[i].cardPhoto}`
                              : ''
                          }
                          className={classes.offerImg}
                          alt=''
                        />
                        <p className={classes.imgTitle}>
                          {cards[i] ? cards[i].title : 'null'}
                        </p>
                      </div>
                    )}
                    <div className={classes.offerStatistics}>
                      <div>
                        <span className={classes.offerTitle}>Collects: </span>
                        <span className={classes.offerTitle}>
                          {card.collected}
                        </span>
                      </div>
                      <div>
                        <span className={classes.offerTitle}>
                          Impressions:{' '}
                        </span>
                        <span className={classes.offerTitle}>
                          {card.impressions}
                        </span>
                      </div>
                      <div>
                        <span className={classes.offerTitle}>Redeems: </span>
                        <span className={classes.offerTitle}>
                          {card.redeemed}
                        </span>
                      </div>
                      <div>
                        <span className={classes.offerTitle}>Expired: </span>
                        <span className={classes.offerTitle}>
                          {card.expired}
                        </span>
                      </div>
                      <div>
                        <span className={classes.offerTitle}>
                          Link clicks:{' '}
                        </span>
                        <span className={classes.offerTitle}>
                          {card.linkClicks}
                        </span>
                      </div>
                      <div>
                        <span className={classes.offerTitle}>Click views:</span>
                        <span className={classes.offerTitle}>{card.views}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </Base>
  );
}

const mapStateToProps = state => ({
  locations: state.campaigns.locations,
  location: state.campaigns.location,
  isLoading: state.campaigns.isLoading,
  stats: state.campaigns.campaignStatistics,
  QRstats: state.campaigns.campaignStatistics,
  wheelId: state.campaigns.wheelData.id,
  campaignId: state.campaigns.wheelData.id,
  QRcampaignId: state.campaigns.wheelData.campaignId,
  // cards:state.campaigns.wheelData.wheel,
  cards: state.campaigns.slots,
  wheelType: state.login.wheelType,
  campaignName: state.campaigns.wheelData.qrUrlPart,
});

export default connect(mapStateToProps, {
  sendLocation,
  setLocationToWheel,
  getWheelData,
  getWheelDataQR,
  setAnalyticsId,
})(EditWheel);
