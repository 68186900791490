import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import PersonalInfoInput from "./PersonalInfoInput";
import Card from "../../../component/Layout/Card";
import back from "../../../assets/icons/backArrow.svg"
import PricingButton from "./PricingButton";
import Plans from "../Plans/Plans";
import Modal from "@material-ui/core/Modal";
import { TOAST } from "../../../redux/actions/actions-types";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData, updateCompanyAvatar } from "../../../redux/requests/authRequests";
import { checkLoginAction } from "../../../redux/actions/authActions";
import { getPaidInvoices } from "../../../redux/requests/paymentRequest";
import validator from "validator";
import Payment from "../Payment/index";
function getModalStyle() {
  const top = 50;
  const left = 50;
  
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    textAlign: "center",
    width: "100%",
    marginBottom: "100px",
  },
  addform: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
    maxWidth: "430px",
    margin: "0 auto",
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  paymentContainer: {
    marginBottom: "1rem",
  },
  avatarImage: {
    // position: "absolute !important",
    borderRadius: "50%",
    height: "180px",
    width: "180px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    transition: "0.5s",
    left: 0,
    top: 0,
    position: "absolute",
    "&:hover": {
      opacity: ".5",
    },
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  wrapper: {
    padding: "15px",
    background: "white",
    borderRadius: "15px",
    marginBottom: "15px",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  sideBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  sideBlock2: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "150px",
    alignItems: "flex-end",
  },
  creditCard: {
    fontSize: "15px",
    whiteSpace: "nowrap",
    marginBottom: "10px",
  },
  owning: {
    width: "100%",
    textAlign: "left",
    marginBottom: "10px",
  },
  Text: {
    padding: "0",
    fontSize: "13px",
    lineHeight: "16px",
  },
  controlBtn: {
    height: "auto!important",
    padding: "0!important",
    minWidth: "auto!important",
  },
  hint: {
    fontSize: "11px!important",
    textAlign: "left",
  },
  invocies: {
    display: "block",
    textAlign: "left",
  },
  devider: {
    height: "1px",
    width: "100%",
    background: "#000",
    margin: "5px 0 7px 0",
  },
  listElement: {
    display: "block",
    textAlign: "left",
    lineHeight: "18px",
  },
  space: {
    marginLeft: "10px",
  },
  modal1: {
    maxWidth: "442px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 2px 12px 1px rgba(0, 0, 0, 0.5)",
    background: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1",
  },
  header: {
    fontSize: "21px!important",
  },
  paragraph: {
    fontSize: "17px!important",
  },
  midBlock: {
    display: "flex",
    flexDirection: "column",
    marginTop: "80px"
  },
  balance: {
    fontSize: "23px!important",
    lineHeight: "60px",
    margin: "0",
  },
  modalBtn: {
    width: "max-content",
    margin: "0 auto 20px auto",
  },
  card: {
    fontSize: "15px!important",
    marginBottom: "0",
  },
  modal2: {
    maxWidth: "375px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 2px 12px 1px rgba(0, 0, 0, 0.5)",
    background: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1",
    paddingBottom: "45px",
  },
  backBtn: {
    width: "fit-content",
    margin: "6px 0 0 15px",
    cursor: "pointer",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
    height: "190px",
    justifyContent: "space-between",
  },
  price: {
    fontSize: "23px!important",
    display: "block",
  },
  gst: {
    fontSize: "10px!important",
    display: "block",
    color: "#A9A9A9"
  },
  socialInputBlock: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "430px",
    boxSizing: "border-box",
    padding: "0 15px",
    borderRadius: "15px",
    marginBottom: "15px",
    background: "#ffffff",
    height: "57px",
    alignItems: "center",
  },
  socialInput: {
    width: "100%",
    outline: "none",
    border: "none",
    borderBottom: "1px solid black",
    lineHeight: "43px",
    maxWidth: "75%",
    padding: "0 10px",
  },
}));

function ViewPersonalInfo(props) {
  const history = useHistory();
  const [userLogo, setUserLogo] = useState();
  const [userAvatar, setUserAvatar] = useState();
  const [modal1Open, setModal1] = useState(false);
  const [modal2Open, setModal2] = useState(false);
  const [error, setError] = useState({facebook: false, instagram: false, twitter: false});
  const userData = useSelector((state) => state.login.userData);
  const dispatch = useDispatch();
  const [paymentModal, setPaymentModal] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const togglePaymentModal = () => setPaymentModal(!paymentModal);
  const [open, setOpen] = React.useState(false);

  const [profile, setProfile] = useState({
    email: {
      value: "",
      editing: false,
    },
    password: {
      value: "",
      editing: false,
    },
    businessWebsite: {
      value: "",
      editing: false,
    },
    facebook: {
      value: "",
      editing: false,
    },
    instagram: {
      value: "",
      editing: false,
    },
    twitter: {
      value: "",
      editing: false,
    },
    billing: {
      value: "$0.00 spent",
      editing: false,
      noButton: true,
    },
    billing_details: {
      value1: "Next billing 07/21",
      value2: "MC **** **** 5042",
    },
  });

  const getBillingInfo = useCallback(async () => {
    let billingResp = await getPaidInvoices();
    if (billingResp && billingResp.data) {
      let amount = 0;
      for (let bill of billingResp.data) {
        amount = amount + bill.amount;
      }
      setProfile({ ...profile, billing: { ...profile.billing, value: `$${parseFloat(amount).toFixed(2)} spent` } });
    }
  }, [profile]);

  useEffect(() => {
    // dispatch(checkLoginAction());
    // getBillingInfo();
  }, [dispatch, getBillingInfo]);

  useEffect(() => {
    if (props.showPricing) {
      setOpen(true);
    }
  }, [props.showPricing]);

  const toggleOpen = () => {
    if (props.showPricing) {
      window.history.replaceState({}, "", `${window.location.origin}/profile-settings`);
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (userData) { 
      let profileData = { ...profile };
      for (let key of Object.keys(profileData)) {
        if (userData[key]) {
          profileData[key].value = userData[key];
        }
      }
      setUserLogo(userData.companyLogo);
      setProfile(profileData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };
  const classes = useStyles();
  const logoSelectedHandler = (event) => {
    let img = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        let userLogo = reader.result;

        localStorage.setItem("userLogo", userLogo);

        setUserAvatar(userLogo);
        updateAvatar(img);
      },
      false
    );

    if (img) {
      reader.readAsDataURL(img);
    }
  };

  const updateAvatar = async (image) => {
    let formData = new FormData();
    formData.append("photo", image);
    let avatarResp = await updateCompanyAvatar(formData);
    if (avatarResp) {
      showToast("success", "Done", "Avatar updated");
      dispatch(checkLoginAction());
    }
  };

  const handleOnChange = (key, value) => {
    const field = { ...profile[key] };
    field.value = value;

    const profileCopy = { ...profile };
    profileCopy[key] = field;
    setProfile(profileCopy);
  };

  const socialUpdate= async (value, social) => {
    const profileCopy = { ...profile };
    let data = { [social]: value };
    let updateResp = await updateUserData(data);
    if (updateResp.name !== "Error") {
      showToast("success", "Done", "Profile updated");
      dispatch(checkLoginAction());
      setProfile(profileCopy);
    } else {
      showToast("error", "Error", "Server Error");
      setProfile(profileCopy);
    }
  };

  const onToggleButton = async (key) => {
    const field = { ...profile[key] };
    const profileCopy = { ...profile };

    let data = { [key]: profileCopy[key].value };
    if (field.editing) {
      console.log(data[key]);
      if (key === "email" && !validator.isEmail(data[key])) {
        showToast("error", "Error", "Invalid email");
      } else if (key === "password" && (!data[key] || !validator.isLength(data[key], { min: 6 }))) {
        showToast("error", "Error", "Invalid password");
      } else if (
        key === "businessWebsite" &&
        profile.businessWebsite.value &&
        (!data[key] || !validator.isURL(data[key]))
      ) {
        showToast("error", "Error", "Invalid bussiness website");
      } else {
        let updateResp = await updateUserData(data);
        if (updateResp.name !== "Error") {
          showToast("success", "Done", "Profile updated");
          dispatch(checkLoginAction());
          field.editing = !field.editing;
          profileCopy[key] = field;

          setProfile(profileCopy);
        }
      }
    } else {
      field.editing = !field.editing;
      profileCopy[key] = field;

      setProfile(profileCopy);
    }
  };

  const handleOpenModal1 = () => {
    setModal1(true);
  }

  const handleCloseModal1 = () => {
    setModal1(false);
  }

  const handleOpenModal2 = () => {
    setModal1(false);
    setModal2(true);
  }

  const handleCloseModal2 = () => {
    setModal2(false);
  }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const checkKey = (key, social) => {
    if(key === "Enter") {
      socialChange(social);
    }
  }

  const prevFacebook = usePrevious(profile.facebook.value);
  const prevInstagram = usePrevious(profile.instagram.value);
  const prevTwitter = usePrevious(profile.twitter.value);

  const setSocial = (value, social) => {
    switch (social) {
      case "facebook":
        setProfile({...profile, facebook: {...profile.facebook, value: value}})
        break;
      case "instagram":
        setProfile({...profile, instagram: {...profile.instagram, value: value}})
        break;
      case "twitter":
        setProfile({...profile, twitter: {...profile.twitter, value: value}})
        break;
      default:
        break;
    }
  }

  const socialChange = (social) => {
    switch (social) {
      case "facebook":
        if ((/(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)S*?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w*)*([\w]*)/.test(profile.facebook.value) && (profile.facebook.value !== prevFacebook) && prevFacebook)) {
          setError({...error, facebook: false});
          socialUpdate(profile.facebook.value,'facebook');
        } else if ((profile.facebook.value !== prevFacebook) && prevFacebook) {
          setError({...error, facebook: true});
          showToast("error", "Error", "The site doesn't link on Facebook");
        }
        break;
      case "instagram":
        if ((/(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)S*?instagram\.com\S*?\/(\w{3})\/?/.test(profile.instagram.value) && (profile.instagram.value !== prevInstagram) && prevInstagram)) {
          setError({...error, instagram: false});
          socialUpdate(profile.instagram.value,'instagram');
        } else if ((profile.instagram.value !== prevInstagram) && prevInstagram) {
          setError({...error, instagram: true});
          showToast("error", "Error", "The site doesn't link on Instagram");
        }
        break;
      case "twitter":
        if ((/(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)S*?twitter\.com\S*?\/(\w{3})\/?/.test(profile.twitter.value) && (profile.twitter.value !== prevTwitter) && prevTwitter)) {
          setError({...error, twitter: false});
          socialUpdate(profile.twitter.value,'twitter');
        } else if ((profile.twitter.value !== prevTwitter) && prevTwitter) {
          setError({...error, twitter: true});
          showToast("error", "Error", "The site doesn't link on Twitter");
        }
        break;
      default:
        break;
    }
  }

  return (
    <div className={`classes.container settings-container`}>
      <form name="form-layout" className={classes.addform} noValidate>
        <div className="avatar-action">
          <label>
            Upload Logo
            <input type="file" onChange={logoSelectedHandler} />
            {userAvatar ? (
              <img
                src={`${userAvatar}`} alt=""
                className={classes.avatarImage}
                // style={{

                // }}
                // alt="logo"
              />
            ) : (
              userLogo && (
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/avatars/${userLogo}`} alt=""
                  className={classes.avatarImage}
                  // style={{
                  //   transition: "0.5s",
                  //   borderRadius: "50%",
                  //   height: "180px",
                  //   width: "180px",
                  //   position: "absolute",
                  // }}
                  // alt="logo"
                />
              )
            )}
          </label>
          <h3>Welcome to Run for stuff</h3>
        </div>

        <div className={classes.modal1} style={modal1Open ? {display : "flex"} : {display: "none"}}>
          <p className={classes.header}>Confirm Cancelation</p>
          <p className={classes.paragraph}>Are you sure you would like to cancel your account?</p>
          <div className={classes.midBlock}>
            <p className={classes.paragraph} style={{margin: "0"}}>Please pay any amount owing</p>
            <p className={classes.balance}>Current balance owing:<br/>$12.20</p>
            <p className={classes.card}>xxxx {' '} 5192 01/22</p>
            <Button className={classes.modalBtn} classes={{label : classes.modalLabel, text: classes.modalText}} style={{color: "#31A300", marginTop: "0"}} onClick={handleOpenModal2}>Pay now</Button>
          </div>
          <Button className={classes.modalBtn} classes={{label : classes.modalLabel, text: classes.modalText}} style={{color: "#D80000"}} onClick={handleCloseModal1}>Yes, proceed to close account</Button>
          <Button className={classes.modalBtn} classes={{label : classes.modalLabel, text: classes.modalText}} onClick={handleCloseModal1}>No, I would not like to close.</Button>
        </div>

        <div className={classes.modal2} style={modal2Open ? {display : "flex"} : {display: "none"}}>
          <img src={back} alt="" className={classes.backBtn} onClick={handleCloseModal2}/>
          <div className={classes.flexCol}>
            <p className={classes.header} style={{margin: "13px 0 0 0"}}>Thank you for your support!</p>
              <div>
                <span className={classes.price}>$12.34</span>
                <span className={classes.gst}>(including gst)</span>
              </div>
            <p className={classes.paragraph} style={{margin: "0", color: "#31A300"}}>Payment succeeded April 1, 2020</p>
          </div>
        </div>

        {userData.role === "company" && (
          <>
            {" "}
            {userData.activePaymentDetail ? (
              <h1>Subscribed</h1>
            ) : (
              <PricingButton creditRemaining={userData?.creditsRemaining || 0} onClick={() => setOpen(true)} />
            )}{" "}
          </>
        )}

        {/* <PricingButton onClick={() => setOpen(true)} /> */}

        <Card>
          <PersonalInfoInput
            label={"Email"}
            value={profile.email.value}
            editing={profile.email.editing}
            buttonText={"Change email"}
            onChangeValue={(e) => handleOnChange("email", e.target.value)}
            onSave={() => onToggleButton("email")}
          />
        </Card>
        <Card>
          <PersonalInfoInput
            label={"Password"}
            value={profile.password.value}
            type={"password"}
            editing={profile.password.editing}
            placeholder="******"
            buttonText={"Change password"}
            onChangeValue={(e) => handleOnChange("password", e.target.value)}
            onSave={() => onToggleButton("password")}
          />
        </Card>
        <Card>
          <PersonalInfoInput
            label={"Business Website"}
            value={profile.businessWebsite.value}
            editing={profile.businessWebsite.editing}
            buttonText={"Edit"}
            onChangeValue={(e) => handleOnChange("businessWebsite", e.target.value)}
            onSave={() => onToggleButton("businessWebsite")}
          />
        </Card>
        <div className={classes.socialInputBlock} style={{border: error.facebook ? "1px solid red" : "none"}}>
          <span className={classes.socialHeader}>Facebook</span>
          <input className={classes.socialInput} onBlur={(event) => socialChange('facebook')} onChange={(event) => setSocial(event.target.value, 'facebook')} onKeyDown={(event) => checkKey(event.key, 'facebook')} placeholder="Paste url" value={profile.facebook.value}/>
        </div>
        <div className={classes.socialInputBlock} style={{border: error.instagram ? "1px solid red" : "none"}}>
          <span className={classes.socialHeader}>Instagram</span>
          <input className={classes.socialInput} onBlur={(event) => socialChange('instagram')} onChange={(event) => setSocial(event.target.value, 'instagram')} onKeyDown={(event) => checkKey(event.key, 'instagram')} placeholder="Paste url" value={profile.instagram.value}/>
        </div>
        <div className={classes.socialInputBlock} style={{border: error.twitter ? "1px solid red" : "none"}}>
          <span className={classes.socialHeader}>Twitter</span>
          <input className={classes.socialInput} onBlur={(event) => socialChange('twitter')} onChange={(event) => setSocial(event.target.value, 'twitter')} onKeyDown={(event) => checkKey(event.key, 'twitter')} placeholder="Paste url" value={profile.twitter.value}/>
        </div>
        <div className={classes.wrapper}>
          <div className={classes.flexContainer}>
            <div className={classes.sideBlock}>
              <span className={classes.creditCard}>Billing: xxxx 5192 01/22</span>
              <Button className={classes.controlBtn} classes={{label : classes.Label, text: classes.Text}} style={{color: "#68C800"}}>Add new</Button>
              <Button className={classes.controlBtn} classes={{label : classes.Label, text: classes.Text}}>Edit</Button>
              <Button className={classes.controlBtn} classes={{label : classes.Label, text: classes.Text}} style={{color: "#FF0000"}} onClick={handleOpenModal1}>Close account</Button>
            </div>
            <div className={classes.sideBlock2}>
              <span className={classes.owning}>Owing: $12.20</span>
              <Button className={classes.controlBtn} classes={{label : classes.Label, text: classes.Text}} style={{color: "#68C800"}}>Make payment</Button>
              <p className={classes.hint}>You will be billed automatically on owing amount of $20.00 or more. </p>
            </div>
          </div>
          <span className={classes.invocies}>Invoices:</span>
          <div className={classes.devider}></div>
            <span className={classes.listElement}>March 1, 2020:<span className={classes.space}>$22.00.</span><span className={classes.space}>xxxx 5192</span><span className={classes.space}>01/22</span></span>
          <span className={classes.listElement}>February 1, 2020: 		$21.20.   xxxx 5192 01/22</span>
        </div>

        <div className="flex" style={{justifyContent: "center"}}><Button className="logoutBtn" onClick={() => history.push("/logout")}>Logout</Button></div>

        {userData.activePaymentDetail && (
          <>
            <Card>
              <PersonalInfoInput
                label={"Billing"}
                value={profile.billing.value}
                editing={profile.billing.editing}
                buttonText={"Edit"}
                onChangeValue={(e) => handleOnChange("billing", e.target.value)}
                onSave={() => onToggleButton("billing")}
                noButton={profile.billing.noButton}
              />
            </Card>
            <Card>
              <PersonalInfoInput
                label={"Billing Details"}
                value1={userData.activePaymentDetail.brand}
                value2={`**** **** ${userData.activePaymentDetail.last4}`}
              />
            </Card>
            <Button variant="outlined" onClick={() => togglePaymentModal()}>
              Change payment method
            </Button>
          </>
        )}
        <Button>
          {userData?.role === "company" && (
            <div className={classes.paymentContainer}>
              <Modal open={paymentModal}>
                <div style={modalStyle} className={classes.paper}>
                  <h2 id="simple-modal-title">Add payment method</h2>
                  <p id="simple-modal-description">
                    <Payment
                      onSuccess={() => {
                        togglePaymentModal();
                        setOpen(false);
                        dispatch(checkLoginAction());
                      }}
                      onClose={() => togglePaymentModal()}
                    />
                  </p>
                </div>
              </Modal>
            </div>
          )}
        </Button>
      </form>

      <Modal open={open} style={{ overflowX: "auto" }}>
        <>
          <Plans
            onClose={() => toggleOpen()}
            backSettings={() => this.changeTab(0)}
            onPressSelect={togglePaymentModal}
          />
        </>
      </Modal>
    </div>
  );
}

export default ViewPersonalInfo;
