import React from "react";
import InputControl from "./InputControl";

const QuantityInput = ({ value, onChange, maxValue, minValue }) => {
  const handleOnIncrease = () => {
    if (parseInt(value) + 1 <= maxValue) {
      onChange(parseInt(value) + 1);
    }
  };

  const handleOnDecrease = () => {
    if (parseInt(value) - 1 >= minValue) {
      onChange(parseInt(value) - 1);
    }
  };

  const handleOnChange = (value) => {
    if (value > 0 && parseInt(value) <= maxValue && parseInt(value) >= minValue) {
      onChange(parseInt(value));
    } else if (!value) {
      onChange();
    } else if (value < 1) {
      onChange(Math.abs(value));
    }
  };

  return (
    <div className={"offer-input-container"}>
      <label className={"noselect"}>Quantity:</label>
      <div className={"input-area"}>
        <input
          className={"gift-card-number-input"}
          type={"number"}
          value={value}
          // min={maxValue}
          // max={maxValue}
          onChange={(e) => handleOnChange(e.target.value)}
        />
      </div>
      <InputControl onIncrease={handleOnIncrease} onDecrease={handleOnDecrease} />
    </div>
  );
};

export default QuantityInput;
