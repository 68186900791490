import React, { useEffect, useState, useCallback } from "react";
import {
  withStyles,
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  LinearProgress
} from "@material-ui/core";
import GoBackButton from "../../../component/Layout/GoBackButton";
import { fetchCardAnalytics } from "../../../redux/requests/cardRequests";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableheadcell: {
    backgroundColor: "#1C1524",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "23px",
  },
  tablebodycell: {
    fontFamily: "Lato",
    fontSize: "22px",
    fontWeight: "400",
    lineHeight: "27px",
  },
  backwrapper: {
    // backgroundColor: "#000",
    minHeight: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0 1rem",
    position: "sticky",
    left: "0",
    top: "0",
  },
  totalCardAmount: {
    fontFamily: "Lato",
    fontSize: "25px",
    fontWeight: "700",
    lineHeight: "29px",
    textAlign: "left",
    padding: "70px 15px",
  },
});

export default function CampaignsList(props) {
  const classes = useStyles();
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(true);
  const getCardAnalytics = useCallback(async () => {
    let analyticsResp = await fetchCardAnalytics(props.currentCampaign);
    setLoading(false);
    if (analyticsResp && analyticsResp.data) {
      setRows(analyticsResp.data);
    }
  }, [props.currentCampaign]);

  useEffect(() => {
    getCardAnalytics();
  }, [getCardAnalytics]);

  return (
    <div className="card-container campaign-list-area">
      <div className={classes.backwrapper}>
      <div style={{padding: "15px", marginLeft: "40px"}}><GoBackButton onClick={props.backToDetails}/></div>
      </div>
      <TableContainer className="rounded-0">
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.tableheadcell}>Gift Card</StyledTableCell>
              <StyledTableCell className={classes.tableheadcell}>ID</StyledTableCell>
              <StyledTableCell className={classes.tableheadcell}>Status</StyledTableCell>
              <StyledTableCell className={classes.tableheadcell}>Date</StyledTableCell>
              <StyledTableCell className={classes.tableheadcell}>Demographic</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(rows) &&
              rows.map((row) => (
                <StyledTableRow key={row.id} className={"table-row-min-height"}>
                  <StyledTableCell component="th" scope="row">
                    <img
                      src={`${process.env.REACT_APP_IMG_URL}/card-photo/${row?.card?.cardPhoto}`}
                      width="100"
                      alt=""
                    />
                  </StyledTableCell>
                  <StyledTableCell className={classes.tablebodycell}>{row?.card?.code}</StyledTableCell>
                  <StyledTableCell className={classes.tablebodycell}>{row.status}</StyledTableCell>
                  <StyledTableCell className={classes.tablebodycell}>
                    {moment(row.createdAt).format("LLL")}
                  </StyledTableCell>
                  <StyledTableCell className={classes.tablebodycell}>
                    {row?.owner?.gender},{row?.owner?.age || "NA"}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <LinearProgress/>}
      {(!rows?.length && !loading) && <p style={{textAlign: "center", fontSize: "26px"}}>No Actions</p>}
      <div className={classes.totalCardAmount}>
        Gift Cards available: {(rows && rows[0]?.campaign?.quantity - rows[0]?.campaign?.collected) || 0}
      </div>
    </div>
  );
}
