import { httpPostCall, httpGetCall, httpPatchCall } from "../../common/commonService";
const URL = process.env.REACT_APP_BASE_URL;

export const addCategory = async (data) => {
  try {
    let categoryResp = await httpPostCall(`${URL}/categories`, data);
    return categoryResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchCategory = async () => {
  try {
    let categoryResp = await httpGetCall(`${URL}/categories`);
    return categoryResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const updateCategory = async (id, data) => {
  try {
    let categoryResp = await httpPatchCall(`${URL}/categories/${id}`, data);
    return categoryResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const saveArrayCategories = async (data) => {
  try {
    let categoryResp = await httpPostCall(`${URL}/campaigns/set-categories`, data);
    return categoryResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};
