import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  dialog: {
    padding: '20px 0'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  title: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 700,
    fontFamily: 'Lato',
    '@media (max-width: 576px)': {
      fontSize: 15
    }
  },
  mainText: {
    textAlign: 'center',
    fontWeight: 700,
    fontFamily: 'Lato',
    fontSize: '1.25rem',
    marginBottom: '2rem',
    '@media (max-width: 576px)': {
      fontSize: 15,
    }
  },
  minorText: {
    textAlign: 'center',
    fontWeight: 700,
    fontFamily: 'Lato',
    fontSize: '1.25rem',
    marginBottom: '2rem',
    '@media (max-width: 576px)': {
      fontSize: 15,
    }
  },
  close: {
    cursor: 'pointer',
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
    height: 50,
    background: '#000000',
    fontSize: 40,
    fontWeight: 700,
    fontFamily: 'Lato',
    marginLeft: 30,
    '@media (max-width: 576px)': {
      fontSize: 20,
      width: 30,
      height: 30
    }
  },
  button: {
    fontFamily: 'Lato',
    fontWeight: 'bold',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: 105,
    background: '#D7D7D7',
    '@media (min-width: 576px)': {
      fontSize: '1.25rem',
      padding: '10px 30px'
    }
  }
});

export function ApproveRemoveModal({ open, onClose, title, mainText, minorText, onApprove }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogContent>
        <header className={classes.header}>
          <span className={classes.close} onClick={onClose}>×</span>
          <h1 className={classes.title}>{title}</h1>
        </header>
        <h1 className={classes.mainText}>{mainText}</h1>
        <h2 className={classes.minorText}>{minorText}</h2>
        <Button variant="contained" className={classes.button} onClick={onApprove}>Remove</Button>
      </DialogContent>
    </Dialog>
  ); 
}
