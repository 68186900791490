import React, { useState, useEffect } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { httpGetCall } from "../../../common/commonService";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import { TOAST } from "../../../redux/actions/actions-types";
import { setPaymentId } from "../../../redux/actions/campaignsActions";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "./CheckoutForm.css";

const URL = process.env.REACT_APP_BASE_URL;

function CheckoutForm({ onSuccess, onClose, setPaymentId }) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.login.userData.role);
  const [key, setKey] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };

  useEffect(() => {
    (async () => {
      if (role !== "admin" && role !== undefined) {
        const {data} = await httpGetCall(`${URL}/payments/client-secret`);
        setKey(data);
      }
    })()
  }, [role])

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (!key) {
      showToast("error", "Error", "Stripe Secret Key Not Recived");
      return;
    }

    setShowLoader(true);

    const result = await stripe.confirmCardSetup(key, {
      payment_method: {
        card: elements.getElement(CardElement),
      }
    });

    if (result.error) {
      showToast("error", "Error", result.error.message);
      console.log(result.error);
    } else {
      setPaymentId(result.setupIntent.payment_method);
      showToast("success", "Done", "Credit Card Added");
    }
    setShowLoader(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        {!stripe ? <p style={{fontSize: "20px", color: "red"}}>Stripe load Erorr</p> : null}
        <CardElement
          options={{
            hidePostalCode: true,
            style: {
              base: {
                fontSize: "20px",
                color: "#000",
                top: "33%",
                position: "relative",
                width: "100%",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />

        <Button
          type="submit"
          disabled={!stripe}
          style={!stripe ? null : { marginTop: "2rem" }}
          className="addCard"
        >
          {showLoader ? (
            <CircularProgress color={"primary"} size={30} />
          ) : (
            "Add Credit Card"
          )}
        </Button>

        {/* <Button style={{ marginTop: "2rem" }} onClick={() => onClose()}>
          Cancel
        </Button> */}
      </div>
    </form>
  );
}

export default connect(null, { setPaymentId })(CheckoutForm);
