import React, { Component } from 'react';

import Base from './../../component/Layout/Base';

import DesignOfferFront from './DesignOffer/DesignOfferFront';
import DesignOfferBackStep1 from './DesignOffer/DesignOfferBackStep1';
import DesignOfferBackStep2 from './DesignOffer/DesignOfferBackStep2';
import DesignOfferReview from './DesignOffer/DesignOfferReview';
import DesignOfferFinal from './DesignOffer/DesignOfferFinal';

export default class GiftCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      details: {
        title: '',
        cardValue: '',
        cardTier: '',
        details: '',
        quantity: '',
        code: '',
        promoteUrl: '',
        logoPhoto: '',
        cardPhoto: '',
        logoShowing: true,
      },
    };
  }

  componentDidMount = () => {
    const paramsString = this.props.location.search;
    if (paramsString) {
      const params = new URLSearchParams(paramsString);
      const page = params.get('page');
      if (page !== null) {
        this.setState({
          tabIndex: 2,
        });
      }
    }
  };

  handleOnClickButton = (tabIndex, detailsToSet = {}) => {
    // window.scrollTo(0, 0);

    if (tabIndex === null) tabIndex = this.state.tabIndex;
    if (tabIndex !== -1) {
      // navigate around the tabs
      this.setState({
        tabIndex,
        details: { ...this.state.details, ...detailsToSet },
      });
    } else {
      this.props.history.push('/dashboard');
    }
  };

  renderComponent = tabIndex => {
    return (
      <>
        <div style={{ display: tabIndex === 0 ? 'block' : 'none' }}>
          <DesignOfferFront
            handleOnClickButton={this.handleOnClickButton}
            details={this.state.details}
          />
        </div>
        <div style={{ display: tabIndex === 1 ? 'block' : 'none' }}>
          <DesignOfferBackStep1
            handleOnClickButton={this.handleOnClickButton}
            details={this.state.details}
          />
        </div>
        <div style={{ display: tabIndex === 2 ? 'block' : 'none' }}>
          <DesignOfferBackStep2
            handleOnClickButton={this.handleOnClickButton}
            details={this.state.details}
          />
        </div>

        <div style={{ display: tabIndex === 3 ? 'block' : 'none' }}>
          <DesignOfferReview
            handleOnClickButton={this.handleOnClickButton}
            details={this.state.details}
            onNavigate={this.handleOnClickButton}
            generateThumbnail={tabIndex === 3}
          />
        </div>
        <div style={{ display: tabIndex === 4 ? 'block' : 'none' }}>
          <DesignOfferFinal
            handleOnClickButton={this.handleOnClickButton}
            details={this.state.details}
          />
        </div>
      </>
    );

    // switch (tabIndex) {
    //   case 0:
    //     return (
    //       <DesignOfferFront
    //         handleOnClickButton={this.handleOnClickButton}
    //         details={this.state.details}
    //       />
    //     );
    //   case 1:
    //     return (
    //       <DesignOfferBackStep1
    //         handleOnClickButton={this.handleOnClickButton}
    //         details={this.state.details}
    //       />
    //     );
    //   case 2:
    //     return (
    //       <DesignOfferBackStep2
    //         handleOnClickButton={this.handleOnClickButton}
    //         details={this.state.details}
    //       />
    //     );
    //   case 3:
    //     return (
    //       <DesignOfferReview
    //         handleOnClickButton={this.handleOnClickButton}
    //         details={this.state.details}
    //         onNavigate={this.handleOnClickButton}
    //       />
    //     );
    //   case 4:
    //     return (
    //       <DesignOfferFinal
    //         handleOnClickButton={this.handleOnClickButton}
    //         details={this.state.details}
    //       />
    //     );

    //   default:
    //     return <div></div>;
    // }
  };

  render() {
    // console.log("state", this.state);
    return (
      <Base
        theme={'light'}
        topGap
      >
        {this.renderComponent(this.state.tabIndex)}
      </Base>
    );
  }
}
