import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import GiftCard from "./GiftCard";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import CardFrontOption from "./CardFrontOption";
import { useDispatch } from "react-redux";
import Footer from "./Footer";
import { TOAST } from "../../../redux/actions/actions-types";
import "./card.module.css";

const useStyles = makeStyles((theme) => ({
  bottomSection: {
    backgroundColor: "#DDDDDD",
    paddingTop: 65,
    paddingBottom: 65,
  },
}));

const DesignOfferFront = ({ handleOnClickButton, details }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const companyLogo = useSelector((state) => state.login.userData.companyLogo);
  const hiddenInputRef = React.createRef();
  const [backgroundImage, setBackgroundImage] = useState(details.cardPhoto);
  const [cardPhoto, setCardPhoto] = useState(details.cardPhoto);
  const [title, setCardTitle] = useState(details.title);
  const [logoPhoto, setCardLogo] = useState(details.logoPhoto);
  const [img, setImg] = useState();
  const [error, setError] = useState(false);
  const matches = useMediaQuery(`(max-width:500px)`);
  useEffect(() => {
    if (details.logoShowing && companyLogo) {
      setCardLogo(`${companyLogo}`);
    }
  }, [companyLogo, details.logoShowing]);

  const handleOnClickInsertImage = () => {
    hiddenInputRef.current.click();
  };

  const handleOnSelectImage = (e) => {
    const image = e.target.files[0];
    setImg(image);
    if (image && image.name.match(/\.(jpg|jpeg|png)$/)) {
      const backgroundImage = URL.createObjectURL(image);
      setCardPhoto(image);
      setBackgroundImage(backgroundImage);
    } else {
      setBackgroundImage("");
    }
  };

  const handleOnChangeCardTitle = (e) => {
    const title = e.target.value;
    setCardTitle(title);
  };
  const getThumbnail = async () => {

    let thumbnail = 3;
    // var img = document.createElement("img");
    // img.src = thumbnail;
    // var src = document.body.appendChild(img);
    // src.appendChild(img);
    return thumbnail;
  };
  const handleOnClickAttachLogo = () => {
    if (companyLogo) {
      setCardLogo(`${process.env.REACT_APP_BASE_URL}/uploads/avatars/${companyLogo}`);
    } else {
      showError("Select company logo from settings");
    }
  };

  const handleOnRemoveLogo = () => {
    setCardLogo();
  };

  if (title.length > 2 && error) {
    setError(false);
  }

  const validation = () => {
    if (title.length < 3) {
      showError("Card title must contain at least three characters");
      setError(true);
      return false;
    } else if (!backgroundImage) {
      showError("Select card background");
      return false;
    }
    else if (img.size > 512000 ) {
      showError("Image size larger Than 500Kb");
      return true;
    }
    return true;
  };
  const handleNextButton = async (tabIndex) => {
    if (validation()) {
      let thumbnail = await getThumbnail();
      // return;

      handleOnClickButton(tabIndex, {
        title: title,
        logoPhoto: logoPhoto,
        cardPhoto: backgroundImage,
        photo: cardPhoto, // this will be actually send to server,
        logoShowing: logoPhoto ? true : false,
        cardFront: thumbnail,
      });
    }
  };
  const showError = (message) => {
    dispatch({
      type: TOAST,
      load: {
        type: "error",
        title: "Error",
        message: message,
        show: true,
      },
    });
  };

  return (
    <div>
      <div>
        <div className="card-offer-container" style={matches? {width : "375px", marginTop: "15px"} : null}>
          <div className="card-offer-content" style={matches? {width : "375px"} : null}>
            <div className={"card-offer-header"}>
              <p>Step 1 of 3</p>
              <p> Let’s get started with the front!</p>
            </div>
            <div className={"card-section"}>
              <div style={{ opacity: 0, position: "absolute" }}>
                <div id="offer-card">
                  <GiftCard
                    rounded
                    shadow
                    side={"front"}
                    cardPhoto={backgroundImage}
                    title={title}
                    onChangeTitle={handleOnChangeCardTitle}
                    logoPhoto={logoPhoto && logoPhoto}
                    // onUploadLogo={handleOnClickAttachLogo}
                    // onRemoveLogo={handleOnRemoveLogo}
                    view={true}
                    isApproved={false}
                    error={error}
                  />
                </div>
              </div>
              <GiftCard
                rounded
                shadow
                side={"front"}
                cardPhoto={backgroundImage}
                title={title}
                onChangeTitle={handleOnChangeCardTitle}
                logoPhoto={logoPhoto && `${process.env.REACT_APP_IMG_URL}/avatars/${logoPhoto}`}
                onUploadLogo={handleOnClickAttachLogo}
                onRemoveLogo={handleOnRemoveLogo}
                view={false}
                isApproved={false}
                error={error}
              />
            </div>
            <div className={`bottom-section card-front-options-container ${classes.bottomSection}`}>
              {/* hidden input of type file to select image */}
              <input type={"file"} style={{ display: "none" }} ref={hiddenInputRef} onChange={handleOnSelectImage} />
              {/* hidden input of type file to select logo */}

              <CardFrontOption
                label={"Insert an image to get started"}
                img={require("../../../assets/images/gift_card/coffee_no_border.svg")}
                onClick={handleOnClickInsertImage}
              />
              {/* <CardFrontOption
          label={"Attach logo"}
          img={require("../../../assets/images/gift_card/c.png")}
          onClick={handleOnClickAttachLogo}
        /> */}
            </div>
          </div>
        </div>
      </div>

      <Footer
        buttons={[
          {
            title: "Next",
            tabIndex: 1,
          },
        ]}
        onClickButton={handleNextButton}
      />
    </div>
  );
};

export default DesignOfferFront;
