import * as types from '../actions/actions-types';
import { httpPostCall, httpGetCall, httpPutCall, httpDeleteCall, httpPatchCall } from "../../common/commonService";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

//saga
export const callUserCards = () => ({
    type: types.CALL_USER_CARDS
})

export const editUserCard = (editCard) => ({
    type: types.EDIT_USER_CARD,
    editCard
})

const showToast = (type, title, message) => ({
      type: types.TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
  });

//reducer
export const addCampaignsCards = (userCards) => {
    return {
        type: types.ADD_CAMPAIGNS_CARDS,
        userCards
    }
}

const cardsDataSucces = (payload) => ({
    payload,
    type: types.CARDS_FOR_WHEEL_DATA_SUCCES,
});

export const fetchCardsWhell = () => async dispatch => {
    try {
      const {data} = await httpGetCall(`${REACT_APP_BASE_URL}/cards/for-wheel`);
      dispatch(cardsDataSucces(data));
    } catch (err) {
      return err;
    }
};

const locationDataSucces = (payload) => ({
    payload,
    type: types.LOCATIONS_FOR_WHEEL_DATA_SUCCES,
});

export const getLocations = (type) => async dispatch => {
    dispatch(isLoading());
    let url;
    if (type === "QR") {
        url = "/wheels/qr"
    } else {
        url = "/drop-locations/for-wheel"
    }
    try {
        const {data} = await httpGetCall(`${REACT_APP_BASE_URL}${url}`);
        dispatch(locationDataSucces(data));
      } catch (err) {
        return err;
      }
} 

const setLocation = (payload) => ({
    payload,
    type: types.SET_CURRENT_LOCATION,
})

const newLocation = (payload) => ({
    payload,
    type: types.ADD_NEW_LOCATION,
})

export const sendLocation = (location, address, long, lat ) => async dispatch => {
    dispatch(isLoading());
    const locations = {
        name: location,
        address: address,
        long: long,
        lat: lat,
    }
    const {data} = await httpPostCall(`${REACT_APP_BASE_URL}/drop-locations/for-wheel`, locations);
    dispatch(setLocation(data));
    dispatch(newLocation(data));
    dispatch(showToast("success", "Done", "Location Created"));
};

export const setLocationToWheel = (location) => dispatch => {
    dispatch(setLocation(location));
}

const sendToWheel = (payload) => ({
    payload,
    type: types.SEND_CARDS_TO_WHEEL,
})

export const sendCardsToWhell = (cards, wheelType) => async dispatch=> {
    dispatch(isLoading());
    try {
        if (wheelType === "QR") {
            const {data} = await httpPostCall(`${REACT_APP_BASE_URL}/wheels/qr/generate`, cards);
            dispatch(sendToWheel(data));
        } else {
            const {data} = await httpPostCall(`${REACT_APP_BASE_URL}/wheels/generate`, cards);
            dispatch(sendToWheel(data));
        }
    } catch (err) {
      return err;
    }
};

const isLoading = () => ({
    type: types.INITIAL_STATE_LOADING
})

export const loadSlots = () => dispatch => {
    dispatch(isLoading());
}

const changeSlotStatus = (slot, status) => ({
    slot,
    status,
    type: types.CHANGE_SLOT_STATUS,
})

export const slotStatus = (slot, status) => dispatch => {
    dispatch(changeSlotStatus(slot, status));
}

const setOffer = (slot, offer) => ({
    slot,
    offer,
    type: types.SET_OFFER_TO_SLOT
})

export const selectOfferToSlot = (slot, offer) => dispatch => {
    dispatch(setOffer(slot, offer));
}

const clearSlot = (slot) => ({
    slot,
    type: types.CLEAR_OFFER_FROM_SLOT
})

export const cancelOffer = (slot) => dispatch => {
    dispatch(clearSlot(slot))
}

const incQuantity = (slot) => ({
    slot,
    type: types.INCREASE_CARDS_QUANTITY
})

export const increaseQuantity = (slot) => dispatch => {
    dispatch(incQuantity(slot));
}

const decQuantity = (slot) => ({
    slot,
    type: types.DECREASE_CARDS_QUANTITY
})

export const decreaseQuantity = (slot) => dispatch => {
    dispatch(decQuantity(slot));
}

const setQuantityToSlot = (slot, number) => ({
    slot,
    number,
    type: types.SET_QUANTITY_TO_SLOT
})

export const setQuantity = (slot, number) => dispatch => {
    dispatch(setQuantityToSlot(slot, number));
}

const setChance = (slot, number) => ({
    slot,
    number,
    type: types.SET_CHANCE_TO_SLOT
})

export const setChanceToSlot = (slot, number) => dispatch => {
    dispatch(setChance(slot, number));
}

const sendWheelData = () =>({
    type: types.SEND_WHEEL_DATA_SUCCES
})

const setWheelID = (payload) => ({
    payload,
    type: types.SET_QRWHEEL_ID
})

export const confirmData = (items, location, logo, file, paymentId, wheelType, colors, avatar, names, expiresOn) => async dispatch=> {
    dispatch(isLoading());
    let wheelData, url;
    if (wheelType === "QR") {
        wheelData = {
            items,
            colors,
            wheelLogo: avatar,
            name: names.name,
            qrUrlPart: names.qrUrlPart,
            expiresOn: null
        };
        if (expiresOn) {
            wheelData.expiresOn = expiresOn
        }
        url = `${REACT_APP_BASE_URL}/wheels/qr/set-campaign`
    } else {
        wheelData = {
            items,
            dropLocationId: location.id,
            expiresOn: null
        };
        if (expiresOn) {
            wheelData.expiresOn = expiresOn
        }
        url = `${REACT_APP_BASE_URL}/wheels/set-campaign`;
    }
    try {
        const {data} = await httpPostCall(url, wheelData);
        dispatch(sendWheelData());
        if (data.wheelId) {
            dispatch(setWheelID(data.wheelId));
        } else {
            dispatch(clearState());
        }
        dispatch(showToast("success", "Done", "Campaign Created"));
        if (logo) {
            let formData = new FormData();
            formData.append("wheel-logo", file);
            try {
                await httpPostCall(`${REACT_APP_BASE_URL}/wheels/${data.wheelId}/set-logo`, formData);
            } catch (err) {
                return err;
            }
        }
        if (paymentId) {
            try {
                await httpPostCall(`${REACT_APP_BASE_URL}/payments/method/${data.campaignId}?pmId=${paymentId}`);
            } catch (err) {
                return err;
            }
        }
    } catch (err) {
        return err;
    }
}

export const clearState = () => ({
    type: types.RESET_CAMPAIGN_STATE
})

export const clearCampaign = () => dispatch => {
    dispatch(clearState());
}

const takeWheelData = (payload) => ({
    payload,
    type: types.GET_WHEEL_DATA_SUCCES
})

export const getWheelData = (id) => async dispatch => {
    dispatch(isLoading());
    try {
        const {data} = await httpGetCall(`${REACT_APP_BASE_URL}/wheels/${id}`);
        dispatch(takeWheelData(data));
    } catch (err) {
        return err;
    }
}

const takeWheelDataQR = (payload) => ({
    payload,
    type: types.GET_WHEEL_DATA_SUCCES_QR
})

export const getWheelDataQR = (id) => async dispatch => {
    dispatch(isLoading());
    try {
        const {data} = await httpGetCall(`${REACT_APP_BASE_URL}/wheels/qr/${id}`);
        dispatch(takeWheelDataQR(data));
    } catch (err) {
        return err;
    }
}

const saveLogo = (logo, url) => ({
    logo,
    url,
    type: types.SAVE_LOGO_TO_STATE
})

export const setLogo = (logo, url) => dispatch => {
    dispatch(saveLogo(logo, url));
}

const setEditToTrue = () => ({
    type: types.SET_EDIT_WHEEL
})

export const setEdit = () => dispatch => {
    dispatch(setEditToTrue());
}

export const editData = (id, data, logo, file, wheelType, expiresOn, colors) => async dispatch => {
    let dataObject= {
        items: data,
        expiresOn: expiresOn || null
    }
    dispatch(isLoading());
    let url = "/wheels/";
    if (wheelType === "QR") {
        url = "/wheels/qr/"
        dataObject = {
            items: data,
            colors,
        }
    }
    try {
        await httpPutCall(`${REACT_APP_BASE_URL}${url}${id}`, dataObject);
        dispatch(showToast("success", "Done", "Campaign Edited"));
        dispatch(sendWheelData());
        if (logo) {
            let formData = new FormData();
            formData.append("wheel-logo", file);
            try {
                await httpPostCall(`${REACT_APP_BASE_URL}/wheels/${id}/set-logo`, formData);
            } catch (err) {
                return err;
            }
        }
    } catch (err) {
        return err;
    }
}

const removeLocation = (payload) => ({
    payload,
    type: types.DELETE_LOCATION 
})

export const deleteLocation = (id) => async dispatch => {
    try {
        const {data} = await httpDeleteCall(`${REACT_APP_BASE_URL}/wheels/${id}`);
        if (data.length) {
            dispatch(removeLocation(id));
            dispatch(showToast("success", "Done", "Campaign Deleted"));
            return data;
        }
    } catch (err) {
        return err;
    }
    
}

export const getKey = () => async dispatch => {
    try {
        const {data} = await httpGetCall(`${REACT_APP_BASE_URL}/users/client-secret`);
        return data;
    } catch (err) {
        return err;
    }
}

const setPaymentIdMethod = (payload) => ({
    payload,
    type: types.SET_PAYMENT_ID
})

export const setPaymentId = (id) => dispatch => {
    dispatch(setPaymentIdMethod(id));
}

const setTargetedCampaign = (payload) => ({
    payload,
    type: types.GET_TARGETED_CAMPAIGN
})

export const getTargetedCampaign = (id) => async dispatch => {
    dispatch(isLoading());
    try {
        const {data} = await httpGetCall(`${REACT_APP_BASE_URL}/campaigns/${id}`);
        dispatch(setTargetedCampaign(data));
    } catch (err) {
        return err;
    }
}

const setCampaignStatus = (payload) => ({
    payload,
    type: types.CHANGE_CAMPAIGN_STATUS
});

export const changeCampaignStatus = (id) => async dispatch => {
    try {
        const {data} = await httpPatchCall(`${REACT_APP_BASE_URL}/campaigns/${id}/toggle-pause`);
        dispatch(setCampaignStatus(data));
    } catch (err) {
        return err;
    }
};

const setCampaignStatistics = (payload, type) => ({
    payload,
    type: type
});

const clearStats = (type) => ({
    type: type
});

export const getStatistics = (id, date = false, type) => async dispatch => {
    let url;
    switch (type) {
        case "main": {
            dispatch(clearStats(types.MAIN_STATISTICS_CLEAR));
            url = "/users/statistics/";
            break;
        }
        case "mainQR": {
            dispatch(clearStats(types.MAIN_STATISTICS_CLEAR));
            url = "/users/qr/statistics/";
            break;
        }
        case "card": {
            dispatch(clearStats(types.CARD_STATISTICS_CLEAR));
            url = "/cards/statistics/";
            break;
        }
        case "targeted": {
            dispatch(clearStats(types.TARGETED_STATISTICS_CLEAR));
            url = "/campaigns/";
            break;
        }
        case "QR": {
            dispatch(clearStats(types.QR_STATISTICS_CLEAR));
            url = "/wheels/qr/statistics/";
            break;
        }
        case "wheel": {
            dispatch(clearStats(types.WHEEL_STATISTICS_CLEAR));
            url = "/wheels/statistics/";
            break;
        }
        default:
    }
    try {
        const {data} = await httpGetCall(`${REACT_APP_BASE_URL}${url}${id}?pastTime=${date}`);
        switch (type) {
            case "main": {
                dispatch(setCampaignStatistics(data, types.MAIN_STATISTICS_SUCCESS));
                break;
            }
            case "mainQR": {
                dispatch(setCampaignStatistics(data, types.MAIN_STATISTICS_SUCCESS));
                break;
            }
            case "card": {
                dispatch(setCampaignStatistics(data, types.CARD_STATISTICS_SUCCESS));
                break;
            }
            case "targeted": {
                dispatch(setCampaignStatistics(data, types.TARGETED_STATISTICS_SUCCESS));
                break;
            }
            case "QR": {
                dispatch(setCampaignStatistics(data, types.QR_STATISTICS_SUCCESS));
                break;
            }
            case "wheel": {
                dispatch(setCampaignStatistics(data, types.WHEEL_STATISTICS_SUCCESS));
                break;
            }
            default:
        }
    } catch (err) {
        return err;
    }
};

const changeWheelType = (payload) => ({
    payload,
    type: types.SIDEBAR_WHEEL_CHANGE
})

export const setWheelType = (type) => dispatch => {
    dispatch(changeWheelType(type));
}

const setColors = (payload) => ({
    payload,
    type: types.SEND_COLORS_TO_WHEEL
})

export const setWheelColors = (colors) => dispatch => {
    dispatch(setColors(colors));
}

const senQRCampaignName = (payload) => ({
    payload,
    type: types.SET_CAMPAIGN_NAME
})

export const setCampaignName = (name) => dispatch => {
    dispatch(senQRCampaignName(name));
}

const editColors = () => ({
    type: types.SET_EDIT_COLORS
})

export const setEditColors = () => dispatch => {
    dispatch(editColors());
}

const chestsDataSucces = (payload) => ({
    payload,
    type: types.GET_CHESTS_DATA_SUCCESS,
});

export const getChests = () => async dispatch => {
    dispatch(isLoading());
    try {
        const {data} = await httpGetCall(`${REACT_APP_BASE_URL}/chests-all`);
        dispatch(chestsDataSucces(data));
      } catch (err) {
        return err;
      }
} 

const chestDataSucces = (payload) => ({
    payload,
    type: types.GET_CHEST_DATA_SUCCESS,
});

export const getChest = (id) => async dispatch => {
    dispatch(isLoading());
    try {
        const {data} = await httpGetCall(`${REACT_APP_BASE_URL}/chests/${id}`);
        dispatch(chestDataSucces(data));
      } catch (err) {
        return err;
      }
}

const changeExpiryCompany = (payload) => ({
    payload,
    type: types.CHANGE_EXPIRY_COMPANY,
})

export const changeExpiry = (date) => dispatch => {
    dispatch(changeExpiryCompany(date))
}