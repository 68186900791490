import React, { Component } from "react";
import Base from "./../../../component/Layout/Base";
import Switch from "../../../component/Layout/SwitchInput";
import { Button, CircularProgress, Checkbox, IconButton, LinearProgress, Select, FormControl, MenuItem } from "@material-ui/core";
import "react-rangeslider/lib/index.css";
import { connect } from "react-redux";
import { getUserCards } from "../../../redux/actions/dropCardsActions";
import moment from 'moment';
import GiftCard from "../../GiftCard/DesignOffer/GiftCard";
import Navigator from "../../GiftCard/Navigator";
import Card from "../../../component/Layout/Card";
import ExpiryDateInput from "../../GiftCard/DesignOffer/Inputs/ExpiryDateInput";
import VerifyCheckbox from "../../GiftCard/DesignOffer/Inputs/VerifyCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import CountryBadge from "../../../component/Layout/CountryBadge";
import CityItem from "../../../component/Layout/CityItem";
import CitiesSearchList from "../../../component/Layout/CitiesSearchList";
import GenderRadioInput from "./GenderRadioInput";
import GradiantButton from "../../../component/Layout/GradiantButton";
import GoBackButton from "../../../component/Layout/GoBackButton";
import {
  fetchCards,
  fetchLocation,
  fetchCountries,
  addCampaign,
  addCountryCampaign,
  editCountryCampaign,
  fetchCampaignDetails,
  updateCampaignDetails,
} from "../../../redux/requests/cardRequests";
import { TOAST } from "../../../redux/actions/actions-types";
const blankCardImage = require("../../../assets/images/home/create_new_offer.png");
class StandardGift extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      step: "create",
      value: 10,
      showMe: false,
      card: 0,
      showing: true,
      approvedCards: [],
      choiceQuantity: 0,
      active: false,
      cities: "canada",
      addCity: [],
      cards: [],
      availableCountries: [],
      citySearchInputValue: "",
      availableCities: [{ id: 1, name: "Lac La Biche", country: "Canada" }],
      citySearchResults: [],
      showSearchResults: false,
      genders: ["Male", "Female", "All"],
      paramsDecided: false,
      editTarget: false,
      cardSide: "front",
      showSubmitSpinner: false,
      campaignId: null,
      show: false,
      selectableCities: [],
      showLoading: true,
      filteredCities: [],
      selectAllCities: false,
      countryWide: false,
      selectOpen: false,
      selectedCountry: 1000,
      countries: [],
      expiresOn: '',
      isGlobal: false,
    };
  }

  componentDidUpdate() {
    const card = this.state.cards[this.state.card];
    if (card && !card?.allowedQuantity) {
      this.updateCards({
        allowedQuantity: 1,
      });
    }

    const paramsString = this.props.location.search;

    if (paramsString) {
      const params = new URLSearchParams(paramsString);
      const edit = params.get("edit");
      const campaignId = params.get("id");

      if (edit === "true") {
        if (!this.state.paramsDecided) {
          this.setState({
            paramsDecided: true,
            editTarget: true,
          });
          this.setState({ campaignId: campaignId });
        }
      } else {
        // this.setState({
        //   paramsDecided: true,
        // });
      }
    } else {
      if (!this.state.paramsDecided) {
        this.setState({
          paramsDecided: true,
        });
      }
    }
  }

  expireHandler = () => {
    if (this.state.expiresOn) {
      this.setState({ expiresOn: ''})
    } else {
      this.setState({ expiresOn: new Date()})
    }
  }

  setExpiredDate = (date) => {
    this.setState({ expiresOn: date });
  }

  getCampaignDetails = async (id) => {
    let campaignDetails = await fetchCampaignDetails(id);
    if (campaignDetails && campaignDetails.data) {
      let currentCardIndex = this.state.cards.findIndex((items) => items.id === campaignDetails.data.cardId);
      let currentCities = campaignDetails.data.locationIds;
      currentCities = this.state.availableCities.filter(
        (items) => currentCities && currentCities.indexOf(items.id) > -1
      );

      let currentCountries = currentCities.map((items) => items.country.toLowerCase());
      currentCities = currentCities.map((items) => items.city);
      let card = [...this.state.cards];
      
      card[currentCardIndex].selectedCountries = currentCountries;
      card[currentCardIndex].selectedCities = currentCities || [];
      // card[currentCardIndex].selectedCities.push(currentCities[0]);
      if (card[currentCardIndex].selectedCountries.length > 0) this.toggleCountryName(currentCities);
      card[currentCardIndex].selectedGender = campaignDetails.data.gender;
      card[currentCardIndex].allowedQuantity = campaignDetails.data.quantity;
      card[currentCardIndex].locationIds = campaignDetails.data.locationIds;
      card[currentCardIndex].inShuffle = campaignDetails.data.inShuffle;
      card[currentCardIndex].inShop = campaignDetails.data.inShop;
      card[currentCardIndex].expiresOn = campaignDetails.data.expiresOn;
      card[currentCardIndex].isGlobal = campaignDetails.data.isGlobal;
      this.setState({ cards: card, card: currentCardIndex, show: true, expiresOn: campaignDetails.data.expiresOn, isGlobal: campaignDetails.data.isGlobal });
      if (campaignDetails.data.type === "country-wide") {
        const index = this.state.countries.findIndex((country) => country.id === campaignDetails.data?.country[0]?._id)
        if (index !== -1) {
          this.setState({...this.state, selectedCountry: this.state.countries[index]})
        }
        this.setState({...this.state, countryWide: true})
      }
    }
  };

  quantityChoice = (check) => {
    const up = this.state.choiceQuantity + 1;
    const down = this.state.choiceQuantity - 1;

    if (check === "up" && this.state.choiceQuantity < this.props.standardUserCards[this.state.card].quantity) {
      this.setState({ choiceQuantity: up });
    } else if (check === "down" && this.state.choiceQuantity > 0) {
      this.setState({ choiceQuantity: down });
    }
  };

  preview() {
    this.setState({
      showMe: !this.state.showMe,
    });
  }

  addCity = (city) => {
    let test = city.includes("Canada") || city.includes("USA");
    if (test) {
      this.setState({ addCity: [...this.state.addCity, city] });
      this.refs.cityInput.value = "";
    }
  };

  removeCity = (city) => {
    const test = this.state.addCity.filter((rem) => rem !== city);
    this.setState({ addCity: test });
    this.setState({ selectAllCities: false})
  };

  componentDidMount() {
    this.getUserCards();
  }

  getUserCards = async () => {
    this.setState({ showLoading: true });
    const paramsString = this.props.location.search;
    const params = new URLSearchParams(paramsString);
    const edit = params.get("edit");
    const campaignId = params.get("id");

    let cards = await fetchCards();

    let locations = await fetchLocation();

    let countries = await fetchCountries();
    
    if (cards && cards.data) {
      this.setState({ cards: cards.data });
    }
    if (countries && countries.data) {
      let ids = [...new Set(countries.data)];
      this.setState({ countries: ids })
    }
    if (locations && locations.data) {
      this.setState({ availableCities: locations.data });
      this.setState({ selectableCities: locations.data });
      let counties = locations.data.map((items) => items.country.toLowerCase());
      counties = [...new Set(counties)];
      this.setState({ availableCountries: counties });
    }

    if (edit && campaignId) {
      this.getCampaignDetails(campaignId);
    } else {
      const card = this.state.cards[this.state.card];
      if (card && !card?.allowedQuantity) {
        this.updateCards({
          allowedQuantity: 1,
        });
      }
      this.setState({ show: true });
    }
    this.setState({ showLoading: false });
  };

  renderCard() {
    const card = this.state.cards[this.state.card];
    return <GiftCard {...card} side={this.state.cardSide} logoPhoto={card.logoPhoto && `${process.env.REACT_APP_IMG_URL}/avatars/${card.logoPhoto}`} view viewDetails rounded shadow step={3} standardGift />;
  }

  incrementQuantity = () => {
    const card = this.state.cards[this.state.card];
    if (!card.allowedQuantity || card.allowedQuantity < card.quantity) {
      this.updateCards({
        allowedQuantity: card.allowedQuantity ? card.allowedQuantity + 1 : 2,
      });
    }
  };

  decrementQuantity = () => {
    const card = this.state.cards[this.state.card];

    if (!card.allowedQuantity || card.allowedQuantity > 0) {
      this.updateCards({
        allowedQuantity: card.allowedQuantity ? card.allowedQuantity - 1 : 0,
      });
    }
  };

  handleOnChangeQuantity = (e) => {
    const value = parseInt(e.target.value);
    const card = this.state.cards[this.state.card];

    if (value <= card.quantity) {
      this.updateCards({ allowedQuantity: value });
    } else if (!value) {
      this.updateCards({ allowedQuantity: ''});
    }
  };

  toggleCountryName = (countryName) => {
    this.setState({selectAllCities: false})
    let selectedCountries = this.state.cards[this.state.card].selectedCountries
      ? [...this.state.cards[this.state.card].selectedCountries]
      : [];
    let selectedCities = this.state.cards[this.state.card].selectedCities
      ? [...this.state.cards[this.state.card].selectedCities]
      : [];
    let locationIds = [];

    if (countryName && selectedCountries.findIndex((items) => items === countryName) === -1) {
      selectedCountries.push(countryName);
    } else {
      selectedCountries = selectedCountries.filter((items) => items !== countryName);

      let removedCities = this.state.availableCities.filter(
        (items) => items.country.toLowerCase() === countryName.toLowerCase()
      ).map((city => {
        return (city.city.toLowerCase())
      }));

      selectedCities = selectedCities.filter((city) => !removedCities.includes(city.toLowerCase()));
    }

    selectedCities.map((item) => {
      const city = this.state.availableCities.find((c) => c.city.toLowerCase() === item.toLowerCase());
      if (city) {
        locationIds.push(city.id)
      }
      return null; 
    });

    this.setCities(selectedCountries);
    this.updateCards({ selectedCountries, selectedCities, locationIds});
  };

  setCities = (countries) => {
    let selectedCity = this.state.availableCities.filter(
      (items) => countries && countries.indexOf(items.country.toLocaleLowerCase()) > -1
    );
    this.setState({ selectableCities: selectedCity });
  };

  handleOnFocusCitySearchInput = () => {
    if (this.state.citySearchInputValue.length) {
      this.search(this.state.citySearchInputValue);
    }
  };

  handleOnSearch = (e) => {
    const searchKeyword = e.target.value;

    this.setState({ citySearchInputValue: searchKeyword });

    this.search(searchKeyword);
  };

  search = (searchKeyword) => {
    if (searchKeyword.length === 0) {
      // let selectedCard = this.state.cards[this.state.card];
      // if (selectedCard) this.setCities(selectedCard.selectedCountries);
      this.setState({ showSearchResults: false });
      return;
    }

    this.setState({ showSearchResults: true });

    const result = [];
    this.state.selectableCities.map((city) => {
      const searchFrom = city.city.toLocaleLowerCase();

      if (searchFrom.includes(searchKeyword.toLocaleLowerCase())) {
        result.push(city);
      }
    return null});
    this.setState({ filteredCities: result });
  };

  selectAll = () => {
    this.setState({selectAllCities: !this.state.selectAllCities})
    if (!this.state.selectAllCities) {
      let selectedCities = [];
      let locationIds = [];

      this.state.availableCities.map((city) => {
        selectedCities.push(city.city.toLowerCase());
        locationIds.push(city.id);
      return null});

      let selectedCountries = this.state.cards[this.state.card].selectedCountries
      ? [...this.state.cards[this.state.card].selectedCountries]
      : [];

      this.state.availableCountries.map((countryName) => {
        selectedCountries.push(countryName)
      return null});
      this.setState({ citySearchInputValue: "" });
      this.setState({ showSearchResults: false });
      this.setCities(selectedCountries);
      this.updateCards({ selectedCities, locationIds, selectedCountries });
    };
  }

  countryWide = () => {
    this.setState({countryWide: !this.state.countryWide})
  }

  openSelect = () => {
    this.setState({selectOpen: true})
  }

  closeSelect = () => {
    this.setState({selectOpen: false})
  }

  selectChange = (event) => {
    this.setState({selectedCountry: event.target.value})
  }

  handleOnSelectCity = (id) => {
    const city = this.state.selectableCities.find((c) => c.id === id);
    if (city) {
      let selectedCard = this.state.cards[this.state.card];
      let selectedCities = selectedCard.selectedCities ? [...selectedCard.selectedCities] : [];
      selectedCities.push(city.city.toLowerCase());
      let locationIds = [];
      if (Object.keys(selectedCard).indexOf("locationIds") > -1) {
        locationIds =
          typeof selectedCard.locationIds === "object" ? selectedCard.locationIds : [selectedCard.locationIds];
        locationIds.push(id);
      } else {
        locationIds = [id];
      }
      this.setState({ citySearchInputValue: "" });
      this.setState({ showSearchResults: false });
      this.updateCards({ selectedCities, locationIds });
    }
  };

  handleOnDismissCity = (name) => {
    this.setState({ selectAllCities: false });
    let selectedCard = this.state.cards[this.state.card];
    let selectedCities = [...selectedCard.selectedCities];
    let locationIds =
      typeof selectedCard.locationIds === "object"
        ? selectedCard.locationIds.filter(
            (items) =>
              items !==
              this.state.availableCities.filter((item) => item.city.toLowerCase() === name.toLowerCase())[0].id
          )
        : [];

    selectedCities = selectedCities.filter((c) => {
      return c && c !== name;
    });

    this.updateCards({ selectedCities, locationIds });
  };

  handleOnSelectGender = (gender) => {
    this.updateCards({ selectedGender: gender });
  };

  renderTopBar = () => {
    return <GoBackButton onClick={() => this.props.history.goBack()} />;
  };

  handleOnNavigateToNext = () => {
    this.setState({ citySearchInputValue: "" });
    const lengthOfCards = this.state.cards.length;

    if (lengthOfCards - 1 > this.state.card) {
      this.setState({ card: this.state.card + 1 });
    }
  };

  handleOnNavigateToPrev = () => {
    this.setState({ citySearchInputValue: "" });

    if (this.state.card - 1 >= 0) {
      this.setState({ card: this.state.card - 1 });
    }
  };

  updateCards = (card) => {
    let cards = [...this.state.cards];
    cards[this.state.card] = { ...cards[this.state.card], ...card };
    this.setState({ cards });
  };

  switchCardSide = () => {
    // this.updateCards({ side: card.side === "front" ? "back" : "front" });
    this.setState({
      cardSide: this.state.cardSide === "front" ? "back" : "front",
    });
  };

  handleReview = () => {
    if (this.validate()) {
      this.setState({ step: "review" });
    }
  };

  validate = () => {
    const selectedCard = this.state.cards[this.state.card];

    if (!selectedCard.allowedQuantity || selectedCard.allowedQuantity < 1) {
      this.showToast("error", "Error", "Allowed quantity is not valid");
      return false;
    } else if ((!selectedCard.selectedCountries || selectedCard.selectedCountries.length < 1) && !this.state.countryWide && !this.state.isGlobal) {
      this.showToast("error", "Error", "please select at least one country ");
      return false;
    } else if (this.state.countryWide && this.state.selectedCountry === 1000 && !this.state.isGlobal) {
      this.showToast("error", "Error", "please select at least one country ");
      return false;
    } else if ((!selectedCard.selectedCities || selectedCard.selectedCities.length < 1) && !this.state.countryWide && !this.state.isGlobal) {
      this.showToast("error", "Error", "please select at least one city");
      return false;
    } else if (!selectedCard.selectedGender || selectedCard.selectedGender.length < 1) {
      this.showToast("error", "Error", "please select a gender");
      return false;
    } else if (this.state.expiresOn && moment(this.state.expiresOn).isBefore(new Date())) {
        this.showToast("error", "Error", "Company Expired");
        return false;
    }
      else {
      return true;
    }
  };

  showToast = (type, title, message) => {
    this.props.dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };


  submitCampaigns = async () => {
    this.setState({ showSubmitSpinner: true });
    const selectedCard = this.state.cards[this.state.card];

    if (this.state.countryWide && !this.state.isGlobal) {
      let countryCampaignData = {
        cardId: selectedCard.id,
        type: "country-wide",
        gender: selectedCard.selectedGender,
        name: selectedCard.title,
        quantity: selectedCard.allowedQuantity,
        expiresOn: moment(this.state.expiresOn),
        inShop: true,
        isGlobal: false,
      }
      if (!this.state.expiresOn && !this.state.editTarget) {
        delete countryCampaignData.expiresOn;
      } else if (!this.state.expiresOn && this.state.editTarget) {
        countryCampaignData.expiresOn = null
      }
      let countryCampaignResp;
      if (!this.state.editTarget) {
        countryCampaignResp = await addCountryCampaign(countryCampaignData, this.state.selectedCountry.id);
      } else {
        countryCampaignResp = await editCountryCampaign(countryCampaignData, this.state.campaignId);
      }
      if (countryCampaignResp && !this.state.editTarget) {
        this.showToast("success", "Done", "Campaign added");
      } else if (countryCampaignResp && this.state.editTarget) {
        this.showToast("success", "Done", "Campaign edited");
      }
      this.props.history.push("/dashboard");
    } else if (!this.state.isGlobal){
      let campaignData = {
        name: selectedCard.title,
        quantity: selectedCard.allowedQuantity,
        gender: selectedCard.selectedGender,
        total: selectedCard.allowedQuantity,
        inShuffle: selectedCard.inShuffle,
        inShop: selectedCard.inShop,
        locationIds: selectedCard.locationIds,
        cardId: selectedCard.id,
        expiresOn: moment(this.state.expiresOn),
        isGlobal: false,
      };
      if (!this.state.expiresOn && !this.state.editTarget) {
        delete campaignData.expiresOn;
      } else if (!this.state.expiresOn && this.state.editTarget) {
        campaignData.expiresOn = null
      }
      let campaignResp;
      if (this.state.campaignId) {
        // alert("update");
        campaignResp = await updateCampaignDetails(this.state.campaignId, campaignData);
        this.showToast("success", "Done", "Campaign updated");
        this.props.history.push("/dashboard");
      } else {
        campaignResp = await addCampaign(campaignData);
  
        if (campaignResp.data) {
          this.showToast("success", "Done", "Campaign added");
        }
  
        this.props.history.push("/dashboard");
      }
    } else {
      let campaignData = {
        name: selectedCard.title,
        quantity: selectedCard.allowedQuantity,
        gender: selectedCard.selectedGender,
        total: selectedCard.allowedQuantity,
        inShuffle: selectedCard.inShuffle,
        inShop: true,
        cardId: selectedCard.id,
        expiresOn: moment(this.state.expiresOn),
        isGlobal: this.state.isGlobal,
      };
      if (!this.state.expiresOn && !this.state.editTarget) {
        delete campaignData.expiresOn;
      } else if (!this.state.expiresOn && this.state.editTarget) {
        campaignData.expiresOn = null
      }
      let campaignResp;
      if (this.state.campaignId) {
        campaignResp = await updateCampaignDetails(this.state.campaignId, campaignData);
        this.showToast("success", "Done", "Campaign updated");
        this.props.history.push("/dashboard");
      } else {
        campaignResp = await addCampaign(campaignData);
  
        if (campaignResp.data) {
          this.showToast("success", "Done", "Campaign added");
        }
  
        this.props.history.push("/dashboard");
      }
    }
    this.setState({ showSubmitSpinner: false });
  };

  render() {
    const { cards, card, show, showLoading } = this.state;
    const selectedCard = cards[card];
    const disabled = selectedCard?.status !== "active";
    if (showLoading)
      return (
        <Base theme={"light"}>
          <LinearProgress/>
        </Base>
      );
    if (cards.length === 0)
      return (
        <Base theme={"light"} topGap>
          <div className="no-offer-container">
            <Card>
              <div className="no-offer-card" style={{ padding: "20% 10%", textAlign: "center" }}>
                <h3 style={{ marginBottom: "40px" }}>Sorry, looks like you have not created an offer yet.</h3>
                <img src={blankCardImage} alt=""/>
                <Button
                  style={{ marginTop: "40px", fontWeight: "bold", textTransform: "inherit" }}
                  onClick={() => this.props.history.push("/gift-card")}>
                  Create a new offer
                </Button>
              </div>
            </Card>
          </div>
        </Base>
      );

    if (this.state.paramsDecided !== null && selectedCard && show) {
      return (
        <Base theme={"light"} topBar={this.state.step === "create" ? this.renderTopBar : null}>
          {this.state.step === "create" && (
            <>
              <div className={"middle-content-area transparent create-new-campaign"}>
                {this.state.editTarget ? <p>Edit your targeting</p> : <p>Create a new campaign</p>}

                {this.renderCard()}
                <div className={"text-center"}>
                  <button className={"transparent-btn view-details-btn"} onClick={this.switchCardSide}>
                    Tap to view details
                  </button>
                </div>

                {!this.state.editTarget ? 
                <Navigator
                onNavigateToNext={this.handleOnNavigateToNext}
                onNavigateToPrev={this.handleOnNavigateToPrev}
              />: null }

                <Card>
                  <div className={"create-campaign-quantity-section"} style={{marginTop: "30px"}}>
                    <div className={"available-section"}>
                      <h3>Quantity to <br/>distribute: Max ({selectedCard.quantity})</h3>
                    </div>
                    <div className={"set-quantity-section"} style={{display: "flex", flex: "none"}}>
                      <IconButton onClick={this.decrementQuantity} disabled={disabled || (selectedCard.allowedQuantity === 1)} style={(selectedCard.allowedQuantity > 1) ? {color: "#000"} : null}>
                        <FontAwesomeIcon icon={faMinus} />
                      </IconButton>
                      <input
                        disabled={disabled}
                        type={"number pl-3"}
                        className={"transparent-input"}
                        value={selectedCard.allowedQuantity}
                        onChange={this.handleOnChangeQuantity}
                        style={{textAlign: "center", maxWidth: "40px"}}
                      />
                      <IconButton onClick={this.incrementQuantity} disabled={disabled || (selectedCard.allowedQuantity === selectedCard.quantity)} style={(selectedCard.allowedQuantity < selectedCard.quantity) ? {color: "#000"} : null}>
                        <FontAwesomeIcon icon={faPlus} />
                      </IconButton>
                    </div>
                      
                      
                  </div>
                </Card>

                <h2>Set your location targeting</h2>
                {this.state.expiresOn && <ExpiryDateInput value={this.state.expiresOn} onChange={this.setExpiredDate}/>}
                <VerifyCheckbox
                  onChange={() => this.expireHandler()}
                  label={"Without expiry date"}
                  checked={!Boolean(this.state.expiresOn)}
                />
                <Card>
                  <div className="toggler-wrapper">
                    <Switch
                      title="isGlobal?"
                      checked={this.state.isGlobal}
                      handleChange={() => this.setState({ isGlobal: !this.state.isGlobal })}
                      fontSize={"18px"}
                    />
                  </div>
                </Card>
                {!this.state.isGlobal &&
                  <Card>
                    <div className={"select-available-country"}>
                    <div style={{display: "flex", justifyContent: "space-between", marginBottom: "20px"}}>
                      <h3 style={{margin: "auto 0"}}>Select country to target</h3>
                        <div className={"verify-checkbox"} style={{margin: "0", display: this.state.editTarget ? "none" :  "flex"}}>
                          <div className={"verify-checkbox-container"} style={{width: "initial"}}>
                            <Checkbox
                              checked={this.state.countryWide}
                              onChange={this.countryWide}
                              color="default"
                            />
                          </div>
                          <h3 style={{margin: "auto 0"}}>Country Wide</h3>
                        </div>
                      </div>
                      <FormControl style={{display: this.state.countryWide ? "inline-flex" : "none"}}>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          open={this.state.selectOpen}
                          onClose={this.closeSelect}
                          onOpen={this.openSelect}
                          value={this.state.selectedCountry}
                          onChange={this.selectChange}
                          className="country-selector"
                        > 
                        <MenuItem value={1000} disabled>Choose country</MenuItem>
                          {this.state.countries.map((countryName, i) => {
                          return (
                          <MenuItem value={countryName} key={i} style={{textTransform: "capitalize"}}>{countryName.name}</MenuItem>
                          )
                        })}
                        </Select>
                      </FormControl>
                      <div style={{display: this.state.countryWide ? "none" : "block"}}>
                        {this.state.availableCountries.map((countryName, i) => (
                          <CountryBadge
                            name={countryName}
                            key={i}
                            selected={selectedCard?.selectedCountries?.includes(countryName)}
                            onClick={() => !disabled && this.toggleCountryName(countryName)}
                          />
                        ))}
                      </div>
                    </div>
                  </Card>
                }
                {!this.state.isGlobal &&
                  <Card>
                    <div className={"select-available-city"} style={{display: this.state.countryWide ? "none" : "block"}}>
                      <div style={{display: "flex"}}>
                        <h3>Select from available cities.</h3>
                        <div className={"verify-checkbox"} style={{margin: "0"}}>
                          <div className={"verify-checkbox-container"} style={{width: "initial"}}>
                            <Checkbox
                              checked={this.state.selectAllCities}
                              onChange={this.selectAll}
                              color="default"
                            />
                          </div>
                          <h3>Select all</h3>
                        </div>
                      </div>
                      {/* {this.state.selectableCities.map(({ city, id }) => (
                        <CountryBadge
                          name={city}
                          selected={selectedCard?.selectedCities?.includes(city.toLowerCase())}
                          onClick={() => !disabled && this.handleOnSelectCity(id)}
                        />
                      ))}
                      {console.log("citySearchInputValue :>> ", this.state.citySearchResults)} */}
                      <input
                        className={"search-city-input"}
                        type={"text"}
                        value={this.state.citySearchInputValue}
                        placeholder={"Search by city name"}
                        onChange={this.handleOnSearch}
                        onFocus={this.handleOnFocusCitySearchInput}
                      />
                      <div style={{ position: "relative" }}>
                        {this.state.showSearchResults && (
                          <CitiesSearchList results={this.state.filteredCities} onSelect={this.handleOnSelectCity} />
                        )}
                      </div>

                      <div className={"selected-cities-container"}>
                        {selectedCard?.selectedCities?.map((city, i) => {
                          return <CityItem name={city} key={i} onDismiss={this.handleOnDismissCity} />;
                        })}
                      </div>
                    </div>
                  </Card>
                }
                <Card>
                  <div className={"select-gender"}>
                    <h3>Select from available genders </h3>
                    <GenderRadioInput
                      disabled={disabled}
                      genders={this.state.genders}
                      selectedGender={selectedCard.selectedGender}
                      onselect={this.handleOnSelectGender}
                    />
                  </div>
                </Card>

                <div className={"bottom-button"}>
                  <GradiantButton disabled={disabled} onClick={() => this.handleReview()}>
                    Complete
                  </GradiantButton>
                </div>
              </div>
            </>
          )}
          {this.state.step === "review" && (
            <div className={"middle-content-area"}>
              <div className={"middle-content-area white preview-campaign"}>
                <p>Preview and Confirm</p>

                {this.renderCard()}

                <Card>
                  <div className={"preview-quantity-section"}>
                    <h3 className={"preview-quantity-text"}>
                      {"Quantity: "}
                      {selectedCard.allowedQuantity}
                    </h3>
                    <h3 className={"preview-quantity-text"} style={{ textTransform: "capitalize", display: this.state.countryWide ? "block" : "none"}}>
                      {"Country: "}
                      {this.state.selectedCountry.name}
                    </h3>
                    <h3 className={"preview-location-text"} style={{ textTransform: "capitalize", display: this.state.countryWide ? "none" : "block"}}>
                      {"Locations: "}
                      {selectedCard?.selectedCities?.length && !this.state.isGlobal?
                        selectedCard?.selectedCities
                          ?.map((items) => {
                            let selectedCity = this.state.availableCities.filter(
                              (item) => item.city.toLowerCase() === items.toLowerCase()
                            );
                            return `${selectedCity[0].city} -${selectedCity[0].country}`;
                          })
                          .join(","):
                          "Global"}
                      {/* {selectedCard?.selectedCities?.length
                        ? `${selectedCard.selectedCities[0]}, ${selectedCard.selectedCountries[0]}.`
                        : ""} */}
                    </h3>
                    <h3 className={"preview-gender-text"}>
                      {"Gender: "}
                      {selectedCard.selectedGender}
                    </h3>

                    <div className={"complete-button-container"}>
                      <GradiantButton onClick={() => this.submitCampaigns()}>
                        {this.state.showSubmitSpinner ? <CircularProgress size={25} /> : "Complete"}
                      </GradiantButton>
                    </div>
                  </div>
                </Card>
              </div>
              <div className={"go-back-button-container"}>
                <GoBackButton onClick={() => this.setState({ step: "create" })} />
              </div>
            </div>
          )}
        </Base>
      );
    } else {
      return (
        <Base theme={"light"}>
          <LinearProgress />
        </Base>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getUserCards: () => dispatch(getUserCards()),
    dispatch: dispatch,
  };
}

function mapStateToProps(state) {
  return {
    standardUserCards: state.dropCards.standardUserCards,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StandardGift);
