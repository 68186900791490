import * as types from './actions-types';
import { httpGetCall } from "../../common/commonService";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const usersDataSucces = (payload) => ({
  payload,
  type: types.SET_USERS,
});

export const getAllUsers = () => async dispatch => {
  try {
    let filter = {
      where: {
        role: "company",
      },
      fields: {
        id: true,
        firstname: true,
        lastname: true,
        company: true
      },
    };
    const {data} = await httpGetCall(`${REACT_APP_BASE_URL}/users/?filter=${JSON.stringify(filter)}`);
    dispatch(usersDataSucces(data));
  } catch (err) {
    return err;
  }
};

const userDataSucces = (payload) => ({
  payload,
  type: types.SET_USERS,
});

export const getUserData = (id) => async dispatch => {
  try {
    let filter = {
      where: {
        role: "company",
      },
      fields: {
        id: true,
        firstname: true,
        lastname: true,
        stripeCustomer: true,
      },
    };
    const {data} = await httpGetCall(`${REACT_APP_BASE_URL}/users/${id}/?filter=${JSON.stringify(filter)}`);
    dispatch(userDataSucces(data));
  } catch (err) {
    return err;
  }
};

const setCurrentUser = (payload) => ({
  payload,
  type: types.SET_CURRENT_USER,
});

export const selectUser = (user) => dispatch => {
  dispatch(setCurrentUser(user));
};