import { ADD_CAMPAIGNS_CARDS,
         INITIAL_STATE_LOADING, 
         SET_CURRENT_LOCATION, 
         LOCATIONS_FOR_WHEEL_DATA_SUCCES, 
         SET_OFFER_TO_SLOT, 
         CLEAR_OFFER_FROM_SLOT, 
         CARDS_FOR_WHEEL_DATA_SUCCES, 
         CHANGE_SLOT_STATUS, 
         SEND_CARDS_TO_WHEEL,
         SEND_WHEEL_DATA_SUCCES,
         INCREASE_CARDS_QUANTITY,
         RESET_CAMPAIGN_STATE,
         DECREASE_CARDS_QUANTITY,
         GET_WHEEL_DATA_SUCCES,
         GET_WHEEL_DATA_SUCCES_QR,
         SAVE_LOGO_TO_STATE,
         SET_EDIT_WHEEL,
         SET_QUANTITY_TO_SLOT,
         DELETE_LOCATION,
         SET_PAYMENT_ID,
         GET_TARGETED_CAMPAIGN, 
         CHANGE_CAMPAIGN_STATUS,
         ADD_NEW_LOCATION,
         MAIN_STATISTICS_SUCCESS,
         MAIN_STATISTICS_CLEAR,
         QR_STATISTICS_CLEAR,
         QR_STATISTICS_SUCCESS,
         WHEEL_STATISTICS_CLEAR,
         WHEEL_STATISTICS_SUCCESS,
         TARGETED_STATISTICS_SUCCESS,
         TARGETED_STATISTICS_CLEAR,
         SEND_COLORS_TO_WHEEL,
         SET_QRWHEEL_ID,
         SET_CHANCE_TO_SLOT,
         SET_CAMPAIGN_NAME,
         SET_EDIT_COLORS,
         GET_CHESTS_DATA_SUCCESS,
         GET_CHEST_DATA_SUCCESS,
         CHANGE_EXPIRY_COMPANY,
         } from '../actions/actions-types'
const initialState = {
    cardsForWheel: [],
    slots: [
        {status: false},
        {status: false},
        {status: false},
        {status: false},
        {status: false},
        {status: false},
        {status: false},
    ],
    coins: [],
    location: {},
    locations: [],
    chests: [],
    chest: {},
    wheelData: {},
    logo: {},
    logoUrl: '',
    paymentId: '',
    isLoading: false,
    targetedData: {},
    stats: null,
    names: '',
    expiredOn: '',
}

export default function campaignsReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_CAMPAIGNS_CARDS:
            return {
                ...state,
                userCards: action.userCards,
            };
        case INITIAL_STATE_LOADING: 
            return {
                ...state,
                isLoading: true
            };
        case SET_CURRENT_LOCATION: 
            return {
                ...state,
                location: action.payload,
                success: true,
            };
        case ADD_NEW_LOCATION: 
            return {
                ...state,
                locations: [...state.locations, action.payload],
            };
        case LOCATIONS_FOR_WHEEL_DATA_SUCCES: 
            return {
                ...state,
                locations: action.payload,
                isLoading: false,
            };
        case SET_OFFER_TO_SLOT: 
            return {
                ...state,
                slots: state.slots.map(
                    (content, i) => i === action.slot ? {...content, ...action.offer, allowedQuantity: action.offer.quantity}
                    : content
                ),
                isLoading: false
            };
        case CLEAR_OFFER_FROM_SLOT: 
            return {
                ...state,
                slots: state.slots.map(
                    (content, i) => i === action.slot ? {status: false}
                    : content
                ),
                isLoading: false
            };
        case INCREASE_CARDS_QUANTITY:
            return {
                ...state,
                slots: state.slots.map(
                    (content, i) => i === action.slot ? {...content, quantity: state.slots[i].quantity + 1}
                    : content
                ),
                isLoading: false
            }    
        case DECREASE_CARDS_QUANTITY:
            return {
                ...state,
                slots: state.slots.map(
                    (content, i) => i === action.slot ? {...content, quantity: state.slots[i].quantity - 1}
                    : content
                ),
                isLoading: false
            }   
        case SET_QUANTITY_TO_SLOT:
            return {
                ...state,
                slots: state.slots.map(
                    (content, i) => i === action.slot ? {...content, quantity: action.number}
                    : content
                ),
                isLoading: false
            }    
        case SET_CHANCE_TO_SLOT:
            return {
                ...state,
                slots: state.slots.map(
                    (content, i) => i === action.slot ? {...content, winChance: action.number}
                    : content
                ),
                isLoading: false
            }    
        case CARDS_FOR_WHEEL_DATA_SUCCES:
            return {
                ...state,
                cardsForWheel: action.payload,
                isLoading: false
            };
        case CHANGE_SLOT_STATUS:
            return {
                ...state,
                slots: state.slots.map(
                    (content, i) => i === action.slot ? {...content, status: action.status}
                    : content
                ),
                isLoading: false
            };
        case SEND_CARDS_TO_WHEEL:
            return {
                ...state,
                coins: action.payload,
                isLoading: false
            };
        case SEND_COLORS_TO_WHEEL:
            return {
                ...state,
                colors: action.payload,
            };
        case SEND_WHEEL_DATA_SUCCES:
            return {
                ...state,
                isLoading: false,
                successWheel: true,
            };
        case GET_WHEEL_DATA_SUCCES:
            return {
                ...state,
                wheelData: action.payload,
                slots: action.payload.wheel.map(
                    (content, i) => content ? {...content, status: action.status}
                    : {status: false}
                ),
                expiredOn: action.payload.expiresOn,
                isLoading: false
            };
        case GET_WHEEL_DATA_SUCCES_QR:
            action.payload.items.splice(5,1);
            return {
                ...state,
                wheelData: {...action.payload, wheelId: action.payload.id},
                slots: action.payload.items,
                colors: action.payload.colors,
                isLoading: false
            };
        case SET_EDIT_WHEEL: {
            return {
                ...state,
                edit: true,
            }
        }
        case SAVE_LOGO_TO_STATE:
            return {
                ...state,
                logo: action.logo,
                logoUrl: action.url,
            }; 
        case DELETE_LOCATION:
            return {
                ...state,
                locations: state.locations.filter(location => location.id !== action.payload),
            }; 
        case SET_PAYMENT_ID:
            return {
                ...state,
                paymentId: action.payload,
            }; 
        case GET_TARGETED_CAMPAIGN:
            return {
                ...state,
                targetedData: action.payload,
                isLoading: false,
            }; 
        case CHANGE_CAMPAIGN_STATUS:
            return {
                ...state,
                targetedData: {...state.targetedData, status: action.payload},
                isLoading: false,
            }; 
        case MAIN_STATISTICS_SUCCESS:
            return {
                ...state,
                stats: action.payload,
            };
        case MAIN_STATISTICS_CLEAR:
            return {
                ...state,
                stats: null,
            };
        case TARGETED_STATISTICS_SUCCESS:
            return {
                ...state,
                targetedData: {...state.targetedData, campaignStatistics : action.payload.campaignStatistics},
            };
        case TARGETED_STATISTICS_CLEAR:
            return {
                ...state,
                targetedData: {...state.targetedData, campaignStatistics : null},
            };
        case QR_STATISTICS_SUCCESS:
            return {
                ...state,
                campaignStatistics: action.payload,
                wheelData: {...state.wheelData, statistics : {...state.statistics, cardsStats: action.payload.cardsStatistics}}
            };
        case QR_STATISTICS_CLEAR:
            return {
                ...state,
                wheelData: {...state.wheelData, statistics : null},
                campaignStatistics: null,
            };
        case WHEEL_STATISTICS_SUCCESS:
            return {
                ...state,
                campaignStatistics: action.payload,
                wheelData: {...state.wheelData, statistics : {...state.statistics, cardsStats: action.payload.cardsStatistics}}
            };
        case WHEEL_STATISTICS_CLEAR:
            return {
                ...state,
                wheelData: {...state.wheelData, statistics : null},
                campaignStatistics: null,
            };
        case SET_QRWHEEL_ID:
            return {
                ...state,
                QRwheelID: action.payload,
            };
        case SET_CAMPAIGN_NAME:
            return {
                ...state,
                names: action.payload,
            };
        case SET_EDIT_COLORS:
            return {
                ...state,
                editColors: true,
            };
        case GET_CHESTS_DATA_SUCCESS:
            return {
                ...state,
                chests: action.payload,
                isLoading: false,
            };
        case GET_CHEST_DATA_SUCCESS:
            return {
                ...state,
                chest: action.payload,
                isLoading: false,
            };
        case CHANGE_EXPIRY_COMPANY:
            return {
                ...state,
                expiredOn: action.payload,
            }
        case RESET_CAMPAIGN_STATE:
            return state = initialState;
            default:
                return state
    }
}