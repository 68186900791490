import { TOAST } from "../actions/actions-types";

const initialState = {
  toastData: {},
};
export default function toastReducer(state = initialState, action) {
  switch (action.type) {
    case TOAST:
      return {
        ...state,
        toastData: action.load,
      };
      default:
        return state
  }
}
