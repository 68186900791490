import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import {
  makeStyles,
  ListItem,
  Drawer,
  List,
  ListItemText,
  Select,
  FormControl,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { getAllUsers } from "../../../redux/actions/adminActions";
import { setWheelType, clearState } from "../../../redux/actions/campaignsActions";
import { 
  home,
  campaign,
  offer,
  offers,
  settings,
  layers,
  pin,
  email
} from "../../../assets/icons"

const drawerWidth = 325;

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  listIcon: {
    color: "#fff",
  },
  search: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    backgroundColor: "#1c1524",
    color: "#fff",
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(0),
      left: theme.spacing(-32),
    },
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  invisible: {
    display: "none",
  },
  visible: {
    display: "flex",
  },
}));

function UserSelect({ users }) {
  const matches = useMediaQuery(`(max-width:500px)`);
  // const [user, setUser] = React.useState(1000);
  const user = 1000;
  const [open, setOpen] = React.useState(false);
  const handleChange = (event) => {
    // setUser(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const useStyles = makeStyles((theme) => ({
    root: {},
    select: {
      width: "325px",
      background: "#F0F0F0",
      boxSizing: "border-box",
      fontSize: "17px",
      height: "40px",
      color: "#000 !important",
      textAlign: "left",
      "&::before": {
        content: "''",
        display: "none",
      }
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <FormControl>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={user}
          onChange={handleChange}
          className={classes.select}
          style={matches? {fontSize: "20px", width: "auto"} : null}
        > 
        <MenuItem value={1000} disabled>Choose User</MenuItem>
          {users ? users.map((user, i) => {
          return (
          <MenuItem value={i} key={i}>{`${user.firstname} ${user.lastname} : ${user.company}`}</MenuItem>
          )
        }) : <MenuItem value={10000}>Loading users</MenuItem>}
        </Select>
      </FormControl>
    </div>
  );
}

function WheelSelect({wheel}) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const [wheelType, setType] = React.useState(wheel);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const data = localStorage.getItem("wheelType");
    if (data) {
      if (wheelType === 'none') {
        setType(JSON.parse(data));
        dispatch(setWheelType(JSON.parse(data)));
      }
    }
  }, [dispatch, wheelType]);

  useEffect(() => {
    localStorage.setItem("wheelType", JSON.stringify(wheelType));
  });

  const handleChange = (event) => {
    dispatch(setWheelType(event.target.value));
    setType(event.target.value);
    if (location.pathname !== "/dashboard") {
      dispatch(clearState());
      history.push("/dashboard");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const useStyles = makeStyles((theme) => ({
    root: {},
    select: {
      width: "325px",
      background: "#F0F0F0",
      boxSizing: "border-box",
      fontSize: "17px",
      height: "40px",
      color: "#000 !important",
      textAlign: "left",
      "&::before": {
        content: "''",
        display: "none",
      }
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <FormControl>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={wheelType}
          onChange={handleChange}
          className={classes.select}
          style={matches? {fontSize: "20px", width: "auto"} : null}
        >
        <MenuItem value={'none'} disabled>Choose Wheel Type</MenuItem>
        <MenuItem value={'standard' || ''}>Standard Dashboard</MenuItem>
        <MenuItem value={'QR'}>QR Dashboard</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

function Sidebar(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const role = useSelector((state) => state.login.userData.role);
  const users = useSelector((state) => state.admin.users);
  const wheel = useSelector((state) => state.login.wheelType)

  useEffect(() => {
    if (!users.length && role === 'admin') {
      dispatch(getAllUsers());
    }
  }, [users, role, dispatch]);

  let url;
  if (wheel === "QR") {
    url = "/campaign/URL";
  } else {
    url = "/campaign/new";
  }

  return (
    <React.Fragment>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx([
            classes.drawerPaper,
            "sidebar-menu-con",
            props.showMenu && "show",
          ]),
        }}
      > 
        {role === "admin" && <UserSelect users={users} />}
        <WheelSelect wheel={wheel}/>
        <List className="drawer-menu-list">
          <ListItem button>
            <NavLink to="/dashboard" activeClassName="active">
              <img src={home} alt="" className="icon"/>
              <ListItemText primary="Dashboard" />
            </NavLink>
          </ListItem>
          <ListItem button>
            <NavLink to="/gift-card" activeClassName="active">
              <img src={offer} alt="" className="icon"/>
              <ListItemText primary="Create an offer" />
            </NavLink>
          </ListItem>
          <ListItem button>
            <NavLink to={url} activeClassName="active">
              <img src={campaign} alt="" className="icon"/>
              <ListItemText primary="Create a campaign" />
            </NavLink>
          </ListItem>
          {/* <ListItem button>
            <NavLink to="/campaigns" activeClassName="active">
              <img src={campaigns} alt="" className="icon"/>
              <ListItemText primary="Existing Campaigns" />
            </NavLink>
          </ListItem> */}
          {/* <ListItem button>
            <NavLink to="/approvals" activeClassName="active">
              <img src={offers} alt="" className="icon"/>
              <ListItemText primary="My offers" />
            </NavLink>
          </ListItem> */}
          <ListItem button>
            <NavLink to="/offers" activeClassName="active" isActive={() => ['/offers', '/offer'].includes(pathname)}>
              <img src={offers} alt="" className="icon"/>
              <ListItemText primary="My offers" />
            </NavLink>
          </ListItem>
          {wheel === "QR" && <ListItem button>
            <NavLink to="/analytics/?type=ALL" activeClassName="active">
              <img src={email} width="35px"  height="35px" alt="" className="icon"/>
              <ListItemText primary="Collected Leads" />
            </NavLink>
          </ListItem>}
          {role === "admin" && (
            <>
              <ListItem button>
                <NavLink to="/locations" activeClassName="active">
                  <img src={layers} width="35px"  height="35px" alt="" className="icon"/>
                  <ListItemText primary="Locations" />
                </NavLink>
              </ListItem>
              <ListItem button>
                <NavLink to="/tiers" activeClassName="active">
                  <img src={pin} width="35px" height="35px"  alt="" className="icon"/>
                  <ListItemText primary="Tiers" />
                </NavLink>
              </ListItem>
              <ListItem button>
                <NavLink to="/approvals" activeClassName="active">
                  <img src={offers} width="35px" height="35px"  alt="" className="icon"/>
                  <ListItemText primary="Approvals" />
                </NavLink>
              </ListItem>
            </>
          )}
          <ListItem button>
            <NavLink to="/profile-settings" activeClassName="active">
            <img src={settings} alt="" className="icon"/>
              <ListItemText primary="Profile settings" />
            </NavLink>
          </ListItem>
          {/* <ListItem button>
            <NavLink to="/invoices" activeClassName="active">
              <ListItemText primary="Invoices" />
            </NavLink>
          </ListItem>
          {role === "admin" && (
            <>
              <ListItem button>
                <NavLink to="/locations" activeClassName="active">
                  <ListItemText primary="Locations" />
                </NavLink>
              </ListItem>
              <ListItem button>
                <NavLink to="/tiers" activeClassName="active">
                  <ListItemText primary="Tiers" />
                </NavLink>
              </ListItem>
            </>
          )}
          <ListItem button>
            <NavLink to="/profile-settings" activeClassName="active">
              <ListItemText primary="Profile settings" />
            </NavLink>
          </ListItem> */}
  
          {/* <ListItem button>
            <NavLink to="/logout" activeClassName="active">
              <ListItemText primary="Logout" />
            </NavLink>
          </ListItem> */}
        </List>
      </Drawer>
    </React.Fragment>
  );
}

export default Sidebar;
