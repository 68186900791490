import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import validator from "validator";
import Base from "./../../component/Layout/Base";
import { CircularProgress } from "@material-ui/core";
import { fetchLocations, addLocation, updateLocation } from "../../redux/requests/locationRequests";
import { TOAST } from "../../redux/actions/actions-types";
import style from "./location.module.css";

const LocationFrom = () => {
  const [location, setLocation] = useState({
    city: "",
    country: "",
  });
  const [loader, setLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      setShowLoader(true);
      fetchLocations(id)
        .then((res) => {
          setLocation(res.data);
          setShowLoader(false);
        })
        .catch((err) => console.log(err));
    } else {
      setShowLoader(false);
    }
  }, [id]);

  const validation = () => {
    if (!validator.isLength(location.city, { min: 3 })) {
      showError("City must contain atleas three characters");
      return false;
    } else if (!validator.isLength(location.country, { min: 3 })) {
      showError("Country must contain atleas three characters");
      return false;
    } else if (!location.viewsPerRoll) {
      showError("Enter views per roll");
      return false;
    } else if (!validator.isInt(String(location.viewsPerRoll)) || location.viewsPerRoll < 0) {
      showError("Invalid views per roll");
      return false;
    } else if (!location.maxPrizePerBox) {
      showError("Enter views per roll");
      return false;
    } else if (!validator.isInt(String(location.maxPrizePerBox)) || location.maxPrizePerBox < 0) {
      showError("Invalid views per roll");
      return false;
    }
    return true;
  };
  const submitForm = async (e) => {
    e.preventDefault();
    setLoader(true);
    let isValid = validation();
    let doRedirect = false;
    location.viewsPerRoll = Number(location.viewsPerRoll);
    location.maxPrizePerBox = Number(location.maxPrizePerBox);
    if (isValid) {
      if (id) {
        delete location.id;
        await updateLocation(id, location);
        doRedirect = true;
      } else {
        delete location.id;
        let isLocation = await addLocation(location);
        if (isLocation.status === 200) {
          doRedirect = true;
        }
      }
    }
    if (doRedirect) {
      dispatch({
        type: TOAST,
        load: {
          type: "success",
          title: "Done",
          message: id ? "Location Updated" : "Location Added",
          show: true,
        },
      });

      history.goBack();
      // history.push(`/user/list/${type}`);
    }
    setLoader(false);
  };

  const showError = (message) => {
    dispatch({
      type: TOAST,
      load: {
        type: "error",
        title: "Error",
        message: message,
        show: true,
      },
    });
  };

  return (
    <Base theme="light">
      {showLoader ? (
        <h1 style={{ marginLeft: "20px" }}>Loading...</h1>
      ) : (
        <div className={"middle-content-area white-card"}>
          <h2 className={style.cardHeader}>Location/{id ? "Edit" : "Add"}</h2>
          <div class="select-available-city" style={{ minHeight: "50px" }}>
            <h3>City.</h3>
            <input
              class="search-city-input"
              type="text"
              placeholder="Enter city name"
              value={location.city}
              onChange={(e) => {
                e.persist();
                setLocation((prevState) => ({
                  ...prevState,
                  city: e.target.value,
                }));
              }}
            />
          </div>
          <div class="select-available-city" style={{ minHeight: "50px" }}>
            <h3>Country</h3>
            <input
              class="search-city-input"
              type="text"
              placeholder="Enter country name"
              value={location.country}
              onChange={(e) => {
                e.persist();
                setLocation((prevState) => ({
                  ...prevState,
                  country: e.target.value,
                }));
              }}
            />
          </div>
          <div class="select-available-city" style={{ minHeight: "50px" }}>
            <h3>Views per roll</h3>
            <input
              class="search-city-input"
              type="number"
              placeholder="Enter views per roll"
              value={location.viewsPerRoll}
              onChange={(e) => {
                e.persist();
                setLocation((prevState) => ({
                  ...prevState,
                  viewsPerRoll: e.target.value,
                }));
              }}
            />
          </div>
          <div class="select-available-city" style={{ minHeight: "50px" }}>
            <h3>Max prize per box</h3>
            <input
              class="search-city-input"
              type="number"
              placeholder="Enter max prize per box "
              value={location.maxPrizePerBox}
              onChange={(e) => {
                e.persist();
                setLocation((prevState) => ({
                  ...prevState,
                  maxPrizePerBox: e.target.value,
                }));
              }}
            />
          </div>
          <div className="bottom-button">
            <button onClick={submitForm} class="gradiant-button" disabled={loader}>
              {loader ? (
                <CircularProgress style={{ margin: 0, padding: 0, height: "20px", width: "20px" }} />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      )}
    </Base>
  );
};

export default LocationFrom;
