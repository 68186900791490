import React from "react";
import { CircularProgress } from "@material-ui/core";

const FooterButton = ({ title, onClick, showLoader }) => {
  return (
    <>
      {showLoader ? (
        <button className={"gift-card-footer-button"}>
          <CircularProgress />
        </button>
      ) : (
        <button disabled={showLoader} className={"gift-card-footer-button"} onClick={onClick}>
          {title}
        </button>
      )}
    </>
  );
};

export default FooterButton;
