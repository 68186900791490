import React from "react";
import Card from "../../../component/Layout/Card";
import ArrowForwardIcon from "../../../assets/images/ArrowForwardIcon.svg";

const PricingButton = ({ onClick, creditRemaining }) => {
  return (
    <Card color={"#d72d83"}>
      <div className={"pricing-button-con"} onClick={onClick}>
        <p>
          Plan:{"   "}Free trial {creditRemaining} offers remaining{" "}
        </p>
        <img src={ArrowForwardIcon} alt="Arrow Forward Icon" />
      </div>
    </Card>
  );
};

export default PricingButton;
