import React from "react";

const PersonalInfoInput = ({
  type,
  label,
  value,
  value1,
  value2,
  editing,
  onChangeValue,
  buttonText,
  onSave,
  noButton,
  placeholder,
}) => {
  const handleOnClickBtn = () => {
    onSave();
  };

  const renderValues = () => {
    if (value) {
      return (
        <p
          className={`label ${
            editing ? "display-none" : "display-inline-block"
          }`}
        >{` ${type !== "password" ? value : "•".repeat(value.length)}`}</p>
      );
    } else {
      return (
        <div
          className={`dual-value ${
            editing ? "display-none" : "display-inline-block"
          }`}
        >
          <p className={"label value1"}>{value1}</p>
          <p className={"value2"}>{value2}</p>
        </div>
      );
    }
  };

  return (
    <div className={"custom-personal-input"}>
      <div className={"left"}>
        <p>{label}:</p>
        <input
          className={`transparent-input ${
            editing ? "display-inline-block" : "display-none"
          }`}
          value={value}
          onChange={onChangeValue}
          type={type ? type : "text"}
        />
        {renderValues()}
      </div>
      {noButton !== true && (
        <div className={"right"}>
          <button
            type={"button"}
            className={"transparent-button"}
            onClick={handleOnClickBtn}
          >
            {editing ? "Save for user" : buttonText}
          </button>
        </div>
      )}
    </div>
  );
};

export default PersonalInfoInput;
