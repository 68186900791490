import React from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  
  homeCard: {
    width: "235px",
    height: "190px",
    backgroundColor: "#fff",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    cursor: "pointer",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.5)",
    '&:hover': {
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgb(0 0 0)",
    }
  },
  homeCardM: {
    width: "165px",
    height: "160px",
    backgroundColor: "#fff",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    cursor: "pointer",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.5)",
    '&:hover': {
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgb(0 0 0)",
    }
  },
  cardImage: {
    maxWidth: "125px",
    width: "100%",
  },
  footer: {
    position: "absolute",
    height: "50px",
    width: "100%",
    bottom: 0,
  },
  optionLabel: {
    fontWeight: "bold",
    maxWidth: "150px",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const HomeOption = ({ onClick, label, optionImage, imageStyle, textStyle }) => {
  const classes = useStyles();
  const matches = useMediaQuery(`(max-width:500px)`);
  return (
    <div className={matches? classes.homeCardM : classes.homeCard} onClick={onClick}>
      <img src={optionImage} className={classes.cardImage} alt="" style={imageStyle} />
      <div className={classes.footer} style={label === "Create a new campaign" && matches ? {bottom: "10px"} : null}>
        <p className={classes.optionLabel} style={textStyle}>{label}</p>
      </div>
    </div>
  );
};

export default HomeOption;
