import React, { useState } from "react";
import { makeStyles, Avatar, Button, TextField, Grid, Typography, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import Auth from "../../component/Layout/Auth";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { addNewPassword } from "../../redux/actions/authActions";
import { resetPass } from "../../redux/requests/authRequests";
import { connect, useDispatch } from "react-redux";
import validator from "validator";
import { TOAST } from "../../redux/actions/actions-types";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#fff",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#d72d83",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#3f51b5 !important",
  },
  link: {
    margin: theme.spacing(0),
    color: "#d72d83",
  },
}));

export const PasswordRecovery = (props) => {
  const { id } = props;
  const classes = useStyles();
  const [showSpinner, setShowSpinner] = useState(false);
  const history = useHistory();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setComfirmPassword] = useState("");
  const [key, setKey] = useState();
  const dispatch = useDispatch();

  const validate = () => {
    if (!key || !validator.isLength(key, { min: 6, max: 6 })) {
      showToast("error", "Error", "Invalid verification key");
      return false;
    } else if (!validator.isLength(newPassword, { min: 6 })) {
      showToast("error", "Error", "Invalid password");
      return false;
    } else if (!validator.isLength(confirmPassword, { min: 6 })) {
      showToast("error", "Error", "Passwords doesn't match");
      return false;
    } else if (newPassword !== confirmPassword) {
      showToast("error", "Error", "Passwords doesn't match");
      return false;
    } else {
      return true;
    }
  };
  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };
  const addNewPassword = async (id, confirmPassword) => {
    setShowSpinner(true);
    let isValid = validate();
    if (isValid) {
      let resetPasswordResp = await resetPass({
        password: confirmPassword,
        email: localStorage.getItem("recovery-email"),
        key,
      });
      if (resetPasswordResp.data) {
        showToast("success", "Done", "Password changed");

        history.push("/");
      }
    }
    setShowSpinner(false);
  };

  // const passwordChange = (value) => {
  //   setNewPassword(value);
  //   passwordValidate(value);
  // };

  return (
    <Auth>
      <Avatar className={classes.avatar}>
        <VpnKeyIcon />
      </Avatar>
      <Typography component="h1" className={classes.title} variant="h5">
        Generate New Password
      </Typography>
      <form name="form-layout" className={classes.form} onSubmit={(e) => e.preventDefault()} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          name="key"
          label="Verification key"
          type="text"
          id="verification-ke"
          onChange={(event) => setKey(event.target.value)}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="newPassword"
          label="New password"
          type="password"
          id="new-password"
          onChange={(event) => setNewPassword(event.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Confirm password"
          type="password"
          id="confirm-password"
          onChange={(event) => setComfirmPassword(event.target.value)}
        />
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => addNewPassword(id, confirmPassword)}>
          {showSpinner ? (
            <CircularProgress color="secondary" />
          ) : (
            <Link to="#" variant="body2" style={{ color: "#fff" }}>
              Recover Password
            </Link>
          )}
        </Button>
        {/* <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={() => addNewPassword(id, confirmPassword)}>
        <Link to="/" variant="body2">
          {showSpinner ? <CircularProgress color="secondary" /> : "Submit"}
        </Link>
      </Button> */}
        <Grid container justify="center">
          <Grid item>
            <p className={classes.link}>
              Already have an account?{" "}
              <Link to="/" variant="body2" className="color-pink">
                Sign In
              </Link>
            </p>
          </Grid>
        </Grid>
      </form>
    </Auth>
  );
};

function mapStateToProps(state) {
  return {
    id: state.login.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addNewPassword: (id, confirmPassword) => dispatch(addNewPassword(id, confirmPassword)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);
