import { combineReducers } from "redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";

import authReducer from "./authReduser";
import addCardReducer from "./addCardReducer";
import approvalReducer from "./approvalsReducer";
import campaignsReducer from "./campaignsReducer";
import dropCardsReducer from "./dropCardsReduser";
import toastReducer from "./toastReducer";
import adminReducer from "./adminReducer";
import * as actionCreators from '../actions/campaignsActions'; 

const composeEnhancers = composeWithDevTools({ actionCreators, trace: true, traceLimit: 25 });

const rootReducer = combineReducers({
  login: authReducer,
  card: addCardReducer,
  approvals: approvalReducer,
  campaigns: campaignsReducer,
  dropCards: dropCardsReducer,
  toast: toastReducer,
  admin: adminReducer,
});

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

