import { httpGetCall, httpPatchCall, httpDeleteCall } from "../../common/commonService";
const URL = process.env.REACT_APP_BASE_URL;

export const fetchCardTemplate = async (filter = null) => {
  try {
    let url = `${URL}/cards`;

    if (filter) url += `?filter=${JSON.stringify(filter)}`;
    let cardsResp = await httpGetCall(`${url}`);
    return cardsResp;
  } catch (err) {
    return err;
  }
};
export const searchCardTemplate = async (title) => {
  try {
    let filter = {
      where: {},
      order: ["date DESC"],
    };
    if (title) {
      filter.where.title = { like: `${title}` };
    }
    let cardsResp = await httpGetCall(`${URL}/cards?filter=${encodeURI(JSON.stringify(filter))}`);
    return cardsResp;
  } catch (err) {
    return err;
  }
};

export const updateCardTemplate = async (id, data) => {
  try {
    let updateResp = await httpPatchCall(`${URL}/cards/${id}`, data);
    return updateResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const deleteCardTemplate = id => {
  return httpDeleteCall(`${URL}/cards/${id}`);
}

export const fetchPendingCampaign = async (role, name = null) => {
  try {
    let filter = {
      where: {},
      order: ["expiresOn DESC"],
    };
    // if (role === "admin") {
    //   // filter.where = {}
    //   filter.where.status = "pending-verification";
    // }

    if (name) {
      filter.where.name = { like: `${name}` };
    }

    console.log("filter :>> ", filter);
    let campaignResp = await httpGetCall(`${URL}/campaigns?filter=${encodeURI(JSON.stringify(filter))}`);
    return campaignResp;
  } catch (err) {
    return err;
  }
};

export const updateCampaign = async (id, data) => {
  try {
    let updateResp = await httpPatchCall(`${URL}/campaigns/${id}`, data);
    return updateResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const deleteCampaign = async (id) => {
  return httpDeleteCall(`${URL}/campaigns/${id}`);
};

export const fetchPendingCardCollection = async (date, campaignId) => {
  try {
    let filter = {
      where: {
        and: [{ status: "pending" }, { createdAt: { gte: date.fromDate } }, { createdAt: { lte: date.toDate } }],
      },
      include: [{ relation: "campaign" }, { relation: "card" }],
      order: ["createdAt DESC"],
    };
    if (campaignId) {
      filter.where.and.push({ campaignId: campaignId });
    }
    let cardListResp = await httpGetCall(`${URL}/card-collections?filter=${JSON.stringify(filter)}`);
    return cardListResp;
  } catch (err) {
    return err;
  }
};

export const updateCardCollection = async (id, data) => {
  try {
    let updateResp = await httpPatchCall(`${URL}/card-collections/${id}`, data);
    return updateResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchCampaign = async () => {
  try {
    let filter = {
      include: [{ relation: "user" }],
      order: ["expiresOn DESC"],
    };

    let campaignResp = await httpGetCall(`${URL}/campaigns?filter=${encodeURI(JSON.stringify(filter))}`);
    return campaignResp;
  } catch (err) {
    return err;
  }
};
