import React from 'react';
import {
  TableHead,
  TableRow,
  Table,
  TableContainer,
  TableBody,
} from '@material-ui/core';
import moment from 'moment';
import { StyledTableCell } from '../../../../component/ui/TableUtils';
import ErrorBoundary from '../../../../component/errorBoundary/ErrorBoundary';

const ChestAnalyticsTable = ({ classes, rows }) => {
  return (
    <TableContainer className={classes.container}>
      <Table
        className={classes.table}
        stickyHeader
        aria-label='sticky table'
      >
        <TableHead>
          <TableRow>
            <StyledTableCell className={classes.tableheadcell}>
              Full name
            </StyledTableCell>
            <StyledTableCell className={classes.tableheadcell}>
              Card title
            </StyledTableCell>
            <StyledTableCell className={classes.tableheadcell}>
              Card value
            </StyledTableCell>
            <StyledTableCell className={classes.tableheadcell}>
              Card status
            </StyledTableCell>
            <StyledTableCell className={classes.tableheadcell}>
              Date
            </StyledTableCell>

            <StyledTableCell className={classes.tableheadcell}>
              Email
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ErrorBoundary>
            {Array.isArray(rows) &&
              rows.map((row, i) => (
                <TableRow
                  key={i}
                  className={'table-row-min-height'}
                >
                  <StyledTableCell
                    component='th'
                    scope='row'
                    className={classes.tablebodycell}
                  >
                    {row.owner.fullname || row.owner.firstname || ''}
                  </StyledTableCell>

                  <StyledTableCell
                    component='th'
                    scope='row'
                    className={classes.tablebodycell}
                  >
                    {row.card.title || ''}
                  </StyledTableCell>

                  <StyledTableCell
                    component='th'
                    scope='row'
                    className={classes.tablebodycell}
                  >
                    {row.card.cardValue || ''}
                  </StyledTableCell>

                  <StyledTableCell
                    component='th'
                    scope='row'
                    className={classes.tablebodycell}
                  >
                    {row.status || ''}
                  </StyledTableCell>

                  <StyledTableCell
                    component='th'
                    scope='row'
                    className={classes.tablebodycell}
                  >
                    {moment(row.createdAt).format('LLL')}
                  </StyledTableCell>
                  <StyledTableCell className={classes.tablebodycell}>
                    {row?.owner.email}
                  </StyledTableCell>
                </TableRow>
              ))}
          </ErrorBoundary>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ChestAnalyticsTable;
