import React, { useState, useEffect } from "react";
import { makeStyles, List, useMediaQuery, Button, FormControl, Select, MenuItem, LinearProgress, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import custom from "../../assets/images/custom-location.png"
import wheel from "../../assets/icons/dashboardWheel.png"
import wheelIcon from "../../assets/icons/dashBoardWheelIcon.png"
import offersIcon from "../../assets/icons/offersIcon.svg"
import chestIcon from "../../assets/images/timed-chest.png"
import Base from "./../../component/Layout/Base";
import { getLocations, getWheelData, getWheelDataQR, setLocationToWheel, getStatistics, getChests } from "../../redux/actions/campaignsActions";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import HomeOption from "./HomeOption";
import moment from "moment";
import { useHistory } from "react-router-dom";

function PerformanceDateSelect({ onSelect }) {
  const matches = useMediaQuery(`(max-width:500px)`);
  const [dateRange, setDateRange] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setDateRange(event.target.value);
    if (event.target.value !== 0) {
      onSelect(moment().subtract(event.target.value, 'days').format('x'));
    } else {
      onSelect(0);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const useStyles = makeStyles((theme) => ({
    root: {},
    select: {
      width: "150px",
      background: "#F0F0F0",
      boxSizing: "border-box",
      fontSize: "17px",
      height: "40px",
      color: "#000 !important",
      textAlign: "left",
      "&::before": {
        content: "''",
        display: "none",
      }
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <FormControl>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={dateRange}
          onChange={handleChange}
          className={classes.select}
          style={matches? {fontSize: "20px", width: "auto"} : null}
        > 
        <MenuItem value={1}>Today</MenuItem>
        <MenuItem value={7}>Last 7 days</MenuItem>
        <MenuItem value={14}>Last 14 days</MenuItem>
        <MenuItem value={30}>Last 30 days</MenuItem>
        <MenuItem value={0}>All Time</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

function Home({ isLoading, locations, getWheelData, getWheelDataQR, setLocationToWheel, stats, wheelType, chests }) {
  const matches = useMediaQuery(`(max-width:1280px)`);
  const matches2 = useMediaQuery(`(max-width:1024px)`);
  const matches3 = useMediaQuery(`(max-width:500px)`);
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {},
    container: {
      textAlign: "center",
      width: "100%",
      marginTop: "10px",
    },
    title: {
      fontSize: "32px",
      color: "#000000",
      textAlign: "center",
      margin: "0 auto",
      marginBottom: "60px",
      maxWidth: "400px",
    },
    list: {
      padding: "0",
      margin: "0 auto",
      maxWidth: "755px",
      display: "grid",
      gridGap: "25px",
      gridTemplateColumns: "repeat(auto-fit, minmax(235px, 1fr));",
      justifyItems: "center",
    },
    topContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      background: "#fff",
      padding: "40px 0 20px 0",
    },
    headerBlock: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "30px",
      padding: "0 10px",
      width: "474px",
      boxSizing: "border-box",
    },
    performanceContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridGap: "20px",
      margin: "0 0 15px 0",
    },
    performanceContainerM: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridGap: "15px",
      margin: "0 0 15px 0",
      width: "375px",
      padding: "10px",
      boxSizing: "border-box",
    },
    performance: {
      fontSize: "29px",
      fontWeight: "bold",
      lineHeight: "40px",
      whiteSpace: "nowrap",
    },
    statisticsBlock: {
      width: "227px",
      height: "97px",
      display: "flex",
      justifyContent: "space-between",
      background: "#F4F4F4",
      borderRadius: "7px",
      padding: "10px 25px 17px 15px",
      boxSizing: "border-box",
    },
    statisticsBlockM: {
      width: "168px",
      height: "75px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      background: "#F4F4F4",
      borderRadius: "7px",
      padding: "10px 20px 12px 15px",
      boxSizing: "border-box",
    },
    statisticsHeader: {
      fontSize: "22px",
      fontWeight: "bold",
    },
    statisticsValue: {
      fontSize: "32px",
      fontWeight: "400",
      textAlign: "left",
      alignSelf: "flex-end",
    },
    campaigns: {
      margin: "0 auto 80px auto",
      maxWidth: "755px",
      display: "grid",
      gridGap: "25px",
      gridTemplateColumns: "repeat(auto-fit, minmax(235px, 1fr));",
      justifyItems: "center",
    },
    campaign: {
      width: matches3 ? "165px" : "235px",
      height: matches3 ? "175px" : "190px",
      backgroundColor: "#fff",
      borderRadius: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.5)",
      paddingTop: matches3 ? "0px" : "12px",
      boxSizing: "border-box",
      cursor: "pointer",
      '&:hover': {
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgb(0 0 0)",
      }
    },
    campaignHeader: {
      fontSize: "22px",
      maxWidth: matches3? "155px" : "220px",
      textAlign: "center",
    },
    campaignImg: {
      width: "91px",
    },
    chestImg: {
      width: matches3 ? "91px" : "120px",
    },
    campaignSummary: {
      fontSize: "17px",
      marginBottom: "10px",
    },
    devider: {
      height: "50px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "0 auto",
      maxWidth: "755px",
    },
    deviderLine: {
      border: "1px solid #979797",
      width: "100%",
    },
    deviderText: {
      padding: "0 10px",
      whiteSpace: "nowrap",

    },
    topBanner: matches
      ? {
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          top: 0,
          textAlign: "center",
          backgroundColor: "red",
          padding: ".75rem",
          background: "linear-gradient(170deg, #4b478d, #cf2d86);",
        }
      : matches2
      ? {
          justifyContent: "center",
          width: "calc(100% - 325px)",
          alignItems: "center",
          top: 0,
          textAlign: "center",
          backgroundColor: "red",
          padding: ".75rem",
          background: "linear-gradient(170deg, #4b478d, #cf2d86);",
        }
      : {
          justifyContent: "center",
          alignItems: "center",
          top: 0,
          textAlign: "center",
          backgroundColor: "red",
          padding: ".75rem",
          background: "linear-gradient(170deg, #4b478d, #cf2d86);",
        },
    bannerTxt: matches2
      ? {
          color: "#fff",
          fontWeight: "bold",
          // display: "inline-block",
        }
      : {
          color: "#fff",
          fontWeight: "bold",
          display: "inline-block",
        },
    bannerButton: matches2
      ? {
          // position: "absolute",
          // right: "70px",
          width: "60%",
          top: "7px",
          color: "#fff",
          fontWeight: "bold",
          borderRadius: "10px",
          borderColor: "#fff",
          textTransform: "capitalize",
        }
      : {
          position: "absolute",
          right: "70px",
          top: "7px",
          color: "#fff",
          fontWeight: "bold",
          borderRadius: "10px",
          borderColor: "#fff",
          textTransform: "capitalize",
        },
    bannerCloseButton: {
      position: "absolute",
      right: "20px",
      top: 0,
      color: "#fff",
      fontWeight: "bold",
      padding: 0,
    },
  }));

  const classes = useStyles();
  const [showBanner, setShowBanner] = useState(false);
  const history = useHistory();
  const userData = useSelector((state) => {
    return state.login.userData || {};
  });

  useEffect(() => {
    const hasClosedBanner = localStorage.getItem("hasClosedBanner");
    if (hasClosedBanner) {
      setShowBanner(false);
    } else {
      if (Object.keys(userData).length > 0 && !userData.activePaymentDetail && userData.role !== "admin")
        setShowBanner(true);
      else setShowBanner(false);
    }
  }, [userData]);

  useEffect(() => {
    if (wheelType === "QR") {
      dispatch(getLocations("QR"));
    } else if ((wheelType === "none" || wheelType === "standard") && userData.id) {
      dispatch(getLocations());
      dispatch(getChests());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, wheelType, userData.id]);

  useEffect(() => {
    if (userData.id && wheelType !== "QR") {
      dispatch(getStatistics(userData.id, 0, 'main'));
    } else if (userData.id && wheelType === "QR") {
      dispatch(getStatistics(userData.id, 0, 'mainQR'));
    }
  }, [dispatch, userData, wheelType]);

  const closeBanner = () => {
    localStorage.setItem("hasClosedBanner", true);
    setShowBanner(false);
  };

  const seePricing = () => {
    history.push("/profile-settings/pricing");
  };

  const setRange = (range) => {
    if (wheelType === "QR") {
      dispatch(getStatistics(userData.id, range, 'mainQR'));
    } else {
      dispatch(getStatistics(userData.id, range, 'main'));
    }
  }

  const toWheel = (location) => {
    // getWheelData(location.campaignId);
    setLocationToWheel(location);
    history.push(`/campaign/edit?id=${location.campaignId}`);
  }

  const toWheelQR = (campaign) => {
    // getWheelDataQR(campaign.id);
    setLocationToWheel(campaign);
    history.push(`/campaign/edit?id=${campaign.id}&type=QR`);
  }

  const toChest = (chest) => {
    history.push(`/campaign/timed-chest/edit?id=${chest}`);
    // history.push(`/campaign/timed-chest`);
  }

  return (
    <Base theme={"light"}>
      {showBanner && (
        <div className={classes.topBanner}>
          <div className={classes.bannerTxt}>Free trial: {userData?.creditsRemaining || 0} offers remaining</div>

          <Button size="small" variant="outlined" className={classes.bannerButton} onClick={() => seePricing()}>
            See pricing
          </Button>
          <Button size="small" className={classes.bannerCloseButton} onClick={() => closeBanner()}>
            X
          </Button>
        </div>
      )}

      <div className={classes.topContainer}>
      <div>
          <div className={classes.headerBlock} style={matches3? {marginBottom: "10px", paddingTop: "65px", width: "375px"} : null}>
            <span className={classes.performance} style={matches3? {fontSize: "20px"} : null}>Performance summary</span>
            <PerformanceDateSelect onSelect={(value)=> setRange(value)}/>
          </div>
          {!stats ? <div style={matches3 ? {width: "40px", height: "290px", margin: "0 auto"} : {width: "40px", height: "346px", margin: "0 auto"}}><CircularProgress /></div> :
          <div className={matches3? classes.performanceContainerM : classes.performanceContainer}>
            <div className={matches3? classes.statisticsBlockM : classes.statisticsBlock}>
              <span className={classes.statisticsHeader} style={matches3? {fontSize: "16px"} : null}>{wheelType === "QR" ? "Wins:" : "Collects:"}</span>
              <span className={classes.statisticsValue} style={matches3? {fontSize: "20px"} : null}>{(stats.collects && stats.collects )|| (stats.wins && stats.wins)}</span>
            </div>
            <div className={matches3? classes.statisticsBlockM : classes.statisticsBlock}>
              <span className={classes.statisticsHeader} style={matches3? {fontSize: "16px"} : null}>Spins:</span>
              <span className={classes.statisticsValue} style={matches3? {fontSize: "20px"} : null}>{stats.spins && stats.spins}</span>
            </div>
            <div className={matches3? classes.statisticsBlockM : classes.statisticsBlock}>
              <span className={classes.statisticsHeader} style={matches3? {fontSize: "16px"} : null}>{wheelType === "QR" ? "New emails collected:" : "Link clicks:"}</span>
              <span className={classes.statisticsValue} style={matches3? {fontSize: "20px"} : null}>{(stats.linkClicks && stats.linkClicks )|| (stats.newEmails && stats.newEmails)}</span>
            </div>
            <div className={matches3? classes.statisticsBlockM : classes.statisticsBlock}>
              <span className={classes.statisticsHeader} style={matches3? {fontSize: "16px"} : null}>Redeems:</span>
              <span className={classes.statisticsValue} style={matches3? {fontSize: "20px"} : null}>{stats.redeems && stats.redeems}</span>
            </div>
            <div className={matches3? classes.statisticsBlockM : classes.statisticsBlock}>
              <span className={classes.statisticsHeader} style={matches3? {fontSize: "16px"} : null}>{wheelType === "QR" ? "Instagram link clicks:" : "Impressions:"}</span>
              <span className={classes.statisticsValue} style={matches3? {fontSize: "20px"} : null}>{(stats.impressions && stats.impressions) || (stats.instagram && stats.instagram)}</span>
            </div>
            <div className={matches3? classes.statisticsBlockM : classes.statisticsBlock}>
              <span className={classes.statisticsHeader} style={matches3? {fontSize: "16px"} : null}>{wheelType === "QR" ? "Facebook link clicks:" : "Spending:"}</span>
              <span className={classes.statisticsValue} style={matches3? {fontSize: "20px"} : null}>{(stats.facebook && stats.facebook) || 0}</span>
            </div>
            <div className={matches3? classes.statisticsBlockM : classes.statisticsBlock} style={{display: wheelType === "QR" ? "flex" : "none"}}>
              <span className={classes.statisticsHeader} style={matches3? {fontSize: "16px"} : null}>{wheelType === "QR" ? "Twitter link clicks:" : "Spending:"}</span>
              <span className={classes.statisticsValue} style={matches3? {fontSize: "20px"} : null}>{(stats.twitter && stats.twitter) || 0}</span>
            </div>
            <div className={matches3? classes.statisticsBlockM : classes.statisticsBlock} style={{display: wheelType === "QR" ? "flex" : "none"}}>
              <span className={classes.statisticsHeader} style={matches3? {fontSize: "16px"} : null}>{wheelType === "QR" ? "Cost:" : "Spending:"}</span>
              <span className={classes.statisticsValue} style={matches3? {fontSize: "20px"} : null}>0</span>
            </div>
          </div>
          }
        </div>
      </div>

      <div className={classes.container}>
        <h1 className={classes.title} style={matches3 ? {marginBottom: "25px"} : null}>What can we do for you today?</h1>
        <List className={classes.list} style={matches3 ? {gridTemplateColumns: "1fr 1fr", padding: "0 10px"} : null}>
          <Link to="gift-card">
            <HomeOption
              label={"Create a new offer"}
              optionImage={require("../../assets/images/home/create_new_offer.png")}
            />
          </Link>
          <Link to={wheelType === "QR" ? "campaign/URL" : "offers"}>
            <HomeOption
              label={wheelType === "QR" ? "Create new URL" : "Existing campaigns"}
              optionImage={wheelType === "QR" ? wheel : require("../../assets/images/home/existing_campaigns.png")}
              imageStyle={{ maxWidth: wheelType === "QR" ? "100px" : "80px" }}
            />
          </Link>
          <Link to={wheelType === "QR" ? "offers" : "campaign/new"}>
            <HomeOption
              label={wheelType === "QR" ? "Offers and campaigns analytics" : "Create a new campaign"}
              optionImage={wheelType === "QR" ? offersIcon : require("../../assets/images/home/create_new_campaign.png")}
              imageStyle={{maxWidth: wheelType === "QR" ? "90px" : "60px", marginTop: wheelType === "QR" ? "-20px" : "initial"}}
              textStyle={{marginTop: wheelType === "QR" ? "0px" : "initial"}}
            />
          </Link>
        </List>
      </div>

      <div className={classes.devider}>
        <div className={classes.deviderLine}></div>
        <span className={classes.deviderText}>{wheelType === "QR" ? "Campaigns" : "Custom locations"}</span>
        <div className={classes.deviderLine}></div>
      </div>
      {((wheelType === "QR" && isLoading ) || (wheelType !== "QR" && isLoading && !chests))? <LinearProgress /> :
      <div className={classes.campaigns} style={matches3 ? {gridTemplateColumns: "1fr 1fr", padding: "0 10px"} : null}>
        <div className={classes.campaign} style={{display: wheelType === "QR" ? "none" : "flex"}} onClick={() => history.push("/campaign/new")}>
          <img src={wheel} alt="" style={matches3? {width: "100px"} : {width: "auto"}}/>
          <span className={classes.campaignSummary}>Sponsor a location</span>
        </div>
        {locations.map((location, i) => {
          if (!location.campaignId && wheelType !== "QR") {
            return null;
          }
          return (
            <div className={classes.campaign} key={i} onClick={wheelType === "QR" ? ()=> toWheelQR(location) : () => toWheel(location)}>
              <span className={classes.campaignHeader}>{location.name}</span>
              <img src={wheelType === "QR" ? wheelIcon : custom} alt="" className={classes.campaignImg}/>
              <span className={classes.campaignSummary}>See summary</span>
            </div>
          )
        })}
        {chests.map((chest, i) => {
          if (wheelType === "QR") {
            return null;
          }
          return (
            <div className={classes.campaign} key={i} onClick={() => toChest(chest.id)}>
              <span className={classes.campaignHeader}>{chest.name ? chest.name : "Chest"}</span>
              <img src={chestIcon} alt="" className={classes.chestImg}/>
              <span className={classes.campaignSummary}>See summary</span>
            </div>
          )
        })}
    </div>}
    </Base>
  );
}

const mapStateToProps = (state) => ({
  locations: state.campaigns.locations,
  chests: state.campaigns.chests,
  isLoading: state.campaigns.isLoading,
  stats: state.campaigns.stats,
  wheelType: state.login.wheelType,
});

export default connect(mapStateToProps, { getWheelData, getWheelDataQR, setLocationToWheel })(Home);