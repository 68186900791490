import {
  httpPostCall,
  httpGetCall,
  httpPatchCall,
  httpDeleteCall,
} from "../../common/commonService";
const URL = process.env.REACT_APP_BASE_URL;

export const fetchLocations = async (id = null) => {
  try {
    const post = id ? id : "";
    let locations = await httpGetCall(`${URL}/locations/${post}`);
    return locations;
  } catch (err) {
    return err;
  }
};

export const addLocation = async (data) => {
  try {
    let location = await httpPostCall(`${URL}/locations/`, data);
    return location;
  } catch (err) {
    return err;
  }
};
export const updateLocation = async (id, data) => {
  try {
    let campaignResp = await httpPatchCall(`${URL}/locations/${id}`, data);
    return campaignResp;
  } catch (err) {
    return err;
  }
};
export const deleteLocation = async (id) => {
  try {
    let campaignResp = await httpDeleteCall(`${URL}/locations/${id}`);
    return campaignResp;
  } catch (err) {
    return err;
  }
};
