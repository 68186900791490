import React, { useEffect } from "react";
import { makeStyles, useMediaQuery, LinearProgress } from "@material-ui/core";
import { useHistory } from "react-router";
import moment from "moment";
import Base from "../../component/Layout/Base";
import { connect, useDispatch } from "react-redux";
import { selectOffer, setSliderIndex, getOffers, clearCurrentOffer } from "../../redux/actions/dropCardsActions";

const imgURL = `${process.env.REACT_APP_IMG_URL}/card-photo/`;

function Offers({ offers, selectOffer, setSliderIndex, isLoading, campaignsQuantity, templatesQuantity, clearCurrentOffer }) {
  const history = useHistory();
  const matches = useMediaQuery(`(max-width:500px)`);
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {},
    header: {
      background: "#fff",
      height: "76px",
      display: "flex",
      justifyContent: "space-between",
      padding: "0 94px 0 58px",
      alignItems: "center",
    },
    templates: {
      fontSize: "20px",
      fontWeight: "bold",
    },
    templateData: {
      marginLeft: "23px",
      fontSize: "20px",
    },
    templateDataM: {
      marginLeft: "20px",
      fontSize: "15px",
      display: "block",
    },
    offersContainer: {
      margin: "44px auto 0 auto",
      display: "flex",
      flexWrap: "wrap",
      justifyItems: "center",
      maxWidth: "1130px"
    },
    offer: {
      maxWidth: "484px",
      width: "100%",
      background: "#fff",
      borderRadius: "6px",
      padding: "28px 24px 16px 24px",
      display: "flex",
      marginBottom: "37px",
      marginLeft: "33px",
      cursor: "pointer",
      '&:hover': {
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgb(0 0 0)",
      }
    },
    offerM: {
      maxWidth: "484px",
      width: "100%",
      background: "#fff",
      borderRadius: "6px",
      padding: "15px",
      display: "flex",
      marginBottom: "20px",
      cursor: "pointer",
      '&:hover': {
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgb(0 0 0)",
      }
    },
    offerImg: {
      width: "151px",
      height: "106px",
      borderRadius: "7px",
    },
    offerImgM: {
      width: "135px",
      height: "80px",
      borderRadius: "7px",
    },
    offerStatus: {
      fontSize: "20px",
      display: "block",
      textTransform: "capitalize",
    },
    offerDataContainer: {
      marginLeft: "47px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    offerData: {
      fontSize: "20px",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (!offers) {
      dispatch(getOffers());
    }
  }, [offers, dispatch]);

  const toOffer = (id, index) => {
    clearCurrentOffer();
    selectOffer(id, index);
    setSliderIndex(index);
    history.push(`/offer?id=${id}`);
  }

  return (
    <Base theme={"light"}>
      {isLoading ? <LinearProgress/> : 
              <React.Fragment>
              <div className={classes.header} style={matches ? {padding: "0 10px 0 58px"} : null}>
              <span className={classes.templates} style={matches ? {fontSize: "15px"} : null}>All Templates</span>
              <div>
                <span className={classes.templateData} style={matches ? {display: "none"} : null}>Total:</span>
                <span className={matches ? classes.templateDataM : classes.templateData}>{`${templatesQuantity? templatesQuantity : 0} Templates`}</span>
                <span className={matches ? classes.templateDataM : classes.templateData}>{`${campaignsQuantity? campaignsQuantity : 0} Campaigns`}</span>
              </div>
            </div>
            {offers.length ? 
            <div className={classes.offersContainer} style={matches ? {margin: "20px auto"} : null}>
              {offers.map((offer, i) => {
                return (
                  <div className={matches ? classes.offerM : classes.offer} onClick={() => toOffer(offer.id, i)} key={i}>
                    <div>
                      <img src={`${imgURL}${offer.cardPhoto}`} alt="" className={classes.offerImg}/>
                <span className={classes.offerStatus}>{offer.status === 'active' ? 'Status Approved' : offer.status}</span>
                    </div>
                    <div className={classes.offerDataContainer}>
                <span className={classes.offerData}>Title: {offer.title}</span>
                <span className={classes.offerData}>{offer.campaignCount} campaigns</span>
                      <span className={classes.offerData}>Created: {moment(offer.date).format("MMM/DD/YYYY")}</span>
                      <span className={classes.offerData}>Win%: 1/{offer.winChanceDivider}</span>
                    </div>
                  </div>
                )
              })} 
            </div>
            :
              <p style={{fontSize: "30px", textAlign: "center"}}>No Offers. Create a new One</p>
            }
            </React.Fragment> 
      }
      
    </Base>
  );
}

const mapStateToProps = (state) => ({
  offers: state.dropCards.offers.cardWithAdditions,
  campaignsQuantity: state.dropCards.offers.campaigns,
  templatesQuantity: state.dropCards.offers.templates,
  isLoading: state.dropCards.isLoading,
});

export default connect(mapStateToProps, { selectOffer, setSliderIndex, clearCurrentOffer })(Offers);