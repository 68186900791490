import React, { Component } from "react";
import Base from "./../../component/Layout/Base";
import CampaignsList from "./List/CampaignsList";
import CampaignsDetail from "./Details/CampaignsDetail";
export default class GiftCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      currentCampaign: null,
    };
  }
  changeTab = (index) => {
    this.setState({ tabIndex: index });
  };
  getTabClass(index) {
    return index === this.state.tabIndex ? "view-show" : "view-hide";
  }
  setCurrentCampaign = (id) => {
    this.setState({ currentCampaign: id });
  };

  render() {
    return (
      <Base theme={"light"}>
        <div className={this.getTabClass(0)}>
          <CampaignsDetail
            setCurrentCampaign={this.setCurrentCampaign}
            previewList={() => this.changeTab(1)}
          />
        </div>
        {this.state.tabIndex === 1 && (
          <div className={this.getTabClass(1)}>
            <CampaignsList
              currentCampaign={this.state.currentCampaign}
              backToDetails={() => this.changeTab(0)}
            />
          </div>
        )}
      </Base>
    );
  }
}
