import React, { useState, useEffect, useCallback } from "react";
import {
  makeStyles,
  Button,
  TextField,
  Modal,
  Typography,
  Grid,
  Paper,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import validator from "validator";
import { TOAST } from "../../redux/actions/actions-types";
import { useHistory, useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { verifyEmail, resendEmailReq } from "../../redux/requests/authRequests";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    textAlign: "center",
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "hidden",
    backgroundColor: "#8888884f",
    flexDirection: "column",
  },
  layout: {
    backgroundColor: "#1c1524",
    width: "25%",
    height: "auto",
    borderRadius: "5px",
  },
  paper: {
    padding: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // height: "calc(100vh - 130px)",
    maxWidth: "500px",
    margin: "0 auto",
    justifyContent: "center",
  },
  title: {
    color: "#000",
    marginBottom: "2.5rem",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#3f51b5 !important",
  },
  description: {
    color: "#fff",
    textAlign: "center",
    marginBottom: "2.5rem",
  },
  paper2: {
    position: "absolute",
    width: 400,
    // backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    backgroundColor: "#1c1524",
  },
  paymentContainer: {
    marginBottom: "1rem",
  },
}));

const CssTextField = withStyles({
  root: {
    "& .MuiInput-input": {
      color: "#000",
      backgroundColor: "#fff",
      borderRadius: "5px",
      paddingLeft: "5px",
    },
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#888",
      borderBottomWidth: 0,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: "#000",
      },
      "&:hover fieldset": {
        // borderColor: "#000",
        borderBottomWidth: 0,
      },
      "&.Mui-focused fieldset": {
        // borderColor: "#000",
      },
    },
  },
})(TextField);
const VerifyEmail = () => {
  const classes = useStyles();
  const params = useParams();
  const [key, setKey] = useState(params.key);
  const [email, setEmail] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const [showResendLoader, setsShowResendLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const userData = useSelector((state) => state.login.userData);
  const toggleModal = () => {
    if (userData.email) {
      resendEmail(userData.email);
    } else {
      setShowModal(true);
    }
  };

  const resendEmail = async (email = userData.email) => {
    setsShowResendLoader(true);
    if (!email || !validator.isEmail(email)) {
      showToast("error", "Error", "Email not valid");
      setsShowResendLoader(false);

      return false;
    }
    let resendEmailResp = await resendEmailReq(email);
    if (resendEmailResp.name !== "Error") {
      setShowModal(false);
      showToast("success", "Done", "New key send to your email");
    }
    setsShowResendLoader(false);
  };

  const showToast = useCallback((type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  }, [dispatch]);

  const verify = useCallback(async () => {
    setShowLoading(true);

    let verifyResp = await verifyEmail(key, `${window.location.href}/${key}`);
    if (verifyResp.data) {
      if (verifyResp.data.emailVerified) {
        showToast("success", "Done", "Account verified");
        if (userData.email) {
          window.location.href = "/dashboard";
        } else {
          window.location.href = "/";
        }
      }
    }
    setShowLoading(false);
  }, [key, showToast, userData.email]);

  useEffect(() => {
    if (key && key.length === 6) {
      verify();
    }
  }, [key, verify]);

  const logout = () => {
    history.push("/logout");
  };
  return (
    <>
      <Grid container component="main" className={classes.root}>
        <Typography component="h2" className={classes.title} variant="h6">
          Please verify account
        </Typography>
        <Grid item className={classes.layout} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Typography component="h2" className={classes.description} variant="h6">
              Please enter 6 digit code sent to email
            </Typography>
            <form name="form-layout" className={classes.form} noValidate>
              <CssTextField
                onChange={(value) => setKey(value.nativeEvent.target.value)}
                disabled={showLoading}
                value={key}
                margin="normal"
                required
                fullWidth
                id="key"
                name="key"
                autoFocus
              />

              <Button
                // fullWidth
                size="sm"
                variant="contained"
                color="primary"
                //   className={classes.submit}
                onClick={() => toggleModal()}>
                {showResendLoader && userData?.email ? <CircularProgress /> : "Resend"}
              </Button>
              {userData && userData.email && (
                <Button
                  ml={3}
                  style={{ marginLeft: "10px" }}
                  size="sm"
                  variant="contained"
                  color="primary"
                  //   className={classes.submit}
                  onClick={logout}>
                  Log out
                </Button>
              )}
            </form>
          </div>
        </Grid>
      </Grid>
      <div className={classes.paymentContainer}>
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <div style={modalStyle} className={classes.paper2}>
            <Typography component="h2" className={classes.description} variant="h6">
              Enter your email
            </Typography>
            <p id="simple-modal-description">
              <CssTextField
                onChange={(value) => setEmail(value.nativeEvent.target.value)}
                value={email}
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                autoFocus
              />
            </p>
            <Button
              // fullWidth
              size="sm"
              variant="contained"
              color="primary"
              disabled={showResendLoader}
              //   className={classes.submit}
              onClick={() => resendEmail(email)}>
              {showResendLoader && !userData?.email ? <CircularProgress /> : "Resend"}
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default VerifyEmail;
