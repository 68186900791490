import React, { useEffect } from "react";
import { makeStyles, useMediaQuery, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { clearState } from "../../redux/actions/campaignsActions";
import Base from "../../component/Layout/Base";
import * as QRCode from 'qrcode.react';

function QRcode() {
  const wheelID = useSelector((state) => state.campaigns.QRwheelID);
  const name = useSelector((state) => state.campaigns.names.qrUrlPart);
  const matches = useMediaQuery(`(max-width:500px)`);
  const history = useHistory();
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {},
    container: {
      textAlign: "center",
      width: "100%",
      marginTop: "10px",
      marginBottom: "80px",
      display: "flex",
      justifyContent: "center",
    },
    wrapper: {
    background: "#fff",
    boxSizing: "border-box",
    width: "375px",
    height: "auto",
    borderRadius: "51px",
    padding: "10px 0 20px 0",
    display: "flex",
    flexDirection: "column",
    },
    bottomBtn: {
      textTransform: "none !important",
      background: "linear-gradient(131.04deg, #4B478D 13.29%, #CA2E86 100%) !important",
      fontSize: "20px !important",
      color: "#fff !important",
      padding: "0px 25px !important",
      boxSizing: "border-box !important",
      height: "55px",
      borderRadius: "25px !important",
      width: "170px !important",
      margin: "30px auto 0 auto !important",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (!wheelID) {
      history.push("/dashboard");
      dispatch(clearState());
    } 
  }, [wheelID, history, dispatch]);

  const close = () => {
    dispatch(clearState());
    history.push("/dashboard");
  }

  return (
    <Base theme={"light"}>
      <div className={classes.container}>
        <div className={matches ? classes.wrapper : "campaign-wrapper"} style={{height: "auto", marginTop: "82px"}}>
          <h1 className="campaign-header">Your QR code</h1>
          <div style={{margin: "auto"}}>
            <QRCode
              includeMargin={true}
              size={350}
              value={`https://spinforstuff.com/${name}?id=${wheelID}`}
              renderAs="svg"
            />
          </div>
          <p>{`Direct link https://spinforstuff.com/${name}?id=${wheelID}`}</p>
          <Button className={matches ? classes.bottomBtn : "campaign-bottomBTN"} variant="contained"
            onClick={() => close()}
          >Done</Button>
        </div>
      </div>
    </Base>
  );
}

export default QRcode;
