import React from "react";
import { makeStyles, Button, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router";
import Base from "../../../component/Layout/Base";
import targetted from "../../../../src/assets/images/drop-box-standard.png"
import custom from "../../../../src/assets/images/custom-location.png"
import chest from "../../../../src/assets/images/timed-chest.png"

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    height: "65px",
    fontSize: "16px"
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

function NewCampaign() {
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    root: {},
    container: {
      textAlign: "center",
      width: "100%",
      marginTop: "10px",
      marginBottom: "80px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    wrapper: {
      background: '#fff',
      width: "300px",
      height: "220px",
      borderRadius: "20px",
      position: "relative",
      boxSizing: "border-box",
      padding: "25px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "30px",
      cursor: "pointer",
      '&:hover': {
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgb(0 0 0)",
      }
    },
    title: {
      fontSize: "35px",
      lineHeight: "41px",
      color: "#000000",
      textAlign: "center",
      margin: "0 0 68px",
    },
    button: {
      fontSize: "22px",
      padding: "0",
      textTransform: "none",
    },
    helper: {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      background: "#000",
      color: "#fff",
      fontSize: "20px",
      position: "absolute",
      bottom: "20px",
      minWidth: "auto",
      right: "11px",
      '&:hover': {
        background: "#000",
      },
    }
  }));
  const classes = useStyles();
  return (
    <Base theme={"light"} topGap>
      <div className={classes.container}>
          <h1 className={classes.title}>Choose type of campaign</h1>
        <div className={classes.wrapper} onClick={() => history.push("/drop-card/standard-gift")}>
          <img src={targetted} alt="" width="136px"/>
          <span className={classes.button}>Targetted locations</span>
          <BootstrapTooltip arrow title="Target locations based on city or country. Gifts will be distributed randomly across locations." placement="right"><Button  variant="contained" className={classes.helper}>?</Button></BootstrapTooltip>
        </div>
        <div className={classes.wrapper} onClick={() => history.push("/campaign/location")}>
          <img src={custom} alt="" width="136px"/>
          <span className={classes.button}>Custom location</span>
          <BootstrapTooltip arrow title="Target your business location. Add gift card offers to your sponsored location in a virtual spin wheel game." placement="right"><Button  variant="contained" className={classes.helper}>?</Button></BootstrapTooltip>
        </div>
        <div className={classes.wrapper} onClick={() => history.push("/campaign/timed-chest")}>
          <img src={chest} alt="" width="136px"/>
          <span className={classes.button}>Countdown Chest</span>
          <BootstrapTooltip arrow title="Countdown Chest." placement="right"><Button  variant="contained" className={classes.helper}>?</Button></BootstrapTooltip>
        </div>
      </div>
    </Base>
  );
}

export default NewCampaign;
