import { ADD_CARD_SUCCESS } from '../actions/actions-types'

const initialState = {
    userCard: {}
}

export default function addCardReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_CARD_SUCCESS:
            return {
                ...state,
                ...action.userCard
            }
        default:
            return state
    }
}