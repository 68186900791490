import * as types from '../actions/actions-types'

const initialState = {
    pendingCards: [],
    collectCards: []
}

export default function approvalReducer(state = initialState, action) {
    switch (action.type) {
        case types.PENDING_CARD:
            return {
                ...state,
                pendingCards: action.pendingCards
            }
        case types.COLLECT_CARD:
            return {
                ...state,
                collectCards: action.collectCards
            }
        case types.MANAGE_RESULT:
            return {
                ...state,
                approveResult: "success"
            }
        default:
            return state
    }
}
