import React from 'react';

const TierInput = ({ value }) => {
  return (
    <div className={'offer-input-container'}>
      <label className={'noselect'}>Tier:</label>
      <div className={'input-area'}>
        <input
          className={'gift-card-number-input'}
          style={{ color: 'black' }}
          type={'number'}
          value={value}
          disabled
        />
      </div>
    </div>
  );
};

export default TierInput;
