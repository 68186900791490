import React from "react";
import DesignOfferReview from "../../GiftCard/DesignOffer/DesignOfferReview";

function CardDetails({ details }) {
  return (
    <div>
      <DesignOfferReview details={details} viewOnly={true} />
    </div>
  );
}

CardDetails.propTypes = {};

export default CardDetails;
