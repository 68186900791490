import React from "react";
import { makeStyles, Box, Paper, Grid, useMediaQuery } from "@material-ui/core";
import Copyright from "./include/Copyright";
import coverPhoto from "../../assets/images/cover-photo.jpeg";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "hidden",
    backgroundColor: "#F0F0F0",
  },
  image: {
    backgroundImage: `url(${coverPhoto})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    padding: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // height: "calc(100vh - 130px)",
    maxWidth: "500px",
    margin: "0 auto",
    justifyContent: "center",
  },
  layout: {
    backgroundColor: "#1c1524",
    width: "25%",
    height: "90vh",
    padding: "0",
    overflow: "hidden",
  },
  layoutResp1025: {
    // width: "40%",
    // height: "80%",
    backgroundColor: "#1c1524",
    paddingTop: "3%",
    // padding: "0",
    overflow: "hidden",
    overflowY: "auto",
    alignItems: "center",
    justifyContent: "center",
  },
  layoutResp767: {
    width: "100%",
    height: "100%",
    backgroundColor: "#1c1524",
    padding: "0",
    overflow: "hidden",
    overflowY: "auto",
    alignItems: "center",
    justifyContent: "center",
  },
  copyRight: {
    // position: "fixed",
    top: "80%",
  },
}));

export default function Auth(props) {
  const classes = useStyles();
  const matches = useMediaQuery(`(max-width:767px)`);
  const matches2 = useMediaQuery(`(max-width:1025px)`);
  return (
    <Grid container component="main" className={classes.root}>
      {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}
      <Grid
        item
        className={matches ? classes.layoutResp767 : matches2 ? classes.layoutResp1025 : classes.layout}
        component={Paper}
        elevation={6}
        square>
        <div className={classes.paper}>
          {props.children}
          <Box className={classes.copyRight} mt={2}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
