import React from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import customStyle from "./card.module.css";
import validator from "validator";

const useStyles = makeStyles((theme) => ({
  rounded: {
    borderRadius: "12px",
  },
  margin: {
    marginTop: "5px",
  }
}));

const GiftCard = ({
  side,
  isApproved,
  cardPhoto,
  title,
  onChangeTitle,
  logoPhoto,
  details,
  code,
  promoteUrl,
  onChangeDescription,
  step,
  rounded,
  shadow,
  viewDetails,
  onUploadLogo,
  onRemoveLogo,
  view,
  generateThumbnail,
  error,
  margin,
  standardGift,
}) => {
  const classes = useStyles();
  const style = {
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const styleM = {
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "300px",
    height: "180px",
  }
  const matches = useMediaQuery(`(max-width:500px)`);
  if (cardPhoto !== null && side === "front" && !standardGift) {
    style.backgroundImage = `url('${cardPhoto}')`;
    styleM.backgroundImage = `url('${cardPhoto}')`;
  } else if (cardPhoto !== null && side === "front" && standardGift) {
    style.backgroundImage = `url('${process.env.REACT_APP_IMG_URL}/card-photo/${cardPhoto}')`;
    styleM.backgroundImage = `url('${process.env.REACT_APP_IMG_URL}/card-photo/${cardPhoto}')`;
  }

  const validateUrl = () => {
    if (!promoteUrl) {
      return false;
    } else if (!validator.isURL(promoteUrl)) {
      // showError("Invalid promo url");
      return false;
    }
    return true;
  };

  return (
    <div
      className={`gift-card ${rounded === true && classes.rounded} 
      ${shadow === true && "shadow"} ${margin && classes.margin}`}
      style={matches? styleM : style}>
      {side === "front" && (
        <div>
          <div className={`card-logo ${view && "no-background"}`} style={matches? {top: "7px"} : null}>
            {logoPhoto ? (
              <img
                src={
                  logoPhoto.includes(window.location.protocol)
                    ? logoPhoto
                    : logoPhoto
                }
                alt=""
                style={{ height: "50px", width: "50px", borderRadius: "50%" }}
              />
            ) : (
              !view && <p onClick={onUploadLogo}> Show Logo</p>
            )}
            {logoPhoto && onRemoveLogo && (
              <span onClick={onRemoveLogo}>
                <FontAwesomeIcon icon={faTimes} color={"#fff"} size={"sm"} />
              </span>
            )}
          </div>

          <div className={`${!view ? "create-a-title" : "view-title"}`} style={error? {border: "3px solid red", boxSizing: "border-box"} : null}>
            {!view ? (
              <input placeholder={"Enter title of offer"} value={title} onChange={onChangeTitle} />
            ) : (
              <p className="card-title">{title}</p>
            )}
          </div>
        </div>
      )}
      {side === "back" && (
        <>
          <div className={`card-description ${view && "light-background"} `}>
            {!view ? (
              <textarea
                placeholder={"Enter offer details…"}
                onChange={onChangeDescription}
                maxLength={1500}
                className={customStyle.inputField}
                id="inputField"
                style={{
                  overflowY: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                readOnly={step === 2}
                value={details}>
                {details}
              </textarea>
            ) : (
              <p>{details ? details : "Write details of your offer"}</p>
            )}
          </div>

          {(step === 1 || step === 3) && (
            <div className={"card-checkout-section"}>
              <p>
                Use code <span>{code}</span> during checkout
              </p>
              {validateUrl() ? (
                <a href={promoteUrl} target={"_blank"} rel="noopener noreferrer">
                  Learn more
                </a>
              ) : (
                <a href={promoteUrl} disabled> Learn more</a>
              )}
            </div>
          )}
        </>
      )}
      {/* {view === true && viewDetails === true && (
        <div className={"tap-to-view-details"}>
          <p>Tap to view details</p>
        </div>
      )} */}
    </div>
  );
};

export default GiftCard;
