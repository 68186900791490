import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';

function SwitchInput({
  name,
  title,
  checked,
  handleChange,
  disabled,
  size = 'large',
  fontSize,
}) {
  let height = size === 'large' ? 40 : 30;
  let width = size === 'large' ? 80 : 60;
  let translate = size === 'large' ? '42px' : '32px';

  const IOSSwitch = withStyles(theme => ({
    root: {
      width: width,
      height: height,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 0,
      '&$checked': {
        transform: `translateX(${translate})`,
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.grey[400],
          opacity: 1,
          border: 'none',
        },
        '& + $thumb': {
          backgroundColor: '#66f875',
        },
      },
      '&$focusVisible $thumb': {
        border: '6px solid #fff',
      },
    },
    thumb: {
      backgroundColor: theme.palette.grey[400],
      width: height - 3,
      height: height - 3,
      '.Mui-checked &': {
        backgroundColor: '#66f875',
      },
    },
    track: {
      borderRadius: width / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      height: '90%',
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        inputProps={{ 'aria-label': 'primary checkbox' }}
        {...props}
      />
    );
  });

  const useStyles = makeStyles(() => ({
    label: {
      fontSize: fontSize || 'initial',
      fontWeight: 'bold',
      marginTop: '20px',
    },
  }));

  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{ label: classes.label }}
      control={
        <IOSSwitch
          disabled={disabled}
          checked={checked}
          onChange={e => handleChange(name, e.target.checked)}
          name={name}
          color='secondary'
        />
      }
      label={title}
      labelPlacement='top'
    />
  );
}

export default SwitchInput;
