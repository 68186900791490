import * as types from "./actions-types";
import { checkLogin } from "../requests/authRequests";

// Saga
export const loginUser = (email, password) => ({
  type: types.LOGIN_USER,
  email,
  password,
});

export const singup = (firstName, lastName, email, password, company) => ({
  type: types.SINGUP_USER,
  firstName,
  lastName,
  email,
  password,
  company,
});

export const forgotPass = (email) => ({
  type: types.FORGOT_PASSWORD,
  email,
});

export const addNewPassword = (id, password) => ({
  type: types.CHANGE_PASSWORD,
  id,
  password,
});

//actions

export const logout = () => {
  localStorage.clear();
  return {
    type: types.LOGIN_LOGOUT,
  };
};

export const loginSuccess = (accessToken) => {
  return {
    type: types.LOGIN_SUCCESS,
    accessToken,
  };
};

export const setUserData = (data) => {
  return {
    type: types.SET_USER_DATA,
    payload: data,
  };
};

export const verificateEmail = (verifcate) => {
  return {
    type: types.SIGN_UP_SUCCESS,
    verifcate: verifcate,
  };
};

export const recoveryPassword = (id) => {
  return {
    type: types.RECOVERY_PASSWORD,
    id,
  };
};

export const errorUser = (error) => {
  return {
    type: types.ERROR,
    error,
  };
};

export const checkLoginAction = () => async (dispatch) => {
  try {
    let loginResp = await checkLogin();
    if (!loginResp.data) {
      window.location.href = "/";
    } else {
      dispatch(loginSuccess(localStorage.getItem("access-token")));
      dispatch(setUserData(loginResp.data));

      if (!loginResp.data.emailVerified && !window.location.pathname.includes("verify-email")) {
        return (window.location.href = "/verify-email");
      } else if (window.location.pathname === "/" && loginResp.data.emailVerified) {
        return (window.location.href = "/dashboard");
      } else if (window.location.pathname.includes("verify-email") && loginResp.data.emailVerified) {
        return (window.location.href = "/dashboard");
      }
    }
  } catch (err) {
    return err;
  }
};
